import React from 'react'

import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid } from '@mui/material'
import { TypeDommage } from 'medecin/store/types/rapportConclusionMedecin'
import { useFormContext } from 'react-hook-form'
import { DOMMAGES_RANGE_MARKS } from 'plateforme/constantes'
import { BluePaper, Loading } from 'plateforme/components'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import determineSuffixeLibelleDommage from 'plateforme/services/dommage.services'
import DommageFieldCommentaire from './DommageFieldCommentaire'
import DommageFieldPeriode from './DommageFieldPeriode'
import DommageFieldRadioGroup from './DommageFieldRadioGroup'
import DommageFieldSlider from './DommageFieldSlider'
import DommageFieldPourcentage from './DommageFieldPourcentage'
import DommageFieldTiercePersonne from './DommageFieldTiercePersonne'
import { DommageData, DommagesData } from '../FicheRapportMedecinForm'

interface DommagesFieldsProps {
  typeDommage: TypeDommage.DEFINITIF | TypeDommage.TEMPORAIRE
  readOnly: boolean
  typeConclusion: TypeRapport
}

function dommageComponent(code: string, readOnly: boolean, typeConclusion: TypeRapport) {
  switch (code) {
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_PROFESSIONNELLES':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SCOLAIRES_UNIVERSITAIRES_OU_DE_FORMATION':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_AGREMENT':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SEXUELLES':
    case 'INSTITUTIONNALISATION':
    case 'FRAIS_FUTURS_A_CARACTERE_CERTAIN_ET_PREVISIBLE':
    case 'FRAIS_APPAREILLAGE':
    case 'AMENAGEMENT_DU_DOMICILE':
    case 'AMENAGEMENT_DU_VEHICULE':
    case 'FRAIS_MEDICAUX_ET_PARAMEDICAUX':
    case 'DOMMAGE_ESTHETIQUE_TEMPORAIRE':
      return <DommageFieldCommentaire name={`dommages.${code}`} code={code} readOnly={readOnly} />

    case 'DATES_HOSPITALISATION_IMPUTABLES':
    case 'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES':
      return <DommageFieldPeriode name={`dommages.${code}`} code={code} readOnly={readOnly} max={5} />

    case 'GENE_TEMPORAIRE_TOTALE':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_1':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_2':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_3':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_4':
      return (
        <DommageFieldRadioGroup
          typeConclusion={typeConclusion}
          name={`dommages.${code}`}
          code={code}
          readOnly={readOnly}
          max={5}
        />
      )

    case 'ATTEINTE_A_INTEGRITE_PHYSIQUE_ET_PSYCHIQUE':
      return <DommageFieldPourcentage name={`dommages.${code}`} code={code} readOnly={readOnly} />

    case 'DOMMAGE_ESTHETIQUE_PERMANENT':
    case 'SOUFFRANCES_ENDUREES':
      return (
        <DommageFieldSlider
          name={`dommages.${code}`}
          code={code}
          marks={DOMMAGES_RANGE_MARKS}
          min={0.5}
          max={7}
          readOnly={readOnly}
        />
      )

    case 'TIERCE_PERSONNE_DEFINITIVE':
    case 'TIERCE_PERSONNE_TEMPORAIRE':
      return <DommageFieldTiercePersonne name={`dommages.${code}`} code={code} readOnly={readOnly} max={5} />

    default:
      return null
  }
}

const dommagesuffixe = (code: string, typeConclusion: TypeRapport): string => {
  switch (code) {
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_PROFESSIONNELLES':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SCOLAIRES_UNIVERSITAIRES_OU_DE_FORMATION':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_AGREMENT':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SEXUELLES':
    case 'INSTITUTIONNALISATION':
    case 'FRAIS_FUTURS_A_CARACTERE_CERTAIN_ET_PREVISIBLE':
    case 'FRAIS_APPAREILLAGE':
    case 'AMENAGEMENT_DU_DOMICILE':
    case 'AMENAGEMENT_DU_VEHICULE':
    case 'FRAIS_MEDICAUX_ET_PARAMEDICAUX':
    case 'DOMMAGE_ESTHETIQUE_TEMPORAIRE':
    case 'DATES_HOSPITALISATION_IMPUTABLES':
    case 'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES':
    case 'GENE_TEMPORAIRE_TOTALE':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_1':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_2':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_3':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_4':
    case 'TIERCE_PERSONNE_DEFINITIVE':
    case 'TIERCE_PERSONNE_TEMPORAIRE':
      return ''
    case 'ATTEINTE_A_INTEGRITE_PHYSIQUE_ET_PSYCHIQUE':
      return determineSuffixeLibelleDommage(typeConclusion)
    case 'DOMMAGE_ESTHETIQUE_PERMANENT':
    case 'SOUFFRANCES_ENDUREES':
      return determineSuffixeLibelleDommage(typeConclusion)

    default:
      return ''
  }
}

export default function DommagesFields({ typeDommage, readOnly, typeConclusion }: DommagesFieldsProps) {
  const { clearErrors, getValues, setValue, resetField } = useFormContext()

  const dommagesForm = getValues('dommages') as DommagesData

  if (!dommagesForm) {
    return <Loading />
  }

  const removeDommages = (codeToRemove: string) => {
    const field = `dommages.${codeToRemove}`
    clearErrors(field)
    resetField(field)
    setValue(field, undefined)
  }

  const dommages: DommageData[] = Object.keys(dommagesForm)
    .filter((code) => dommagesForm[code] as DommageData)
    .map((code) => dommagesForm[code] as DommageData)
    .filter((dommage) => dommage.typeDommage === typeDommage)
    .filter((dommage) => dommage.code && dommage.label)

  if (dommages.length === 0) {
    return (
      <Grid container marginBottom={3}>
        <Grid item xs={12}>
          <BluePaper sx={{ fontStyle: 'italic', paddingTop: 3.5, textAlign: 'center' }}>Aucun dommage</BluePaper>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container marginBottom={3}>
      {dommages.map((dommage) => (
        <Grid item xs={12} key={dommage.code}>
          <BluePaper>
            <Grid container>
              <Grid item xs={12} lg={3}>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel
                      label={dommage.label + dommagesuffixe(dommage.code, typeConclusion)}
                      sx={{ minHeight: 55 }}
                      control={
                        <Checkbox
                          disabled={readOnly}
                          name={dommage.code}
                          checked
                          onClick={() => {
                            removeDommages(dommage.code)
                          }}
                          sx={{ padding: 0, marginX: 1.5, display: 'flex', alignItems: 'flex-start' }}
                        />
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={9}>
                {dommageComponent(dommage.code, readOnly, typeConclusion)}
              </Grid>
            </Grid>
          </BluePaper>
        </Grid>
      ))}
    </Grid>
  )
}
