import React from 'react'
import { useGetHistoriqueDossierQuery } from 'medecin/store/apis/dossierMedecinApi'
import { useErrorHandler } from 'react-error-boundary'
import { AlertErreur, AreaLoading } from 'plateforme/components'
import DossierMedecin, { HistoriqueDossierMedecin } from 'medecin/store/types/dossierMedecin'
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { formatDateTimeFR } from 'plateforme/services/dates.services'
import InformationIconButton from 'plateforme/components/buttons/InformationIconButton'
import { HistoriqueAction } from 'assureur/store/types/dossierEntreprise'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import showPropertiesChanged from 'plateforme/services/historique.service'

type TabHistoriqueDossierPartProps = {
  dossier?: DossierMedecin
  loading?: boolean
}

export default function TabHistoriqueDossierMedecinPart({ dossier, loading }: TabHistoriqueDossierPartProps) {
  const {
    data: historiquesDossierMedecin,
    error,
    isError,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetHistoriqueDossierQuery({ codeDossier: dossier?.code ?? '' }, { skip: !dossier?.code })

  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()

  // Handling des erreurs de chargement des donnees
  useErrorHandler(error)

  if (loading || isLoading || isFetching) {
    return <AreaLoading height={224} />
  }

  if (isError || !isSuccess) {
    return <AlertErreur title="Erreur du chargement de l'historique" error={JSON.stringify(error)} />
  }

  if (!historiquesDossierMedecin) {
    return (
      <Alert severity="info" sx={{ width: '100%' }}>
        Aucune donnée remontée.
      </Alert>
    )
  }

  return (
    <Card>
      {confirmDialog}
      <CardHeader title="Historique du dossier" />
      <CardContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '15%' }}>Date / heure</TableCell>
                <TableCell sx={{ width: '15%' }}>Mission</TableCell>
                <TableCell sx={{ width: '15%' }}>Utilisateur</TableCell>
                <TableCell sx={{ width: '25%' }}>Action</TableCell>
                <TableCell sx={{ width: '30%' }}>Détails</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historiquesDossierMedecin?.map((row: HistoriqueDossierMedecin) => (
                <TableRow key={`${row.action}_${row.dateAction}`}>
                  <TableCell sx={{ width: '15%' }}>{formatDateTimeFR(row.dateAction)}</TableCell>
                  <TableCell sx={{ width: '15%' }}>{row.codeMission}</TableCell>
                  <TableCell sx={{ width: '15%' }}>
                    <Stack direction="column" margin={0} padding={0} spacing={0}>
                      <Typography variant="body2" color="text" minWidth={82}>
                        {row.partenaire?.libelle}
                      </Typography>
                      <Typography variant="body2" color="readOnly.main" minWidth={82}>
                        {row.utilisateur?.libelle}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ width: '25%' }}>{row.actionLabel}</TableCell>
                  <TableCell sx={{ width: '30%' }}>
                    {row.action === HistoriqueAction.MODIFIER_DOSSIER_ENTREPRISE && (
                      <InformationIconButton
                        color="secondary"
                        onClick={() =>
                          showPropertiesChanged(
                            row.actionLabel ?? `Modification par l'entreprise`,
                            row.propertiesChanged ?? [],
                            confirm,
                            closeConfirmDialog
                          )
                        }
                      />
                    )}
                    {row.codeDocument && (
                      <Stack direction="row" margin={0} padding={0} spacing={0}>
                        <Typography variant="body2" color="readOnly.main" minWidth={82}>
                          {'Document : '}
                        </Typography>
                        <Typography variant="body2" color="text.primary">
                          {row.codeDocument}
                        </Typography>
                      </Stack>
                    )}
                    {row.codeFacture && (
                      <Stack direction="row" margin={0} padding={0} spacing={0}>
                        <Typography variant="body2" color="readOnly.main" minWidth={82}>
                          {'Facture : '}
                        </Typography>
                        <Typography variant="body2" color="text.primary">
                          {row.codeFacture}
                        </Typography>
                      </Stack>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}
