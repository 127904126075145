import * as React from 'react'
import MenuBaseButton, { MenuBaseButtonProps } from 'plateforme/components/menu/MenuBaseButton'
import ParametresIcon from '../icons/ParametresIcon'

export default function ParametresMenuButton(props: MenuBaseButtonProps) {
  const { icon, tooltip, ...other } = props
  return (
    <MenuBaseButton icon={icon ?? <ParametresIcon color="primary" />} tooltip={tooltip ?? 'Paramètres'} {...other} />
  )
}
