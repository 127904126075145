import React, { useEffect } from 'react'
import { Box, Stack, Typography } from '@mui/material'

import NavigationPart from 'plateforme/parts/NavigationPart'
import { SelectInput } from 'plateforme/components'
import { Controller, useForm } from 'react-hook-form'
import { SelectOption } from 'plateforme/components/inputs/SelectInput'
import { useLazyGetRevisionDossierQuery } from 'assureur/store/apis/dossierAssureurApi'
import { SearchParamsNames } from 'plateforme/hooks/useTabNavigate'
import { useSearchParams } from 'react-router-dom'
import { setDossierRevision, setSelectedMission } from 'assureur/store/slices/selectedMission/selectedMissionSlice'
import { useDispatch } from 'react-redux'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import { RevisionDossierRequest } from 'plateforme/store/types/dossier'

interface NavigationDossierPartProps {
  libelleReferenceDossier?: string
  complementReferenceDossier?: string
  isLoading?: boolean
  missions?: [MissionEntreprise]
  codeDossier?: string
}

export default function NavigationDossierPart({
  libelleReferenceDossier,
  complementReferenceDossier,
  isLoading,
  missions,
  codeDossier,
}: NavigationDossierPartProps) {
  const [trigger, result] = useLazyGetRevisionDossierQuery()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { control } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })

  useEffect(() => {
    dispatch(setDossierRevision(result?.data))
  }, [dispatch, result?.data])

  const handleOnChangeMission = (code: string) => {
    trigger({
      codeMission: code,
      codeDossier,
    } as RevisionDossierRequest)
    dispatch(
      setSelectedMission({
        code,
      })
    )
  }

  const selectOptionsMissions = missions?.map((m) => ({
    code: m.code,
    label: `Mission ${m.code} <${m.statut}> - ${m.medecin?.libelle}`,
  }))

  const isCurrentTabDetails = searchParams.get(SearchParamsNames.ONGLET) === 'details'

  return (
    <NavigationPart isLoading={isLoading} height={isCurrentTabDetails && missions ? 80 : 61} fullWidth>
      <Stack direction="row" spacing={0} alignItems="center" overflow="clip" width="70%">
        <Typography variant="subtitle1" noWrap>
          Détail du dossier&nbsp;
        </Typography>
        <Typography variant="h2" color="primary" whiteSpace="nowrap">
          {libelleReferenceDossier}
        </Typography>
        <Typography variant="subtitle1" noWrap>
          {complementReferenceDossier ? ` |  ${complementReferenceDossier} ` : ''}
        </Typography>
      </Stack>
      {isCurrentTabDetails && missions && (
        <Box>
          <Stack direction="row" justifyContent="end">
            <Controller
              name="mission"
              defaultValue={missions?.at(0)?.code}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectInput
                  fullWidth
                  id="service"
                  label="Voir les données d’une mission précédente du dossier"
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    handleOnChangeMission(e)
                    onChange(e)
                  }}
                  options={selectOptionsMissions as SelectOption[]}
                  fieldError={error}
                />
              )}
            />
          </Stack>
        </Box>
      )}
    </NavigationPart>
  )
}
