import searchCriteriaReducer, { searchCriteriaSliceName } from './slices/searchCriteriaSlice/searchCriteriaAdminSlice'
import { actualiteAdminApi } from './apis/actualiteAdminApi'
import { dossierAdminApi } from './apis/dossierAdminApi'
import { gestionEntrepriseApi } from './apis/gestionEntrepriseApi'
import { gestionMedecinApi } from './apis/gestionMedecinApi'
import { habilitationEntrepriseAdminApi } from './apis/habilitationEntrepriseAdminApi'
import { habilitationMedecinAdminApi } from './apis/habilitationMedecinAdminApi'
import { notificationsAdminApi } from './apis/notificationsAdminApi'
import { indicateurActiviteAdminApi } from './apis/indicateurActiviteAdminApi'

/* Définition des reducers de la plateforme Admin */
export const adminReducers = {
  /* --- sync --- */
  [searchCriteriaSliceName]: searchCriteriaReducer,

  /* --- async -> rtk.query --- */
  [dossierAdminApi.reducerPath]: dossierAdminApi.reducer,
  [gestionEntrepriseApi.reducerPath]: gestionEntrepriseApi.reducer,
  [gestionMedecinApi.reducerPath]: gestionMedecinApi.reducer,
  [actualiteAdminApi.reducerPath]: actualiteAdminApi.reducer,
  [habilitationEntrepriseAdminApi.reducerPath]: habilitationEntrepriseAdminApi.reducer,
  [habilitationMedecinAdminApi.reducerPath]: habilitationMedecinAdminApi.reducer,
  [notificationsAdminApi.reducerPath]: notificationsAdminApi.reducer,
  [indicateurActiviteAdminApi.reducerPath]: indicateurActiviteAdminApi.reducer,

  /* --- autres --- */
}

/* Définition des middlewares de la plateforme Admin */
export const adminMiddlewares = [
  dossierAdminApi.middleware,
  gestionMedecinApi.middleware,
  gestionEntrepriseApi.middleware,
  actualiteAdminApi.middleware,
  habilitationEntrepriseAdminApi.middleware,
  habilitationMedecinAdminApi.middleware,
  notificationsAdminApi.middleware,
  indicateurActiviteAdminApi.middleware,
]

/* Définition des utils de la plateforme Admin */
export const adminUtils = [
  dossierAdminApi.util,
  gestionMedecinApi.util,
  gestionEntrepriseApi.util,
  actualiteAdminApi.util,
  habilitationEntrepriseAdminApi.util,
  habilitationMedecinAdminApi.util,
  notificationsAdminApi.util,
  indicateurActiviteAdminApi.util,
]
