import React from 'react'
import { Card, CardContent, CardHeader, Grid, Stack, Step, StepLabel, Stepper } from '@mui/material'
import { formatDateFR } from 'plateforme/services/dates.services'
import { AnnulerButton, StepIconComponent, TitleChip } from 'plateforme/components'
import labelTypeRapport from 'plateforme/services/rapport.services'
import InformationIconButton from 'plateforme/components/buttons/InformationIconButton'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import useTabNavigate from 'plateforme/hooks/useTabNavigate'
import { StatutMission } from 'plateforme/store/types/mission'
import MissionEntreprise from 'medecin/store/types/missionMedecin'
import { StatutFacture } from 'medecin/store/types/factureMedecin'
import dateOfStatutFacture from 'plateforme/services/factures.services'

type SuiviMissionSyntheseProps = {
  mission: MissionEntreprise
}

type StepType = {
  key: string
  label: string
  commentaire?: string
}

export default function SuiviMissionPart({ mission }: SuiviMissionSyntheseProps) {
  // props:
  const { rapport, statut, descriptionStatut, factures, demandesModifications, dateCloture, dateReponseMedecin } =
    mission

  const sortedFactures =
    factures && factures?.length > 0
      ? [...factures]?.sort((a, b) => {
          return a.code.localeCompare(b.code)
        })
      : []

  // hooks:
  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()
  const { navigateTab } = useTabNavigate()

  // format:
  const titre = rapport ? `Conclusions pour la mission : ${labelTypeRapport(rapport)}` : `${descriptionStatut}`
  const isMissionAnnulee = statut === StatutMission.ANNULEE
  const isMissionProposeeSansSuite = statut === StatutMission.PROPOSEE_SANS_SUITE
  const isMissionCloturee = statut === StatutMission.CLOTUREE
  const isMissionArretee = statut === StatutMission.ARRETEE

  const createSteps = (): StepType[] => {
    // L'ordre de push représente l'affichage du stepper attention a bien garder la cohérence en le modifiant
    const stepsArray: StepType[] = []
    // on peut avoir maximum 3 demandes de modification donc la step_ est calculé en fonction
    demandesModifications?.forEach((dModification, index) => {
      stepsArray.push({
        key: `step_0${index + 9}_demande`,
        label: `Demande de compléments entreprise le ${formatDateFR(dModification.date)}`,
        commentaire: dModification.commentaire,
      })
      if (dModification?.dateValidation) {
        stepsArray.push({
          key: `step_0${index + 9}_complement`,
          label: `Compléments transmis le ${formatDateFR(dModification?.dateValidation)}`,
        })
      } else {
        stepsArray.push({
          key: `step_0${index + 9}_complement`,
          label: `Compléments à transmettre`,
        })
      }
    })

    return stepsArray
  }
  const steps = createSteps()

  // navigate mission tab if rapport exists
  const navigateTabRapport = () => {
    if (mission.rapport) {
      navigateTab(mission.code)
    }
  }

  // dialog show commentaire:
  const showCommentaire = (commentaire: string) => {
    confirm({
      title: 'Commentaire',
      maxWidth: 'md',
      confirmMsg: commentaire,
      withForm: true,
      form: (
        <Grid container paddingTop={0} width="500px">
          <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
            <Grid item>
              <AnnulerButton onClick={() => closeConfirmDialog()}>Fermer</AnnulerButton>
            </Grid>
          </Grid>
        </Grid>
      ),
    })
  }

  // render:
  return (
    <Card>
      {confirmDialog}
      <CardHeader title="Suivi de la mission" />
      <CardContent>
        <TitleChip title={titre} onClick={mission.rapport ? navigateTabRapport : undefined} />
        <Stepper orientation="vertical" sx={{ paddingTop: 1.5, marginBottom: -1 }}>
          {dateReponseMedecin && (
            <Step completed>
              <StepLabel StepIconComponent={StepIconComponent}>{`Mission acceptée le ${formatDateFR(
                dateReponseMedecin
              )}`}</StepLabel>
            </Step>
          )}
          {isMissionAnnulee && dateCloture && (
            <Step completed>
              <StepLabel StepIconComponent={StepIconComponent}>
                <Stack direction="row" alignItems="center">
                  {`Mission annulée le ${formatDateFR(dateCloture)}`}
                  {mission.commentaireCloture !== undefined && (
                    <InformationIconButton
                      color="secondary"
                      onClick={() => showCommentaire(mission.commentaireCloture ?? '')}
                    />
                  )}
                </Stack>
              </StepLabel>
            </Step>
          )}
          {isMissionProposeeSansSuite && dateCloture && (
            <Step completed>
              <StepLabel StepIconComponent={StepIconComponent}>
                <Stack direction="row" alignItems="center">
                  {`Proposition de mission classée sans suite (entreprise) le ${formatDateFR(dateCloture)}`}
                  {mission.commentaireCloture !== undefined && (
                    <InformationIconButton
                      color="secondary"
                      onClick={() => showCommentaire(mission.commentaireCloture ?? '')}
                    />
                  )}
                </Stack>
              </StepLabel>
            </Step>
          )}
          {rapport?.dateExamen && (
            <Step completed>
              <StepLabel StepIconComponent={StepIconComponent}>{`Date d'examen le ${formatDateFR(
                rapport.dateExamen
              )}`}</StepLabel>
            </Step>
          )}
          {rapport?.dateValidationMedecin && (
            <Step completed>
              <StepLabel StepIconComponent={StepIconComponent}>{`${labelTypeRapport(
                rapport
              )} et rapport transmis le ${formatDateFR(rapport.dateValidationMedecin)}`}</StepLabel>
            </Step>
          )}
          {steps.map((step: StepType) => (
            <Step key={step.key} completed>
              <StepLabel StepIconComponent={StepIconComponent}>
                <Stack direction="row" alignItems="center">
                  {step.label}
                  {step.commentaire !== undefined && (
                    <InformationIconButton color="secondary" onClick={() => showCommentaire(step.commentaire ?? '')} />
                  )}
                </Stack>
              </StepLabel>
            </Step>
          ))}
          {isMissionCloturee && dateCloture && (
            <Step completed>
              <StepLabel StepIconComponent={StepIconComponent}>
                <Stack direction="row" alignItems="center">
                  {`Mission clôturée le ${formatDateFR(dateCloture)}`}
                  {mission.commentaireCloture !== undefined && (
                    <InformationIconButton
                      color="secondary"
                      onClick={() => showCommentaire(mission.commentaireCloture ?? '')}
                    />
                  )}
                </Stack>
              </StepLabel>
            </Step>
          )}
          {isMissionArretee && dateCloture && (
            <Step completed>
              <StepLabel StepIconComponent={StepIconComponent}>
                <Stack direction="row" alignItems="center">
                  {`Mission arrêtée le ${formatDateFR(dateCloture)}`}
                  {mission.commentaireCloture !== undefined && (
                    <InformationIconButton
                      color="secondary"
                      onClick={() => showCommentaire(mission.commentaireCloture ?? '')}
                    />
                  )}
                </Stack>
              </StepLabel>
            </Step>
          )}
          {sortedFactures?.map((f) => {
            return (
              <Step completed key={f.code}>
                <StepLabel StepIconComponent={StepIconComponent}>
                  {`Facture ${f.code} du ${formatDateFR(f.dateFacture)} ${f.labelStatut} le ${formatDateFR(
                    dateOfStatutFacture(f)
                  )}${f.statut === StatutFacture.DEPOSEE ? ' : en attente de traitement entreprise' : ''}`}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </CardContent>
    </Card>
  )
}
