import React from 'react'
import { Grid, Stack } from '@mui/material'

import NavigationPart from 'plateforme/parts/NavigationPart'
import InformationsMedecinPart from 'assureur/parts/ficheMedecin/InformationsMedecinPart'
import MessagesMedecinPart from 'assureur/parts/ficheMedecin/MessageMedecinPart'
import LieuxExerciceMedecinPart from 'assureur/parts/ficheMedecin/LieuxExerciceMedecinPart'
import HabilitationsMedecinPart from 'assureur/parts/ficheMedecin/HablitationsMedecinPart'
import { useParams } from 'react-router-dom'
import { useGetMedecinByCodeQuery } from 'assureur/store/apis/medecinEntrepriseApi'

export default function FicheMedecinEntreprisePage() {
  const { codeMedecin } = useParams()
  if (codeMedecin === undefined) {
    throw new Error('Aucun code médecin défini')
  }

  const { data: medecin, isLoading, isFetching, isError } = useGetMedecinByCodeQuery({ codeMedecin })

  if (medecin === undefined && !isFetching && !isLoading && isError) {
    throw new Error('Impossible de récuperer le profil médecin')
  }

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start">
      <Grid item xs={12}>
        <NavigationPart label="Fiche médecin" />
      </Grid>
      <Grid item container xs={12} sm={12} md={12} lg={5}>
        <Grid item xs={12}>
          <InformationsMedecinPart medecin={medecin} loading={isFetching || isLoading} />
        </Grid>
        <Grid item xs={12}>
          <MessagesMedecinPart codeMedecin={codeMedecin} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={7}>
        <Stack>
          <LieuxExerciceMedecinPart medecin={medecin} loading={isFetching || isLoading} />
          <HabilitationsMedecinPart medecin={medecin} loading={isFetching || isLoading} />
        </Stack>
      </Grid>
    </Grid>
  )
}
