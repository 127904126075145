import React from 'react'
import { useSnackbar } from 'notistack'
import { Alert, AlertTitle, Card, CardContent, CardHeader, Grid, Stack } from '@mui/material'
import {
  determineDocumentsRapport,
  determineLibelleRapport,
  isRapportCarence,
} from 'plateforme/services/rapport.services'
import DocumentsTablePart from 'plateforme/parts/DocumentsTablePart'
import { formatDateFR } from 'plateforme/services/dates.services'
import { ButtonsStack, TelechargerPDFButton } from 'plateforme/components'
import { usePostAuthDownloadMutation } from 'plateforme/store/apis/utilisateurApi'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { AuthDownloadAction, AuthDownloadDocumentDossierRequest } from 'plateforme/store/types/utilisateur'
import { toTelechargementDocumentDossierHref } from 'plateforme/App'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import FicheRapportEntreprisePart from './FicheRapportEntreprisePart'

interface TabRapportEntreprisePartProps {
  dossier: DossierEntreprise
  mission: MissionEntreprise
  typeConclusion: TypeRapport.CONCLUSION_PROVISOIRE | TypeRapport.CONCLUSION_DEFINITIVE | TypeRapport.CARENCE
}

export default function TabRapportEntreprisePart({ dossier, mission, typeConclusion }: TabRapportEntreprisePartProps) {
  const { code: codeDossier } = dossier
  const { demandesModifications, rapport } = mission

  const [authDownload, { isLoading: isLoadingAuthDownload }] = usePostAuthDownloadMutation()
  const { enqueueSnackbar } = useSnackbar()

  const documents = determineDocumentsRapport(mission)
  const derniereDemandeDeModification =
    !demandesModifications || demandesModifications?.length < 1
      ? undefined
      : demandesModifications.at(demandesModifications.length - 1)

  const derniereDemandeDeModificationEnCours =
    derniereDemandeDeModification && !derniereDemandeDeModification?.dateValidation
      ? derniereDemandeDeModification
      : undefined

  const ficheDocument = rapport?.documents
    ?.filter((d) => d.statut === 'ACTIF')
    .find((d) => d.typeDocument === 'FP' || d.typeDocument === 'FD' || d.typeDocument === 'FC')

  const missionCloturee = mission?.statut === 'CLOTUREE'

  function alerteDemandeModification() {
    return (
      <Alert
        severity="warning"
        elevation={1}
        sx={{
          '& .MuiAlert-message': {
            width: '100%',
          },
        }}
      >
        <AlertTitle>
          <strong>Demande de modification : </strong>
          {derniereDemandeDeModificationEnCours?.commentaire}
        </AlertTitle>
      </Alert>
    )
  }

  function alerteDemandeModificationEnCourDeSaisie() {
    return (
      <Alert
        severity="info"
        elevation={1}
        sx={{
          '& .MuiAlert-message': {
            width: '100%',
          },
          paddingBottom: 0.5,
        }}
      >
        <AlertTitle>
          <Grid container>
            <Grid item xs={12} lg={6} marginTop="-2px">
              <strong>
                {isRapportCarence(typeConclusion)
                  ? 'Rapport de carence de la mission : '
                  : 'Conclusions de la mission : '}
              </strong>
              {derniereDemandeDeModificationEnCours &&
                `en demande de modification depuis le ${formatDateFR(derniereDemandeDeModificationEnCours?.date)}`}
            </Grid>
          </Grid>
        </AlertTitle>
      </Alert>
    )
  }

  function alerteRapportValide() {
    return (
      <Alert
        severity="info"
        elevation={1}
        sx={{
          '& .MuiAlert-message': {
            width: '100%',
          },
          paddingBottom: 0.5,
        }}
      >
        <AlertTitle>
          <Grid container>
            <Grid item xs={12} lg={6} marginTop="-2px">
              <strong>Le rapport a été validé : </strong>
              le {formatDateFR(rapport?.dateValidationMedecin)}
            </Grid>
            <Grid item xs={12} lg={6} display="flex" alignItems="flex-end" justifyContent="flex-end">
              <ButtonsStack>
                <TelechargerPDFButton
                  onClick={() => ficheDocument && onDownload(ficheDocument)}
                  loading={isLoadingAuthDownload}
                >
                  Télécharger la fiche au format pdf
                </TelechargerPDFButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </AlertTitle>
      </Alert>
    )
  }

  const onDownload: (doc: DocumentTeleverse) => Promise<void> = async (doc: DocumentTeleverse) => {
    if (!codeDossier) {
      throw new Error('erreur inattendu')
    }

    const request: AuthDownloadDocumentDossierRequest = {
      action: AuthDownloadAction.TELECHARGER_DOCUMENT_DOSSIER,
      codeDossier,
      codeDocument: doc.code,
    }

    await authDownload(request)
      .unwrap()
      .then(() => {
        const href = toTelechargementDocumentDossierHref(codeDossier, doc.code)
        window.open(href, '_self')
      })
      .catch(() => {
        enqueueSnackbar(`Le téléchargement du document a échoué`, { variant: 'error' })
      })
  }

  return (
    <Stack>
      {!missionCloturee && derniereDemandeDeModificationEnCours && alerteDemandeModification()}
      {!missionCloturee && derniereDemandeDeModificationEnCours && alerteDemandeModificationEnCourDeSaisie()}
      {(missionCloturee || !derniereDemandeDeModificationEnCours) && ficheDocument && alerteRapportValide()}
      <Card>
        <CardHeader title={`Fiche de ${determineLibelleRapport(typeConclusion)}`} />
        <CardContent>
          <FicheRapportEntreprisePart dossier={dossier} mission={mission} typeConclusion={typeConclusion} />
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Documents rapport et annexe" />
        <CardContent>
          <DocumentsTablePart documents={documents} dossier={dossier} nbLigneParPage={15} readOnly />
        </CardContent>
      </Card>
    </Stack>
  )
}
