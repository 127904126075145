import { hasRoles, Role } from 'plateforme/services/roles.services'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import MissionMedecin from '../store/types/missionMedecin'
import DossierMedecin from '../store/types/dossierMedecin'

export function dossierConsultable(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.MED_SECRETAIRE, Role.MED_MEDECIN])
}

export function ficheDossierTelechargeable(mission: MissionMedecin, profilUtilisateur?: ProfilUtilisateurActif) {
  return dossierConsultable(profilUtilisateur) && !!mission.ficheGenerable
}

export function propositionMissionTraitable(mission: MissionMedecin, profilUtilisateur?: ProfilUtilisateurActif) {
  return dossierConsultable(profilUtilisateur) && !!mission.propositionTraitable
}

export function documentDossierAjoutable(dossier: DossierMedecin, profilUtilisateur?: ProfilUtilisateurActif) {
  return dossierConsultable(profilUtilisateur) && !!dossier.documentDeposable
}

export function rapportCreable(
  mission: MissionMedecin,
  typeRapport: TypeRapport,
  profilUtilisateur?: ProfilUtilisateurActif
): boolean {
  if (!dossierConsultable(profilUtilisateur)) {
    return false
  }
  switch (typeRapport) {
    case TypeRapport.CARENCE:
      return !!mission.carenceCreable
    case TypeRapport.CONCLUSION_PROVISOIRE:
      return !!mission.conclusionProvisoireCreable
    case TypeRapport.CONCLUSION_DEFINITIVE:
      return !!mission.conclusionDefinitiveCreable
    default:
      return false
  }
}

export function rapportModifiable(
  mission: MissionMedecin,
  typeRapport: TypeRapport,
  profilUtilisateur?: ProfilUtilisateurActif
) {
  return (
    dossierConsultable(profilUtilisateur) && !!mission.rapportModifiable && typeRapport === mission.rapport?.typeRapport
  )
}

export function rapportValidable(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.MED_MEDECIN])
}

export function missionFacturable(mission: MissionMedecin, profilUtilisateur?: ProfilUtilisateurActif) {
  return dossierConsultable(profilUtilisateur) && !!mission.facturable
}
