import React from 'react'
import { Grid, Typography } from '@mui/material'
import { ReadOnlyTextField } from 'plateforme/components'

interface ReadOnlyDommageTexteProps {
  value?: string | number
  title?: string
  label?: string
}

export default function ReadOnlyDommageTexte({ label, value, title }: ReadOnlyDommageTexteProps) {
  return (
    <Grid item display="flex" alignItems="center">
      <Typography marginLeft={2} sx={{ width: '30%' }} color="primary" variant="subtitle2">
        {`${title} :`}
      </Typography>
      <ReadOnlyTextField fullWidth multiline value={value} label={label} />
    </Grid>
  )
}
