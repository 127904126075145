import React from 'react'
import { Alert, Card, CardContent, CardHeader, Grid } from '@mui/material'
import { ReadOnlyEmailField, ReadOnlyTextField } from 'plateforme/components'
import DossierPlateforme from 'plateforme/store/types/dossier'

type PersonneAContacterPartProps = {
  dossier: DossierPlateforme
}

export default function PersonneAContacterPart({ dossier }: PersonneAContacterPartProps) {
  // props:
  const { personneAContacter } = dossier

  // erreur:
  if (!dossier || !personneAContacter) {
    throw new Error(`Erreur de chargement des données`)
  }

  // format:
  const isCoordonneesIdentiquesPersonneAExaminer = personneAContacter?.coordonneesIdentiquesPersonneAExaminer

  // rendu:
  return (
    <Card>
      <CardHeader title="Personne à contacter" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={4}>
            <ReadOnlyTextField id="nom-personne-a-contacter" label="Nom" value={personneAContacter?.nom} fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReadOnlyTextField
              id="personne-a-contacter-prenom"
              label="Prénom"
              value={personneAContacter?.prenom}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReadOnlyTextField
              id="personne-a-contacter-qualite"
              label="Qualité"
              value={personneAContacter?.labelQualite}
              fullWidth
            />
          </Grid>
          {isCoordonneesIdentiquesPersonneAExaminer ? (
            <Grid item xs={12}>
              <Alert severity="info" id="personne-a-contacter-coordonnees-personne-a-examiner">
                Les coordonnées de la personne à contacter sont celles de la personne à examiner
              </Alert>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Alert severity="info" id="personne-a-contacter-coordonnees-personne-a-examiner">
                  <strong>Les coordonnées de la personne à contacter</strong> sont précisées ci-dessous :
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <ReadOnlyTextField
                  id="personne-a-contacter-adresse"
                  label="Adresse"
                  value={personneAContacter?.adresseComplete}
                  fullWidth
                  minRows={3}
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <ReadOnlyEmailField
                  id="personne-a-contacter-mail"
                  label="Adresse mail"
                  value={personneAContacter?.mail}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyTextField
                  id="personne-a-contacter-telephone-mobile"
                  label="Tél. portable"
                  value={personneAContacter?.telephoneMobile}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyTextField
                  id="personne-a-contacter-telephone-fixe"
                  label="Tél. fixe"
                  value={personneAContacter?.telephoneFixe}
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
