import React, { ReactNode, useState } from 'react'
import ConfirmDialog from '../components/dialog/ConfirmDialog'

export type DialogArgs = {
  confirmMsg?: string
  title?: string
  withForm?: boolean
  form?: React.ReactNode
  maxWidth?: 'sm' | 'md' | 'lg'
  fullWidth?: boolean
  onYes?: VoidFunction
  variant?: 'confirm' | 'delete'
}

export default function useConfirmDialog() {
  const [isLocalLoading, setIsLocalLoading] = useState<boolean | undefined>(false)
  const [isOpen, setOpen] = useState(false)
  const [confirmMsg, setConfirmMsg] = useState<string | undefined>('')
  const [title, setTitle] = useState<string | undefined>('')
  const [maxWitdth, setMaxWidth] = useState<'sm' | 'md' | 'lg' | undefined>()
  const [withForm, setWithForm] = useState<boolean | undefined>(false)
  const [fullWidth, setFullWidth] = useState<boolean | undefined>(false)
  const [form, setForm] = useState<ReactNode | undefined>()
  const [onLocalYes, setOnLocalYes] = useState<VoidFunction>(() => {
    return null
  })
  const [variant, setVariant] = useState<'confirm' | 'delete' | undefined>('confirm')

  const confirm = (args: DialogArgs) => {
    setConfirmMsg(args.confirmMsg)
    setOnLocalYes(() => args?.onYes)
    setIsLocalLoading(false)
    setOpen(true)
    setWithForm(args.withForm)
    setForm(args.form)
    setFullWidth(args.fullWidth)
    setMaxWidth(args.maxWidth)
    setTitle(args.title)
    setVariant(args.variant)
  }

  const onYes = async () => {
    setIsLocalLoading(true)
    await onLocalYes()
    setOpen(false)
  }

  const closeConfirmDialog = () => setOpen(false)

  const confirmDialog = (
    <ConfirmDialogMemo
      isLocalLoading={isLocalLoading}
      withForm={withForm}
      fullWidth={fullWidth}
      maxWidth={maxWitdth}
      form={form}
      open={isOpen}
      onYes={onYes}
      onNo={closeConfirmDialog}
      confirmMsg={confirmMsg}
      title={title}
      variant={variant}
    />
  )

  return { confirm, closeConfirmDialog, confirmDialog }
}

const ConfirmDialogMemo = React.memo(
  ConfirmDialog,
  (prev, next) => prev.open === next.open && prev.isLocalLoading === next.isLocalLoading
)
