import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
} from '@mui/material'
import {
  ButtonsStack,
  ChercherButton,
  DatePickerInput,
  EditTextField,
  RechargerButton,
  SelectInputParametrage,
  SelectInputReferentiel,
  SelectInputNatureEvenement,
} from 'plateforme/components'
import { ParametrageNameEnum } from 'plateforme/components/inputs/SelectInputParametrage'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatDateISO } from 'plateforme/services/dates.services'
import { emptyToUndefined, trimToUndefined } from 'plateforme/services/utils'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import SelectInputGestionnaire from 'plateforme/components/inputs/SelectInputGestionnaire'
import { formatBooleanFilter } from 'plateforme/services/apis.services'
import { SelectOption } from 'plateforme/components/inputs/SelectInput'
import { StatutMission } from 'plateforme/store/types/mission'
import { StatutDossier } from 'plateforme/store/types/dossier'
import { DossierEntrepriseFilter } from 'assureur/store/types/dossierEntreprise'
import ProfilEntreprise from 'assureur/store/types/profilEntreprise'
import {
  getDossierCriteria,
  setDossierCriteria,
} from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'
import AutoCompleteEntrepriseMedecin from 'assureur/components/AutocompleteEntrepriseMedecin'

const AUCUNE_MISSION = 'AUCUNE_MISSION'

type RechercheDossierEntreprisePartProps = {
  search: (criteria: DossierEntrepriseFilter) => void
  searchError: IQueryErrorResponse
  profilEntreprise?: ProfilEntreprise
  profilUtilisateur?: ProfilUtilisateurActif
}

type DossierFilterData = {
  motCle?: string
  statutsDossier: StatutDossier[] | null
  statutsMission: StatutMission[] | null
  natureEvenement: string | null
  cadreExpertise: string | null
  dateCreationDebut: Date | null
  dateCreationFin: Date | null
  service: string | null
  gestionnaire: string | null
  horsDelai?: boolean
  codeMedecin: SelectOption | null
}

export default function RechercheDossierEntreprisePart({
  search,
  searchError,
  profilEntreprise,
  profilUtilisateur,
}: RechercheDossierEntreprisePartProps) {
  const cloisonnementServiceActif =
    !profilUtilisateur?.codeService && profilEntreprise?.parametrage?.cloisonnementService

  // get default values from localStorage
  const defaultDossierCriteria = useSelector(getDossierCriteria)
  const dispatch = useDispatch()

  const initialStatutsMission = () => {
    let result = [] as StatutMission[]

    if (defaultDossierCriteria?.statutsMission) {
      result = [...defaultDossierCriteria.statutsMission]
    }

    if (defaultDossierCriteria?.aucuneMission === 1) {
      result = [...result, AUCUNE_MISSION as StatutMission]
    }

    return result
  }

  // Les valeurs initiales du formulaire :
  const initialValues: DossierFilterData = {
    motCle: defaultDossierCriteria?.motCle?.length === 0 ? '' : defaultDossierCriteria?.motCle ?? '',
    service: defaultDossierCriteria?.service ?? null,
    gestionnaire: defaultDossierCriteria?.gestionnaire ?? null,
    statutsDossier: defaultDossierCriteria?.statutsDossier ?? null,
    statutsMission: initialStatutsMission(),
    natureEvenement: defaultDossierCriteria?.natureEvenement ?? null,
    cadreExpertise: defaultDossierCriteria?.cadreExpertise ?? null,
    dateCreationDebut: defaultDossierCriteria?.dateCreationDebut
      ? new Date(defaultDossierCriteria.dateCreationDebut)
      : null,
    dateCreationFin: defaultDossierCriteria?.dateCreationFin ? new Date(defaultDossierCriteria.dateCreationFin) : null,
    horsDelai: defaultDossierCriteria?.horsDelai === undefined ? undefined : defaultDossierCriteria?.horsDelai === 1,
    codeMedecin: defaultDossierCriteria?.codeMedecin
      ? ({ label: defaultDossierCriteria?.codeMedecin } as SelectOption)
      : null,
  }

  const resetValues: DossierFilterData = {
    motCle: '',
    service: null,
    gestionnaire: null,
    statutsDossier: null,
    statutsMission: null,
    natureEvenement: null,
    cadreExpertise: null,
    dateCreationDebut: null,
    dateCreationFin: null,
    horsDelai: false,
    codeMedecin: null,
  }

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    setError,
    watch,
    formState: { isValid },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })

  useErrorFormMapper(searchError, setError, getValues)
  const onSubmit = async (data: DossierFilterData) => {
    const filtre = {
      motCle: trimToUndefined(data.motCle),
      statutsDossier: emptyToUndefined(data.statutsDossier),
      statutsMission: emptyToUndefined(
        // On enleve le faux filtre AUCUNE_MISSION
        data.statutsMission?.filter((v) => v !== (AUCUNE_MISSION as StatutMission))
      ),
      // Récupération du filtre depuis les statuts mission
      aucuneMission: data.statutsMission?.includes(AUCUNE_MISSION as StatutMission) ? 1 : undefined,
      natureEvenement: trimToUndefined(data.natureEvenement),
      service: trimToUndefined(data.service),
      gestionnaire: trimToUndefined(data.gestionnaire),
      cadreExpertise: trimToUndefined(data.cadreExpertise),
      dateCreationDebut: formatDateISO(data.dateCreationDebut),
      dateCreationFin: formatDateISO(data.dateCreationFin),
      horsDelai: formatBooleanFilter(data.horsDelai),
      codeMedecin: trimToUndefined(data.codeMedecin?.code),
    } as DossierEntrepriseFilter

    search(filtre)
    dispatch(setDossierCriteria(filtre))
  }

  const handleClickInitSearch = () => {
    const filtre = {
      motCle: undefined,
      statutsDossier: undefined,
      statutsMission: undefined,
      natureEvenement: undefined,
      service: undefined,
      affectationCentrale: undefined,
      gestionnaire: undefined,
      cadreExpertise: undefined,
      dateCreationDebut: undefined,
      dateCreationFin: undefined,
      horsDelai: undefined,
    } as DossierEntrepriseFilter

    reset(resetValues)
    search(filtre)
    dispatch(setDossierCriteria(filtre))
  }

  const serviceEntreprise = trimToUndefined(
    profilUtilisateur?.codeService ? profilUtilisateur?.codeService : watch('service')
  )

  const sizeMotCle = cloisonnementServiceActif ? 4 : 8

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-habilitation">
          <Grid container>
            <Grid item xs={12} sm={12} md={sizeMotCle}>
              <Controller
                name="motCle"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="mot-cle"
                    value={value}
                    label="Mots clés"
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            {cloisonnementServiceActif && (
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="service"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <SelectInputParametrage
                      id="service"
                      parametrageName={ParametrageNameEnum.SERVICE}
                      label="Service"
                      value={value}
                      onChange={onChange}
                      fullWidth
                      onBlur={onBlur}
                      fieldError={error}
                      withNoSelectionItem
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="gestionnaire"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectInputGestionnaire
                    id="gestionnaire"
                    label="Gestionnaire"
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                    }}
                    fullWidth
                    onBlur={onBlur}
                    fieldError={error}
                    codeService={serviceEntreprise}
                    withNoSelectionItem
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="statutsDossier"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputReferentiel
                    id="statuts-dossier"
                    label="Statuts Dossier"
                    referentielName="statutsDossier"
                    multiple
                    value={value}
                    onChangeMultiple={onChange}
                    onBlur={onBlur}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="natureEvenement"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputNatureEvenement
                    id="nature-evenement"
                    label="Nature d'événement"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    profilEntreprise={profilEntreprise}
                    referentielName="naturesEvenement"
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="cadreExpertise"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputReferentiel
                    id="cadre-expertise"
                    label="Cadre de l'expertise"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    referentielName="cadresExpertise"
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="statutsMission"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputReferentiel
                    id="statuts-mission"
                    label="Statuts Mission"
                    referentielName="statutsMission"
                    multiple
                    value={value}
                    optionsAdded={[{ code: AUCUNE_MISSION, label: 'Aucune mission' }]}
                    onChangeMultiple={onChange}
                    onBlur={onBlur}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="dateCreationDebut"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DatePickerInput
                    InputProps={{
                      id: 'date-creation-debut',
                      fullWidth: true,
                    }}
                    label="Date de début"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="dateCreationFin"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DatePickerInput
                    InputProps={{
                      id: 'date-creation-fin',
                      fullWidth: true,
                    }}
                    label="Date de fin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="codeMedecin"
                control={control}
                defaultValue={initialValues.codeMedecin}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <AutoCompleteEntrepriseMedecin
                    id="medecin"
                    label="Médecin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="horsDelai"
                control={control}
                defaultValue={initialValues.horsDelai}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <FormControl error={error !== undefined}>
                    <FormGroup>
                      <FormControlLabel
                        label="Hors délais"
                        sx={{ height: 30 }}
                        control={
                          <Checkbox
                            name="horsDelai"
                            checked={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            sx={{ padding: 0, marginX: 1.5 }}
                          />
                        }
                      />
                    </FormGroup>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton
                  variant="contained"
                  type="submit"
                  form="form-recherche-habilitation"
                  disabled={!isValid}
                >
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}
