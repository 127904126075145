import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import addDays from 'date-fns/addDays'
import { Box, Stack } from '@mui/material'
import { DatePickerInput, AjouterIconButton, SupprimerIconButton } from 'plateforme/components'
import { PeriodeData } from '../FicheRapportMedecinForm'

interface DommageFieldPeriodeProps {
  name: string
  code: string
  readOnly?: boolean
  max?: number
}

export default function DommageFieldPeriode({ name, code, readOnly, max }: DommageFieldPeriodeProps) {
  const fieldName = `${name}.plageDeDates.periodes`
  const { control, getValues, clearErrors, resetField, watch } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  })

  function addTooltip(index: number) {
    if (fields.length === max) {
      return `Le nombre maximum de périodes est de ${max}`
    }
    return index === fields.length - 1 ? 'Ajouter une période' : ''
  }

  function addDisabled() {
    return fields.length === max
  }

  function addOnClick() {
    return () => {
      let du: Date | null = null
      if (fields.length > 0) {
        const lastAu = getValues(`${fieldName}.${fields.length - 1}.au`)
        du = lastAu ? addDays(new Date(lastAu), 1) : null
      }

      append({ du, au: null } as PeriodeData)
    }
  }

  function removeTooltip() {
    return fields.length <= 1 ? 'Au minimum, une période est requise' : 'Retirer'
  }

  function removeDisabled() {
    return fields.length <= 1
  }

  function removeOnClick(index: number) {
    return () => remove(index)
  }

  return (
    <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
      {fields.map((item, index) => {
        return (
          <li key={`${code}_${item.id}`}>
            <Stack direction="row" spacing={3.5} marginBottom={index === fields.length - 1 ? 0 : 2.5}>
              <Controller
                name={`${fieldName}.${index}.du`}
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DatePickerInput
                    readOnly={readOnly}
                    InputProps={{
                      id: `${fieldName}.${index}.du`,
                      fullWidth: true,
                    }}
                    label="Du"
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => {
                      const relatedFieldName = `${fieldName}.${index}.au`
                      clearErrors(relatedFieldName)
                      resetField(relatedFieldName, {
                        defaultValue: watch(relatedFieldName),
                      })
                      onChange(e)
                    }}
                    fieldError={error}
                  />
                )}
              />
              <Controller
                name={`${fieldName}.${index}.au`}
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DatePickerInput
                    InputProps={{
                      id: `${fieldName}.${index}.au`,
                      fullWidth: true,
                    }}
                    label="Au"
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => {
                      const relatedFieldName = `${fieldName}.${index}.du`
                      clearErrors(relatedFieldName)
                      resetField(relatedFieldName, {
                        defaultValue: watch(relatedFieldName),
                      })
                      onChange(e)
                    }}
                    readOnly={readOnly}
                    fieldError={error}
                  />
                )}
              />
              {!readOnly && (
                <Box height="60px" paddingTop="12px" marginLeft="8px !important">
                  <SupprimerIconButton
                    onClick={removeOnClick(index)}
                    disabled={removeDisabled()}
                    tooltip={removeTooltip()}
                  />
                </Box>
              )}
              {!readOnly && (
                <Box height="60px" minWidth="40px" paddingTop="10px" marginLeft="8px !important">
                  {index === fields.length - 1 ? (
                    <AjouterIconButton
                      title="Ajouter"
                      onClick={addOnClick()}
                      disabled={addDisabled()}
                      tooltip={addTooltip(index)}
                    />
                  ) : (
                    ' '
                  )}
                </Box>
              )}
            </Stack>
          </li>
        )
      })}
    </ul>
  )
}
