import React from 'react'
import { useSnackbar } from 'notistack'
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  FileInProgressIcon,
  NoRowsOverlay,
  ReferentielText,
  SupprimerIconButton,
  TelechargerIconButton,
} from 'plateforme/components'
import { toHumanReadableFileSize } from 'plateforme/services/utils'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { usePostAuthDownloadMutation } from 'plateforme/store/apis/utilisateurApi'
import { formatDateFR, formatDateTimeFR } from 'plateforme/services/dates.services'
import { AuthDownloadAction, AuthDownloadDocumentDossierRequest } from 'plateforme/store/types/utilisateur'
import { DocumentPanelType } from 'plateforme/store/types/documentUpload'
import { toTelechargementDocumentDossierHref } from 'plateforme/App'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import DossierAdmin from 'admin/store/types/dossierAdmin'

type DocumentsTableProps = {
  dossier?: DossierMedecin | DossierEntreprise | DossierAdmin
  documents: DocumentTeleverse[]
  readOnly?: boolean
  documentDownloadable?: boolean
  nbLigneParPage: number
  typeDocumentsTable?: DocumentPanelType
  onDeleteDocument?: (document: DocumentTeleverse, typeDocument: DocumentPanelType) => Promise<void>
  isLoadingDelete?: boolean
  compact?: boolean
}

export default function DocumentsTablePart({
  dossier,
  documents,
  readOnly = false,
  documentDownloadable,
  nbLigneParPage,
  typeDocumentsTable = DocumentPanelType.DOSSIER_MEDECIN,
  onDeleteDocument,
  isLoadingDelete,
  compact,
}: DocumentsTableProps) {
  const codeDossier = dossier?.code
  const [authDownload, { isLoading: isLoadingAuthDownload }] = usePostAuthDownloadMutation()
  const { enqueueSnackbar } = useSnackbar()

  const nonModifiableGlobal = !documents?.some((document) => document.supprimable) ?? true

  const onDownload: (doc: DocumentTeleverse) => Promise<void> = async (doc: DocumentTeleverse) => {
    if (!codeDossier) {
      throw new Error('erreur inattendue')
    }

    const request: AuthDownloadDocumentDossierRequest = {
      action: AuthDownloadAction.TELECHARGER_DOCUMENT_DOSSIER,
      codeDossier,
      codeDocument: doc.code,
    }

    await authDownload(request)
      .unwrap()
      .then(() => {
        const href = toTelechargementDocumentDossierHref(codeDossier, doc.code)
        window.open(href, '_self')
      })
      .catch(() => {
        enqueueSnackbar(`Le téléchargement du document a échoué`, { variant: 'error' })
      })
  }

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(nbLigneParPage)
  const theme = useTheme()

  const isXl = useMediaQuery(theme.breakpoints.up('xl'))
  const isLg = useMediaQuery(theme.breakpoints.only('lg'))
  const isMd = useMediaQuery(theme.breakpoints.only('md'))
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  const showFormatEtTaille = !isXs && !isSm
  const showDateDepot = (compact && (isXl || isMd)) || (!compact && (isXl || isLg || isMd))
  const showTypeDocument = !compact && !isXs && !isSm && !isMd
  const showDeposePar = !compact && !isXs

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const total = documents?.length ?? 0

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom du document</TableCell>
              {showTypeDocument && <TableCell>Type du document</TableCell>}
              <TableCell>Date du document</TableCell>
              {showDeposePar && <TableCell>Déposé par</TableCell>}
              {showDateDepot && <TableCell>Date de dépôt</TableCell>}
              {showFormatEtTaille && <TableCell>Format / Taille</TableCell>}
              {documentDownloadable && <TableCell sx={{ width: nonModifiableGlobal ? 50 : 100 }} />}
            </TableRow>
          </TableHead>
          {total > 0 ? (
            <TableBody>
              {documents
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((document: DocumentTeleverse) => (
                  <TableRow key={document.code}>
                    <TableCell component="th" scope="row">
                      {document.nomDocument}
                    </TableCell>
                    {showTypeDocument && (
                      <TableCell component="th" scope="row">
                        <ReferentielText referentielName="typesDocument" referentielCode={document.typeDocument} />
                      </TableCell>
                    )}
                    <TableCell component="th" scope="row">
                      {formatDateFR(document.dateDocument)}
                    </TableCell>
                    {showDeposePar && (
                      <TableCell component="th" scope="row">
                        {document.libelleCreateur}
                      </TableCell>
                    )}
                    {showDateDepot && (
                      <TableCell component="th" scope="row">
                        {formatDateTimeFR(document.dateDepot)}
                      </TableCell>
                    )}
                    {showFormatEtTaille && (
                      <TableCell component="th" scope="row">
                        {`${document.formatFichier ?? '--'} / ${toHumanReadableFileSize(document.tailleFichier)}`}
                      </TableCell>
                    )}
                    {documentDownloadable && (
                      <TableCell component="th" scope="row" sx={{ width: nonModifiableGlobal ? 50 : 100 }}>
                        <Stack direction="row" spacing={0.5} justifyContent="flex-start" paddingRight={1}>
                          {document.statut === 'ACTIF' ? (
                            <TelechargerIconButton
                              onClick={() => onDownload(document)}
                              loading={isLoadingAuthDownload}
                              tooltip
                              tooltipPlacement={readOnly ? 'right' : 'left'}
                            />
                          ) : (
                            <FileInProgressIcon
                              tooltip
                              tooltipPlacement={readOnly ? 'right' : 'left'}
                              sx={{ width: 46 }}
                            />
                          )}
                          {!readOnly && onDeleteDocument && document.supprimable && (
                            <SupprimerIconButton
                              onClick={() => onDeleteDocument(document, typeDocumentsTable)}
                              loading={isLoadingDelete}
                              tooltip
                            />
                          )}
                        </Stack>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          ) : (
            <NoRowsOverlay />
          )}
        </Table>
      </TableContainer>
      {total > 0 && (
        <TablePagination
          component="div"
          count={total}
          rowsPerPageOptions={[nbLigneParPage]}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  )
}
