import React from 'react'
import { Grid, Stack, Step, StepLabel, Stepper } from '@mui/material'
import { formatDateFR } from 'plateforme/services/dates.services'
import { AnnulerButton, StepIconComponent, TitleChip } from 'plateforme/components'
import { trimToUndefined } from 'plateforme/services/utils'
import labelTypeRapport from 'plateforme/services/rapport.services'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import InformationIconButton from 'plateforme/components/buttons/InformationIconButton'
import useTabNavigate from 'plateforme/hooks/useTabNavigate'
import { StatutMission, StatutSuiviMission } from 'plateforme/store/types/mission'
import dateOfStatutFacture from 'plateforme/services/factures.services'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import Facture, { StatutFacture } from 'assureur/store/types/factureEntreprise'
import { TabsConsultationDossierEntrepriseTabId } from 'assureur/parts/consultationDossier/TabsConsultationDossierEntreprise'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'

type WorkflowMissionPartProps = {
  dossier: DossierEntreprise
  mission: MissionEntreprise
}

type StepType = {
  key: string
  label: string
  commentaire?: string
}

type ChipsDescription = {
  title: string
  withError?: boolean
}

type Steper = {
  chipsStatut?: ChipsDescription
  chipsFacture?: ChipsDescription
  steps?: StepType[]
}

export default function WorkflowMissionPart({ dossier, mission }: WorkflowMissionPartProps) {
  // props:
  const {
    statut,
    statutSuivi,
    rapport,
    dateReponseMedecin,
    dateExamenPrevue,
    dateProposition,
    dateMiseAJour,
    dateCloture,
    dateMiseAJourSuiviMission,
    dateValidationMedecin,
    demandesModifications,
    factures,
  } = mission

  // error:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  // hooks:
  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()
  const { navigateTab } = useTabNavigate()

  // format:
  const isMissionEnProposeeMedecin = statut === StatutMission.PROPOSEE
  const isMissionAnnulee = statut === StatutMission.ANNULEE
  const isMissionRefuseeMedecin = statut === StatutMission.REFUSEE
  const isMissionProposeeSansSuite = statut === StatutMission.PROPOSEE_SANS_SUITE
  const isMissionEnCours = statut === StatutMission.EN_COURS
  const isMissionConclusionsValideesMedecin = statut === StatutMission.VALIDEE_MEDECIN
  const isMissionComplementsAjustements = statut === StatutMission.EN_DEMANDE_DE_MODIFICATION
  const isMissionCloturee = statut === StatutMission.CLOTUREE
  const isMissionArretee = statut === StatutMission.ARRETEE
  const isMissionBloquee = statutSuivi === StatutSuiviMission.BLOQUE

  // chips facture assureur
  //	si 1 seule facture liée à la mission :
  //   si StatutFacture = 'DEPOSEE', libellé chips = "Facture à traiter déposée le xx/xx/xxxx" (et couleur chips rouge)
  //   si StatutFacture différent de 'DEPOSEE' (donc StatutFacture = 'ANNULEE', ou ACCEPTEE' ou 'DEMANDE_MODIFICATION'),
  //     libellé chips ="Facture déposée le xx/xx/xxxx : [StatutFacture] " si statut autre que « déposée médecin » (et couleur de chips bleue)
  // Si plus d’une facture, libellé chips =  "x factures déposées", où x est le nombre de factures liées à la mission
  //   couleur rouge si au moins une facture possède un StatutFacture = 'DEPOSEE'
  //   couleur bleue sinon (donc si toutes les factures ont un StatutFacture différent de 'DEPOSEE'
  //         (donc StatutFacture  = 'ANNULEE' ou 'ACCEPTEE' ou 'DEMANDE_MODIFICATION')

  // total de factures de la mission
  const totalFacture = factures?.length
  // total des factures au statut DEPOSEE de la mission
  const totalFactureDeposee = factures?.filter((f) => f.statut === StatutFacture.DEPOSEE).length as number

  // chips d'affichage assureur "Factures"
  const chipsFacture = () => {
    if (totalFacture !== undefined && totalFactureDeposee !== undefined) {
      // facture unique au statut DEPOSEE - fond bleu
      if (totalFacture === 1 && totalFactureDeposee === 1) {
        const factureDeposee = factures?.find((f) => f.statut === StatutFacture.DEPOSEE) as Facture
        return {
          title: `Facture à traiter déposée le ${formatDateFR(factureDeposee.dateCreation)}`,
          withError: true,
        }
      }
      // facture unique qui n'est pas au statut DEPOSEE - fond rouge
      if (totalFacture === 1 && totalFactureDeposee === 0) {
        const factureAutre = factures?.find((f) => f.statut !== StatutFacture.DEPOSEE) as Facture
        return {
          title: `Facture déposée le ${formatDateFR(factureAutre.dateCreation)} : ${factureAutre.labelStatut}`,
          withError: false,
        }
      }
      // plusieurs factures, aucune facture au statut DEPOSEE - fond bleu
      if (totalFacture >= 1 && totalFactureDeposee === 0) {
        return {
          title: `${totalFacture} factures déposées`,
          withError: false,
        }
      }
      // plusieurs factures, au moins une facture est au statut DEPOSEE - fond rouge
      if (totalFacture >= 1 && totalFactureDeposee > 0) {
        return {
          title: `${totalFacture} factures déposées`,
          withError: true,
        }
      }
    }
    return undefined
  }

  // Cette fonction permet de determiner le titre statut de la mission
  const chipsStatutEnCours = () => {
    if (statutSuivi === StatutSuiviMission.EN_COURS) {
      if (rapport && rapport?.dateExamen) {
        return {
          title: `Mission en cours - date d'examen le ${formatDateFR(rapport?.dateExamen)}`,
          withError: false,
        }
      }

      return {
        title: `Mission en cours depuis le ${formatDateFR(mission?.dateReponseMedecin)}`,
        withError: false,
      }
    }

    if (statutSuivi === StatutSuiviMission.BLOQUE) {
      return {
        title: mission.labelMotifBlocage
          ? `Mission en cours - bloquée - ${mission.labelMotifBlocage}`
          : `Mission en cours - bloquée`,
        withError: false,
      }
    }

    return {
      title: `Mission en cours depuis le ${formatDateFR(mission?.dateReponseMedecin)}`,
      withError: true,
    }
  }

  // Cette fonction permet de créer les steps du composant workflow
  const createSteps = (): StepType[] => {
    // L'ordre de push représente l'affichage du stepper attention a bien garder la cohérence en le modifiant
    const stepsArray: StepType[] = []

    const sortedFactures =
      factures && factures?.length > 0
        ? [...factures]?.sort((a, b) => {
            return a.code.localeCompare(b.code)
          })
        : []

    if (dateProposition) {
      stepsArray.push({ key: 'step_01', label: `Mission proposée le ${formatDateFR(dateProposition)}` })
    }

    if (!isMissionRefuseeMedecin && dateReponseMedecin) {
      const refMissionMedecin =
        trimToUndefined(mission.refMissionMedecin) === undefined ? '' : `(${mission.refMissionMedecin})`
      stepsArray.push({
        key: 'step_02',
        label: `Mission acceptée le ${formatDateFR(dateReponseMedecin)} ${refMissionMedecin}`,
      })
    }

    if (isMissionBloquee && dateMiseAJourSuiviMission) {
      stepsArray.push({
        key: 'step_03',
        label: `Mission bloquée le ${formatDateFR(dateMiseAJourSuiviMission)}`,
      })
    }
    if (isMissionAnnulee && dateCloture) {
      stepsArray.push({
        key: 'step_04',
        label: `Mission annulée le ${formatDateFR(dateCloture)}`,
        commentaire: mission.commentaireCloture,
      })
    }

    if (isMissionRefuseeMedecin && dateReponseMedecin) {
      stepsArray.push({
        key: 'step_05',
        label: `Mission refusée le ${formatDateFR(dateReponseMedecin)}`,
        commentaire: mission?.commentaireRefus ?? '',
      })
    }

    if (isMissionProposeeSansSuite && dateCloture) {
      stepsArray.push({
        key: 'step_06',
        label: `Proposition de mission classée sans suite (entreprise) le ${formatDateFR(dateCloture)}`,
        commentaire: mission.commentaireCloture,
      })
    }

    if (
      (isMissionEnCours ||
        isMissionConclusionsValideesMedecin ||
        isMissionComplementsAjustements ||
        isMissionCloturee) &&
      dateExamenPrevue
    ) {
      stepsArray.push({
        key: 'step_07',
        label: `Date d'examen prévue le ${formatDateFR(dateExamenPrevue)}`,
      })
    }

    if (
      (isMissionConclusionsValideesMedecin || isMissionComplementsAjustements || isMissionCloturee) &&
      rapport &&
      dateValidationMedecin
    ) {
      stepsArray.push({
        key: 'step_08',
        label: `${labelTypeRapport(rapport)} et rapport transmis le ${formatDateFR(dateValidationMedecin)}`,
      })
    }
    // on peut avoir maximum 3 demandes de modification donc la step_ est calculé en fonction
    demandesModifications?.forEach((demandeModification, index) => {
      stepsArray.push({
        key: `step_0${index + 9}_demande`,
        label: `Demande de compléments entreprise le ${formatDateFR(demandeModification.date)}`,
        commentaire: demandeModification.commentaire,
      })
      if (demandeModification.dateValidation) {
        stepsArray.push({
          key: `step_0${index + 9}_complement`,
          label: `Compléments transmis le ${formatDateFR(demandeModification.dateValidation)}`,
        })
      }
    })
    if (isMissionCloturee && dateCloture) {
      stepsArray.push({
        key: 'step_13',
        label: `Mission clôturée le ${formatDateFR(dateCloture)}`,
        commentaire: mission.commentaireCloture,
      })
    }

    if (isMissionArretee && dateCloture) {
      stepsArray.push({
        key: 'step_14',
        label: `Mission arrêtée le ${formatDateFR(dateCloture)}`,
        commentaire: mission.commentaireCloture,
      })
    }

    sortedFactures?.forEach((f, index) => {
      stepsArray.push({
        key: `step_15_${index}`,
        label: `Facture ${f.code} du ${formatDateFR(f.dateFacture)} ${f.labelStatut} le ${formatDateFR(
          dateOfStatutFacture(f)
        )}${f.statut === StatutFacture.DEPOSEE ? ' : en attente de traitement entreprise' : ''}`,
      })
    })

    return stepsArray
  }

  // Cette fonction permet de créer les chips statut et facture du composant workflow
  const createChips = (): Steper | null => {
    if (isMissionEnProposeeMedecin) {
      return {
        chipsStatut: { title: `Mission proposée médecin le ${formatDateFR(dateProposition)}` },
        // pas de chipsFacture pour ce statut mission
      }
    }

    if (isMissionAnnulee) {
      return {
        chipsStatut: { title: `Mission annulée le ${formatDateFR(dateMiseAJour)}` },
        // pas de chipsFacture pour ce statut mission
      }
    }

    if (isMissionRefuseeMedecin) {
      return {
        chipsStatut: { title: `Mission refusée médecin le ${formatDateFR(dateMiseAJour)}` },
        // pas de chipsFacture pour ce statut mission
      }
    }

    if (isMissionProposeeSansSuite) {
      return {
        chipsStatut: { title: `Mission proposée - classée sans suite entreprise le ${formatDateFR(dateMiseAJour)}` },
        // pas de chipsFacture pour ce statut mission
      }
    }

    if (isMissionEnCours) {
      return {
        chipsStatut: chipsStatutEnCours(),
        chipsFacture: chipsFacture(),
      }
    }

    if (isMissionConclusionsValideesMedecin && rapport) {
      return {
        chipsStatut: {
          title: `${labelTypeRapport(rapport)} le ${formatDateFR(rapport.dateValidationMedecin)}`,
        },
        chipsFacture: chipsFacture(),
      }
    }

    if (isMissionComplementsAjustements && rapport) {
      return {
        chipsStatut: {
          title: `${labelTypeRapport(rapport)} le ${formatDateFR(
            rapport.dateValidationMedecin
          )} - en attente de compléments`,
        },
        chipsFacture: chipsFacture(),
      }
    }

    if (isMissionCloturee && rapport) {
      return {
        chipsStatut: {
          title: `Mission clôturée le ${formatDateFR(dateMiseAJour)}`,
        },
        chipsFacture: chipsFacture(),
      }
    }

    if (isMissionArretee) {
      return {
        chipsStatut: {
          title: `Mission arrêtée le ${formatDateFR(dateCloture)}`,
        },
        chipsFacture: chipsFacture(),
      }
    }
    return null
  }

  const showCommentaire = (commentaire: string) => {
    confirm({
      title: 'Commentaire',
      maxWidth: 'md',
      confirmMsg: commentaire,
      withForm: true,
      form: (
        <Grid container paddingTop={0} width="500px">
          <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
            <Grid item>
              <AnnulerButton onClick={() => closeConfirmDialog()}>Fermer</AnnulerButton>
            </Grid>
          </Grid>
        </Grid>
      ),
    })
  }

  const chips = createChips()
  const steps = createSteps()
  const stepper = { ...chips, steps }

  // navigate mission tab if rapport exists
  const navigateTabRapport = () => {
    if (mission.rapport) {
      navigateTab(mission.code)
    }
  }

  // navigate factures tab
  const navigateTabFactures = () => {
    navigateTab(TabsConsultationDossierEntrepriseTabId.FACTURES_TAB)
  }

  // render:
  return (
    <>
      {confirmDialog}
      <Stack direction="row">
        {stepper?.chipsStatut && (
          <TitleChip
            title={stepper?.chipsStatut?.title}
            modeError={stepper?.chipsStatut?.withError}
            onClick={mission.rapport ? navigateTabRapport : undefined}
          />
        )}
        {stepper?.chipsFacture && (
          <TitleChip
            title={stepper?.chipsFacture?.title}
            modeError={stepper?.chipsFacture?.withError}
            onClick={navigateTabFactures}
          />
        )}
      </Stack>
      <Stepper orientation="vertical" sx={{ paddingTop: 1.5, marginBottom: -1 }}>
        {stepper?.steps &&
          stepper.steps.map((step: StepType) => (
            <Step key={step.key} completed>
              <StepLabel StepIconComponent={StepIconComponent}>
                <Stack direction="row" alignItems="center">
                  {step.label}
                  {step.commentaire !== undefined && (
                    <InformationIconButton color="secondary" onClick={() => showCommentaire(step.commentaire ?? '')} />
                  )}
                </Stack>
              </StepLabel>
            </Step>
          ))}
      </Stepper>
    </>
  )
}
