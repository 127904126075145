import React from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { Stack } from '@mui/material'
import TableauDeBordAdminPart from 'admin/part/home/TableauDeBordAdminPart'
import ActualitesPart from 'plateforme/parts/ActualitesPart'
import MessageAccueilPart from 'plateforme/parts/MessageAccueilPart'
import { determineAccesTableauDeBord } from 'admin/services/rolesAdmin.service'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import { FallbackError } from 'plateforme/components/dialog/ErrorFallback'

export default function HomeAdminPage() {
  const handleError = useErrorHandler()
  const {
    data: utilisateur,
    isError: isErrorUtilisateur,
    error: errorUtilisateur,
    isLoading: isLoadingUtilisateur,
    isFetching: isFetchingUtilisateur,
  } = useGetUtilisateurActifQuery()
  if (isErrorUtilisateur) {
    handleError({
      errorApi: errorUtilisateur,
      title: `Erreur chargement de l'utilisateur`,
      api: 'GetUtilisateurActif',
    } as FallbackError)
  }

  const loading = isLoadingUtilisateur || isFetchingUtilisateur
  const profilUtilisateur = utilisateur?.profilActif
  return (
    <Stack>
      <MessageAccueilPart utilisateur={utilisateur} loading={loading} />
      <ActualitesPart loading={loading} />
      {determineAccesTableauDeBord(profilUtilisateur) && (
        <TableauDeBordAdminPart utilisateur={utilisateur} loading={loading} />
      )}
    </Stack>
  )
}
