import React from 'react'
import { Alert, Grid } from '@mui/material'
import { InfiniteScrollComponent, ResultatRechercheBarCard } from 'plateforme/components'
import { SortParam, SortOption } from 'plateforme/store/types/pageRecherche'
import DossierEntreprise, { DossierEntrepriseSortType } from 'assureur/store/types/dossierEntreprise'
import { AuthDownloadAction } from 'plateforme/store/types/utilisateur'
import { toTelechargementExportDossierHref } from 'plateforme/App'
import DossierCard from './DossierCard'

type ListeDossierEntreprisePartProps = {
  dossiers: DossierEntreprise[]
  total?: number
  readMore: VoidFunction
  sortBy: (param: SortParam<DossierEntrepriseSortType>) => void
  initSort: VoidFunction
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
  exportParams?: string
}

const sortOptions: SortOption<DossierEntrepriseSortType>[] = [
  { sortType: DossierEntrepriseSortType.DATE_CREATION_DOSSIER_SORT, label: `Date de création` },
  { sortType: DossierEntrepriseSortType.CADRE_EXPERTISE_SORT, label: `Cadre de l'expertise` },
  { sortType: DossierEntrepriseSortType.NATURE_EVENEMENT_SORT, label: `Nature de l'événement` },
  { sortType: DossierEntrepriseSortType.REFERENCE_DOSSIER_SORT, label: 'Référence dossier entreprise' },
  { sortType: DossierEntrepriseSortType.MEDECIN_SORT, label: 'Médecin' },
  { sortType: DossierEntrepriseSortType.STATUT_SORT, label: 'Statut' },
]

export default function ListeDossierEntreprisePart({
  dossiers,
  total = 0,
  readMore,
  sortBy,
  initSort,
  isError,
  isLoading,
  isFetching,
  isSuccess,
  exportParams,
}: ListeDossierEntreprisePartProps) {
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}
    >
      <Grid item container xs={12} paddingBottom={2.5}>
        <ResultatRechercheBarCard
          title="Liste des dossiers"
          total={total}
          loading={isLoading || isFetching}
          sortBy={sortBy}
          sortOptions={sortOptions}
          initSort={initSort}
          withSorting
          withExportCSV
          hrefExportCSV={toTelechargementExportDossierHref(exportParams)}
          requestExportCSV={{ action: AuthDownloadAction.TELECHARGER_CSV_DOSSIER }}
        />
      </Grid>
      {(isError && !isSuccess && !isLoading && !isFetching) || total === 0 ? (
        <Alert severity="info" sx={{ width: '100%' }}>
          {isLoading ? 'En cours de chargement' : 'Aucun dossier remonté'}
        </Alert>
      ) : (
        <InfiniteScrollComponent
          dataLength={dossiers?.length}
          next={readMore}
          hasMore={dossiers.length < (total ?? 0)}
          isLoading={isLoading || isFetching}
          xs={12}
        >
          <Grid container item xs={12}>
            {dossiers?.map((dossier) => (
              <Grid item xs={12} key={dossier.refDossierEntreprise}>
                <DossierCard dossier={dossier} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScrollComponent>
      )}
    </Grid>
  )
}
