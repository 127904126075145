import { Box } from '@mui/system'
import { useGetVersionApiQuery, useGetVersionQuery } from 'plateforme/store/apis/versionsApi'
import * as React from 'react'

const sxFooter = {
  width: '100%',
  textAlign: 'center',
  color: '#b7b7b7',
  fontSize: '12px',
  fontSmooth: 'auto',
  fontWeight: '500',
}

export default function VersionApplicationPart() {
  const {
    data: versionFront,
    isLoading: isLoadingVersionFront,
    isFetching: isFetchingVersionFront,
  } = useGetVersionQuery()
  const {
    data: versionBack,
    isLoading: isLoadingVersionBack,
    isFetching: isFetchingVersionBack,
  } = useGetVersionApiQuery()

  const isLoading = isLoadingVersionFront || isFetchingVersionFront || isLoadingVersionBack || isFetchingVersionBack
  const year = new Date().getFullYear()

  let version = '...'
  if (!isLoading) {
    const sameVersion = versionFront?.version === versionBack?.version
    version = sameVersion ? versionBack?.version ?? '???' : `${versionFront?.version} – ${versionBack?.version}`
  }

  return <Box sx={sxFooter}>{`TransMED – Agira © ${year} – ${version}`}</Box>
}
