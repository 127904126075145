import React from 'react'
import { Stack } from '@mui/material'
import ListeEntrepriseReferencesPart from 'admin/part/rechercheEntreprises/ListeEntrepriseReferencesPart'
import RechercheEntrepriseReferencesPart from 'admin/part/rechercheEntreprises/RechercheEntrepriseReferencesPart'
import { useGetListeReferentielAdminEntrepriseQuery } from 'admin/store/apis/gestionEntrepriseApi'
import { getEntrepriseReferenceCriteria } from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import NavigationPart from 'plateforme/parts/NavigationPart'
import { useSelector } from 'react-redux'

export default function RechercheEntrepriseReferencesPage() {
  // get default values from localStorage
  const defaultEntrepriseReferenceCriteria = useSelector(getEntrepriseReferenceCriteria)
  const {
    combinedData,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetListeReferentielAdminEntrepriseQuery, {
    defaultCriteria: defaultEntrepriseReferenceCriteria,
  })

  return (
    <Stack>
      <NavigationPart label="Entreprises référencées" />
      <RechercheEntrepriseReferencesPart search={search} searchError={error} />
      <ListeEntrepriseReferencesPart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        entreprises={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        isSuccess={isSuccess}
      />
    </Stack>
  )
}
