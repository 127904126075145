import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { toMedecinHref } from 'plateforme/App'
import { PAGE_LARGE_PADDING_X, PAGE_PADDING_X, PAGE_PADDING_Y } from 'plateforme/constantes'
import ErrorFallback from 'plateforme/components/dialog/ErrorFallback'
import NotFoundPage from 'plateforme/pages/NotFoundPage'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import { medecinRoles } from 'plateforme/services/roles.services'
import RequireAuthInterceptor from 'plateforme/security/RequireAuthInterceptor'
import HomeMedecinPage from './pages/HomeMedecinPage'
import ConsultationDossierMedecinPage from './pages/ConsultationDossierMedecinPage'
import ProfilMedecin from './pages/ProfilMedecinPage'
import RechercheDossierMedecinPage from './pages/RechercheDossierMedecinPage'
import RechercheHabilitationsMedecinPage from './pages/RechercheHabilitationsMedecinPage'
import RechercheFacturesMedecinPage from './pages/RechercheFactureMedecinPage'
import MenuMedecinPart from './parts/menu/MenuMedecinPart'
import RechercheNotificationsDossierMedecinPage from './pages/RechercheNotificationsDossierMedecinPage'
import RechercheNotificationsMessageMedecinPage from './pages/RechercheNotificationsMessageMedecinPage'

/**
 * Home Médecin
 * Href = /extranet/medecin/
 */
export const basePath = '/'
export const homeHref = toMedecinHref(basePath)

/**
 * Profil Médecin
 * Href = /extranet/medecin/profil
 */
export const profilPath = 'profil'
export const profilHref = toMedecinHref(profilPath)

/**
 * Consultation de details des habilitation du profil Médecin
 * Href = /extranet/medecin/profil/habilitations
 */
export const listeHabilitationsPath = 'profil/habilitations'
export const listeHabilitationsHref = toMedecinHref(listeHabilitationsPath)

/**
 * Recherche de factures Médecin
 * Href = /extranet/medecin/factures
 */
export const rechercheFacturesPath = 'factures'
export const rechercheFacturesHref = toMedecinHref(rechercheFacturesPath)

/**
 * Recherche de dossiers Médecin
 * Href = /extranet/medecin/dossiers
 */
export const rechercheDossiersPath = 'dossiers'
export const rechercheDossiersHref = toMedecinHref(rechercheDossiersPath)

/**
 * Consultation de dossier Médecin
 * Href = /extranet/medecin/dossier/:codeDossier
 */
export const consultationDossierPath = 'dossier/:codeDossier'
export function consultationDossierHref(codeDossier: string) {
  return toMedecinHref(consultationDossierPath).replace(':codeDossier', codeDossier)
}

/**
 * Consultation des notifications dossiers
 * Href = /extranet/medecin/notifications/dossiers
 */
export const listeNotificationsDossiersPath = 'notifications/dossiers'
export const listeNotificationsDossiersHref = toMedecinHref(listeNotificationsDossiersPath)

/**
 * Consultation des notifications messages
 * Href = /extranet/medecin/notifications/messages
 */
export const listeNotificationsMessagesPath = 'notifications/messages'
export const listeNotificationsMessagesHref = toMedecinHref(listeNotificationsMessagesPath)

/**
 * Upload urls
 */
export function toUploadDossierDocumentUrl(codeDossier: string) {
  return `${transmedApiUrl}medecin/dossier/${codeDossier}/document`
}

export function toUploadRapportDocumentUrl(codeDossier: string, codeMission: string) {
  return `${transmedApiUrl}medecin/dossier/${codeDossier}/mission/${codeMission}/rapport/document`
}

export function toUploadFactureUrl(codeDossier: string, codeMission: string) {
  return `${transmedApiUrl}medecin/dossier/${codeDossier}/mission/${codeMission}/facture`
}

export function toUploadMessageUrl() {
  return `${transmedApiUrl}medecin/message`
}

/**
 * Router Médecin
 * Root = /extranet/medecin/
 * par defaut tout les roles médecins sont authorisés {@see medecinRoles}
 */
export default function MedecinApp() {
  return (
    <Routes>
      <Route path={basePath} element={<MedecinLayout />}>
        <Route
          index
          element={
            <RequireAuthInterceptor roles={medecinRoles}>
              <ErrorBoundary key="home-medecin-error" FallbackComponent={ErrorFallback}>
                <HomeMedecinPage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={profilPath}
          element={
            <RequireAuthInterceptor roles={medecinRoles}>
              <ErrorBoundary key="profil-medecin-error" FallbackComponent={ErrorFallback}>
                <ProfilMedecin />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={rechercheDossiersPath}
          element={
            <RequireAuthInterceptor roles={medecinRoles}>
              <ErrorBoundary key="recherche-dossier-medecin-error" FallbackComponent={ErrorFallback}>
                <RechercheDossierMedecinPage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={consultationDossierPath}
          element={
            <RequireAuthInterceptor roles={medecinRoles}>
              <ErrorBoundary key="consultation-dossier-medecin-error" FallbackComponent={ErrorFallback}>
                <ConsultationDossierMedecinPage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={listeHabilitationsPath}
          element={
            <RequireAuthInterceptor roles={medecinRoles}>
              <ErrorBoundary key="liste-habilitation-medecin-error" FallbackComponent={ErrorFallback}>
                <RechercheHabilitationsMedecinPage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={rechercheFacturesPath}
          element={
            <RequireAuthInterceptor roles={medecinRoles}>
              <ErrorBoundary key="recherche-facture-medecin-error" FallbackComponent={ErrorFallback}>
                <RechercheFacturesMedecinPage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={listeNotificationsDossiersPath}
          element={
            <RequireAuthInterceptor roles={medecinRoles}>
              <ErrorBoundary key="recherche-notifications-dossier-error" FallbackComponent={ErrorFallback}>
                <RechercheNotificationsDossierMedecinPage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={listeNotificationsMessagesPath}
          element={
            <RequireAuthInterceptor roles={medecinRoles}>
              <ErrorBoundary key="recherche-notifications-medecin-error" FallbackComponent={ErrorFallback}>
                <RechercheNotificationsMessageMedecinPage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path="*"
          element={
            <ErrorBoundary key="not-found-medecin-error" FallbackComponent={ErrorFallback}>
              <NotFoundPage />
            </ErrorBoundary>
          }
        />
      </Route>
    </Routes>
  )
}

/* Layout utilisé par toute les pages de la plateforme médecin */
function MedecinLayout() {
  const theme = useTheme()
  const margin = useMediaQuery(theme.breakpoints.up('xl'))
    ? theme.spacing(PAGE_PADDING_Y, PAGE_LARGE_PADDING_X)
    : theme.spacing(PAGE_PADDING_X)
  return (
    <>
      {/* Nav Bar spécifique médecin */}
      <MenuMedecinPart />
      {/* An <Outlet> should be used in parent route elements to render their child route elements. */}
      <Box margin={margin} paddingBottom={1.5}>
        <Outlet />
      </Box>
    </>
  )
}
