import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { DemandeModification } from 'plateforme/store/types/demandeModification'
import { MotifBlocage, StatutMission, StatutSuiviMission } from 'plateforme/store/types/mission'
import { ReferenceDossier } from './dossierAdmin'
import RapportConclusionAdmin from './rapportConlusionAdmin'
import Facture from './factureAdmin'

export default interface MissionAdmin {
  code: string
  refMissionEntreprise?: string
  refMissionMedecin?: string
  refMissionAnterieure?: string
  libelle?: string
  dossier?: ReferenceDossier
  medecin?: ReferenceMedecin
  statut?: StatutMission
  motifBlocage?: MotifBlocage
  labelMotifBlocage?: string
  labelStatut?: string
  statutSuivi?: StatutSuiviMission
  labelStatutSuivi?: string
  descriptionStatut?: string
  commentaireCloture?: string
  dateExamenPrevue?: Date
  commentaireRefus?: string
  demandesModifications: [DemandeModification]
  rapport?: RapportConclusionAdmin
  factures?: [Facture]
  documents?: DocumentTeleverse[]
  annulable: boolean
  arretable: boolean
  declarableSansSuite: boolean
  modificationDemandable: boolean
  dateProposition?: Date
  dateReponseMedecin?: Date
  dateMiseAJour?: Date
  dateMiseAJourSuiviMission?: Date
  dateValidationMedecin?: Date
  dateCloture?: Date
  dateDemandeModification?: Date
}

export interface ReferenceMission {
  code?: string
  refMissionEntreprise?: string
  refMissionMedecin?: string
  libelle?: string
}

export interface ReferenceMedecin {
  code?: string
  codeRPPS?: string
  codeSIREN?: string
  libelle?: string
}

export enum TypeRapport {
  CONCLUSION_PROVISOIRE = 'PRO',
  CONCLUSION_DEFINITIVE = 'DEF',
  CARENCE = 'CAR',
}

export enum LibelleRapport {
  CONCLUSION_PROVISOIRE = 'conclusions provisoires',
  CONCLUSION_DEFINITIVE = 'conclusions définitives',
  CARENCE = 'carence',
}

export enum StatutRapport {
  EN_COURS = 'EN_COURS',
  VALIDE = 'VALIDE',
  VALIDE_DEFINITIVEMENT = 'VALIDE_DEFINITIVEMENT',
  VALIDE_DEFINITIVEMENT_EN_COURS = 'VALIDE_DEFINITIVEMENT_EN_COURS',
}
