import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { MedecinEntrepriseRequest } from 'assureur/store/types/medecinEntreprise'
import ProfilEntreprise from 'assureur/store/types/profilEntreprise'
import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import EntrepriseAdmin, {
  InscrireEntrepriseRequest,
  ReferentielEntreprise,
  ReferentielEntrepriseFilter,
  ReferentielEntrepriseSortType,
} from '../types/profilEntrepriseAdmin'

export const gestionEntrepriseApi = createApi({
  reducerPath: 'gestionEntrepriseApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['GestionEntreprise'],
  endpoints: (builder) => ({
    getAdminEntreprise: builder.query<ProfilEntreprise, { code: string }>({
      query: ({ code }) => `admin/entreprise/${code}`,
      providesTags: ['GestionEntreprise'],
    }),
    postAdminEntreprise: builder.mutation<ProfilEntreprise, InscrireEntrepriseRequest>({
      query: (body) => {
        return {
          url: 'admin/entreprise',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result, error) => (error ? [] : ['GestionEntreprise']),
    }),
    putAdminEntreprise: builder.mutation<ProfilEntreprise, ProfilEntreprise>({
      query(data) {
        const { code, ...body } = data
        return {
          url: `admin/entreprise/${code}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (result, error, { code }) => (error ? [] : [{ type: 'GestionEntreprise', code }]),
    }),
    getReferentielAdminEntrepriseByCodeMedecin: builder.query<ReferentielEntreprise[], MedecinEntrepriseRequest>({
      query: ({ codeMedecin }) => `admin/medecin/${codeMedecin}/entreprise-partenaire`,
    }),
    getListeReferentielAdminEntreprise: builder.query<
      PageRecherche<EntrepriseAdmin>,
      PageRechercheRequest<ReferentielEntrepriseFilter, ReferentielEntrepriseSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => ({
        url: 'admin/entreprise',
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
        },
      }),
    }),
    getReferentielEntrepriseAdmin: builder.query<ReferentielEntreprise[], void>({
      query: () => `admin/entreprise-referentiel`,
    }),
  }),
})

export const {
  useGetAdminEntrepriseQuery,
  usePutAdminEntrepriseMutation,
  usePostAdminEntrepriseMutation,
  useGetReferentielEntrepriseAdminQuery,
  useGetListeReferentielAdminEntrepriseQuery,
  useGetReferentielAdminEntrepriseByCodeMedecinQuery,
} = gestionEntrepriseApi
