import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DossierEntrepriseFilter } from 'assureur/store/types/dossierEntreprise'
import { FactureFilter } from 'assureur/store/types/factureEntreprise'
import { MedecinEntrepriseFilter } from 'assureur/store/types/medecinEntreprise'
import { RootState } from 'plateforme/store'
import { FluxTechniqueEntrepriseFilter } from 'assureur/store/types/fluxTechniqueEntreprise'
import { endOfYesterday, formatISO, startOfYesterday } from 'date-fns'
import { NotificationEntrepriseFilter } from 'assureur/store/types/notificationsEntreprise'

export interface SearchCriteria {
  dossierCriteria?: DossierEntrepriseFilter
  factureCriteria?: FactureFilter
  medecinCriteria?: MedecinEntrepriseFilter
  notificationCriteria?: NotificationEntrepriseFilter
  fluxTechniqueCriteria?: FluxTechniqueEntrepriseFilter
}

export const searchCrieteriaSliceName = 'searchCriteriaEntreprise'

const searchCriteriaSlice = createSlice({
  name: searchCrieteriaSliceName,
  initialState: {
    dossierCriteria: undefined,
    factureCriteria: undefined,
    medecinCriteria: undefined,
    notificationCriteria: undefined,
  } as SearchCriteria,
  reducers: {
    setDossierCriteria: (state, action: PayloadAction<DossierEntrepriseFilter>) => {
      state.dossierCriteria = action.payload
    },
    setDossierMotCleCriteria: (state, action: PayloadAction<string>) => {
      state.dossierCriteria = {
        motCle: action.payload === '' ? undefined : action.payload,
        codeMedecin: undefined,
        statutsDossier: undefined,
        statutsMission: undefined,
        natureEvenement: undefined,
        cadreExpertise: undefined,
        dateCreationDebut: undefined,
        dateCreationFin: undefined,
        service: undefined,
        horsDelai: undefined,
        aucuneMission: undefined,
      }
    },
    setFactureCriteria: (state, action: PayloadAction<FactureFilter>) => {
      state.factureCriteria = action.payload
    },
    setMedecinCriteria: (state, action: PayloadAction<MedecinEntrepriseFilter>) => {
      state.medecinCriteria = action.payload
    },
    setNotificationCriteria: (state, action: PayloadAction<NotificationEntrepriseFilter>) => {
      state.notificationCriteria = action.payload
    },
    setFluxTechniqueCriteria: (state, action: PayloadAction<FluxTechniqueEntrepriseFilter>) => {
      state.fluxTechniqueCriteria = action.payload
      if (!action.payload.dateDebut) {
        state.fluxTechniqueCriteria.dateDebut = formatISO(startOfYesterday())
      }
      if (!action.payload.dateFin) {
        state.fluxTechniqueCriteria.dateFin = formatISO(endOfYesterday())
      }
    },
  },
})

export const {
  setDossierCriteria,
  setDossierMotCleCriteria,
  setFactureCriteria,
  setMedecinCriteria,
  setNotificationCriteria,
  setFluxTechniqueCriteria,
} = searchCriteriaSlice.actions

export const getSearchCriteria = (state: RootState) => state?.searchCriteriaEntreprise
export const getDossierCriteria = (state: RootState) => state?.searchCriteriaEntreprise.dossierCriteria
export const getFactureCriteria = (state: RootState) => state?.searchCriteriaEntreprise.factureCriteria
export const getMedecinCriteria = (state: RootState) => state?.searchCriteriaEntreprise.medecinCriteria
export const getNotificationCriteria = (state: RootState) => state?.searchCriteriaEntreprise.notificationCriteria
export const getFluxTechniqueCriteria = (state: RootState) => state?.searchCriteriaEntreprise.fluxTechniqueCriteria

export default searchCriteriaSlice.reducer
