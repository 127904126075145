import React from 'react'

import { DommageField } from 'assureur/store/types/dommagesEntreprise'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import determineSuffixeLibelleDommage from 'plateforme/services/dommage.services'
import ReadOnlyDommageTexte from './ReadOnlyDommageTexte'
import ReadOnlyDommagePeriode from './ReadOnlyDommagePeriode'
import ReadOnlyDommageRadioGroup from './ReadOnlyDommageRadioGroup'
import ReadOnlyDommageSlider from './ReadOnlyDommageSlider'
import ReadOnlyDommagePersonBlock from './ReadOnlyDommagePersonBlock'

interface DommagesFieldProps {
  dommage: DommageField
  typeRapport: TypeRapport
}

export default function DommageComponent({ dommage, typeRapport }: DommagesFieldProps) {
  const suffixeTitre = determineSuffixeLibelleDommage(typeRapport)

  switch (dommage.fieldName) {
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_PROFESSIONNELLES':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SCOLAIRES_UNIVERSITAIRES_OU_DE_FORMATION':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_AGREMENT':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SEXUELLES':
    case 'INSTITUTIONNALISATION':
    case 'FRAIS_FUTURS_A_CARACTERE_CERTAIN_ET_PREVISIBLE':
    case 'FRAIS_APPAREILLAGE':
    case 'AMENAGEMENT_DU_DOMICILE':
    case 'AMENAGEMENT_DU_VEHICULE':
    case 'FRAIS_MEDICAUX_ET_PARAMEDICAUX':
    case 'DOMMAGE_ESTHETIQUE_TEMPORAIRE':
      return <ReadOnlyDommageTexte value={dommage.commentaire} title={dommage.label} label="Commentaire" />

    case 'DATES_HOSPITALISATION_IMPUTABLES':
    case 'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES':
      return <ReadOnlyDommagePeriode plageDeDate={dommage?.plageDeDates} label={dommage.label} />

    case 'GENE_TEMPORAIRE_TOTALE':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_1':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_2':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_3':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_4':
      return (
        <ReadOnlyDommageRadioGroup code={dommage?.code} plageDeDate={dommage?.plageDeDates} label={dommage.label} />
      )

    case 'ATTEINTE_A_INTEGRITE_PHYSIQUE_ET_PSYCHIQUE':
      return (
        <ReadOnlyDommageTexte
          value={dommage.valeur === 0 ? '0' : dommage.valeur}
          title={dommage.label + suffixeTitre}
          label="Pourcentage"
        />
      )

    case 'DOMMAGE_ESTHETIQUE_PERMANENT':
    case 'SOUFFRANCES_ENDUREES':
      return <ReadOnlyDommageSlider value={dommage?.valeur} label={dommage.label + suffixeTitre} />

    case 'TIERCE_PERSONNE_DEFINITIVE':
    case 'TIERCE_PERSONNE_TEMPORAIRE':
      return <ReadOnlyDommagePersonBlock periodes={dommage.periodes} label={dommage.label} />

    default:
      return null
  }
}
