import { dossierAssureurApi } from 'assureur/store/apis/dossierAssureurApi'
import {
  addDocumentEntrepriseUpload,
  changeDocumentEntrepriseUpload,
  clearDocumentEntrepriseUpload,
  documentEntrepriseUploadSliceName,
  removeDocumentEntrepriseUpload,
  setDocumentEntrepriseUploadValid,
  startUploadDocumentEntreprise,
} from 'assureur/store/slices/document/documentDossierUploadSlice'
import { dossierMedecinApi } from 'medecin/store/apis/dossierMedecinApi'
import {
  addDocumentUpload,
  changeDocumentUpload,
  clearDocumentUpload,
  documentUploadSliceName,
  removeDocumentUpload,
  setDocumentUploadValid,
  startUploadDocument,
} from 'medecin/store/slices/document/documentDossierUploadSlice'
import {
  addDocumentRapportUpload,
  changeDocumentRapportUpload,
  clearDocumentRapportUpload,
  documentRapportUploadSliceName,
  removeDocumentRapportUpload,
  setDocumentRapportUploadValid,
  startUploadDocumentRapport,
} from 'medecin/store/slices/document/documentRapportUploadSlice'

import { MAX_UPLOAD_SIZE } from 'plateforme/constantes'
import makeUpload from 'plateforme/services/document.services'
import { useEffect } from 'react'
import { DocumentPanelType } from '../types/documentUpload'
import DossierPlateforme from '../types/dossier'
import MissionPlateforme from '../types/mission'
import { StartUploadDocumentDossierConfig } from '../types/startUploadDocumentDossierConfig'
import { useAppDispatch } from './hooks'
import useUpload from './useUpload'

const prepareParamUpload = (documentPanelType: DocumentPanelType) => {
  switch (documentPanelType) {
    case DocumentPanelType.DOSSIER_ENTREPRISE:
      return {
        uploadSliceName: documentEntrepriseUploadSliceName,
        makeUpload,
        addUploadAction: addDocumentEntrepriseUpload,
        maxSize: MAX_UPLOAD_SIZE,
      }
    case DocumentPanelType.DOSSIER_MEDECIN:
      return {
        uploadSliceName: documentUploadSliceName,
        makeUpload,
        addUploadAction: addDocumentUpload,
        maxSize: MAX_UPLOAD_SIZE,
      }
    case DocumentPanelType.RAPPORT:
      return {
        uploadSliceName: documentRapportUploadSliceName,
        makeUpload,
        addUploadAction: addDocumentRapportUpload,
        maxSize: MAX_UPLOAD_SIZE,
      }
    default:
      throw new Error(`Type de documentPanelType non supporté`)
  }
}

const clearDocumentAction = (typeRapport: DocumentPanelType) => {
  if (typeRapport === DocumentPanelType.RAPPORT) {
    return clearDocumentRapportUpload()
  }

  if (typeRapport === DocumentPanelType.DOSSIER_ENTREPRISE) {
    return clearDocumentEntrepriseUpload()
  }
  return clearDocumentUpload()
}

const startUploadDocumentAction = (typeRapport: DocumentPanelType, actionParam: StartUploadDocumentDossierConfig) => {
  if (typeRapport === DocumentPanelType.RAPPORT) {
    return startUploadDocumentRapport(actionParam)
  }
  if (typeRapport === DocumentPanelType.DOSSIER_ENTREPRISE) {
    return startUploadDocumentEntreprise(actionParam)
  }
  return startUploadDocument(actionParam)
}

// todo add user param :
export default function useDocumentActions({
  typeDocumentPanel,
  dossier,
  mission,
}: {
  typeDocumentPanel: DocumentPanelType
  dossier?: DossierPlateforme
  mission?: MissionPlateforme
}) {
  const codeDossier = dossier?.code
  const codeMission = mission?.code
  const dispatch = useAppDispatch()
  const paramUpload = prepareParamUpload(typeDocumentPanel)
  const { filesMapRef, styledDropzoneProps, uploaderState } = useUpload(paramUpload)

  const onClear = () => dispatch(clearDocumentAction(typeDocumentPanel))

  useEffect(() => {
    dispatch(clearDocumentAction(typeDocumentPanel))
  }, [typeDocumentPanel, dispatch])

  const onStartUploadMissionDocuments = async () => {
    if (!codeDossier || !codeMission) {
      throw new Error('Aucune mission associée n&apos;a été trouvée')
    }
    await dispatch(
      startUploadDocumentAction(typeDocumentPanel, {
        codeDossier,
        codeMission,
        uploadFileMap: filesMapRef.current,
      })
    )
  }

  const onStartUploadDossierDocuments = async () => {
    if (codeDossier === undefined) {
      throw new Error('Aucun dossier associée n&apos;a été trouvé')
    }
    await dispatch(
      startUploadDocumentAction(typeDocumentPanel, {
        codeDossier,
        uploadFileMap: filesMapRef.current,
      })
    )
  }

  const onStartUpload = () => {
    if (typeDocumentPanel === DocumentPanelType.RAPPORT) {
      onStartUploadMissionDocuments()
    } else {
      onStartUploadDossierDocuments()
    }
  }

  const changeDocumentUploadAction = () => {
    if (typeDocumentPanel === DocumentPanelType.RAPPORT) {
      return changeDocumentRapportUpload
    }
    if (typeDocumentPanel === DocumentPanelType.DOSSIER_ENTREPRISE) {
      return changeDocumentEntrepriseUpload
    }
    return changeDocumentUpload
  }

  const removeDocumentUploadAction = () => {
    if (typeDocumentPanel === DocumentPanelType.RAPPORT) {
      return removeDocumentRapportUpload
    }
    if (typeDocumentPanel === DocumentPanelType.DOSSIER_ENTREPRISE) {
      return removeDocumentEntrepriseUpload
    }
    return removeDocumentUpload
  }

  const setDocumentUploadValidAction = () => {
    if (typeDocumentPanel === DocumentPanelType.RAPPORT) {
      return setDocumentRapportUploadValid
    }
    if (typeDocumentPanel === DocumentPanelType.DOSSIER_ENTREPRISE) {
      return setDocumentEntrepriseUploadValid
    }
    return setDocumentUploadValid
  }

  // NOTE: il faut vider le cache dans le cas isSuccessUploads est vrai seulement:
  useEffect(() => {
    if (uploaderState.isSuccessUploads && !uploaderState.isErrorUploads && !uploaderState.isRunning) {
      dispatch(
        dossierAssureurApi.util.invalidateTags([
          { type: 'Dossier', id: codeDossier },
          { type: 'Mission', id: codeMission },
        ])
      )
      dispatch(
        dossierMedecinApi.util.invalidateTags([
          { type: 'Dossier', id: codeDossier },
          { type: 'Mission', id: codeMission },
        ])
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploaderState.isSuccessUploads, uploaderState.isErrorUploads, uploaderState.isRunning])

  return {
    styledDropzoneProps,
    uploaderState,
    onClear,
    onStartUpload,
    changeDocumentUploadAction: changeDocumentUploadAction(),
    removeDocumentUploadAction: removeDocumentUploadAction(),
    setDocumentUploadValidAction: setDocumentUploadValidAction(),
  }
}
