import RapportConclusionMedecin, {
  Dommage,
  Dommages,
  LesionRequest,
  LesionSequelleRequest,
  ModeDommage,
  Periode,
  PeriodeRequest,
  SauvegarderConclusionRequest,
  TiercePersonne,
} from 'medecin/store/types/rapportConclusionMedecin'
import { formatDateISO } from 'plateforme/services/dates.services'
import { trimToUndefined } from 'plateforme/services/utils'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import { ReferentielDommage } from 'plateforme/store/types/referentiel'
import {
  DommageData,
  EstimationData,
  FicheConclusionData,
  PeriodeData,
  PlageDeDateData,
  TiercePersonneData,
} from './FicheRapportMedecinForm'

export const formatPeriode = (periode: Periode): PeriodeRequest => {
  return {
    au: periode?.au !== undefined ? formatDateISO(periode.au) : undefined,
    du: periode?.du !== undefined ? formatDateISO(periode.du) : undefined,
  }
}

const convertToDommage = (dommage: DommageData | undefined): Dommage | undefined => {
  switch (dommage?.code) {
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_PROFESSIONNELLES':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SCOLAIRES_UNIVERSITAIRES_OU_DE_FORMATION':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_AGREMENT':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SEXUELLES':
    case 'INSTITUTIONNALISATION':
    case 'FRAIS_FUTURS_A_CARACTERE_CERTAIN_ET_PREVISIBLE':
    case 'FRAIS_APPAREILLAGE':
    case 'AMENAGEMENT_DU_DOMICILE':
    case 'AMENAGEMENT_DU_VEHICULE':
    case 'FRAIS_MEDICAUX_ET_PARAMEDICAUX':
    case 'DOMMAGE_ESTHETIQUE_TEMPORAIRE':
      return {
        codePrejudice: dommage.codePrejudice,
        code: dommage.code,
        label: dommage.label,
        typeDommage: dommage.typeDommage,
        commentaire: trimToUndefined(dommage.commentaire),
      } as Dommage

    case 'DATES_HOSPITALISATION_IMPUTABLES':
    case 'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES':
    case 'GENE_TEMPORAIRE_TOTALE':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_1':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_2':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_3':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_4':
      return {
        codePrejudice: dommage.codePrejudice,
        code: dommage.code,
        label: dommage.label,
        typeDommage: dommage.typeDommage,
        plageDeDates: {
          mode: dommage?.plageDeDates && dommage.plageDeDates.mode ? dommage.plageDeDates.mode : undefined,
          min:
            dommage?.plageDeDates && dommage.plageDeDates.mode === ModeDommage.FOURCHETTE && dommage.plageDeDates.min
              ? dommage.plageDeDates.min
              : undefined,
          max:
            dommage?.plageDeDates && dommage.plageDeDates.mode === ModeDommage.FOURCHETTE && dommage.plageDeDates.max
              ? dommage.plageDeDates.max
              : undefined,
          estimation:
            dommage?.plageDeDates &&
            dommage.plageDeDates.mode === ModeDommage.ESTIMATION &&
            dommage.plageDeDates.estimation
              ? dommage.plageDeDates.estimation
              : undefined,
          periodes:
            dommage?.plageDeDates && dommage.plageDeDates.mode === ModeDommage.PERIODE && dommage.plageDeDates.periodes
              ? dommage.plageDeDates.periodes.map((periode) => formatPeriode(periode as Periode))
              : undefined,
        },
      } as Dommage

    /*     case 'SOUFFRANCES_ENDUREES':
      return {
        codePrejudice: dommage.codePrejudice,
        code: dommage.code,
        label: dommage.label,
        typeDommage: dommage.typeDommage,
        min: dommage?.valeur instanceof Array && dommage?.valeur.length > 0 ? dommage?.valeur[0] : undefined,
        max: dommage?.valeur instanceof Array && dommage?.valeur.length > 1 ? dommage?.valeur[1] : undefined,
      } as Dommage */

    case 'ATTEINTE_A_INTEGRITE_PHYSIQUE_ET_PSYCHIQUE':
    case 'DOMMAGE_ESTHETIQUE_PERMANENT':
    case 'SOUFFRANCES_ENDUREES':
      return {
        codePrejudice: dommage.codePrejudice,
        code: dommage.code,
        label: dommage.label,
        typeDommage: dommage.typeDommage,
        valeur: dommage.valeur ?? undefined,
      } as Dommage

    case 'TIERCE_PERSONNE_DEFINITIVE':
    case 'TIERCE_PERSONNE_TEMPORAIRE':
      return {
        codePrejudice: dommage.codePrejudice,
        code: dommage.code,
        label: dommage.label,
        typeDommage: dommage.typeDommage,
        periodes: dommage?.periodes
          ? dommage.periodes.map((tiercePersonne) => {
              return {
                type: tiercePersonne.type ?? undefined,
                nombreHeures: tiercePersonne.nombreHeures ?? undefined,
                par: tiercePersonne.par ?? undefined,
                periode: formatPeriode(tiercePersonne.periode as Periode),
              } as TiercePersonne
            })
          : undefined,
      } as Dommage

    default:
      return undefined
  }
}

export const convertToDommageForm = (dommage?: Dommage, dommagePredefini?: ReferentielDommage): DommageData => {
  switch (dommagePredefini?.code) {
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_PROFESSIONNELLES':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SCOLAIRES_UNIVERSITAIRES_OU_DE_FORMATION':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_AGREMENT':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SEXUELLES':
    case 'INSTITUTIONNALISATION':
    case 'FRAIS_FUTURS_A_CARACTERE_CERTAIN_ET_PREVISIBLE':
    case 'FRAIS_APPAREILLAGE':
    case 'AMENAGEMENT_DU_DOMICILE':
    case 'AMENAGEMENT_DU_VEHICULE':
    case 'FRAIS_MEDICAUX_ET_PARAMEDICAUX':
    case 'DOMMAGE_ESTHETIQUE_TEMPORAIRE':
      return {
        codePrejudice: dommagePredefini.codePrejudice,
        code: dommagePredefini.code,
        label: dommagePredefini.label,
        typeDommage: dommagePredefini.typeDommage,
        commentaire: dommage?.commentaire ? dommage.commentaire : '',
      } as DommageData

    case 'DATES_HOSPITALISATION_IMPUTABLES':
    case 'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES':
      return {
        codePrejudice: dommagePredefini.codePrejudice,
        code: dommagePredefini.code,
        label: dommagePredefini.label,
        typeDommage: dommagePredefini.typeDommage,
        plageDeDates: {
          mode: ModeDommage.PERIODE,
          periodes: (dommage?.plageDeDates?.periodes ?? [undefined]).map((p) => {
            return { du: p?.du ?? null, au: p?.au ?? null } as PeriodeData
          }),
          min: null,
          max: null,
          estimation: null,
        } as PlageDeDateData,
      } as DommageData
    case 'GENE_TEMPORAIRE_TOTALE':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_1':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_2':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_3':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_4':
      return {
        codePrejudice: dommagePredefini.codePrejudice,
        code: dommagePredefini.code,
        label: dommagePredefini.label,
        typeDommage: dommagePredefini.typeDommage,
        plageDeDates: {
          mode: dommage?.plageDeDates?.mode ?? null,
          periodes: (dommage?.plageDeDates?.periodes ?? [undefined]).map((p) => {
            return { du: p?.du ?? null, au: p?.au ?? null } as PeriodeData
          }),
          min: dommage?.plageDeDates?.min ?? ({ valeur: '', unite: null } as EstimationData),
          max: dommage?.plageDeDates?.max ?? ({ valeur: '', unite: null } as EstimationData),
          estimation: dommage?.plageDeDates?.estimation ?? ({ valeur: '', unite: null } as EstimationData),
        } as PlageDeDateData,
      } as DommageData

    /*    case 'SOUFFRANCES_ENDUREES':
      return {
        codePrejudice: dommagePredefini.codePrejudice,
        code: dommagePredefini.code,
        label: dommagePredefini.label,
        typeDommage: dommagePredefini.typeDommage,
        valeur: [dommage?.min ?? null, dommage?.max ?? null],
        min: dommage?.min ?? null,
        max: dommage?.max ?? null,
      } as DommageData */

    case 'ATTEINTE_A_INTEGRITE_PHYSIQUE_ET_PSYCHIQUE':
      return {
        codePrejudice: dommagePredefini.codePrejudice,
        code: dommagePredefini.code,
        label: dommagePredefini.label,
        typeDommage: dommagePredefini.typeDommage,
        valeur: dommage?.valeur ?? '',
      } as DommageData

    case 'DOMMAGE_ESTHETIQUE_PERMANENT':
    case 'SOUFFRANCES_ENDUREES':
      return {
        codePrejudice: dommagePredefini.codePrejudice,
        code: dommagePredefini.code,
        label: dommagePredefini.label,
        typeDommage: dommagePredefini.typeDommage,
        valeur: dommage?.valeur ?? null,
      } as DommageData

    case 'TIERCE_PERSONNE_DEFINITIVE':
    case 'TIERCE_PERSONNE_TEMPORAIRE':
      return {
        codePrejudice: dommagePredefini.codePrejudice,
        code: dommagePredefini.code,
        label: dommagePredefini.label,
        typeDommage: dommagePredefini.typeDommage,
        periodes: (dommage?.periodes ?? [undefined]).map((p) => {
          return {
            type: p?.type ?? null,
            nombreHeures: p?.nombreHeures ?? '',
            par: p?.par ?? null,
            periode: { du: p?.periode?.du ?? null, au: p?.periode?.au ?? null } as PeriodeData,
          } as TiercePersonneData
        }),
      } as DommageData

    default:
      return {
        codePrejudice: dommagePredefini?.codePrejudice,
        code: dommagePredefini?.code,
        label: dommagePredefini?.label,
        typeDommage: dommagePredefini?.typeDommage,
      } as DommageData
  }
}

// build de l'objet SauvegarderConclusionRequest
export const prepareSauvegarderConclusionRequestFromRapportConclusionMedecin = (
  codeDossier: string,
  codeMission: string,
  typeRapport: TypeRapport,
  validation: boolean,
  rapportConclusionMedecin: RapportConclusionMedecin
): SauvegarderConclusionRequest => {
  return {
    codeDossier,
    codeMission,
    typeRapport,
    validation,
    nomPersonneAExaminer: trimToUndefined(rapportConclusionMedecin.nomPersonneAExaminer),
    prenomPersonneAExaminer: trimToUndefined(rapportConclusionMedecin.prenomPersonneAExaminer),
    dateNaissancePersonneAExaminer: formatDateISO(rapportConclusionMedecin.dateNaissancePersonneAExaminer),
    lieuSignature: trimToUndefined(rapportConclusionMedecin.lieuSignature),
    dateSignature: formatDateISO(rapportConclusionMedecin.dateSignature),
    consentement: rapportConclusionMedecin.consentement,
    refConsentement: rapportConclusionMedecin.refConsentement,
    signataire: trimToUndefined(rapportConclusionMedecin.signataire),
    commentaireNouvelExamen: trimToUndefined(rapportConclusionMedecin.commentaireNouvelExamen),
    commentaire: trimToUndefined(rapportConclusionMedecin.commentaire),
    dateConsolidationPro: formatDateISO(rapportConclusionMedecin.dateConsolidationPro),
    dateConsolidationDef: formatDateISO(rapportConclusionMedecin.dateConsolidationDef),
    delaiNouvelExamen: trimToUndefined(rapportConclusionMedecin.delaiNouvelExamen),
    dateExamen: formatDateISO(rapportConclusionMedecin.dateExamen),
    lesions: rapportConclusionMedecin.lesions ?? [],
    lesionSequelles:
      rapportConclusionMedecin.lesionSequelles?.map(
        ({ lesion, sequelle }) =>
          ({
            lesion,
            sequelle,
          } as LesionSequelleRequest)
      ) || [],
    dommages: rapportConclusionMedecin.dommages,
    motifCarence: trimToUndefined(rapportConclusionMedecin.motifCarence),
    remissionnementPreconise: !!rapportConclusionMedecin.remissionnementPreconise,
  }
}

export const prepareSauvegarderConclusionRequestFromDataConclusionForm = (
  codeDossier: string,
  codeMission: string,
  typeRapport: TypeRapport,
  validation: boolean,
  dataConclusionForm: FicheConclusionData
): SauvegarderConclusionRequest => {
  const dommages = dataConclusionForm.dommages || {}
  return {
    codeDossier,
    codeMission,
    typeRapport,
    validation,
    nomPersonneAExaminer: trimToUndefined(dataConclusionForm.nomPersonneAExaminer),
    prenomPersonneAExaminer: trimToUndefined(dataConclusionForm.prenomPersonneAExaminer),
    dateNaissancePersonneAExaminer: formatDateISO(dataConclusionForm.dateNaissancePersonneAExaminer),
    lieuSignature: trimToUndefined(dataConclusionForm.lieuSignature),
    dateSignature: formatDateISO(dataConclusionForm.dateSignature),
    consentement: dataConclusionForm.consentement,
    refConsentement: trimToUndefined(dataConclusionForm.refConsentement),
    signataire: trimToUndefined(dataConclusionForm.signataire),
    commentaireNouvelExamen: trimToUndefined(dataConclusionForm.commentaireNouvelExamen),
    commentaire: trimToUndefined(dataConclusionForm.commentaire),
    dateConsolidationPro: formatDateISO(dataConclusionForm.dateConsolidationPro),
    dateConsolidationDef: formatDateISO(dataConclusionForm.dateConsolidationDef),
    delaiNouvelExamen: trimToUndefined(dataConclusionForm.delaiNouvelExamen),
    dateExamen: formatDateISO(dataConclusionForm.dateExamen),
    lesions: dataConclusionForm.lesions?.map((lesion: LesionRequest) => trimToUndefined(lesion?.code)) || [],
    lesionSequelles:
      dataConclusionForm.lesionSequelles?.map((lesionSequelle: LesionSequelleRequest) => {
        return {
          lesion: trimToUndefined(lesionSequelle?.lesion),
          sequelle: trimToUndefined(lesionSequelle?.sequelle),
        } as LesionSequelleRequest
      }) || [],
    dommages: Object.fromEntries(
      Object.keys(dommages)
        .filter((code) => dommages[code])
        .map((code) => convertToDommage(dommages[code]))
        .map((df) => [df?.code, df])
    ) as Dommages,
    motifCarence: trimToUndefined(dataConclusionForm.motifCarence),
    remissionnementPreconise: dataConclusionForm.remissionnementPreconise,
  }
}
