import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Stack } from '@mui/material'
import Prompt from 'plateforme/components/dialog/Prompt'
import { StatutDossier } from 'plateforme/store/types/dossier'
import { AreaLoading } from 'plateforme/components'
import useErrorFormMapper, {
  IQueryErrorDetailsResponse,
  IQueryErrorResponse,
} from 'plateforme/hooks/useErrorFormMapper'
import { trimToRemove, trimToUndefined } from 'plateforme/services/utils'
import {
  SuiviDossierAction,
  SuiviDossierRequest,
  usePostSuiviDossierMutation,
  usePutModifierDossierMutation,
} from 'assureur/store/apis/dossierAssureurApi'
import DossierEntreprise, {
  ContactEntreprise,
  EtapeCreationParam,
  ModificationDossierRequest,
} from 'assureur/store/types/dossierEntreprise'
import { formatDateISO } from 'plateforme/services/dates.services'
import ProfilEntreprise, { CodeContact, ContactInformations } from 'assureur/store/types/profilEntreprise'
import EditionDossierEntrepriseButtons from 'assureur/parts/editionDossier/EditionDossierEntrepriseButtons'
import { consultationDossierHref } from 'assureur/EntrepriseApp'
import { getErrorDossier, setErrorDossier } from 'assureur/store/slices/dossier/errorDossierSlice'
import useTabNavigate from 'plateforme/hooks/useTabNavigate'
import Referentiel from 'plateforme/store/types/referentiel'
import GestionDossierEntreprise from './GestionDossierEntreprise'
import IdentificationDossier from './IdentificationDossier'
import InformationsEvenement from './InformationsEvenement'
import { TabId } from '../EditionDossierEntreprisePart/EditionDossierEntreprisePart'

type InformationsDossierFormProps = {
  dossier?: DossierEntreprise
  profilEntreprise?: ProfilEntreprise
  loading?: boolean
  referentiel?: Referentiel
  onContinue: VoidFunction
}

type InformationsDossierFormValues = {
  refDossierEntreprise: string
  refVictimeEntreprise: string
  typeContactEntreprise: string
  contactEntreprisePersonnalise: {
    libelle: string
    mail: string
    telephone: string
  }
  evenement: {
    reference: string
    nature: string
    date: Date | null
    commentaire: string
  }
}

export function initContactEntrepriseParametrage(profil?: ProfilEntreprise, natureEvenement?: string) {
  const contactEntrepriseParametrageEntry = Object.entries(profil?.parametrage?.contacts ?? {}).find(
    (a) => a[0] === natureEvenement
  )

  const contactEntrepriseParametrage = contactEntrepriseParametrageEntry
    ? (contactEntrepriseParametrageEntry[1] as ContactInformations)
    : undefined
  const isContactEntreprisePersonnaliseParametrage =
    contactEntrepriseParametrage?.code === CodeContact.CODE_BAL_PERSONNALISE
  return {
    typeContact: contactEntrepriseParametrage?.code ?? '',
    contactPersonnalise: {
      libelle: isContactEntreprisePersonnaliseParametrage ? contactEntrepriseParametrage?.libelle ?? '' : '',
      mail: isContactEntreprisePersonnaliseParametrage ? contactEntrepriseParametrage?.mail ?? '' : '',
      telephone: isContactEntreprisePersonnaliseParametrage ? contactEntrepriseParametrage?.telephone ?? '' : '',
    },
  }
}

export function initContactEntreprisePersonnalise(
  typeContactEntreprise?: string,
  contactEntreprise?: ContactEntreprise
) {
  const isContactEntreprisePersonnalise = typeContactEntreprise === CodeContact.CODE_BAL_PERSONNALISE

  return isContactEntreprisePersonnalise
    ? {
        libelle: contactEntreprise?.libelle ?? '',
        mail: contactEntreprise?.mail ?? '',
        telephone: contactEntreprise?.telephone ?? '',
      }
    : undefined
}

export default function InformationsDossierForm({
  dossier,
  profilEntreprise,
  loading,
  referentiel,
  onContinue,
}: InformationsDossierFormProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={450} />
  }
  return (
    <InformationsDossierFormLoaded
      dossier={dossier}
      profilEntreprise={profilEntreprise}
      loading={loading}
      referentiel={referentiel}
      onContinue={onContinue}
    />
  )
}

function InformationsDossierFormLoaded({
  dossier,
  profilEntreprise,
  referentiel,
  onContinue,
}: InformationsDossierFormProps) {
  // error:
  if (!dossier || !dossier.editable || !profilEntreprise) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const {
    code: codeDossier,
    refDossierEntreprise,
    refVictimeEntreprise,
    typeContactEntreprise,
    contactEntreprise,
    evenement,
  } = dossier

  // default values:
  const contactEntrepriseParametrage = initContactEntrepriseParametrage(profilEntreprise, evenement?.nature)
  const initialContactEntreprisePersonnalise = initContactEntreprisePersonnalise(
    typeContactEntreprise,
    contactEntreprise
  )
  const defaultValues = {
    refDossierEntreprise: refDossierEntreprise ?? '',
    refVictimeEntreprise: refVictimeEntreprise ?? '',
    typeContactEntreprise: typeContactEntreprise ?? contactEntrepriseParametrage.typeContact,
    contactEntreprisePersonnalise:
      initialContactEntreprisePersonnalise ?? contactEntrepriseParametrage.contactPersonnalise,
    evenement: {
      reference: evenement?.reference ?? '',
      nature: evenement?.nature ?? '',
      date: evenement?.date ?? null,
      commentaire: evenement?.commentaire ?? '',
    },
  } as InformationsDossierFormValues

  // hooks:
  const { navigateTab } = useTabNavigate()
  const dispatch = useDispatch()
  const [postSuiviDossier] = usePostSuiviDossierMutation()
  const [putModifierDossier, { error: errorSauvegarder, isLoading: loadingSauvegarder }] =
    usePutModifierDossierMutation()
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  })
  const {
    getValues,
    reset,
    setError,
    setValue,
    watch,
    formState: { isDirty, touchedFields },
  } = methods
  const [evenementNatureWatched, typeContactEntrepriseWatched] = watch(['evenement.nature', 'typeContactEntreprise'])
  const [isBrouillon, setIsBrouillon] = useState(false)

  // Si le contact n'a pas été changé et que la nature d'evenement change alors on recalcule le contact avec le parametrage
  useEffect(() => {
    if (
      Object.keys(touchedFields).length === 0 &&
      evenementNatureWatched === evenement?.nature &&
      typeContactEntrepriseWatched === typeContactEntreprise
    ) {
      return
    }

    const newParametrage = initContactEntrepriseParametrage(profilEntreprise, trimToUndefined(evenementNatureWatched))
    setValue('typeContactEntreprise', newParametrage.typeContact ?? '')
    setValue('contactEntreprisePersonnalise.libelle', newParametrage.contactPersonnalise.libelle)
    setValue('contactEntreprisePersonnalise.mail', newParametrage.contactPersonnalise.mail)
    setValue('contactEntreprisePersonnalise.telephone', newParametrage.contactPersonnalise.telephone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evenementNatureWatched])
  const { enqueueSnackbar } = useSnackbar()
  const dataErrorDossier = useSelector(getErrorDossier)
  const errorToBeMapped = (errorSauvegarder || dataErrorDossier.errorDossier) as IQueryErrorResponse
  useErrorFormMapper(errorToBeMapped as IQueryErrorResponse, setError, getValues)
  const navigate = useNavigate()

  // format:
  const formId = 'form-info-dossier'

  // leave:
  const onLeave = () => {
    reset()
    navigate(consultationDossierHref(codeDossier))
  }

  // save form and leave: :
  const onSubmitAndLeave = async () => {
    await onSubmit(onLeave)
  }

  // save form and continue: :
  const onSubmitAndContinue = async () => {
    await onSubmit(onContinue)
  }

  // save form: :
  const onSubmit = async (onAfterSuccess: VoidFunction) => {
    const formValues = getValues() as InformationsDossierFormValues

    const refDossierEntrepriseFormValues =
      dossier.statut === StatutDossier.EN_CREATION
        ? trimToRemove(formValues.refDossierEntreprise)
        : refDossierEntreprise

    const refVictimeEntrepriseFormValues =
      dossier.statut === StatutDossier.EN_CREATION
        ? trimToRemove(formValues.refVictimeEntreprise)
        : refVictimeEntreprise

    const natureEvenementFormValues = trimToUndefined(formValues.evenement?.nature)

    const qualiteRequise = referentiel?.naturesEvenement
      .find((ne) => ne.code === natureEvenementFormValues)
      ?.tags.includes('QUALITE_PERSONNE_A_EXAMINER_REQUISE')

    const dateEvenementFormValues =
      dossier.statut === StatutDossier.EN_CREATION ? formatDateISO(formValues.evenement?.date) : evenement?.date

    const contactEntreprisePersonnaliseFormValues =
      formValues.typeContactEntreprise === CodeContact.CODE_BAL_PERSONNALISE
        ? {
            libelle: trimToUndefined(formValues.contactEntreprisePersonnalise?.libelle),
            mail: trimToUndefined(formValues.contactEntreprisePersonnalise?.mail),
            telephone: trimToUndefined(formValues.contactEntreprisePersonnalise?.telephone),
          }
        : { _op: 'REMOVE' }

    let body = {
      etape: EtapeCreationParam.EVENEMENT,
      refDossierEntreprise: refDossierEntrepriseFormValues,
      refVictimeEntreprise: refVictimeEntrepriseFormValues,
      typeContactEntreprise: trimToRemove(formValues.typeContactEntreprise),
      contactEntreprisePersonnalise: contactEntreprisePersonnaliseFormValues,
      evenement: {
        nature: natureEvenementFormValues,
        date: dateEvenementFormValues,
        reference: trimToUndefined(formValues.evenement?.reference),
        commentaire: trimToUndefined(formValues.evenement?.commentaire),
      },
    } as ModificationDossierRequest

    const controleBody = {
      codeDossier,
      action: SuiviDossierAction.CONTROLER,
      etapes: [EtapeCreationParam.EVENEMENT],
    } as SuiviDossierRequest

    // si dossier EN_COURS et pas de qualiteRequise, on supprime la qualite de la personne a examiner
    if (dossier.statut === StatutDossier.EN_COURS && !qualiteRequise) {
      body = {
        ...body,
        personneAExaminer: { ...dossier.personneAExaminer, qualite: undefined },
      } as ModificationDossierRequest
    }

    return putModifierDossier({
      codeDossier,
      ...body,
    })
      .unwrap()
      .then(() => {
        postSuiviDossier(controleBody)
          .unwrap()
          .then(() => dispatch(setErrorDossier({ status: 200, data: undefined })))
          .catch((error) => {
            dispatch(setErrorDossier(error))
          })
        enqueueSnackbar('Les modifications ont été enregistrées avec succès', { variant: 'success' })
        // NOTE: reset the form and specially the state isDirty, to unblock navigation between tabs in case success
        reset(
          {
            ...getValues(),
          },
          {
            keepValues: true,
          }
        )
        onAfterSuccess()
      })
      .catch((error) => {
        let isErrorQualite = false
        error?.data?.details.forEach((err: IQueryErrorDetailsResponse) => {
          if (err.fields?.includes('personneAExaminer.qualite')) {
            isErrorQualite = true
            setIsBrouillon(true)
            dispatch(setErrorDossier({ ...error, dirtyBody: body }))
            navigateTab(TabId.PERSONNES_TAB)
          }
        })

        if (isErrorQualite) {
          enqueueSnackbar(
            'Votre modification ne sera pas sauvegardée si vous ne renseignez pas les éléments manquants.',
            { variant: 'warning' }
          )
        } else {
          enqueueSnackbar('La sauvegarde des modifications a échoué', { variant: 'error' })
        }
      })
  }

  // render:
  return (
    <FormProvider {...methods}>
      <form id={formId} name={formId}>
        <Prompt
          title={isBrouillon ? 'Modification requise' : `Actualiser l'Application TRANSMED ?`}
          msgConfirmation={
            isBrouillon
              ? 'Pour valider les modifications apportées, vous allez devoir renseigner les éléments manquants.'
              : 'Les modifications que vous avez apportées ne seront peut-être pas enregistrées.'
          }
          when={isDirty}
          displayNo={!isBrouillon}
        />
        <Stack>
          <IdentificationDossier dossier={dossier} profilEntreprise={profilEntreprise} />
          <InformationsEvenement dossier={dossier} profilEntreprise={profilEntreprise} />
          <GestionDossierEntreprise dossier={dossier} profilEntreprise={profilEntreprise} />
          <EditionDossierEntrepriseButtons
            dossier={dossier}
            onLeave={onLeave}
            onSubmitAndLeave={onSubmitAndLeave}
            onSubmitAndContinue={onSubmitAndContinue}
            loading={loadingSauvegarder}
          />
        </Stack>
      </form>
    </FormProvider>
  )
}
