import React from 'react'

import { Grid } from '@mui/material'
import { BluePaper, SelectInputReferentiel } from 'plateforme/components'
import DividerFormButton from 'plateforme/components/buttons/DividerFormButton'
import { LesionSequelle } from 'admin/store/types/lesionSequelleAdmin'
import uuid from 'react-uuid'
import { Stack } from '@mui/system'

interface ReadOnlyLesionSequellesProps {
  lesionSequelles: LesionSequelle[]
}

export default function ReadOnlyLesionSequellesAdmin({ lesionSequelles }: ReadOnlyLesionSequellesProps) {
  return (
    <>
      <Grid item xs={12}>
        <DividerFormButton title="Codes Lésions / Séquelles" readOnly />
      </Grid>
      <Grid item xs={12}>
        {lesionSequelles.length === 0 ? (
          <BluePaper sx={{ fontStyle: 'italic', paddingTop: 3.5, textAlign: 'center' }}>
            Aucune lésion / séquelle
          </BluePaper>
        ) : (
          <BluePaper>
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              {lesionSequelles.map((item, index) => {
                return (
                  <li key={uuid()}>
                    <Stack direction="row" spacing={3.5} marginBottom={index === lesionSequelles.length - 1 ? 0 : 2.5}>
                      <SelectInputReferentiel
                        readOnly
                        id="codes-lesion"
                        label="Code Lésion"
                        value={item.lesion.code}
                        referentielName="lesion"
                        fullWidth
                      />
                      <SelectInputReferentiel
                        readOnly
                        id="codes-sequelle"
                        label="Code Sequelle"
                        value={item.sequelle.code}
                        referentielName="sequelle"
                        fullWidth
                      />
                    </Stack>
                  </li>
                )
              })}
            </ul>
          </BluePaper>
        )}
      </Grid>
    </>
  )
}
