import React from 'react'
import { Box, Stack, styled, Typography } from '@mui/material'
import { TransmedLogo } from 'plateforme/components'
import AccueilButton from 'plateforme/components/menu/AccueilButton'

const BoxStyled = styled(Box)(() => ({
  backgroundColor: '#ffffff',
}))

export default function NotFoundPage() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="95vh">
      <BoxStyled>
        <Stack direction="row" alignItems="center" justifyContent="center" padding={4}>
          <TransmedLogo />
        </Stack>
        <Typography variant="h1" sx={{ textAlign: 'center' }} padding={4}>
          Vous tentez d&apos;accéder à une ressource qui n&apos;existe pas.
        </Typography>
        <Typography variant="h1" sx={{ textAlign: 'center' }} padding={4}>
          Si le problème persiste, merci de contacter notre assistance :
          <a href="mailto:assistance-transmed@agira.asso.fr">assistance-transmed@agira.asso.fr</a>
        </Typography>
        <Box display="flex" justifyContent="center" padding={4}>
          <AccueilButton href="/"> Retour à la page d&apos;accueil</AccueilButton>
        </Box>
      </BoxStyled>
    </Box>
  )
}
