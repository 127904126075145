import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Stack } from '@mui/material'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { LoadingProps, trimToUndefined } from 'plateforme/services/utils'
import Prompt from 'plateforme/components/dialog/Prompt'
import {
  SuiviDossierAction,
  SuiviDossierRequest,
  usePostSuiviDossierMutation,
  usePutModifierDossierMutation,
} from 'assureur/store/apis/dossierAssureurApi'
import { consultationDossierHref } from 'assureur/EntrepriseApp'
import DossierEntreprise, {
  EtapeCreationParam,
  ModificationDossierRequest,
} from 'assureur/store/types/dossierEntreprise'
import { AreaLoading } from 'plateforme/components'
import { setErrorDossier } from 'assureur/store/slices/dossier/errorDossierSlice'
import InformationsAssure from './InformationsAssure'
import EditionDossierEntrepriseButtons from '../EditionDossierEntrepriseButtons'

type InformationsAssureFormProps = {
  dossier?: DossierEntreprise
  onContinue: VoidFunction
}

type InformationsAssureFormValues = {
  assure: { numeroContrat: string; codeAssure: string; nom: string }
}

export default function InformationsAssureForm({
  dossier,
  loading,
  onContinue,
}: InformationsAssureFormProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={450} />
  }
  return <InformationsAssureFormLoaded dossier={dossier} onContinue={onContinue} />
}

function InformationsAssureFormLoaded({ dossier, onContinue }: InformationsAssureFormProps) {
  // error:
  if (!dossier || !dossier.editable) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const { code: codeDossier, assure } = dossier
  const personneAExaminerEstLAssure = dossier?.personneAExaminer?.assure
  const nomPersonneAExaminer = () => {
    return personneAExaminerEstLAssure ? dossier?.personneAExaminer?.nom : ''
  }

  // default values:
  const defaultValues: InformationsAssureFormValues = {
    assure: {
      numeroContrat: assure?.numeroContrat ?? '',
      codeAssure: assure?.codeAssure ?? '',
      nom: personneAExaminerEstLAssure ? assure?.nom ?? nomPersonneAExaminer() ?? '' : '',
    },
  }

  // hooks:
  const [putInformationsAssure, { error: errorSauvegarder, isLoading: loadingSauvegarder }] =
    usePutModifierDossierMutation()
  const [postSuiviDossier] = usePostSuiviDossierMutation()
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  })
  const {
    setError,
    getValues,
    reset,
    formState: { isDirty },
  } = methods
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  useErrorFormMapper(errorSauvegarder as IQueryErrorResponse, setError, getValues)
  const navigate = useNavigate()

  // format:
  const formId = 'form-info-assure'

  // leave:
  const onLeave = () => {
    reset()
    navigate(consultationDossierHref(codeDossier))
  }

  // save form and leave: :
  const onSubmitAndLeave = async () => {
    await onSubmit(onLeave)
  }

  // save form and continue: :
  const onSubmitAndContinue = async () => {
    await onSubmit(onContinue)
  }

  // save form: :
  const onSubmit = async (onAfterSuccess: VoidFunction) => {
    const formValues = getValues() as InformationsAssureFormValues

    const isNotEmpty = Object.values(formValues.assure).some((value) => trimToUndefined(value) !== undefined)

    const assureFormValues = isNotEmpty
      ? {
          numeroContrat: trimToUndefined(formValues.assure?.numeroContrat),
          codeAssure: trimToUndefined(formValues.assure?.codeAssure),
          nom: trimToUndefined(formValues.assure?.nom),
        }
      : { _op: 'REMOVE' }

    const body = {
      etape: EtapeCreationParam.ASSURE,
      assure: assureFormValues,
    } as ModificationDossierRequest

    const controleBody = {
      codeDossier,
      action: SuiviDossierAction.CONTROLER,
      etapes: [EtapeCreationParam.ASSURE],
    } as SuiviDossierRequest

    return putInformationsAssure({ codeDossier, ...body })
      .unwrap()
      .then(() => {
        postSuiviDossier(controleBody)
          .unwrap()
          .then(() => dispatch(setErrorDossier({ status: 200, data: undefined })))
          .catch((error) => {
            dispatch(setErrorDossier(error))
          })
        enqueueSnackbar('Les modifications ont été enregistrées avec succès', { variant: 'success' })
        // NOTE: reset the form and specially the state isDirty, to unblock navigation between tabs in case success
        reset(
          {
            ...getValues(),
          },
          {
            keepValues: true,
          }
        )
        onAfterSuccess()
      })
      .catch(() => {
        enqueueSnackbar('Une erreur est survenue', { variant: 'error' })
      })
  }

  // render:
  return (
    <FormProvider {...methods}>
      <form id={formId} name={formId}>
        <Prompt
          title="Actualiser l'Application TRANSMED ?"
          msgConfirmation="Les modifications que vous avez apportées ne seront peut-être pas enregistrées."
          when={isDirty}
        />
        <Stack>
          <InformationsAssure personneAExaminerEstLAssure={personneAExaminerEstLAssure} />
          <EditionDossierEntrepriseButtons
            dossier={dossier}
            onLeave={onLeave}
            onSubmitAndLeave={onSubmitAndLeave}
            onSubmitAndContinue={onSubmitAndContinue}
            loading={loadingSauvegarder}
          />
        </Stack>
      </form>
    </FormProvider>
  )
}
