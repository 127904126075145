import React from 'react'
import { Alert, Card, CardContent, CardHeader, Grid } from '@mui/material'
import { AreaLoading, LabelIconField, ReadOnlyTextField } from 'plateforme/components'
import DossierPlateforme from 'plateforme/store/types/dossier'
import MissionPlateforme from 'plateforme/store/types/mission'
import { LoadingProps } from 'plateforme/services/utils'

type ExpertisePartProps = {
  dossier?: DossierPlateforme
  mission?: MissionPlateforme
}

export default function ExpertisePart({ dossier, mission, loading }: ExpertisePartProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={502} />
  }
  return <ExpertisePartLoaded dossier={dossier} mission={mission} />
}

function ExpertisePartLoaded({ dossier, mission }: ExpertisePartProps) {
  // error:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  const consentement = dossier && 'consentement' in dossier && dossier.consentement
  const refConsentement =
    dossier && 'refConsentement' in dossier && dossier.refConsentement ? dossier.refConsentement : undefined

  // props:
  const { expertise } = dossier
  const { prejudiceEnAggravation } = expertise || {}

  // render:
  if (!expertise) {
    return null
  }
  return (
    <Card>
      <CardHeader title="Expertise" />
      <CardContent>
        <Grid container>
          <Grid item xs={expertise.domaineContractuel ? 6 : 12}>
            <ReadOnlyTextField
              fullWidth
              id="id-cadre-expertise"
              value={expertise.labelCadreExpertise}
              label="Cadre de l'expertise"
            />
          </Grid>
          {expertise.domaineContractuel && (
            <Grid item xs={6}>
              <ReadOnlyTextField
                fullWidth
                id="id-domaine-expertise"
                value={expertise.domaineContractuel?.libelle}
                label="Domaine contractuel"
              />
            </Grid>
          )}
          {consentement && (
            <>
              <Grid item xs={12} md={refConsentement ? 6 : 12} display="flex" alignItems="center">
                <LabelIconField iconType="check" label="Consentement recueilli" />
              </Grid>
              {refConsentement && (
                <Grid item xs={12} md={6}>
                  <ReadOnlyTextField
                    fullWidth
                    id="id-ref-consentement"
                    value={refConsentement}
                    label="Référence du recueil de consentement"
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <Alert severity="info">
              {prejudiceEnAggravation ? (
                <>
                  La mission est <strong>en aggravation</strong>
                </>
              ) : (
                `La mission n'est pas en aggravation`
              )}
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <ReadOnlyTextField
              fullWidth
              id="id-ref-anterieur-expertise"
              value={mission?.refMissionAnterieure}
              label="Référence mission antérieure"
            />
          </Grid>
          <Grid item xs={12}>
            <ReadOnlyTextField
              multiline
              minRows={3}
              fullWidth
              id="id-expertise-garantie"
              value={expertise.garantieApplicable}
              label="Garanties applicables"
            />
          </Grid>
          <Grid item xs={12}>
            <ReadOnlyTextField
              multiline
              minRows={3}
              fullWidth
              id="id-commentaires-expertise"
              value={expertise.commentaire}
              label="Commentaire"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
