import { trimToUndefined } from 'plateforme/services/utils'
import Referentiel, { TypeMessageNotification, TypeMessageNotificationTag } from 'plateforme/store/types/referentiel'
import ProfilEntreprise, {
  CodeContact,
  CodeFichesConclusions,
  ContactInformations,
  DomaineContractuelEntreprise,
  EnumTypesFichesConclusions,
  ParametrageEntrepriseCommon,
  ProfilEntrepriseCommon,
  RemissionnementCarence,
  RemissionnementConclusionProvisoire,
  ServiceEntreprise,
} from 'assureur/store/types/profilEntreprise'

export interface ProfilEntrepriseForm extends ProfilEntrepriseCommon {
  parametrage?: ParametrageEntrepriseForm
}

export interface ParametrageEntrepriseForm extends ParametrageEntrepriseCommon {
  naturesEvenements: {
    BIRC?: boolean
    RCGE?: boolean
    BNIR?: boolean
    RCMD?: boolean
    ASSC?: boolean
    PREV?: boolean
    ACCV?: boolean
    FAUT?: boolean
    AUTR?: boolean
  }
  fichesConclusions?: FichesConclusionsForm
  remissionnementCarence?: RemissionnementCarenceForm
  remissionnementConclusionProvisoire?: RemissionnementConclusionProvisoireForm
}

export interface FichesConclusionsForm {
  BIRC?: TypeFicheConclusionForm
  BNIR?: TypeFicheConclusionForm
  RCGE?: TypeFicheConclusionForm
  RCMD?: TypeFicheConclusionForm
  ASSC?: TypeFicheConclusionForm
  PREV?: TypeFicheConclusionForm
  ACCV?: TypeFicheConclusionForm
  FAUT?: TypeFicheConclusionForm
  AUTR?: TypeFicheConclusionForm
}

export interface TypeFicheConclusionForm {
  expertiseUnilaterale: string
  expertiseConjointe: string
  expertiseJudiciaire: string
  arbitrage: string
  avisTechnique: string
}

export interface RemissionnementCarenceForm extends RemissionnementCarence {
  enabled?: boolean
}

export interface RemissionnementConclusionProvisoireForm extends RemissionnementConclusionProvisoire {
  enabled?: boolean
}

// REQUEST TO FORM
export const getFicheConclusionForm = (entreprise: ProfilEntreprise | undefined): FichesConclusionsForm | undefined => {
  return {
    BIRC: ficheConclusionRequestToForm('BIRC', entreprise),
    BNIR: ficheConclusionRequestToForm('BNIR', entreprise),
    RCGE: ficheConclusionRequestToForm('RCGE', entreprise),
    RCMD: ficheConclusionRequestToForm('RCMD', entreprise),
    ASSC: ficheConclusionRequestToForm('ASSC', entreprise),
    PREV: ficheConclusionRequestToForm('PREV', entreprise),
    ACCV: ficheConclusionRequestToForm('ACCV', entreprise),
    FAUT: ficheConclusionRequestToForm('FAUT', entreprise),
    AUTR: ficheConclusionRequestToForm('AUTR', entreprise),
  } as FichesConclusionsForm
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// any necessaire pour utiliser [nature]
const ficheConclusionRequestToForm = (nature: string, entreprise?: any) => {
  return {
    expertiseUnilaterale: convertFicheConclusionValueToText(
      entreprise?.parametrage?.typesFichesConclusions[nature]?.[CodeFichesConclusions.expertiseUnilaterale]
    ),
    expertiseConjointe: convertFicheConclusionValueToText(
      entreprise?.parametrage?.typesFichesConclusions[nature]?.[CodeFichesConclusions.expertiseConjointe]
    ),
    expertiseJudiciaire: convertFicheConclusionValueToText(
      entreprise?.parametrage?.typesFichesConclusions[nature]?.[CodeFichesConclusions.expertiseJudiciaire]
    ),
    arbitrage: convertFicheConclusionValueToText(
      entreprise?.parametrage?.typesFichesConclusions[nature]?.[CodeFichesConclusions.arbitrage]
    ),
    avisTechnique: convertFicheConclusionValueToText(
      entreprise?.parametrage?.typesFichesConclusions[nature]?.[CodeFichesConclusions.avisTechnique]
    ),
  }
}

const convertFicheConclusionValueToText = (ficheConclusionValue: EnumTypesFichesConclusions | undefined) => {
  return ficheConclusionValue === EnumTypesFichesConclusions.CONV
    ? EnumTypesFichesConclusions.CONV.valueOf()
    : EnumTypesFichesConclusions.SIMP.valueOf()
}

export const getNatureEvenementsForm = (entreprise: ProfilEntreprise | undefined) => {
  return {
    BIRC: entreprise?.parametrage?.typesFichesConclusions?.BIRC !== undefined,
    BNIR: entreprise?.parametrage?.typesFichesConclusions?.BNIR !== undefined,
    RCGE: entreprise?.parametrage?.typesFichesConclusions?.RCGE !== undefined,
    RCMD: entreprise?.parametrage?.typesFichesConclusions?.RCMD !== undefined,
    ASSC: entreprise?.parametrage?.typesFichesConclusions?.ASSC !== undefined,
    PREV: entreprise?.parametrage?.typesFichesConclusions?.PREV !== undefined,
    ACCV: entreprise?.parametrage?.typesFichesConclusions?.ACCV !== undefined,
    FAUT: entreprise?.parametrage?.typesFichesConclusions?.FAUT !== undefined,
    AUTR: entreprise?.parametrage?.typesFichesConclusions?.AUTR !== undefined,
  }
}

export const getContactsForm = (entreprise: ProfilEntreprise | undefined) => {
  return {
    BIRC: getContactsValues('BIRC', entreprise),
    RCGE: getContactsValues('RCGE', entreprise),
    BNIR: getContactsValues('BNIR', entreprise),
    RCMD: getContactsValues('RCMD', entreprise),
    ASSC: getContactsValues('ASSC', entreprise),
    PREV: getContactsValues('PREV', entreprise),
    ACCV: getContactsValues('ACCV', entreprise),
    FAUT: getContactsValues('FAUT', entreprise),
    AUTR: getContactsValues('AUTR', entreprise),
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// any necessaire pour utiliser [nature]
const getContactsValues = (nature: string, entreprise: any) => {
  return {
    code: entreprise?.parametrage?.contacts?.[nature]?.code ?? CodeContact.CODE_BAL_GESTIONNAIRE,
    libelle: entreprise?.parametrage?.contacts?.[nature]?.libelle ?? '',
    mail: entreprise?.parametrage?.contacts?.[nature]?.mail ?? '',
    telephone: entreprise?.parametrage?.contacts?.[nature]?.telephone ?? '',
  }
}

export const getDelaisForm = (entreprise: ProfilEntreprise | undefined) => {
  return {
    DELAI_REPONSE_MISSION: getDelaisFormByTypeDelais('DELAI_REPONSE_MISSION', entreprise),
    DELAI_REPONSE_SUIVI_MISSION: getDelaisFormByTypeDelais('DELAI_REPONSE_SUIVI_MISSION', entreprise),
    DELAI_VALIDATION_RAPPORT: getDelaisFormByTypeDelais('DELAI_VALIDATION_RAPPORT', entreprise),
    DELAI_VALIDATION_DEMANDE_MODIFICATION: getDelaisFormByTypeDelais(
      'DELAI_VALIDATION_DEMANDE_MODIFICATION',
      entreprise
    ),
  }
}

const getDelaisFormByTypeDelais = (typeDelais: string, entreprise: ProfilEntreprise | undefined) => {
  return {
    BIRC: delaisRequestToForm(typeDelais, 'BIRC', entreprise),
    BNIR: delaisRequestToForm(typeDelais, 'BNIR', entreprise),
    RCGE: delaisRequestToForm(typeDelais, 'RCGE', entreprise),
    RCMD: delaisRequestToForm(typeDelais, 'RCMD', entreprise),
    ASSC: delaisRequestToForm(typeDelais, 'ASSC', entreprise),
    PREV: delaisRequestToForm(typeDelais, 'PREV', entreprise),
    ACCV: delaisRequestToForm(typeDelais, 'ACCV', entreprise),
    FAUT: delaisRequestToForm(typeDelais, 'FAUT', entreprise),
    AUTR: delaisRequestToForm(typeDelais, 'AUTR', entreprise),
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// any necessaire pour utiliser [typeDelais] et [nature]
const delaisRequestToForm = (typeDelais: string, nature: string, entreprise: any) => {
  return {
    [CodeFichesConclusions.expertiseUnilaterale]:
      entreprise?.parametrage?.delaisRelance?.[typeDelais]?.[nature]?.[CodeFichesConclusions.expertiseUnilaterale] ?? 0,
    [CodeFichesConclusions.expertiseConjointe]:
      entreprise?.parametrage?.delaisRelance?.[typeDelais]?.[nature]?.[CodeFichesConclusions.expertiseConjointe] ?? 0,
    [CodeFichesConclusions.expertiseJudiciaire]:
      entreprise?.parametrage?.delaisRelance?.[typeDelais]?.[nature]?.[CodeFichesConclusions.expertiseJudiciaire] ?? 0,
    [CodeFichesConclusions.arbitrage]:
      entreprise?.parametrage?.delaisRelance?.[typeDelais]?.[nature]?.[CodeFichesConclusions.arbitrage] ?? 0,
    [CodeFichesConclusions.avisTechnique]:
      entreprise?.parametrage?.delaisRelance?.[typeDelais]?.[nature]?.[CodeFichesConclusions.avisTechnique] ?? 0,
  }
}

// Tranform referentiel list to object
export const getNotificationsFormFromReferentiel = (
  entreprise: ProfilEntreprise | undefined,
  referentiel: Referentiel | undefined,
  partenaire: TypeMessageNotificationTag,
  typesExclus: TypeMessageNotification[] = [] // type de message a exclure
) => {
  return referentiel?.typeMessageNotification
    .filter((typeMessage) => !typesExclus.includes(typeMessage.code))
    .filter((t) => t.partenaires.includes(partenaire))
    .reduce((acc: any, item) => {
      if (
        entreprise?.parametrage?.notifications !== undefined &&
        entreprise?.parametrage?.notifications[item.code] !== undefined
      ) {
        acc[item.code] = entreprise?.parametrage?.notifications[item.code]
      } else {
        acc[item.code] = {
          CG: '00',
          SV: '00',
          GE: '00',
        }
      }
      return acc
    }, {})
}

// FORM TO REQUEST
export const getFicheConclusionRequest = (formValues: ProfilEntrepriseForm) => {
  return {
    BIRC: formValues.parametrage?.naturesEvenements.BIRC ? ficheConclusionFormToRequest('BIRC', formValues) : undefined,
    BNIR: formValues.parametrage?.naturesEvenements.BNIR ? ficheConclusionFormToRequest('BNIR', formValues) : undefined,
    RCGE: formValues.parametrage?.naturesEvenements.RCGE ? ficheConclusionFormToRequest('RCGE', formValues) : undefined,
    RCMD: formValues.parametrage?.naturesEvenements.RCMD ? ficheConclusionFormToRequest('RCMD', formValues) : undefined,
    ASSC: formValues.parametrage?.naturesEvenements.ASSC ? ficheConclusionFormToRequest('ASSC', formValues) : undefined,
    PREV: formValues.parametrage?.naturesEvenements.PREV ? ficheConclusionFormToRequest('PREV', formValues) : undefined,
    ACCV: formValues.parametrage?.naturesEvenements.ACCV ? ficheConclusionFormToRequest('ACCV', formValues) : undefined,
    FAUT: formValues.parametrage?.naturesEvenements.FAUT ? ficheConclusionFormToRequest('FAUT', formValues) : undefined,
    AUTR: formValues.parametrage?.naturesEvenements.AUTR ? ficheConclusionFormToRequest('AUTR', formValues) : undefined,
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// any necessaire pour utiliser [nature]
const ficheConclusionFormToRequest = (nature: string, formValues: any) => {
  return {
    [CodeFichesConclusions.expertiseUnilaterale]: convertFicheConclusionTextToValue(
      formValues?.parametrage?.fichesConclusions[nature]?.expertiseUnilaterale
    ),
    [CodeFichesConclusions.expertiseConjointe]: convertFicheConclusionTextToValue(
      formValues?.parametrage?.fichesConclusions[nature]?.expertiseConjointe
    ),
    [CodeFichesConclusions.expertiseJudiciaire]: convertFicheConclusionTextToValue(
      formValues?.parametrage?.fichesConclusions[nature]?.expertiseJudiciaire
    ),
    [CodeFichesConclusions.arbitrage]: convertFicheConclusionTextToValue(
      formValues?.parametrage?.fichesConclusions[nature]?.arbitrage
    ),
    [CodeFichesConclusions.avisTechnique]: formValues.parametrage?.expertiseSurPiece
      ? convertFicheConclusionTextToValue(formValues?.parametrage?.fichesConclusions[nature]?.avisTechnique)
      : undefined,
  }
}

const convertFicheConclusionTextToValue = (ficheConclusionText: string) => {
  return ficheConclusionText === EnumTypesFichesConclusions.CONV.valueOf()
    ? EnumTypesFichesConclusions.CONV
    : EnumTypesFichesConclusions.SIMP
}

export const addStatutAndRegisteredToForm = (object: ServiceEntreprise | DomaineContractuelEntreprise) => {
  return {
    ...object,
    statutLabel: object.actif ? 'Actif' : 'Inactif',
    registered: true,
  }
}

export const addStatutToRequest = (object: ServiceEntreprise | DomaineContractuelEntreprise) => {
  return {
    ...object,
    actif: object.statutLabel === 'Actif',
  }
}

export const getServicesFormToRequest = (formValues: ProfilEntrepriseForm) => {
  return formValues?.parametrage?.services?.map((service: ServiceEntreprise) =>
    addStatutToRequest({
      ...service,
      telephone: trimToUndefined(service.telephone),
    })
  )
}

export const getDomainesFormToRequest = (formValues: ProfilEntrepriseForm) => {
  return formValues?.parametrage?.domainesContractuels?.map((domaine: DomaineContractuelEntreprise) =>
    addStatutToRequest(domaine)
  )
}

export const getContactInformationRequest = (contact?: ContactInformations) => {
  return {
    code: contact?.code,
    libelle: trimToUndefined(contact?.libelle),
    telephone: trimToUndefined(contact?.telephone),
    mail: trimToUndefined(contact?.mail),
  } as ContactInformations
}

export const getContactsFormToRequest = (formValues: ProfilEntrepriseForm) => {
  return {
    BIRC: formValues.parametrage?.naturesEvenements.BIRC
      ? getContactInformationRequest(formValues.parametrage?.contacts?.BIRC)
      : undefined,
    RCGE: formValues.parametrage?.naturesEvenements.RCGE
      ? getContactInformationRequest(formValues.parametrage?.contacts?.RCGE)
      : undefined,
    BNIR: formValues.parametrage?.naturesEvenements.BNIR
      ? getContactInformationRequest(formValues.parametrage?.contacts?.BNIR)
      : undefined,
    RCMD: formValues.parametrage?.naturesEvenements.RCMD
      ? getContactInformationRequest(formValues.parametrage?.contacts?.RCMD)
      : undefined,
    ASSC: formValues.parametrage?.naturesEvenements.ASSC
      ? getContactInformationRequest(formValues.parametrage?.contacts?.ASSC)
      : undefined,
    PREV: formValues.parametrage?.naturesEvenements.PREV
      ? getContactInformationRequest(formValues.parametrage?.contacts?.PREV)
      : undefined,
    ACCV: formValues.parametrage?.naturesEvenements.ACCV
      ? getContactInformationRequest(formValues.parametrage?.contacts?.ACCV)
      : undefined,
    FAUT: formValues.parametrage?.naturesEvenements.FAUT
      ? getContactInformationRequest(formValues.parametrage?.contacts?.FAUT)
      : undefined,
    AUTR: formValues.parametrage?.naturesEvenements.AUTR
      ? getContactInformationRequest(formValues.parametrage?.contacts?.AUTR)
      : undefined,
  }
}

export const getDelaisFormToRequest = (formValues: ProfilEntrepriseForm) => {
  return {
    DELAI_REPONSE_MISSION: getDelaisFormToRequestByTypeDelais('DELAI_REPONSE_MISSION', formValues),
    DELAI_REPONSE_SUIVI_MISSION: getDelaisFormToRequestByTypeDelais('DELAI_REPONSE_SUIVI_MISSION', formValues),
    DELAI_VALIDATION_RAPPORT: getDelaisFormToRequestByTypeDelais('DELAI_VALIDATION_RAPPORT', formValues),
    DELAI_VALIDATION_DEMANDE_MODIFICATION: getDelaisFormToRequestByTypeDelais(
      'DELAI_VALIDATION_DEMANDE_MODIFICATION',
      formValues
    ),
  }
}

const getDelaisFormToRequestByTypeDelais = (typeDelais: string, formValues: ProfilEntrepriseForm) => {
  return {
    BIRC: formValues.parametrage?.naturesEvenements.BIRC ? getDelaisValues('BIRC', typeDelais, formValues) : undefined,
    BNIR: formValues.parametrage?.naturesEvenements.BNIR ? getDelaisValues('BNIR', typeDelais, formValues) : undefined,
    RCGE: formValues.parametrage?.naturesEvenements.RCGE ? getDelaisValues('RCGE', typeDelais, formValues) : undefined,
    RCMD: formValues.parametrage?.naturesEvenements.RCMD ? getDelaisValues('RCMD', typeDelais, formValues) : undefined,
    ASSC: formValues.parametrage?.naturesEvenements.ASSC ? getDelaisValues('ASSC', typeDelais, formValues) : undefined,
    PREV: formValues.parametrage?.naturesEvenements.PREV ? getDelaisValues('PREV', typeDelais, formValues) : undefined,
    ACCV: formValues.parametrage?.naturesEvenements.ACCV ? getDelaisValues('ACCV', typeDelais, formValues) : undefined,
    FAUT: formValues.parametrage?.naturesEvenements.FAUT ? getDelaisValues('FAUT', typeDelais, formValues) : undefined,
    AUTR: formValues.parametrage?.naturesEvenements.AUTR ? getDelaisValues('AUTR', typeDelais, formValues) : undefined,
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// any necessaire pour utiliser [typeEvenement] et [typeDelais]
const getDelaisValues = (typeEvenement: string, typeDelais: string, formValues: any) => {
  return {
    [CodeFichesConclusions.expertiseUnilaterale]:
      formValues.parametrage?.delaisRelance?.[typeDelais]?.[typeEvenement]?.[
        CodeFichesConclusions.expertiseUnilaterale
      ],
    [CodeFichesConclusions.expertiseConjointe]:
      formValues.parametrage?.delaisRelance?.[typeDelais]?.[typeEvenement]?.[CodeFichesConclusions.expertiseConjointe],
    [CodeFichesConclusions.expertiseJudiciaire]:
      formValues.parametrage?.delaisRelance?.[typeDelais]?.[typeEvenement]?.[CodeFichesConclusions.expertiseJudiciaire],
    [CodeFichesConclusions.arbitrage]:
      formValues.parametrage?.delaisRelance?.[typeDelais]?.[typeEvenement]?.[CodeFichesConclusions.arbitrage],
    [CodeFichesConclusions.avisTechnique]: formValues.parametrage?.expertiseSurPiece
      ? formValues.parametrage?.delaisRelance?.[typeDelais]?.[typeEvenement]?.[CodeFichesConclusions.avisTechnique]
      : undefined,
  }
}
