import * as React from 'react'
import MenuBaseButton, { MenuBaseButtonProps } from 'plateforme/components/menu/MenuBaseButton'
import AccueilIcon from 'plateforme/components/icons/AccueilIcon'

export default function AccueilButton(props: MenuBaseButtonProps) {
  const { icon, tooltip, color, ...other } = props
  return (
    <MenuBaseButton
      icon={icon ?? <AccueilIcon htmlColor={color ?? 'primary'} />}
      tooltip={tooltip ?? 'Retour à la page d&apos;accueil'}
      {...other}
    />
  )
}
