export const montantFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
})

export const formatMontant = (montant?: number) => {
  if (montant) {
    return montantFormatter.format(montant)
  }
  return '--'
}
