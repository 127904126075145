import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  AideIcon,
  AideMenuButton,
  ChangerProfilIcon,
  ChangerProfilMenuButton,
  DeconnexionIcon,
  DeconnexionMenuButton,
  MenuAppBar,
  MenuAppBarItem,
  NotificationsIcon,
  NotificationsMenuButton,
} from 'plateforme/components'
import { profilHref as profilUtilisateurHref } from 'plateforme/App'
import ParametresMenuButton from 'plateforme/components/menu/ParametresMenuButton'
import ParametresIcon from 'plateforme/components/icons/ParametresIcon'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import getUtilisataurRoles from 'plateforme/services/utilisateur.service'
import {
  setDossierMotCleCriteria,
  setNotificationCriteria,
} from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'
import { homeHref, rechercheDossiersHref } from 'assureur/EntrepriseApp'
import {
  notificationConsultable,
  messageConsultable,
  parametrageConsultable,
} from 'assureur/services/rolesEntreprise.services'
import { useGetListeMessagesQuery } from 'assureur/store/apis/messageEntrepriseApi'
import aideEnLigneEntreprisesPdf from 'assets/media/pdf/aide_en_ligne_assureurs_TransMED_v1.0.pdf'
import { isRechercheDossierPageHref, normalizeHref, trimToUndefined } from 'plateforme/services/utils'
import {
  dernieresNotificationsDossierNonLueFiltre,
  dernieresNotificationsMessageNonLueFiltre,
} from 'assureur/services/messageEntreprise.services'

export default function MenuEntreprisePart() {
  const navigate = useNavigate()
  const filtreDernieresNotificationsDossierNonLue = dernieresNotificationsDossierNonLueFiltre()
  const filtreDernieresNotificationsMessageNonLue = dernieresNotificationsMessageNonLueFiltre()
  const { data: notificationDossier } = useGetListeMessagesQuery({
    onlyTotal: 1,
    filter: filtreDernieresNotificationsDossierNonLue,
  })
  const { data: notificationMessage } = useGetListeMessagesQuery({
    onlyTotal: 1,
    filter: filtreDernieresNotificationsMessageNonLue,
  })
  const { data: utilisateur } = useGetUtilisateurActifQuery()
  const profilUtilisateur = utilisateur?.profilActif
  const isUserWithMultipleRoles = getUtilisataurRoles(utilisateur).length > 1
  const libelleRole = profilUtilisateur?.role?.description
  const libelleRaisonSocial = profilUtilisateur?.raisonSociale
  const libelleService = profilUtilisateur?.libelleService
  const dispatch = useDispatch()

  /* menu flottant en mode tablette */
  const menuItems: MenuAppBarItem[] = [
    {
      id: 'menu-aide',
      target: '_blank',
      href: normalizeHref(aideEnLigneEntreprisesPdf),
      rel: 'noopener noreferrer',
      button: <AideMenuButton />,
      mobile: (
        <>
          <AideIcon withMargin /> Aide
        </>
      ),
    },
    {
      id: 'menu-profil-entreprise',
      href: 'profil',
      button: <ParametresMenuButton />,
      mobile: (
        <>
          <ParametresIcon withMargin /> Paramètres
        </>
      ),
    },
    {
      id: 'menu-changer-profil-utilisateur',
      href: profilUtilisateurHref,
      button: (
        <ChangerProfilMenuButton
          fontSize={11}
          lineHeight={1.2}
          noWrap
          disabled={!isUserWithMultipleRoles}
          withCompareArrows={isUserWithMultipleRoles}
        >
          {libelleRole}
          <br />
          {libelleRaisonSocial}
          {libelleService ? ` - ${libelleService}` : null}
        </ChangerProfilMenuButton>
      ),
      mobile: (
        <>
          <ChangerProfilIcon withCompareArrows={isUserWithMultipleRoles} withMargin /> {libelleRole}
        </>
      ),
    },
    {
      id: 'menu-notif-dossier',
      onClick: () => {
        dispatch(setNotificationCriteria(filtreDernieresNotificationsDossierNonLue))
      },
      href: 'notifications/dossiers',
      button: <NotificationsMenuButton nbNotifications={notificationDossier?.totalItems ?? 0} />,
      mobile: (
        <>
          <NotificationsIcon nbNotifications={notificationDossier?.totalItems ?? 0} withMargin /> Notifications
        </>
      ),
    },
    {
      id: 'menu-notif-message',
      onClick: () => {
        dispatch(setNotificationCriteria(filtreDernieresNotificationsMessageNonLue))
      },
      href: 'notifications/messages',
      button: <NotificationsMenuButton nbNotifications={notificationMessage?.totalItems ?? 0} tooltip="Messages" />,
      mobile: (
        <>
          <NotificationsIcon nbNotifications={notificationMessage?.totalItems ?? 0} withMargin /> Notifications
        </>
      ),
    },
    {
      id: 'menu-deconnexion',
      isDeconnexion: true,
      button: <DeconnexionMenuButton />,
      mobile: (
        <>
          <DeconnexionIcon withMargin /> Se déconnecter
        </>
      ),
    },
  ]
    .filter((menuItem) => (parametrageConsultable(profilUtilisateur) ? true : menuItem.id !== 'menu-profil-entreprise'))
    .filter((menuItem) => (notificationConsultable(profilUtilisateur) ? true : menuItem.id !== 'menu-notif-dossier'))
    .filter((menuItem) => (messageConsultable(profilUtilisateur) ? true : menuItem.id !== 'menu-notif-message'))

  const rechercheSubmit = async (motCle?: string) => {
    await dispatch(setDossierMotCleCriteria(trimToUndefined(motCle) ?? ''))
  }

  const rechercheClick = () => {
    if (isRechercheDossierPageHref(window.location.pathname)) {
      window.location.reload()
    } else {
      navigate(rechercheDossiersHref)
    }
  }

  return (
    // passer un props supplémentaire avec le type de partenaire ?
    <MenuAppBar
      homeHref={homeHref}
      onRechercheSubmit={rechercheSubmit}
      onRechercheClick={rechercheClick}
      menuItems={menuItems}
      profilUtilisateur={profilUtilisateur}
    />
  )
}
