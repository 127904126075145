import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import { AreaLoading } from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { LoadingProps, trimToUndefined } from 'plateforme/services/utils'
import { formatDateISO } from 'plateforme/services/dates.services'
import Referentiel from 'plateforme/store/types/referentiel'
import ProfilEntreprise from 'assureur/store/types/profilEntreprise'
import DossierEntreprise, {
  EtapeCreationParam,
  ModificationDossierRequest,
} from 'assureur/store/types/dossierEntreprise'
import {
  SuiviDossierAction,
  SuiviDossierRequest,
  usePostSuiviDossierMutation,
  usePutModifierDossierMutation,
} from 'assureur/store/apis/dossierAssureurApi'
import { consultationDossierHref } from 'assureur/EntrepriseApp'

import { getErrorDossier, setErrorDossier } from 'assureur/store/slices/dossier/errorDossierSlice'
import { useDispatch, useSelector } from 'react-redux'
import Prompt from 'plateforme/components/dialog/Prompt'
import PersonneAExaminer from './PersonneExaminer'
import PersonneAContacter from './PersonneContacter'
import EditionDossierEntrepriseButtons from '../EditionDossierEntrepriseButtons'

type PersonneAExaminerAContacterFormProps = {
  dossier?: DossierEntreprise
  profilEntreprise?: ProfilEntreprise
  referentiel?: Referentiel
  onContinue: VoidFunction
}

type PersonneAExaminerAContacterFormValues = {
  personneAExaminer: {
    sexe: string
    adresse1: string
    adresse2: string
    adresse3: string
    telephoneFixe: string
    telephoneMobile: string
    mail: string
    nom: string
    prenom: string
    profession: string
    dateNaissance: Date | null
    commune: string
    pays: string
    codePostal: string
    qualite: string
    assure: boolean
    contactUnique: boolean
  }
  personneAContacter: {
    adresse1: string
    adresse2: string
    adresse3: string
    telephoneFixe: string
    telephoneMobile: string
    mail: string
    nom: string
    prenom: string
    commune: string
    pays: string
    codePostal: string
    qualite: string
    coordonneesIdentiquesPersonneAExaminer: boolean
  }
}

export default function PersonneAExaminerAContacterForm({
  dossier,
  profilEntreprise,
  referentiel,
  loading,
  onContinue,
}: PersonneAExaminerAContacterFormProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={450} />
  }
  return (
    <PersonneAExaminerAContacterFormLoaded
      dossier={dossier}
      profilEntreprise={profilEntreprise}
      referentiel={referentiel}
      onContinue={onContinue}
    />
  )
}

function PersonneAExaminerAContacterFormLoaded({
  dossier,
  profilEntreprise,
  referentiel,
  onContinue,
}: PersonneAExaminerAContacterFormProps) {
  // error:
  if (!dossier || !profilEntreprise || !dossier.editable) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const { code: codeDossier, personneAExaminer, personneAContacter } = dossier

  const isParametrageAssure = profilEntreprise?.parametrage?.assure
  // hooks:
  const dispatch = useDispatch()
  const [postSuiviDossier] = usePostSuiviDossierMutation()
  const [putModifierDossier, { error: errorSauvegarder, isLoading: loadingSauvegarder }] =
    usePutModifierDossierMutation()
  const defaultValues: PersonneAExaminerAContacterFormValues = {
    personneAExaminer: {
      nom: personneAExaminer?.nom ?? '',
      sexe: personneAExaminer?.sexe ?? '',
      telephoneFixe: personneAExaminer?.telephoneFixe ?? '',
      mail: personneAExaminer?.mail ?? '',
      prenom: personneAExaminer?.prenom ?? '',
      profession: personneAExaminer?.profession ?? '',
      dateNaissance: personneAExaminer?.dateNaissance ?? null,
      telephoneMobile: personneAExaminer?.telephoneMobile ?? '',
      adresse1: personneAExaminer?.adresse1 ?? '',
      adresse2: personneAExaminer?.adresse2 ?? '',
      adresse3: personneAExaminer?.adresse3 ?? '',
      commune: personneAExaminer?.commune ?? '',
      pays: personneAExaminer?.pays ?? 'FR',
      codePostal: personneAExaminer?.codePostal ?? '',
      qualite: personneAExaminer?.qualite ?? '',
      assure: personneAExaminer?.assure ?? true,
      contactUnique: personneAExaminer?.contactUnique ?? false,
    },
    personneAContacter: {
      nom: personneAContacter?.nom ?? '',
      prenom: personneAContacter?.prenom ?? '',
      mail: personneAContacter?.mail ?? '',
      telephoneFixe: personneAContacter?.telephoneFixe ?? '',
      telephoneMobile: personneAContacter?.telephoneMobile ?? '',
      adresse1: personneAContacter?.adresse1 ?? '',
      adresse2: personneAContacter?.adresse2 ?? '',
      adresse3: personneAContacter?.adresse3 ?? '',
      commune: personneAContacter?.commune ?? '',
      pays: personneAContacter?.pays ?? 'FR',
      codePostal: personneAContacter?.codePostal ?? '',
      qualite: personneAContacter?.qualite ?? '',
      coordonneesIdentiquesPersonneAExaminer: personneAContacter?.coordonneesIdentiquesPersonneAExaminer ?? true,
    },
  }
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  })
  const {
    setError,
    getValues,
    reset,
    watch,
    formState: { isDirty },
  } = methods
  const { enqueueSnackbar } = useSnackbar()
  const dataErrorDossier = useSelector(getErrorDossier)
  const errorToBeMapped = (errorSauvegarder || dataErrorDossier.errorDossier) as IQueryErrorResponse
  useErrorFormMapper(errorToBeMapped as IQueryErrorResponse, setError, getValues)
  const navigate = useNavigate()

  const isDirtyBody = dataErrorDossier.errorDossier.dirtyBody !== undefined

  const natureEvenement = isDirtyBody
    ? dataErrorDossier.errorDossier.dirtyBody?.evenement?.nature
    : dossier.evenement?.nature

  const qualiteRequise = referentiel?.naturesEvenement
    .find((ne) => ne.code === natureEvenement)
    ?.tags.includes('QUALITE_PERSONNE_A_EXAMINER_REQUISE')

  // format:
  const formId = 'form-personne-a-examiner-a-contacter'
  const contactUniqueWatched = watch('personneAExaminer.contactUnique')

  // leave:
  const onLeave = () => {
    reset()
    navigate(consultationDossierHref(codeDossier))
  }

  // save form and leave: :
  const onSubmitAndLeave = async () => {
    await onSubmit(onLeave)
  }

  // save form and continue: :
  const onSubmitAndContinue = async () => {
    await onSubmit(onContinue)
  }

  // save form: :
  const onSubmit = async (onAfterSuccess: VoidFunction) => {
    const formValues = getValues() as PersonneAExaminerAContacterFormValues
    const isContactUnique = formValues.personneAExaminer?.contactUnique
    const isPersonneAExaminerAssure = formValues.personneAExaminer.assure
    const personneAExaminerFormValues = {
      nom: trimToUndefined(formValues.personneAExaminer?.nom),
      sexe: trimToUndefined(formValues.personneAExaminer?.sexe),
      qualite: qualiteRequise ? trimToUndefined(formValues.personneAExaminer?.qualite) : undefined,
      prenom: trimToUndefined(formValues.personneAExaminer?.prenom),
      profession: trimToUndefined(formValues.personneAExaminer?.profession),
      dateNaissance: formatDateISO(formValues.personneAExaminer?.dateNaissance),
      adresse1: trimToUndefined(formValues.personneAExaminer?.adresse1),
      adresse2: trimToUndefined(formValues.personneAExaminer?.adresse2),
      adresse3: trimToUndefined(formValues.personneAExaminer?.adresse3),
      codePostal: trimToUndefined(formValues.personneAExaminer?.codePostal),
      commune: trimToUndefined(formValues.personneAExaminer?.commune),
      pays: trimToUndefined(formValues.personneAExaminer?.pays),
      mail: trimToUndefined(formValues.personneAExaminer?.mail),
      telephoneFixe: trimToUndefined(formValues.personneAExaminer?.telephoneFixe),
      telephoneMobile: trimToUndefined(formValues.personneAExaminer?.telephoneMobile),
      assure: formValues.personneAExaminer?.assure,
      contactUnique: isContactUnique,
    }
    const isCoordonneesIdentiques = formValues.personneAContacter?.coordonneesIdentiquesPersonneAExaminer

    const personneAContacterFormValues = isContactUnique
      ? { _op: 'REMOVE' }
      : {
          nom: trimToUndefined(formValues.personneAContacter?.nom),
          prenom: trimToUndefined(formValues.personneAContacter?.prenom),
          qualite: trimToUndefined(formValues.personneAContacter?.qualite),
          coordonneesIdentiquesPersonneAExaminer: isCoordonneesIdentiques,
          adresse1: trimToUndefined(isCoordonneesIdentiques ? '' : formValues.personneAContacter?.adresse1),
          adresse2: trimToUndefined(isCoordonneesIdentiques ? '' : formValues.personneAContacter?.adresse2),
          adresse3: trimToUndefined(isCoordonneesIdentiques ? '' : formValues.personneAContacter?.adresse3),
          codePostal: trimToUndefined(isCoordonneesIdentiques ? '' : formValues.personneAContacter?.codePostal),
          commune: trimToUndefined(isCoordonneesIdentiques ? '' : formValues.personneAContacter?.commune),
          pays: trimToUndefined(isCoordonneesIdentiques ? '' : formValues.personneAContacter?.pays),
          mail: trimToUndefined(isCoordonneesIdentiques ? '' : formValues.personneAContacter?.mail),
          telephoneFixe: trimToUndefined(isCoordonneesIdentiques ? '' : formValues.personneAContacter?.telephoneFixe),
          telephoneMobile: trimToUndefined(
            isCoordonneesIdentiques ? '' : formValues.personneAContacter?.telephoneMobile
          ),
        }

    // NOTE: mettre à jour le nom de l'assuré si c'est personne à examiner et que le parametrage assure est valide :
    const assure =
      isPersonneAExaminerAssure && isParametrageAssure
        ? {
            nom: trimToUndefined(formValues.personneAExaminer?.nom),
            numeroContrat: dossier?.assure?.numeroContrat,
            codeAssure: dossier?.assure?.codeAssure,
          }
        : undefined

    const body = {
      ...dataErrorDossier.errorDossier.dirtyBody,
      etape: EtapeCreationParam.PERSONNES,
      personneAExaminer: personneAExaminerFormValues,
      assure,
      personneAContacter: personneAContacterFormValues,
    } as ModificationDossierRequest

    const controleBody = {
      codeDossier,
      action: SuiviDossierAction.CONTROLER,
      etapes: [EtapeCreationParam.EVENEMENT, EtapeCreationParam.EXPERTISE, EtapeCreationParam.PERSONNES],
    } as SuiviDossierRequest

    return putModifierDossier({ codeDossier, ...body })
      .unwrap()
      .then(() => {
        postSuiviDossier(controleBody)
          .unwrap()
          .then(() => dispatch(setErrorDossier({ status: 200, data: undefined })))
          .catch((error) => {
            dispatch(setErrorDossier(error))
          })
        enqueueSnackbar('Les modifications ont été enregistrées avec succès', { variant: 'success' })
        // NOTE: reset the form and specially the state isDirty, to unblock navigation between tabs in case success
        reset(
          {
            ...getValues(),
          },
          {
            keepValues: true,
          }
        )
        onAfterSuccess()
      })
      .catch(() => {
        enqueueSnackbar('La sauvegarde des modifications a échoué', { variant: 'error' })
      })
  }

  return (
    <FormProvider {...methods}>
      <form id={formId} name={formId}>
        <Prompt
          title="Actualiser l'Application TRANSMED ?"
          msgConfirmation="Les modifications que vous avez apportées ne seront peut-être pas enregistrées."
          when={isDirty || isDirtyBody}
        />
        <Stack>
          <PersonneAExaminer qualiteRequise={qualiteRequise} />
          {!contactUniqueWatched && <PersonneAContacter />}
          <EditionDossierEntrepriseButtons
            dossier={dossier}
            onLeave={onLeave}
            onSubmitAndLeave={onSubmitAndLeave}
            onSubmitAndContinue={onSubmitAndContinue}
            loading={loadingSauvegarder}
          />
        </Stack>
      </form>
    </FormProvider>
  )
}
