import React, { useState } from 'react'
import { Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material'
import { AccepterRoundButton, RefuserRoundButton } from 'plateforme/components'
import { formatDateFR } from 'plateforme/services/dates.services'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionEntreprise from 'medecin/store/types/missionMedecin'
import AccepterMissionForm from './AccepterMissionForm'
import RefuserMissionForm from './RefuserMissionForm'

interface PropositionMissionPartProps {
  dossier: DossierMedecin
  mission: MissionEntreprise
}

export default function PropositionMissionPart({ dossier, mission }: PropositionMissionPartProps) {
  // props:
  const { dateProposition } = mission
  const { entreprise } = dossier

  // states:
  const [isAcceptationFormOpen, setOpenAcceptation] = useState(false)
  const [isRejectionFormOpen, setOpenRejection] = useState(false)
  const openAcceptation = () => {
    setOpenAcceptation(!isAcceptationFormOpen)
    setOpenRejection(false)
  }

  // actions:
  const openRejection = () => {
    setOpenRejection(!isRejectionFormOpen)
    setOpenAcceptation(false)
  }

  const closeAcceptation = () => {
    setOpenAcceptation(false)
  }

  const closeRejection = () => {
    setOpenRejection(false)
  }

  // rendu:
  return (
    <Card>
      <CardHeader title="Proposition de mission ?" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {`La société ${entreprise?.libelle} vous a proposé une nouvelle mission le ${formatDateFR(
                dateProposition
              )}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" marginBottom={isAcceptationFormOpen ? 0 : 2}>
              <AccepterRoundButton onClick={openAcceptation} />
              <Typography variant="subtitle1">Accepter la mission</Typography>
            </Stack>
            {isAcceptationFormOpen && (
              <AccepterMissionForm dossier={dossier} mission={mission} onClose={closeAcceptation} />
            )}
            <Stack direction="row" alignItems="center">
              <RefuserRoundButton onClick={openRejection} />
              <Typography variant="subtitle1">Refuser la mission</Typography>
            </Stack>
            {isRejectionFormOpen && <RefuserMissionForm dossier={dossier} mission={mission} onClose={closeRejection} />}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
