import React from 'react'
import { Alert, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { DocumentsAdmin } from 'admin/store/types/documentsAdmin'
import {
  AreaLoading,
  FileIcon,
  InfiniteScrollComponent,
  NoRowsOverlay,
  ResultatRechercheBarCard,
  VoirIconButton,
} from 'plateforme/components'
import { formatDateTimeFR } from 'plateforme/services/dates.services'
import { SortOption, SortParam } from 'plateforme/store/types/pageRecherche'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import {
  NotificationEntreprise,
  NotificationEntrepriseMessageSortType,
} from 'assureur/store/types/notificationsEntreprise'
import MessageMedecinDetails from 'assureur/parts/ficheMedecin/MessageMedecinPart/MessageMedecinDetails'
import { MessageDuMedecinVersAssureur } from 'assureur/store/types/messageEntreprise'
import { makeEllipsis } from 'plateforme/services/utils'

type ListeNotificationsEntrepriseMessagePartProps = {
  combinedData: Array<NotificationEntreprise>
  total?: number
  readMore: VoidFunction
  sortBy: (param: SortParam<NotificationEntrepriseMessageSortType>) => void
  initSort: VoidFunction
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
}

const getDocuments = (documents: DocumentsAdmin[] | undefined) => {
  return documents && documents.length > 0 ? <FileIcon fontSize="medium" /> : ''
}

export default function ListeNotificationsMessageEntreprisePart({
  combinedData,
  total,
  readMore,
  sortBy,
  initSort,
  isError,
  isLoading,
  isFetching,
  isSuccess,
}: ListeNotificationsEntrepriseMessagePartProps) {
  const sortOptions: SortOption<NotificationEntrepriseMessageSortType>[] = [
    { sortType: NotificationEntrepriseMessageSortType.OBJET, label: 'Objet' },
    { sortType: NotificationEntrepriseMessageSortType.EXPEDITEUR, label: 'Médecin' },
    { sortType: NotificationEntrepriseMessageSortType.STATUT_NOTIFICATION, label: 'Statut' },
    { sortType: NotificationEntrepriseMessageSortType.DATE_CREATION_SORT, label: 'Date' },
  ]

  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}
    >
      <Grid item container xs={12} paddingBottom={2.5}>
        {confirmDialog}
        <ResultatRechercheBarCard
          title="Liste des messages"
          total={total}
          sortBy={sortBy}
          sortOptions={sortOptions}
          initSort={initSort}
          withSorting
          withExportCSV={false}
        />
      </Grid>
      {isError && !isSuccess && !isLoading && !isFetching ? (
        <Alert severity="info" sx={{ width: '100%' }}>
          Aucune donnée
        </Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '40%' }}>Objet</TableCell>
                <TableCell sx={{ width: '20%' }}>Médecin</TableCell>
                <TableCell sx={{ width: '10%' }}>Statut</TableCell>
                <TableCell sx={{ width: '10%' }}>Pièce jointe</TableCell>
                <TableCell sx={{ width: '10%' }}>Date/Heure</TableCell>
                <TableCell sx={{ width: '10%' }} align="center" />
              </TableRow>
            </TableHead>
          </Table>
          {total && total > 0 ? (
            <InfiniteScrollComponent
              dataLength={combinedData?.length}
              next={readMore}
              hasMore={combinedData.length < (total ?? 0)}
              isLoading={isLoading || isFetching}
              areaLoading={<AreaLoading height={90} />}
              xs={12}
            >
              {combinedData?.map((na: NotificationEntreprise) => (
                <Table key={na.code}>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ width: '40%' }}>{makeEllipsis(na.objet, 40)}</TableCell>
                      <TableCell sx={{ width: '20%' }}>{na.medecin?.libelle}</TableCell>
                      <TableCell sx={{ width: '10%' }}>{na.lu ? 'Lue' : 'Non lue'}</TableCell>
                      <TableCell sx={{ width: '10%' }}>{getDocuments(na.documents)}</TableCell>
                      <TableCell sx={{ width: '10%' }}>{formatDateTimeFR(na.date)}</TableCell>
                      <TableCell sx={{ width: '10%' }} align="center">
                        <VoirIconButton
                          onClick={() =>
                            confirm({
                              maxWidth: 'lg',
                              fullWidth: true,
                              withForm: true,
                              form: (
                                <MessageMedecinDetails
                                  codeMessage={na.code}
                                  messageMedecin={na as MessageDuMedecinVersAssureur}
                                  onClose={closeConfirmDialog}
                                />
                              ),
                              confirmMsg: '',
                              title: 'Détail du message du médecin',
                            })
                          }
                          tooltip="Voir le message"
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ))}
            </InfiniteScrollComponent>
          ) : (
            <Table>
              <NoRowsOverlay />
            </Table>
          )}
        </TableContainer>
      )}
    </Grid>
  )
}
