import React, { useEffect } from 'react'
import { Controller, useForm, FieldValues } from 'react-hook-form'
import { useErrorHandler } from 'react-error-boundary'
import { Card, CardContent, CardHeader, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import {
  AnnulerButton,
  AreaLoading,
  ButtonsStack,
  EditTextField,
  ReadOnlyEmailField,
  SauvegarderButton,
  SelectInputReferentiel,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { FallbackError } from 'plateforme/components/dialog/ErrorFallback'
import { useGetProfilMedecinQuery, usePutParamMedecinMutation } from 'medecin/store/apis/profilMedecinApi'

export default function PreferencesPlateformeMedecinPart() {
  const {
    data: medecin,
    isError,
    error: errorGetProfil,
    isLoading: isLoadingProfil,
    isFetching: isFetchingProfil,
  } = useGetProfilMedecinQuery()

  // handle error of useGetReferentielQuery:
  const handleError = useErrorHandler()
  if (isError && !medecin) {
    handleError({
      errorApi: errorGetProfil,
      title: 'Erreur chargement des données utilisateur actif',
      api: 'GetUtilisateurActif',
    } as FallbackError)
  }

  const [putParamMedecin, { error: errorPut, isLoading: isLoadingSauvegarder }] = usePutParamMedecinMutation()
  const { enqueueSnackbar } = useSnackbar()

  // extract parametrage
  const parametrage = medecin?.parametrage

  // Form hook  :
  const {
    handleSubmit,
    control,
    setError,
    getValues,
    reset,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      frequenceNotification: parametrage?.frequenceNotification ?? null,
      commentaireActivite: medecin?.commentaireActivite ?? '',
    },
  })

  // mapping errors :
  useErrorFormMapper(errorPut as IQueryErrorResponse, setError, getValues)

  useEffect(() => {
    reset({
      frequenceNotification: parametrage?.frequenceNotification,
      commentaireActivite: medecin?.commentaireActivite,
    })
  }, [medecin, parametrage, reset])

  if (isLoadingProfil || isFetchingProfil) {
    return <AreaLoading height={363} />
  }

  const onSubmit = async (data: FieldValues) => {
    await putParamMedecin(data)
      .unwrap()
      .then(() => enqueueSnackbar('Les préférences de la plateforme ont été enregistrées', { variant: 'success' }))
      .catch(() => {
        enqueueSnackbar('La modification des préférences plateforme a échoué', { variant: 'error' })
      })
  }

  const handleCancel = () =>
    reset({
      frequenceNotification: parametrage?.frequenceNotification,
      commentaireActivite: medecin?.commentaireActivite,
    })

  return (
    <Card>
      <CardHeader title="Mes préférences plateforme" />
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)} id="form-preference-plateforme">
          <Stack>
            <ReadOnlyEmailField
              id="mail-notification"
              label="Adresse mail notifications"
              value={parametrage?.mailNotification}
              fullWidth
            />
            <Controller
              name="frequenceNotification"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <SelectInputReferentiel
                  id="input-frequence-notifications"
                  label="Fréquence des notifications"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  referentielName="frequencesNotification"
                  fullWidth
                  fieldError={error}
                />
              )}
            />
            <Controller
              name="commentaireActivite"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="input-commentaire"
                  label="Commentaire activité"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  minRows={4}
                  multiline
                  fieldError={error}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              )}
            />
            {isDirty && (
              <ButtonsStack>
                <AnnulerButton onClick={handleCancel}>Annuler</AnnulerButton>
                <SauvegarderButton
                  type="submit"
                  form="form-preference-plateforme"
                  disabled={!isValid}
                  loading={isLoadingSauvegarder || isSubmitting}
                >
                  Sauvegarder
                </SauvegarderButton>
              </ButtonsStack>
            )}
          </Stack>
        </form>
      </CardContent>
    </Card>
  )
}
