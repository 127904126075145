import * as React from 'react'
import { SvgIconProps, Tooltip, useTheme } from '@mui/material'
import { AssignmentTurnedInOutlined } from '@mui/icons-material'

export default function RapportIcon(props: SvgIconProps & { withMargin?: boolean }) {
  const { withMargin, sx: otherSx, ...other } = props
  const theme = useTheme()
  const iconSx = withMargin ? { margin: theme.spacing(1.5), ...otherSx } : { ...otherSx }
  return (
    <Tooltip title="Rapport déposé" placement="left">
      <AssignmentTurnedInOutlined sx={iconSx} {...other} />
    </Tooltip>
  )
}
