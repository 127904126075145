import * as React from 'react'
import { DeconnexionIcon } from 'plateforme/components'
import MenuBaseButton, { MenuBaseButtonProps } from 'plateforme/components/menu/MenuBaseButton'

export default function DeconnexionMenuButton(props: MenuBaseButtonProps) {
  const { icon, tooltip, color, ...other } = props
  return (
    <MenuBaseButton
      icon={icon ?? <DeconnexionIcon htmlColor={color ?? 'primary'} />}
      tooltip={tooltip ?? 'Déconnexion'}
      {...other}
    />
  )
}
