import { dossierAssureurApi } from './apis/dossierAssureurApi'
import { gestionnaireEntrepriseApi } from './apis/gestionnaireEntrepriseApi'
import { habilitationEntrepriseApi } from './apis/habilitationEntrepriseApi'
import { medecinEntrepriseApi } from './apis/medecinEntrepriseApi'
import { messageEntrepriseApi } from './apis/messageEntrepriseApi'
import { profilEntrepriseApi } from './apis/profilEntrepriseApi'
import selectedMedecinReducer, { selectedMedecinSliceName } from './slices/selectedMedecin/selectedMedecinSlice'
import searchCriteriaReducer, {
  searchCrieteriaSliceName,
} from './slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'
import { fluxTechniqueEntrepriseApi } from './apis/fluxTechniqueEntrepriseApi'
import selectedMissionReducer, { selectedMissionSliceName } from './slices/selectedMission/selectedMissionSlice'
import errorDossierReducer, { errorDossierSliceName } from './slices/dossier/errorDossierSlice'

/* Définition des reducers de la plateforme Assureur */
export const assureurReducers = {
  /* --- sync --- */
  [searchCrieteriaSliceName]: searchCriteriaReducer,
  [selectedMedecinSliceName]: selectedMedecinReducer,
  [selectedMissionSliceName]: selectedMissionReducer,
  [errorDossierSliceName]: errorDossierReducer,

  /* --- async -> rtk.query --- */
  [dossierAssureurApi.reducerPath]: dossierAssureurApi.reducer,
  [habilitationEntrepriseApi.reducerPath]: habilitationEntrepriseApi.reducer,
  [medecinEntrepriseApi.reducerPath]: medecinEntrepriseApi.reducer,
  [messageEntrepriseApi.reducerPath]: messageEntrepriseApi.reducer,
  [profilEntrepriseApi.reducerPath]: profilEntrepriseApi.reducer,
  [gestionnaireEntrepriseApi.reducerPath]: gestionnaireEntrepriseApi.reducer,
  [fluxTechniqueEntrepriseApi.reducerPath]: fluxTechniqueEntrepriseApi.reducer,
}

/* Définition des middlewares de la plateforme Assureur */
export const assureurMiddlewares = [
  dossierAssureurApi.middleware,
  habilitationEntrepriseApi.middleware,
  medecinEntrepriseApi.middleware,
  messageEntrepriseApi.middleware,
  profilEntrepriseApi.middleware,
  gestionnaireEntrepriseApi.middleware,
  fluxTechniqueEntrepriseApi.middleware,
]

/* Définition des utils de la plateforme Assureur */
export const assureurUtils = [
  dossierAssureurApi.util,
  habilitationEntrepriseApi.util,
  medecinEntrepriseApi.util,
  messageEntrepriseApi.util,
  profilEntrepriseApi.util,
  gestionnaireEntrepriseApi.util,
  fluxTechniqueEntrepriseApi.util,
]
