import React from 'react'
import { Grid, Stack } from '@mui/material'
import InformationsMedecinPart from 'medecin/parts/profil/InformationsMedecinPart'
import LieuxExerciceMedecinPart from 'medecin/parts/profil/LieuxExerciceMedecinPart'
import PreferencesPlateformeMedecinPart from 'medecin/parts/profil/PreferencesPlateformeMedecinPart'
import HabilitationsActivesMedecinPart from 'medecin/parts/profil/HablitationsActivesMedecinPart'
import NavigationPart from 'plateforme/parts/NavigationPart'
import ActionTilesProfilMedecinPart from 'medecin/parts/profil/ActionTilesProfilMedecinPart/ActionTilesProfilMedecinPart'
import { useGetListeHabilitationsMedecinQuery } from 'medecin/store/apis/habilitationMedecinApi'
import { top100HabilitationRequest } from 'medecin/store/types/habilitationMedecin'
import DernieresNotificationsMessageMedecinPart from 'medecin/parts/home/DernieresNotificationsMessageMedecinPart'

export default function ProfilMedecin() {
  const {
    data: habilitationResponse,
    isLoading,
    isFetching,
  } = useGetListeHabilitationsMedecinQuery(top100HabilitationRequest())

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start">
      <Grid item xs={12}>
        <NavigationPart label="Mon profil et mes habilitations" />
      </Grid>
      <Grid item container xs={12} sm={12} md={12} lg={5}>
        <Grid item xs={12}>
          <InformationsMedecinPart />
        </Grid>
        <Grid item xs={12}>
          <PreferencesPlateformeMedecinPart />
        </Grid>
        <Grid item xs={12}>
          <ActionTilesProfilMedecinPart />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={7}>
        <Stack>
          <LieuxExerciceMedecinPart />
          <HabilitationsActivesMedecinPart
            habilitationResponse={habilitationResponse}
            isLoading={isLoading}
            isFetching={isFetching}
          />
          <DernieresNotificationsMessageMedecinPart loading={isLoading} />
        </Stack>
      </Grid>
    </Grid>
  )
}
