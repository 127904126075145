import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, Fab } from '@mui/material'
import RequireAuthInterceptor from './security/RequireAuthInterceptor'
import NotFoundPage from './pages/NotFoundPage'
import ProfilSelectionPage from './pages/ProfilSelectionPage'
import { adminRoles, entrepriseRoles, medecinRoles } from './services/roles.services'
import { ScrollTopRoundButton } from './components'
import ErrorFallback from './components/dialog/ErrorFallback'
import transmedApiUrl from './store/apis/transmedApi'
import ScrollToTop from './helpers/ScrollToTop'
import { determinerIDP, keycloak } from './security/keycloak'
import LogoutPage from './pages/LogoutPage/LogoutPage'
import VersionApplicationPart from './parts/VersionPart/VersionPart'
import { DEFAULT_IDP_VALUE } from './constantes'

const AdminApp = React.lazy(() => import('admin/AdminApp'))
const EntrepriseApp = React.lazy(() => import('assureur/EntrepriseApp')) // todo: a changer le nom du dossier vers entreprise au lieu de assureur
const MedecinApp = React.lazy(() => import('medecin/MedecinApp'))

export const profilPath = 'profil'
export const profilHref = `/${profilPath}`

export const logoutPath = 'logout'
export const logoutHref = `/${logoutPath}`

export const adminBasePath = 'admin'
export function toAdminHref(path: string) {
  return `/${adminBasePath}/${path}`
}
export const entrepriseBasePath = 'entreprise'
export function toEntrepriseHref(path: string) {
  return `/${entrepriseBasePath}/${path}`
}
export const medecinBasePath = 'medecin'
export function toMedecinHref(path: string) {
  return `/${medecinBasePath}/${path}`
}

export function toTelechargementDocumentDossierHref(codeDossier: string, codeDocument: string) {
  return `${transmedApiUrl}telechargement/dossier/${codeDossier}/document/${codeDocument}`
}

export function toTelechargementFicheMissionHref(codeDossier: string, codeMission: string) {
  return `${transmedApiUrl}telechargement/dossier/${codeDossier}/mission/${codeMission}/fiche`
}

export function toTelechargementArchiveDossierHref(codeDossier: string, codeMission: string) {
  return `${transmedApiUrl}telechargement/dossier/${codeDossier}/archive?codeMission=${codeMission}`
}

export function toTelechargementExportDossierHref(exportParams?: string) {
  return `${transmedApiUrl}telechargement/dossier/export${exportParams ? `?${exportParams}` : ''}`
}

export function toTelechargementExportHabilitationHref(exportParams?: string) {
  return `${transmedApiUrl}telechargement/habilitation/export${exportParams ? `?${exportParams}` : ''}`
}

export function toTelechargementExportFactureHref(exportParams?: string) {
  return `${transmedApiUrl}telechargement/facture/export${exportParams ? `?${exportParams}` : ''}`
}

export function toTelechargementDocumentMessageHref(codeMessage: string, codeDocument: string) {
  return `${transmedApiUrl}telechargement/message/${codeMessage}/document/${codeDocument}`
}

/* root {BASE_URL}/extranet/ */
export default function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        <Route index element={<ProfilSelectionPage />} />
        <Route path={profilPath} element={<ProfilSelectionPage forceRedirect={false} />} />
        <Route path={logoutPath} element={<LogoutPage />} />
        <Route
          path={`${adminBasePath}/*`}
          element={
            <ErrorBoundary key="admin-error" FallbackComponent={ErrorFallback}>
              <RequireAuthInterceptor roles={adminRoles}>
                <AdminApp />
              </RequireAuthInterceptor>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${entrepriseBasePath}/*`}
          element={
            <ErrorBoundary key="entreprise-error" FallbackComponent={ErrorFallback}>
              <RequireAuthInterceptor roles={entrepriseRoles}>
                <EntrepriseApp />
              </RequireAuthInterceptor>
            </ErrorBoundary>
          }
        />
        <Route
          path={`${medecinBasePath}/*`}
          element={
            <ErrorBoundary key="medecin-error" FallbackComponent={ErrorFallback}>
              <RequireAuthInterceptor roles={medecinRoles}>
                <MedecinApp />
              </RequireAuthInterceptor>
            </ErrorBoundary>
          }
        />
        <Route
          path="*"
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <NotFoundPage />
            </ErrorBoundary>
          }
          hasErrorBoundary
        />
      </Route>
    ),
    {
      basename: '/extranet',
    }
  )
  return <RouterProvider router={router} />
}

function Layout() {
  // NOTE: get IDP param to store it in localStorage after any relaod
  const idp = determinerIDP()
  if (idp !== null && idp !== DEFAULT_IDP_VALUE) {
    localStorage.setItem('idp', idp)
    if (!keycloak.authenticated) {
      keycloak.login({
        idpHint: idp,
      })
    }
  }

  return (
    <>
      <Box margin={0} padding={0} minHeight="calc(100vh - 18px)">
        <Outlet />
        <ScrollToTop />
      </Box>
      {keycloak.authenticated && <VersionApplicationPart />}
      <ScrollTopRoundButton>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTopRoundButton>
    </>
  )
}
