import { useNavigate, useSearchParams } from 'react-router-dom'

export const SearchParamsNames = {
  ONGLET: 'onglet',
}

export default function useTabNavigate() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentTab = searchParams.get(SearchParamsNames.ONGLET)
  const navigateTab = (tabId?: string, path?: string) => {
    if (path && tabId) {
      navigate(`${path}?${SearchParamsNames.ONGLET}=${tabId}`)
    } else if (path && !tabId) {
      navigate(path + searchParams.toString())
    } else {
      setSearchParams({ [SearchParamsNames.ONGLET]: tabId ?? '' }, { replace: true })
    }
  }
  return { currentTab, navigateTab }
}
