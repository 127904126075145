import React from 'react'
import { Grid } from '@mui/material'
import { useSnackbar } from 'notistack'
import { AnnulerButton, EditTextField, SelectInput, ValiderButton } from 'plateforme/components'
import DateTimePickerInput from 'plateforme/components/inputs/DateTimePickerInput'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { trimToUndefined } from 'plateforme/services/utils'
import { TypePartenaire } from 'plateforme/store/types/utilisateur'
import { Controller, useForm } from 'react-hook-form'
import { ALL_OPTION_CODE } from 'plateforme/constantes'
import Actualite from 'plateforme/store/types/actualite'
import RefuserButton from 'plateforme/components/buttons/RefuserButton'
import { formatDateTimeISO } from 'plateforme/services/dates.services'
import { usePostCreerActualiteMutation, usePutModifierActualiteMutation } from 'admin/store/apis/actualiteAdminApi'
import { CreerActualiteRequest, ModifierActualiteRequest } from 'admin/store/types/actualiteAdmin'

interface EditerActualiteFormProps {
  desactivation?: boolean
  actualite?: Actualite
  onClose: VoidFunction
}

interface EditerActualiterData {
  titre: string
  contenu: string
  dateDebut: Date | null
  dateFin: Date | null
  typePartenaire: TypePartenaire
}

export default function EditerActualiteForm({ desactivation, actualite, onClose }: EditerActualiteFormProps) {
  const defaultValues: EditerActualiterData = {
    titre: actualite?.titre ?? '',
    contenu: actualite?.contenu ?? '',
    dateDebut: actualite?.dateDebut ?? null,
    dateFin: actualite?.dateFin ?? null,
    typePartenaire: actualite?.typePartenaire ?? (ALL_OPTION_CODE as TypePartenaire),
  }

  const { control, setError, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  })

  const { enqueueSnackbar } = useSnackbar()

  const [createActualite, { error: errorCreation, isLoading: isLoadingCreation }] = usePostCreerActualiteMutation()
  const [modifyActualite, { error: errorModification, isLoading: isLoadingModification }] =
    usePutModifierActualiteMutation()

  useErrorFormMapper(errorCreation as IQueryErrorResponse, setError, getValues)
  useErrorFormMapper(errorModification as IQueryErrorResponse, setError, getValues)

  const typePartenaireDataToRequest = (typePartenaire?: TypePartenaire) => {
    if (typePartenaire === (ALL_OPTION_CODE as TypePartenaire) || typePartenaire === undefined) {
      return undefined
    }
    return typePartenaire
  }

  const creerActualite = async () => {
    const formData = getValues()

    createActualite({
      titre: trimToUndefined(formData.titre),
      contenu: trimToUndefined(formData.contenu),
      dateDebut: formatDateTimeISO(formData.dateDebut),
      dateFin: formatDateTimeISO(formData.dateFin),
      typePartenaire: typePartenaireDataToRequest(formData.typePartenaire),
    } as CreerActualiteRequest)
      .unwrap()
      .then(() => {
        enqueueSnackbar(`L'actualité a été créée avec succès`, {
          variant: 'success',
        })
        onClose()
      })
      .catch(() => {
        enqueueSnackbar(`La création a échouée`, { variant: 'error' })
      })
  }

  const modifierActualite = async (desactiver: boolean) => {
    if (actualite === undefined) {
      throw new Error('Aucune actualité définie')
    }
    const formData = getValues() as EditerActualiterData
    const now = new Date()
    // Ajout d'une seconde pour valider la contrainte coté back
    // Faire un endpoint dédié a la désactivation plus tard si nécéssaire
    const nowPlus1Seconde = new Date(now.getTime() + 1000)

    if (desactiver) {
      await modifyActualite({
        id: actualite.id,
        titre: trimToUndefined(actualite.titre),
        contenu: trimToUndefined(actualite.contenu),
        dateDebut: formatDateTimeISO(nowPlus1Seconde),
        dateFin: formatDateTimeISO(nowPlus1Seconde),
        typePartenaire: typePartenaireDataToRequest(formData.typePartenaire),
      } as ModifierActualiteRequest)
        .unwrap()
        .then(() => {
          enqueueSnackbar(`L'actualité a été désactivée avec succès`, {
            variant: 'success',
          })
          onClose()
        })
        .catch(() => {
          enqueueSnackbar(`La désactivation a échouée`, { variant: 'error' })
        })
    } else {
      await modifyActualite({
        id: actualite.id,
        titre: trimToUndefined(formData.titre),
        contenu: trimToUndefined(formData.contenu),
        dateDebut: formatDateTimeISO(formData.dateDebut),
        dateFin: formatDateTimeISO(formData.dateFin),
        typePartenaire: typePartenaireDataToRequest(formData.typePartenaire),
      } as ModifierActualiteRequest)
        .unwrap()
        .then(() => {
          enqueueSnackbar(`L'actualité a été modifiée avec succès`, {
            variant: 'success',
          })
          onClose()
        })
        .catch(() => {
          enqueueSnackbar(`La modification a échouée`, { variant: 'error' })
        })
    }
  }

  return (
    <form id="form-save-modification-actualite">
      <Grid container paddingTop={0}>
        <Grid item xs={12}>
          <Controller
            name="titre"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                id="titre-actualite"
                label="Titre"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fullWidth
                fieldError={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="contenu"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                id="contenu-actualite"
                label="Contenu"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fullWidth
                fieldError={error}
                multiline
                minRows={8}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="dateDebut"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <DateTimePickerInput
                InputProps={{
                  id: 'date-debut',
                  fullWidth: true,
                }}
                label="Date de début de l'actualité"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="dateFin"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <DateTimePickerInput
                InputProps={{
                  id: 'date-fin',
                  fullWidth: true,
                }}
                label="Date de fin de l'actualité"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="typePartenaire"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <SelectInput
                fullWidth
                id="typePartenaire-actualite"
                label="Accès"
                withAllSelectionItem
                options={[
                  { code: TypePartenaire.Medecin, label: TypePartenaire.Medecin },
                  { code: TypePartenaire.Entreprise, label: TypePartenaire.Entreprise },
                  { code: TypePartenaire.Admin, label: TypePartenaire.Admin },
                ]}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
              />
            )}
          />
        </Grid>
        <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
          <Grid item>
            <AnnulerButton onClick={onClose} loading={isLoadingCreation}>
              Annuler
            </AnnulerButton>
          </Grid>
          {!actualite && (
            <Grid item>
              <ValiderButton onClick={() => creerActualite()} loading={isLoadingCreation}>
                Créer
              </ValiderButton>
            </Grid>
          )}
          {actualite && desactivation && (
            <Grid item>
              <RefuserButton onClick={() => modifierActualite(true)}>Désactiver</RefuserButton>
            </Grid>
          )}
          {actualite && !desactivation && (
            <Grid item>
              <ValiderButton onClick={() => modifierActualite(false)} loading={isLoadingModification}>
                Modifier
              </ValiderButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  )
}
