import React from 'react'
import { Stack } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import { useGetAdminMedecinsQuery } from 'admin/store/apis/gestionMedecinApi'
import ListeMedecinsAdminPart from 'admin/part/rechercheMedecinAdmin/ListeMedecinsAdminPart'
import RechercheMedecinsAdminPart from 'admin/part/rechercheMedecinAdmin/RechercheMedecinsAdminPart'
import { useSelector } from 'react-redux'
import { getMedecinReferenceCriteria } from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'

export default function RechercheMedecinsAdminPage() {
  // get default values from localStorage
  const defaultCriteria = useSelector(getMedecinReferenceCriteria)
  const {
    combinedData,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetAdminMedecinsQuery, { defaultCriteria })

  return (
    <Stack>
      <NavigationPart label="Médecins référencés" />
      <RechercheMedecinsAdminPart search={search} searchError={error} />
      <ListeMedecinsAdminPart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        isSuccess={isSuccess}
      />
    </Stack>
  )
}
