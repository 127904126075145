import React from 'react'
import ProfilEntreprise, { ParametrageType } from 'assureur/store/types/profilEntreprise'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'
import { AFFECTATION_CENTRALE_VALUE_OPTION } from 'plateforme/constantes'
import SelectInputWithQuery, { SelectInputWithQueryProps } from './SelectInputWithQuery'
import { SelectOption } from './SelectInput'

export type ParametrageName = ParametrageNameEnum.DOMAIN_CONTRACTUEL | ParametrageNameEnum.SERVICE
export enum ParametrageNameEnum {
  DOMAIN_CONTRACTUEL = 'domainesContractuels',
  SERVICE = 'services',
}

type SelectInputParametrageProps = Omit<SelectInputWithQueryProps<ParametrageName>, 'useQuery' | 'dataTransformer'> & {
  parametrageName: ParametrageName // todo specifier le type de parmatrge
  parametrageCodeFilter?: string[]
  dataFormatter?: (code: string, label: string) => string
}

export default function SelectInputParametrage({
  parametrageName,
  parametrageCodeFilter,
  dataFormatter,
  ...restProps
}: SelectInputParametrageProps) {
  const dataTransformer = (data: ProfilEntreprise) => {
    let ret = (data.parametrage ? data?.parametrage[parametrageName] : []) as ParametrageType[]
    if (parametrageCodeFilter && parametrageCodeFilter.length > 0) {
      ret = ret?.filter((ref: ParametrageType) => parametrageCodeFilter?.includes(ref.code))
    }

    return ret
      ?.filter((ref: ParametrageType) => ref.actif)
      .map((e: ParametrageType) => {
        return {
          code: e.code,
          label: dataFormatter ? dataFormatter(e.code, e.libelle) : e.libelle,
        } as SelectOption
      })
  }

  const optionsAdded = [
    parametrageName === ParametrageNameEnum.SERVICE ? AFFECTATION_CENTRALE_VALUE_OPTION : undefined,
  ] as SelectOption[]

  return (
    <SelectInputWithQuery
      optionsAdded={optionsAdded}
      {...restProps}
      useQuery={useGetProfilEntrepriseQuery}
      dataTransformer={dataTransformer}
    />
  )
}
