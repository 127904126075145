import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { createRoot } from 'react-dom/client'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import { MAX_SNACKBAR } from 'plateforme/constantes'
import {
  determinerIDP,
  eventHandler,
  initOptions,
  initOptionsIdp,
  keycloak,
  tokenHandler,
} from 'plateforme/security/keycloak'
import { persistor, store } from 'plateforme/store'
import theme from 'plateforme/theme'
import App from 'plateforme/App'
import { BackdropComponent } from 'plateforme/components'
import ErrorFallback from 'plateforme/components/dialog/ErrorFallback'
import './index.css'
import { AuthClientInitOptions } from '@react-keycloak/core'
import reportWebVitals from './reportWebVitals'

const container = document.getElementById('root')

const determineInitOptions = () => {
  let options: AuthClientInitOptions | undefined = initOptions
  if (determinerIDP() !== null) {
    options = initOptionsIdp
  }
  if (window.location.href?.endsWith('/logout')) {
    options = undefined
  }
  return options
}

if (container) {
  createRoot(container).render(
    <ErrorBoundary key="main-error" fallbackRender={ErrorFallback}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ReactKeycloakProvider
          initOptions={determineInitOptions()}
          authClient={keycloak}
          LoadingComponent={<BackdropComponent />}
          onEvent={eventHandler}
          onTokens={tokenHandler}
        >
          <ReduxProvider store={store}>
            <PersistGate loading={<BackdropComponent />} persistor={persistor}>
              <Suspense fallback={<BackdropComponent />}>
                <SnackbarProvider
                  autoHideDuration={5000}
                  maxSnack={MAX_SNACKBAR}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <React.StrictMode>
                    <App />
                  </React.StrictMode>
                </SnackbarProvider>
              </Suspense>
            </PersistGate>
          </ReduxProvider>
        </ReactKeycloakProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
