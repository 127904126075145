import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import ReferentielEntreprise from '../types/referentielEntreprise'

export const referentielEntrepriseMedecinApi = createApi({
  reducerPath: 'referentielEntrepriseApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  endpoints: (builder) => ({
    getReferentielEntreprise: builder.query<ReferentielEntreprise[], void>({
      query: () => 'medecin/entreprise-partenaire',
    }),
  }),
})

export const {
  //  endpoints: { getReferentielEntreprise },
  useGetReferentielEntrepriseQuery,
} = referentielEntrepriseMedecinApi
