import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import MissionEntreprise, {
  MissionRequest,
  SuiviMissionEntrepriseRequest,
} from 'assureur/store/types/missionEntreprise'
import Facture, {
  FactureFilter,
  FactureSortType,
  SuiviFactureEntrepriseAction,
} from 'assureur/store/types/factureEntreprise'
import DossierEntreprise, {
  DossierEntrepriseList,
  InformationDossier,
  DossierEntrepriseFilter,
  DossierRequest,
  DossierEntrepriseSortType,
  DocumentRequest,
  CreationDossierRequest,
  HistoriqueDossierEntreprise,
  ChangerAffectationExEntRequest,
  EtapeCreationParam,
} from 'assureur/store/types/dossierEntreprise'
import { SupprimerDocumentConclusionRequest } from 'assureur/store/types/rapportConlusionEntreprise'
import { RevisionDossierRequest } from 'plateforme/store/types/dossier'

export interface SuiviDossierRequest extends DossierRequest {
  action: SuiviDossierAction
  motifCloture?: string
  commentaire?: string
  etapes?: EtapeCreationParam[]
}

export enum SuiviDossierAction {
  VALIDER = 'VALIDER',
  CONTROLER = 'CONTROLER',
  ANNULER = 'ANNULER',
  CLOTURER = 'CLOTURER',
  PURGER = 'PURGER',
}

/* Pour l'ajout d'un document au dossier voir --> documentDossierUploadSlice.ts */
export const dossierAssureurApi = createApi({
  reducerPath: 'dossierAssureurApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['Dossier', 'Mission'],
  endpoints: (builder) => ({
    getListeDossier: builder.query<DossierEntrepriseList, void>({
      query: () => 'entreprise/dossier/',
    }),
    getDossierById: builder.query<DossierEntreprise, DossierRequest>({
      query: ({ codeDossier }) => `entreprise/dossier/${codeDossier}`,
      providesTags: (result, error, { codeDossier }) => [{ type: 'Dossier', id: codeDossier }],
    }),
    getHistoriqueDossier: builder.query<HistoriqueDossierEntreprise[], DossierRequest>({
      query: ({ codeDossier }) => `entreprise/dossier/${codeDossier}/historique`,
      providesTags: (result, error, { codeDossier }) => [{ type: 'Dossier', id: codeDossier }],
    }),
    postCreerDossier: builder.mutation<DossierEntreprise, CreationDossierRequest>({
      query: (body) => ({
        url: 'entreprise/dossier',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Dossier']),
    }),
    postChangerAffectation: builder.mutation<DossierEntreprise, DossierRequest & ChangerAffectationExEntRequest>({
      query(data) {
        const { codeDossier, ...body } = data
        return {
          url: `entreprise/dossier/${codeDossier}/affectation`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result, error, { codeDossier }) => (error ? [] : [{ type: 'Dossier', id: codeDossier }]),
    }),
    putModifierDossier: builder.mutation<DossierRequest & InformationDossier, unknown>({
      query: ({ codeDossier, etape, ...body }) => ({
        url: `entreprise/dossier/${codeDossier}`,
        method: 'PUT',
        params: {
          etape,
        },
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Dossier']),
    }),
    postSuiviDossier: builder.mutation<DossierEntreprise, SuiviDossierRequest>({
      query: ({ codeDossier, etapes, ...body }) => ({
        url: `entreprise/dossier/${codeDossier}/suivi`,
        method: 'POST',
        params: {
          etapes,
        },
        body,
      }),
      invalidatesTags: (result, error, { codeDossier }) => (error ? [] : [{ type: 'Dossier', id: codeDossier }]),
    }),
    postSuiviMission: builder.mutation<MissionEntreprise, SuiviMissionEntrepriseRequest>({
      query: ({ codeDossier, codeMission, ...body }) => ({
        url: `entreprise/dossier/${codeDossier}/mission/${codeMission}/suivi`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { codeDossier, codeMission }) =>
        error
          ? []
          : [
              { type: 'Dossier', id: codeDossier },
              { type: 'Mission', id: codeMission },
            ],
    }),
    postSuiviFacture: builder.mutation<
      void,
      {
        codeDossier: string
        codeMission: string
        codeFacture: string
        action: SuiviFactureEntrepriseAction
        dateTraitement?: string
        commentaire?: string
      }
    >({
      query: ({ codeDossier, codeMission, codeFacture, action, dateTraitement, commentaire }) => {
        return {
          url: `entreprise/dossier/${codeDossier}/mission/${codeMission}/facture/${codeFacture}/suivi`,
          body: {
            dateTraitement,
            commentaire,
            action,
          },
          method: 'POST',
        }
      },
      invalidatesTags: (result, error, { codeDossier, codeMission }) =>
        error
          ? []
          : [
              { type: 'Dossier', id: codeDossier },
              { type: 'Mission', id: codeMission },
            ],
    }),
    deleteDocumentDossier: builder.mutation<void, DocumentRequest>({
      query: ({ codeDossier, codeDocument }) => {
        return {
          url: `entreprise/dossier/${codeDossier}/document/${codeDocument}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, { codeDossier }) => (error ? [] : [{ type: 'Dossier', id: codeDossier }]),
    }),
    deleteConclusionDocument: builder.mutation<void, SupprimerDocumentConclusionRequest>({
      query: ({ codeDossier, codeMission, codeDocument }) => ({
        url: `entreprise/dossier/${codeDossier}/mission/${codeMission}/rapport/document/${codeDocument}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { codeDossier, codeMission }) =>
        error
          ? []
          : [
              { type: 'Dossier', id: codeDossier },
              { type: 'Mission', id: codeMission },
            ],
    }),
    getRechercheDossier: builder.query<
      PageRecherche<DossierEntreprise>,
      PageRechercheRequest<DossierEntrepriseFilter, DossierEntrepriseSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => ({
        url: 'entreprise/dossier',
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
        },
      }),
    }),
    getRechercheFacture: builder.query<PageRecherche<Facture>, PageRechercheRequest<FactureFilter, FactureSortType>>({
      query: ({ perPage, page, filter, sort, onlyTotal }) => ({
        url: 'entreprise/facture',
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
        },
      }),
    }),
    postCreerMission: builder.mutation<DossierEntreprise, MissionRequest>({
      query: ({ codeDossier, generationOrdreMission, ...body }) => ({
        url: `entreprise/dossier/${codeDossier}/mission`,
        params: {
          generationOrdreMission: generationOrdreMission ? 1 : 0,
        },
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Dossier']),
    }),
    getRevisionDossier: builder.query<DossierEntreprise, RevisionDossierRequest>({
      query: ({ codeDossier, codeMission }) => ({
        url: `entreprise/dossier/${codeDossier}/revision`,
        params: {
          codeMission,
        },
      }),
      providesTags: (result, error, { codeDossier }) => [{ type: 'Dossier', id: codeDossier }],
    }),
  }),
})

export const {
  useGetListeDossierQuery,
  useGetDossierByIdQuery,
  usePostCreerDossierMutation,
  usePutModifierDossierMutation,
  usePostSuiviDossierMutation,
  usePostSuiviMissionMutation,
  useDeleteDocumentDossierMutation,
  useDeleteConclusionDocumentMutation,
  useGetRechercheDossierQuery,
  useGetRechercheFactureQuery,
  usePostCreerMissionMutation,
  usePostSuiviFactureMutation,
  useGetHistoriqueDossierQuery,
  usePostChangerAffectationMutation,
  useGetRevisionDossierQuery,
  useLazyGetRevisionDossierQuery,
} = dossierAssureurApi
