import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import ProfilEntreprise from '../types/profilEntreprise'

export const profilEntrepriseApi = createApi({
  reducerPath: 'profilEntrepriseApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['ParametrageEntreprise'],
  endpoints: (builder) => ({
    getProfilEntreprise: builder.query<ProfilEntreprise, void>({
      query: () => 'entreprise/profil',
      providesTags: ['ParametrageEntreprise'],
    }),
  }),
})

export const { useGetProfilEntrepriseQuery } = profilEntrepriseApi
