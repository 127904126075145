import React from 'react'
import { Button, Card, Checkbox, Divider, Stack, Tooltip, Typography } from '@mui/material'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeSvgIcon, SortComponentMenu } from 'plateforme/components/index'
import { SortOption, SortParam } from 'plateforme/store/types/pageRecherche'
import { AuthDownloadRequest } from 'plateforme/store/types/utilisateur'
import { usePostAuthDownloadMutation } from 'plateforme/store/apis/utilisateurApi'
import { useSnackbar } from 'notistack'

interface SearchResultBarCardProps<T> {
  title?: string
  total?: number
  loading?: boolean
  withSorting: boolean
  withExportCSV: boolean
  sortBy?: (param: SortParam<T>) => void
  sortOptions?: SortOption<T>[]
  initSort?: VoidFunction
  requestExportCSV?: AuthDownloadRequest
  hrefExportCSV?: string
}

export default function ResultatRechercheBarCard({
  title,
  total = 0,
  loading = false,
  withSorting,
  withExportCSV,
  sortBy,
  sortOptions,
  initSort,
  requestExportCSV,
  hrefExportCSV,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
SearchResultBarCardProps<any>) {
  const { enqueueSnackbar } = useSnackbar()
  const [authDownload] = usePostAuthDownloadMutation()

  const handleExportCSV = async () => {
    if (requestExportCSV !== undefined) {
      await authDownload(requestExportCSV)
        .unwrap()
        .then(() => {
          window.open(hrefExportCSV, '_self')
        })
        .catch(() => {
          enqueueSnackbar(`L'export du fichier CSV a échoué`, { variant: 'error' })
        })
    }
  }

  return (
    <Card
      sx={{
        width: '100%',
        height: '50px',
        backgroundColor: (theme) => theme.palette.info.main,
        color: (theme) => theme.palette.info.contrastText,
      }}
    >
      <Stack paddingLeft="20px" direction="row" alignItems="center" justifyContent="flex-start">
        {withExportCSV && (
          <Checkbox
            sx={{
              color: '#fff',
              '&.Mui-checked': {
                color: '#fff',
              },
            }}
          />
        )}
        <Typography variant="h5" sx={{ WebkitFontSmoothing: 'auto', MozOsxFontSmoothing: 'auto' }}>
          {title} {loading ? '...' : ` (${total})`}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={0} flexGrow={3} justifyContent="flex-end">
        {withExportCSV && (
          <>
            <Divider color="#f9f9f9" variant="inset" orientation="vertical" light flexItem sx={{ marginLeft: 0 }} />
            <Tooltip title="Télécharger CSV" placement="bottom">
              <Button onClick={handleExportCSV} id="downlod-csv" color="info" variant="contained" disableElevation>
                <FontAwesomeSvgIcon icon={faFileCsv} />
              </Button>
            </Tooltip>
          </>
        )}

        {withSorting && sortBy && sortOptions && initSort && (
          <>
            <Divider color="#eee" variant="inset" orientation="vertical" light flexItem sx={{ marginLeft: 0 }} />
            <SortComponentMenu sortBy={sortBy} sortOptions={sortOptions} initSort={initSort} />
          </>
        )}
      </Stack>
    </Card>
  )
}
