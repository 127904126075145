import React, { useEffect } from 'react'
import { Alert, Grid } from '@mui/material'
import { InfiniteScrollComponent, ResultatRechercheBarCard } from 'plateforme/components'
import { SortParam, SortOption } from 'plateforme/store/types/pageRecherche'
import MedecinEntreprise, { MedecinEntrepriseSortType } from 'assureur/store/types/medecinEntreprise'
import { useSnackbar } from 'notistack'
import MedecinEntrepriseCard from 'assureur/parts/rechercheMedecin/MedecinEntrepriseCard'
import { AuthDownloadAction } from 'plateforme/store/types/utilisateur'
import { toTelechargementExportHabilitationHref } from 'plateforme/App'

type ListeMedecinsPartProps = {
  combinedData: Array<MedecinEntreprise>
  total?: number
  readMore: VoidFunction
  sortBy: (param: SortParam<MedecinEntrepriseSortType>) => void
  initSort: VoidFunction
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
  exportParams?: string
}

export default function ListeMedecinsEntreprisePart({
  combinedData,
  total,
  readMore,
  sortBy,
  initSort,
  isError,
  isLoading,
  isFetching,
  isSuccess,
  exportParams,
}: ListeMedecinsPartProps) {
  // les options de tri disponibles a passer au composant SortComponentMenu
  const sortOptions: SortOption<MedecinEntrepriseSortType>[] = [
    { sortType: MedecinEntrepriseSortType.NOM_MEDECIN_SORT, label: 'Nom du médecin' },
    { sortType: MedecinEntrepriseSortType.CODE_MEDECIN_SORT, label: 'Identifiant médecin plateforme' },
  ]

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isError) {
      enqueueSnackbar('La recherche des médecins a échoué.', { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}
    >
      <Grid item container xs={12} paddingBottom={2.5}>
        <ResultatRechercheBarCard
          title="Liste des médecins"
          total={total}
          loading={isLoading || isFetching}
          sortBy={sortBy}
          sortOptions={sortOptions}
          initSort={initSort}
          withSorting
          withExportCSV={false}
          hrefExportCSV={toTelechargementExportHabilitationHref(exportParams)}
          requestExportCSV={{ action: AuthDownloadAction.TELECHARGER_CSV_HABILITATION }}
        />
      </Grid>
      {(isError && !isSuccess && !isLoading && !isFetching) || total === 0 ? (
        <Alert severity="info" sx={{ width: '100%' }}>
          Aucun médecin remonté.
        </Alert>
      ) : (
        <InfiniteScrollComponent
          dataLength={combinedData?.length}
          next={readMore}
          hasMore={combinedData.length < (total ?? 0)}
          isLoading={isLoading || isFetching}
          xs={12}
          lg={6}
        >
          <Grid item container xs={12}>
            {combinedData?.map((medecin: MedecinEntreprise) => (
              <Grid item xs={12} lg={6} key={medecin.code}>
                <MedecinEntrepriseCard key={medecin.code} medecin={medecin} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScrollComponent>
      )}
    </Grid>
  )
}
