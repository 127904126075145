import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material'
import { ButtonsStack, ChercherButton, DatePickerInput, EditTextField, RechargerButton } from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatBooleanFilter } from 'plateforme/services/apis.services'
import { formatDateISO, isDateAfterNow } from 'plateforme/services/dates.services'
import { trimToUndefined } from 'plateforme/services/utils'
import { HabilitationEntrepriseFilter } from 'assureur/store/types/habilitationEntreprise'
import ProfilEntreprise from 'assureur/store/types/profilEntreprise'

type SearchHabilitationsProps = {
  search: (criteria: HabilitationEntrepriseFilter) => void
  initSearch: VoidFunction
  searchError: IQueryErrorResponse
  profilEntreprise?: ProfilEntreprise
}

export interface HabilitationFilterData {
  numeroHabilitation: string
  nomMedecin: string
  codeMedecin: string
  intervenantOccasionnel: boolean
  expertiseSurPiece: boolean
  irca: boolean
  dateActive: Date | null
  codePostal: string
}

export default function RechercheHabilitationsEntreprisePart({
  search,
  initSearch,
  searchError,
  profilEntreprise,
}: SearchHabilitationsProps) {
  // Les valeurs initiales du formulaire :
  const initialValues: HabilitationFilterData = {
    numeroHabilitation: '',
    nomMedecin: '',
    codeMedecin: '',
    intervenantOccasionnel: false,
    expertiseSurPiece: false,
    irca: false,
    dateActive: null,
    codePostal: '',
  }
  const {
    handleSubmit,
    control,
    reset,
    setError,
    getValues,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })
  useErrorFormMapper(searchError, setError, getValues)

  // state management
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const isParamIntervenantOccasionnel = profilEntreprise?.parametrage?.intervenantOccasionnel ?? false
  const isParamAvisSurPiece = profilEntreprise?.parametrage?.expertiseSurPiece ?? false

  const determineActive = (dateActive: Date | null) => {
    if (dateActive === undefined || dateActive === null) {
      return undefined
    }
    return isDateAfterNow(dateActive) ? undefined : 1
  }

  const determineActiveFutur = (dateActive: Date | null) => {
    if (dateActive === undefined || dateActive === null) {
      return undefined
    }
    return isDateAfterNow(dateActive) ? 1 : undefined
  }

  const onSubmit = async (data: HabilitationFilterData) => {
    search({
      numeroHabilitation: trimToUndefined(data.numeroHabilitation),
      nomMedecin: trimToUndefined(data.nomMedecin),
      codeMedecin: trimToUndefined(data.codeMedecin),
      intervenantOccasionnel: formatBooleanFilter(data.intervenantOccasionnel),
      expertiseSurPiece: formatBooleanFilter(data.expertiseSurPiece),
      irca: formatBooleanFilter(data.irca),
      dateActive: formatDateISO(data.dateActive),
      active: determineActive(data.dateActive),
      activeFutur: determineActiveFutur(data.dateActive),
      codePostal: trimToUndefined(data.codePostal),
    })
  }

  const handleClickInitSearch = () => {
    initSearch()
    reset(initialValues)
  }

  // Snackbar handlers :
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-habilitation">
          <Grid container>
            <Grid item xs={12}>
              <Controller
                name="nomMedecin"
                control={control}
                defaultValue={initialValues.nomMedecin}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="nom-medecin"
                    label="Nom du médecin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>

            <Grid container item xs={12}>
              <Grid container item xs={12} sm={12} md={8}>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="codeMedecin"
                    control={control}
                    defaultValue={initialValues.codeMedecin}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <EditTextField
                        id="id-plateforme"
                        label="Identifiant médecin plateforme"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        fullWidth
                        fieldError={error}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="numeroHabilitation"
                    control={control}
                    defaultValue={initialValues.numeroHabilitation}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <EditTextField
                        id="numero-habilitation"
                        label="Identifiant médecin entreprise (numéro d’habilitation)"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        fullWidth
                        fieldError={error}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Stack direction="row" alignItems="center" justifyContent="flex-start">
                    <Typography width={100}>Habilitation active au</Typography>
                    <Controller
                      name="dateActive"
                      control={control}
                      defaultValue={initialValues.dateActive}
                      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                        <DatePickerInput
                          InputProps={{
                            id: 'date-active',
                            fullWidth: true,
                          }}
                          label="Date"
                          value={value === undefined ? null : value}
                          onBlur={onBlur}
                          onChange={onChange}
                          fieldError={error}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="codePostal"
                    control={control}
                    defaultValue={initialValues.codePostal}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <EditTextField
                        id="lieu-exercice-medecin"
                        label="Code postal du lieu d’exercice"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        fullWidth
                        fieldError={error}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Stack direction="column" alignItems="flex-start" justifyContent="center" spacing={0} height="100%">
                  {isParamIntervenantOccasionnel && (
                    <Controller
                      name="intervenantOccasionnel"
                      control={control}
                      defaultValue={initialValues.intervenantOccasionnel}
                      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                        <FormControl error={error !== undefined}>
                          <FormGroup>
                            <FormControlLabel
                              label="Intervenant occasionnel"
                              sx={{ height: 30 }}
                              control={
                                <Checkbox
                                  name="intervenantOccasionnel"
                                  checked={value}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  sx={{ padding: 0, marginX: 1.5 }}
                                />
                              }
                            />
                          </FormGroup>
                          {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  )}
                  {isParamAvisSurPiece && (
                    <Controller
                      name="expertiseSurPiece"
                      control={control}
                      defaultValue={initialValues.expertiseSurPiece}
                      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                        <FormControl error={error !== undefined}>
                          <FormGroup>
                            <FormControlLabel
                              label="Avis technique sur pièce"
                              sx={{ height: 30 }}
                              control={
                                <Checkbox
                                  name="expertiseSurPiece"
                                  checked={value}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  sx={{ padding: 0, marginX: 1.5, display: 'bloc' }}
                                />
                              }
                            />
                          </FormGroup>
                          {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  )}
                  <Controller
                    name="irca"
                    control={control}
                    defaultValue={initialValues.irca}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <FormControl error={error !== undefined}>
                        <FormGroup>
                          <FormControlLabel
                            label="Médecin IRCA"
                            sx={{ height: 30 }}
                            control={
                              <Checkbox
                                name="typologieMedecin"
                                checked={value}
                                onBlur={onBlur}
                                onChange={onChange}
                                sx={{ padding: 0, marginX: 1.5, display: 'bloc' }}
                              />
                            }
                          />
                        </FormGroup>
                        {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton
                  variant="contained"
                  type="submit"
                  form="form-recherche-habilitation"
                  disabled={!isValid}
                >
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            Erreur serveur lors de la recherche des habilitations
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  )
}
