import {
  differenceInMonths,
  format,
  formatDistanceToNowStrict,
  isAfter,
  isBefore,
  isValid,
  setDefaultOptions,
} from 'date-fns'
import frLocale from 'date-fns/locale/fr'
import { formatInTimeZone } from 'date-fns-tz'

export const isDateValide = (date: Date | null) => {
  // NB : on considère que date inferieure a 1900-01-01 est invalide
  if (date !== null && isBefore(new Date(date), new Date(1900, 1, 1))) {
    return false
  }
  // NB: on considere que la date null est valide
  return date === null ? true : isValid(new Date(date))
}
export const isDateAfterNow = (date?: Date | null) => {
  if (date === null || date === undefined) {
    return true
  }
  const myDate = new Date(date).setHours(0, 0, 0, 0)
  const now = new Date()
  return isAfter(myDate, now.setHours(0, 0, 0, 0))
}

export const isDateBeforeNow = (date?: Date | null) => {
  if (date === null || date === undefined) {
    return true
  }
  const myDate = new Date(date).setHours(0, 0, 0, 0)
  const now = new Date()
  return isBefore(myDate, now.setHours(0, 0, 0, 0))
}

export const formatDurationFromNow = (date?: Date | null): string | undefined => {
  if (!date) {
    return undefined
  }

  return isValid(date)
    ? formatDistanceToNowStrict(date, { addSuffix: false, locale: frLocale })
    : 'error: durée invalide'
}

export const formatDurationFromNowInMonths = (date?: Date | null): number => {
  if (!date) {
    return -1
  }
  return isValid(date) ? differenceInMonths(date, new Date()) : -1
}

setDefaultOptions({ locale: frLocale, weekStartsOn: 1 })

export const formatDateISO = (date?: Date | null): string | undefined => {
  if (!date) {
    return undefined
  }
  if (isDateValide(date)) {
    try {
      return formatInTimeZone(date, 'Europe/Paris', 'yyyy-MM-dd')
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('erreur de formattage de date to string', date, e)
    }
  }
  return '#invalid#'
}

export const formatDateTimeISO = (date?: Date | null): string | undefined => {
  if (!date) {
    return undefined
  }
  return formatInTimeZone(date, 'Europe/Paris', `yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX`)
}

export const formatDateFR = (date?: Date | null): string | undefined => {
  if (!date) {
    return undefined
  }
  if (date.toString() === '••••••••') {
    return '••••••••'
  }
  try {
    return formatInTimeZone(date, 'Europe/Paris', 'dd/MM/yyyy')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('erreur de formattage de date to string', date, e)
  }
  return undefined
}

export const formatDateTimeFR = (
  date?: Date | null,
  timeSperator?: string,
  withSeconds?: boolean
): string | undefined => {
  if (!date) {
    return undefined
  }
  try {
    return formatInTimeZone(
      date,
      'Europe/Paris',
      `dd/MM/yyyy ${timeSperator ? `'${timeSperator}' ` : ''}HH:mm${withSeconds ? ':ss' : ''}`
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('erreur de formattage de date to string', date, e)
  }
  return undefined
}

export const parseDateLocal = (dateStr?: string | null): Date | undefined => {
  if (!dateStr) {
    return undefined
  }

  return new Date(dateStr)
}

export const formatDateLocalISO = (date?: Date | null): string | undefined => {
  if (!date) {
    return undefined
  }
  return format(date, 'yyyy-MM-dd')
}
