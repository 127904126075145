import React from 'react'
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { ReadOnlyTextField } from 'plateforme/components'
import { Stack } from '@mui/system'
import { ModeDommagesField, Periode, PlageDeDate, UniteEstimation } from 'admin/store/types/dommagesAdmin'
import ReadOnlyDatePicker from 'plateforme/components/inputs/ReadOnlyDatePicker'
import uuid from 'react-uuid'

interface DommageFieldRadioGroupProps {
  code?: string
  label?: string
  plageDeDate?: PlageDeDate
}

export default function ReadOnlyDommageRadioGroup({ code, label, plageDeDate }: DommageFieldRadioGroupProps) {
  const modeDommage = plageDeDate && plageDeDate.mode
  const periodes = plageDeDate?.periodes as Periode[]
  return (
    <Grid container>
      <Grid item xs={12} lg={3}>
        <Typography marginTop={2} marginLeft={2} color="primary" variant="subtitle2">
          {`${label} :`}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid item xs={12} md={6} display="flex" alignItems="flex-start" paddingBottom={2}>
          <Stack direction="row" alignItems="center">
            <Typography color="primary" variant="subtitle2">
              Type :
            </Typography>
            <RadioGroup row aria-labelledby="demo-error-radios" value={modeDommage}>
              <FormControlLabel disabled value={ModeDommagesField.PERIODE} control={<Radio />} label="Périodes" />
              <FormControlLabel disabled value={ModeDommagesField.FOURCHETTE} control={<Radio />} label="Fourchette" />
              <FormControlLabel disabled value={ModeDommagesField.ESTIMATION} control={<Radio />} label="Estimation" />
            </RadioGroup>
          </Stack>
        </Grid>

        {modeDommage === ModeDommagesField.PERIODE && periodes && (
          <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
            {periodes.map((item, index) => {
              return (
                <li key={uuid()}>
                  <Stack direction="row" spacing={3.5} marginBottom={index === periodes.length - 1 ? 0 : 2.5}>
                    <ReadOnlyDatePicker
                      InputProps={{
                        id: `date-debut-${index}`,
                        fullWidth: true,
                      }}
                      value={item.du === undefined ? null : item.du}
                      label="Du"
                    />

                    <ReadOnlyDatePicker
                      InputProps={{
                        id: `date-debut-${index}`,
                        fullWidth: true,
                      }}
                      value={item.au === undefined ? null : item.au}
                      label="Au"
                    />
                  </Stack>
                </li>
              )
            })}
          </ul>
        )}
        {modeDommage === ModeDommagesField.FOURCHETTE && plageDeDate && (
          <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack direction="row" alignItems="center" justifyContent="flex-start">
                <ReadOnlyTextField
                  type="number"
                  id={`id-gtt-estimation-min-${code}`}
                  label="Entre"
                  value={plageDeDate?.min?.valeur}
                  fullWidth
                />
                <RadioGroup row aria-labelledby="demo-error-radios" value={plageDeDate?.min?.unite}>
                  <FormControlLabel disabled value={UniteEstimation.JOUR} control={<Radio />} label="Jours" />
                  <FormControlLabel disabled value={UniteEstimation.SEMAINE} control={<Radio />} label="Semaines" />
                  <FormControlLabel disabled value={UniteEstimation.MOIS} control={<Radio />} label="Mois" />
                  <FormControlLabel disabled value={UniteEstimation.ANNEE} control={<Radio />} label="Années" />
                </RadioGroup>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack direction="row" alignItems="center" justifyContent="flex-start">
                <ReadOnlyTextField
                  type="number"
                  id={`id-gtt-estimation-max-${code}`}
                  label="Et"
                  value={plageDeDate.max?.valeur}
                  fullWidth
                />
                <RadioGroup row aria-labelledby="demo-error-radios" value={plageDeDate?.max?.unite}>
                  <FormControlLabel disabled value={UniteEstimation.JOUR} control={<Radio />} label="Jours" />
                  <FormControlLabel disabled value={UniteEstimation.SEMAINE} control={<Radio />} label="Semaines" />
                  <FormControlLabel disabled value={UniteEstimation.MOIS} control={<Radio />} label="Mois" />
                  <FormControlLabel disabled value={UniteEstimation.ANNEE} control={<Radio />} label="Années" />
                </RadioGroup>
              </Stack>
            </Grid>
          </Grid>
        )}
        {modeDommage === ModeDommagesField.ESTIMATION && plageDeDate && (
          <Stack direction="row" alignItems="center" justifyContent="flex-start">
            <ReadOnlyTextField
              type="number"
              id={`id-gtt-estimation-${code}`}
              label="nombre"
              value={plageDeDate?.estimation?.valeur}
            />
            <RadioGroup row aria-labelledby="demo-error-radios" value={plageDeDate?.estimation?.unite}>
              <FormControlLabel disabled value={UniteEstimation.JOUR} control={<Radio />} label="Jours" />
              <FormControlLabel disabled value={UniteEstimation.SEMAINE} control={<Radio />} label="Semaines" />
              <FormControlLabel disabled value={UniteEstimation.MOIS} control={<Radio />} label="Mois" />
              <FormControlLabel disabled value={UniteEstimation.ANNEE} control={<Radio />} label="Années" />
            </RadioGroup>
          </Stack>
        )}
      </Grid>
    </Grid>
  )
}
