import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'
import { DatePickerInput, EditTextField, SelectInputReferentiel } from 'plateforme/components'
import PhoneNumberInput from 'plateforme/components/inputs/PhoneNumberInput'
import { validateRequiredWithCondition } from 'plateforme/services/utils'
import { MSG_FIELD_REQUIRED } from 'plateforme/constantes'
import InfosContactRequisesAlert from '../InfosContactRequisesAlert'

export type PersonneAExaminerProps = {
  qualiteRequise?: boolean
}

export default function PersonneAExaminer({ qualiteRequise }: PersonneAExaminerProps) {
  const { clearErrors, control, formState, setValue } = useFormContext()

  const personneAExaminerError = 'personneAExaminer._error'
  const personneAContacterError = 'personneAContacter._error'

  const severity =
    formState.errors.personneAExaminer && '_error' in formState.errors.personneAExaminer ? 'error' : 'info'

  function clearPersonneAExaminerError() {
    clearErrors(personneAExaminerError)
    setValue(personneAExaminerError, undefined, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }
  function clearPersonneAContacterError() {
    clearErrors(personneAContacterError)
    setValue(personneAContacterError, undefined, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  return (
    <Card>
      <CardHeader title="Personne à examiner" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="personneAExaminer.sexe"
              control={control}
              rules={{ required: MSG_FIELD_REQUIRED }}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <SelectInputReferentiel
                  id="personne-a-examiner-sexe"
                  label="Sexe"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  referentielName="sexes"
                  fullWidth
                  withNoSelectionItem
                  fieldError={error}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="personneAExaminer.nom"
              control={control}
              rules={{ required: MSG_FIELD_REQUIRED }}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="personne-a-examiner-nom"
                  label="Nom"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="personneAExaminer.prenom"
              control={control}
              rules={{ required: MSG_FIELD_REQUIRED }}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="personne-a-examiner-prenom"
                  label="Prénom"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="personneAExaminer.dateNaissance"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <DatePickerInput
                  InputProps={{
                    id: 'personne-a-examiner-date-naissance',
                    fullWidth: true,
                  }}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  label="Date de naissance"
                  fieldError={error}
                />
              )}
            />
          </Grid>
          {qualiteRequise && (
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="personneAExaminer.qualite"
                control={control}
                rules={{ required: validateRequiredWithCondition(qualiteRequise) }}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputReferentiel
                    id="personne-a-examiner-qualite"
                    label="Qualité"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    referentielName="qualitesPersonneAExaminer"
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                    required={qualiteRequise}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={qualiteRequise ? 6 : 12} md={qualiteRequise ? 4 : 8}>
            <Controller
              name="personneAExaminer.profession"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="personne-a-examiner-profession"
                  label="Profession"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <InfosContactRequisesAlert severity={severity} />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="personneAExaminer.adresse1"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="personne-a-examiner-adresse1"
                  label="Adresse 1"
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    clearPersonneAExaminerError()
                    onChange(e)
                  }}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="personneAExaminer.adresse2"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="personne-a-examiner-adresse2"
                  label="Adresse 2"
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    clearPersonneAExaminerError()
                    onChange(e)
                  }}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="personneAExaminer.adresse3"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="personne-a-examiner-adresse3"
                  label="Adresse 3"
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    clearPersonneAExaminerError()
                    onChange(e)
                  }}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="personneAExaminer.codePostal"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="personne-a-examiner-code-postal"
                  label="Code postal"
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    clearPersonneAExaminerError()
                    onChange(e)
                  }}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="personneAExaminer.commune"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="personne-a-examiner-commune"
                  label="Ville"
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    clearPersonneAExaminerError()
                    onChange(e)
                  }}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="personneAExaminer.pays"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <SelectInputReferentiel
                  id="personne-a-examiner-pays"
                  label="Pays"
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    clearPersonneAExaminerError()
                    onChange(e)
                  }}
                  referentielName="pays"
                  fullWidth
                  withNoSelectionItem
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="personneAExaminer.mail"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="personne-a-examiner-mail"
                  label="Adresse mail"
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    clearPersonneAExaminerError()
                    onChange(e)
                  }}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="personneAExaminer.telephoneMobile"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <PhoneNumberInput
                  id="personne-a-examiner-telephone-mobile"
                  label="Tél. portable"
                  value={value}
                  onChange={(e) => {
                    clearPersonneAExaminerError()
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  fieldError={error}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="personneAExaminer.telephoneFixe"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <PhoneNumberInput
                  id="personne-a-examiner-telephone-fixe"
                  label="Tél. fixe"
                  value={value}
                  onChange={(e) => {
                    clearPersonneAExaminerError()
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  fieldError={error}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="personneAExaminer.assure"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <FormControl error={!!error} variant="standard">
                  <FormControlLabel
                    id="personne-a-examiner-assure"
                    value={value}
                    control={<Switch onBlur={onBlur} onChange={onChange} checked={value} />}
                    label="La personne à examiner est l'assuré"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="personneAExaminer.contactUnique"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <FormControl error={!!error} variant="standard">
                  <FormControlLabel
                    id="personne-a-examiner-contact-unique"
                    value={value}
                    control={
                      <Switch
                        onBlur={onBlur}
                        onChange={(e) => {
                          clearPersonneAContacterError()
                          onChange(e)
                        }}
                        checked={value}
                      />
                    }
                    label="La personne à contacter est la personne à examiner"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
