import React from 'react'
import { Box, useTheme } from '@mui/material'
import { AreaLoading } from 'plateforme/components'
import { LoadingProps } from 'plateforme/services/utils'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import { dossierConsultable } from 'assureur/services/rolesEntreprise.services'
import SuiviDossierPart from 'plateforme/parts/SuiviDossierPart'
import SuiviMissionAccordion from './SuiviMissionAccordion'

type SuiviSyntheseEntreprisePartProps = {
  dossier?: DossierEntreprise
  profilUtilisateur?: ProfilUtilisateurActif
}

type SuiviMissionParam = {
  mission: MissionEntreprise
  index?: number
}

export default function SuiviSyntheseEntreprisePart({
  dossier,
  profilUtilisateur,
  loading,
}: SuiviSyntheseEntreprisePartProps & LoadingProps) {
  // chargement:
  if (loading) {
    return <AreaLoading height={224} />
  }
  return <SuiviSyntheseEntreprisePartLoaded dossier={dossier} profilUtilisateur={profilUtilisateur} />
}

function SuiviSyntheseEntreprisePartLoaded({ dossier, profilUtilisateur }: SuiviSyntheseEntreprisePartProps) {
  // erreur:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const { missions } = dossier
  const derniereMission = missions && missions.length > 0 ? missions[0] : undefined

  // hooks:
  const theme = useTheme()

  // state:
  const [expanded, setExpanded] = React.useState<string | undefined | false>(derniereMission?.code)

  // change accordion:
  const onChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  let missionIndex = 0
  let carenceIndex = 0
  let conclusionsProvisoiresIndex = 0
  let conclusionsDefinitivesIndex = 0

  const params =
    missions
      ?.filter((mission) => mission.code)
      .map((mission) => {
        missionIndex += 1
        switch (mission.rapport?.typeRapport) {
          case TypeRapport.CONCLUSION_PROVISOIRE: {
            conclusionsProvisoiresIndex += 1
            return { mission, index: conclusionsProvisoiresIndex } as SuiviMissionParam
          }
          case TypeRapport.CONCLUSION_DEFINITIVE: {
            conclusionsDefinitivesIndex += 1
            return { mission, index: conclusionsDefinitivesIndex } as SuiviMissionParam
          }
          case TypeRapport.CARENCE: {
            carenceIndex += 1
            return { mission, index: carenceIndex } as SuiviMissionParam
          }
          default: {
            return { mission } as SuiviMissionParam
          }
        }
      }) || []

  const calcMissionIndex = (mission: MissionEntreprise, index?: number) => {
    if (index === undefined) {
      return undefined
    }
    if (missionIndex <= 1) {
      return undefined
    }
    return missionIndex - index
  }

  const calcRapportIndex = (mission: MissionEntreprise, index?: number) => {
    if (!index) {
      return undefined
    }

    switch (mission.rapport?.typeRapport) {
      case TypeRapport.CONCLUSION_PROVISOIRE: {
        if (conclusionsProvisoiresIndex <= 1) {
          return undefined
        }
        return conclusionsProvisoiresIndex - index + 1
      }
      case TypeRapport.CONCLUSION_DEFINITIVE: {
        if (conclusionsDefinitivesIndex <= 1) {
          return undefined
        }
        return conclusionsDefinitivesIndex - index + 1
      }
      case TypeRapport.CARENCE: {
        if (carenceIndex <= 1) {
          return undefined
        }
        return carenceIndex - index + 1
      }
      default: {
        return undefined
      }
    }
  }

  // rendu:
  return (
    <>
      <SuiviDossierPart dossier={dossier} />
      <Box marginBottom={`${theme.spacing(-2.5)} !important`}>
        {dossierConsultable(profilUtilisateur) &&
          params.map((param, index) => (
            <SuiviMissionAccordion
              key={param.mission.code}
              dossier={dossier}
              mission={param.mission}
              missionIndex={calcMissionIndex(param.mission, index)}
              rapportIndex={calcRapportIndex(param.mission, param.index)}
              expanded={expanded === param.mission.code}
              onChange={() => onChange(param.mission.code)}
            />
          ))}
      </Box>
    </>
  )
}
