export type Dommages = {
  [key: string]: DommageField
}

export type DommageField = {
  code?: string
  label?: string
  fieldName?: string
  mode?: keyof typeof ModeDommagesField
  plageDeDates?: PlageDeDate
  commentaire?: string
  valeur?: number
  min?: number
  max?: number
  typeDommage: TypeDommage.DEFINITIF | TypeDommage.TEMPORAIRE
  periodes: TiercePersonne[]
}

export enum TypeDommage {
  DEFINITIF = 'DEFINITIF',
  TEMPORAIRE = 'TEMPORAIRE',
}

export type PlageDeDate = {
  mode?: string
  periodes?: Periode[] | TiercePersonne[] | []
  min?: Estimation
  max?: Estimation
  estimation?: Estimation
}

export type TiercePersonne = {
  type: keyof typeof TypePersonne
  nombreHeures: number
  par: keyof typeof UniteEstimation
  periode: Periode
}

export enum TypePersonne {
  ACTIVE = 'ACTIVE',
  PASSIVE = 'PASSIVE',
}

export type Periode = {
  du?: Date
  au?: Date
}

export type Estimation = {
  valeur: number
  unite: keyof typeof UniteEstimation
}

export enum UniteEstimation {
  JOUR = 'JOUR',
  SEMAINE = 'SEMAINE',
  MOIS = 'MOIS',
  ANNEE = 'ANNEE',
}

export enum ModeDommagesField {
  PERIODE = 'PERIODE',
  ESTIMATION = 'ESTIMATION',
  FOURCHETTE = 'FOURCHETTE',
}
