import React from 'react'
import { EditTextField } from 'plateforme/components'
import { Controller, useFormContext } from 'react-hook-form'

interface DommageFieldNumericProps {
  name: string
  code: string
  readOnly?: boolean
}

export default function DommageFieldPourcentage({ name, code, readOnly }: DommageFieldNumericProps) {
  const { control } = useFormContext()

  return (
    <Controller
      name={`${name}.valeur`}
      control={control}
      /*
        rules={{
          required: true,
          validate: (value?: string) =>
           value && !/^([1-9]\d?|100)$/.test(value) ? 'Veuillez saisir une valeur numérique entre 1 et 100' : true,
        }
        */
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
        <EditTextField
          value={value}
          id={code}
          type="number"
          label="Valeur numérique entière (%)"
          onBlur={onBlur}
          onChange={onChange}
          fieldError={error}
          readOnly={readOnly}
          fullWidth
        />
      )}
    />
  )
}
