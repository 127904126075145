import { SelectOption } from 'plateforme/components/inputs/SelectInput'

export default interface ProfilMedecinAdmin {
  code?: string | null
  codeRPPS?: string | null
  codeSIREN?: string | null
  libelle: string | null
  statut?: StatutMedecin
  labelStatut?: string | null
  irca: boolean
  lieuxExercice: LieuExerciceAdmin[]
  mailContact: string | null
  expertiseSurPiece: boolean
  parametrage: ParametrageMedecinAdmin
  commentaireActivite?: string | null
  activable?: boolean
  suspensible?: boolean
  supprimable?: boolean
  dateCreation?: Date
  dateMiseAJour?: Date
  dateInscription?: Date
  dateSuspension?: Date
  dateSuppression?: Date
}

export enum StatutMedecin {
  INSCRIPTION = 'INSCRIPTION',
  ACTIF = 'ACTIF',
  SUSPENDU = 'SUSPENDU',
  SUPPRIME = 'SUPPRIME',
}

export interface ReferentielMedecin extends SelectOption {
  code: string
  libelle: string
  codeRPPS: string
  codeSIREN: string
  actif: boolean
}

export interface LieuExerciceAdmin {
  principal: boolean
  codePostal: string | null
  commune: string | null
  codeSIRET: string | null
  telephone?: string
  adresse1?: string | null
  adresse2?: string | null
  adresse3?: string | null
  pays: string | null
}

export interface ParametrageMedecinAdmin {
  signature?: string | null
  mailNotification: string | null
  frequenceNotification: string | null
  labelFrequenceNotification?: string | null
}

export interface SuiviMedecinRequest {
  code: string
  action: SuiviMedecinType
}

export enum SuiviMedecinType {
  ACTIVER = 'ACTIVER',
  SUSPENDRE = 'SUSPENDRE',
  SUPPRIMER = 'SUPPRIMER',
}

export interface AdminMedecinRequest {
  codeMedecin: string
}

export interface MedecinAdminFilter {
  motCle?: string
  nomMedecin?: string
  statut?: string
  codeEntreprise?: string
  dateCreationDebut?: string
  irca?: 0 | 1
  expertiseSurPiece?: 0 | 1
}

export enum MedecinAdminSortType {
  CODE_MEDECIN_SORT = 'code_medecin',
  NOM_MEDECIN_SORT = 'nom_medecin',
  DATE_INSCRIPTION_SORT = 'date_inscription',
  STATUT_SORT = 'statut',
}
