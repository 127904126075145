import { TypeMessageNotification } from 'plateforme/store/types/referentiel'
import DossierAdmin, { ReferenceEntreprise } from './dossierAdmin'
import { DocumentsAdmin } from './documentsAdmin'
import { ReferenceMedecin } from './missionAdmin'

export type NotificationAdmin = {
  code?: string
  type?: TypeMessageNotification
  labelType?: string
  date?: Date
  entreprise?: ReferenceEntreprise
  expediteur?: ReferenceMedecin
  medecin?: ReferenceMedecin
  dossier?: DossierAdmin
  documents?: DocumentsAdmin[]
  objet?: string
}

export interface NotificationAdminFilter {
  motCle?: string
  objet?: string
  typesExclus?: TypeMessageNotification[]
  types?: TypeMessageNotification[]
  codeMedecin?: string
  codeEntreprise?: string
  codeExpediteur?: string
  dateDebut?: string
  dateFin?: string
}

export enum NotificationAdminSortType {
  DATE_CREATION_SORT = 'date_creation',
  TYPE_NOTIFICATION = 'type_notification',
  DOSSIER = 'dossier',
  ACTEUR_NOTIFIE = 'entreprise',
  STATUT_NOTIFICATION = 'statut',
}

export enum NotificationMessageAdminSortType {
  DATE_CREATION = 'date_creation',
  EXPEDITEUR = 'expediteur',
  DESTINATAIRE = 'entreprise_dest',
  OBJET = 'objet',
}
