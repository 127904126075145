import { TableCell, TableRow } from '@mui/material'
import { CodeFichesConclusions } from 'assureur/store/types/profilEntreprise'
import { EditTextField } from 'plateforme/components'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface DelaisCommonRowProps {
  rowTitle: string
  controllerName: string
  readOnly?: boolean
}

export default function DelaisCommonRow({ rowTitle, controllerName, readOnly }: DelaisCommonRowProps) {
  const { control, watch } = useFormContext()

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {rowTitle}
      </TableCell>
      <TableCell component="th" scope="row">
        <Controller
          name={`${controllerName}.${[CodeFichesConclusions.expertiseUnilaterale]}`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              fieldError={error}
              readOnly={readOnly}
              label="Jours"
              fullWidth
              type="number"
            />
          )}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <Controller
          name={`${controllerName}.${[CodeFichesConclusions.expertiseConjointe]}`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              fieldError={error}
              readOnly={readOnly}
              label="Jours"
              fullWidth
              type="number"
            />
          )}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <Controller
          name={`${controllerName}.${[CodeFichesConclusions.expertiseJudiciaire]}`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              fieldError={error}
              readOnly={readOnly}
              label="Jours"
              fullWidth
              type="number"
            />
          )}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <Controller
          name={`${controllerName}.${[CodeFichesConclusions.arbitrage]}`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              fieldError={error}
              readOnly={readOnly}
              label="Jours"
              fullWidth
              type="number"
            />
          )}
        />
      </TableCell>
      {watch('parametrage.expertiseSurPiece') && (
        <TableCell component="th" scope="row">
          <Controller
            name={`${controllerName}.${[CodeFichesConclusions.avisTechnique]}`}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                readOnly={readOnly}
                label="Jours"
                fullWidth
                type="number"
              />
            )}
          />
        </TableCell>
      )}
    </TableRow>
  )
}
