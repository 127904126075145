import React from 'react'
import uuid from 'react-uuid'
import { Grid, Stack } from '@mui/material'
import { BluePaper, ReadOnlyTextField } from 'plateforme/components'
import DividerFormButton from 'plateforme/components/buttons/DividerFormButton'
import { LesionSequelle } from 'assureur/store/types/lesionSequelleEntreprise'

interface ReadOnlyLesionSequellesProps {
  lesionSequelles: LesionSequelle[]
}

export default function ReadOnlyLesionSequellesEntreprise({ lesionSequelles }: ReadOnlyLesionSequellesProps) {
  return (
    <>
      <Grid item xs={12}>
        <DividerFormButton title="Codes Lésions / Séquelles" readOnly />
      </Grid>
      <Grid item xs={12}>
        {lesionSequelles.length === 0 ? (
          <BluePaper sx={{ fontStyle: 'italic', paddingTop: 3.5, textAlign: 'center' }}>
            Aucune lésion / séquelle
          </BluePaper>
        ) : (
          <BluePaper>
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              {lesionSequelles.map((item, index) => {
                return (
                  <li key={uuid()}>
                    <Stack direction="row" spacing={3.5} marginBottom={index === lesionSequelles.length - 1 ? 0 : 2.5}>
                      <ReadOnlyTextField
                        fullWidth
                        id="codes-lesion"
                        value={`${item.lesion} - ${item.labelLesion}`}
                        label="Code lésion"
                      />
                      <ReadOnlyTextField
                        fullWidth
                        id="codes-sequelle"
                        value={`${item.sequelle} - ${item.labelSequelle}`}
                        label="Code séquelle"
                      />
                    </Stack>
                  </li>
                )
              })}
            </ul>
          </BluePaper>
        )}
      </Grid>
    </>
  )
}
