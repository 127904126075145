import React from 'react'

import { Card, CardContent, CardHeader, Grid } from '@mui/material'

import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionEntreprise from 'medecin/store/types/missionMedecin'

import { formatDurationFromNow, formatDurationFromNowInMonths } from 'plateforme/services/dates.services'

import { TitleChip } from 'plateforme/components'
import SuiviMissionForm from './SuiviMissionForm'

interface SuiviDossierSyntheseProps {
  dossier: DossierMedecin
  mission: MissionEntreprise
}

export default function SuiviDossierMedecinPart({ dossier, mission }: SuiviDossierSyntheseProps) {
  // TODO: à voir cette régle Actuellement le délais d’acceptation est calculé en ( seconde  ou minute ou  heurs ou semaines ) si la durée < un mois
  const delaisAcceptationInMonths = formatDurationFromNowInMonths(mission.dateReponseMedecin)
  const delaisAcceptation = mission.dateReponseMedecin ? formatDurationFromNow(mission.dateReponseMedecin) : ''

  const delaisAcceptationTitle = ` depuis ${
    // eslint-disable-next-line quotes
    delaisAcceptationInMonths > 0 ? delaisAcceptation : " moins d'un mois"
  }`

  const titleSuiviDossier = `Mission acceptée ${delaisAcceptationTitle}`

  return (
    <Card>
      <CardHeader title="Suivi de la mission" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <TitleChip title={titleSuiviDossier} />
          </Grid>
          <Grid item xs={12}>
            <SuiviMissionForm dossier={dossier} mission={mission} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
