import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { LibelleRapport, TypeRapport } from 'plateforme/store/types/rapportConclusion'
import { trimToUndefined } from 'plateforme/services/utils'
import { StatutMission } from 'plateforme/store/types/mission'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import WorkflowMissionPart from './WorkflowMissionPart'

export interface SuiviMissionAccordionProps {
  dossier: DossierEntreprise
  mission: MissionEntreprise
  missionIndex?: number
  rapportIndex?: number
  expanded?: boolean
  onChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void
}

export default function SuiviMissionAccordion({
  dossier,
  mission,
  missionIndex,
  rapportIndex,
  expanded,
  onChange,
}: SuiviMissionAccordionProps) {
  const labelStatut = () => {
    if (mission.statut === StatutMission.VALIDEE_MEDECIN && mission.rapport?.typeRapport) {
      switch (mission.rapport?.typeRapport) {
        case TypeRapport.CONCLUSION_PROVISOIRE: {
          return `${LibelleRapport.CONCLUSION_PROVISOIRE}${rapportIndex ? ` ${rapportIndex}` : ''}`
        }
        case TypeRapport.CONCLUSION_DEFINITIVE: {
          return `${LibelleRapport.CONCLUSION_DEFINITIVE}${rapportIndex ? ` ${rapportIndex}` : ''}`
        }
        case TypeRapport.CARENCE: {
          return `${LibelleRapport.CARENCE}${rapportIndex ? ` ${rapportIndex}` : ''}`
        }
        default: {
          break
        }
      }
    }
    return mission?.labelStatut
  }

  const refMissionEntreprise =
    trimToUndefined(mission.refMissionEntreprise) === undefined ? '' : `- ${mission.refMissionEntreprise}`

  const title = `Mission${missionIndex ? ` n°${missionIndex}` : ''} ${labelStatut()} ${refMissionEntreprise} - ${
    mission.medecin?.libelle
  }`
  const prefix = `panel-${mission.code}`

  return (
    <Accordion expanded={expanded} onChange={onChange(mission.code)}>
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: '#fff' }} />}
        aria-controls={`${prefix}-content`}
        id={`${prefix}`}
      >
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: '#fff' }}>
        <WorkflowMissionPart dossier={dossier} mission={mission} />
      </AccordionDetails>
    </Accordion>
  )
}
