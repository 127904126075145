import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import {
  MarquerMessagesLusEntrepriseRequest,
  MessageEntreprise,
  MessageEntrepriseFilter,
  MessageEntrepriseSortType,
} from '../types/messageEntreprise'

export const messageEntrepriseApi = createApi({
  reducerPath: 'messageEntrepriseApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['MessageEntreprise'],
  endpoints: (builder) => ({
    getListeMessages: builder.query<
      PageRecherche<MessageEntreprise>,
      PageRechercheRequest<MessageEntrepriseFilter, MessageEntrepriseSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => {
        return {
          url: 'entreprise/message',
          params: {
            ...filter,
            ...sort,
            perPage,
            page,
            onlyTotal,
          },
        }
      },
      providesTags: ['MessageEntreprise'],
    }),
    getMessageByCode: builder.query<MessageEntreprise, string>({
      query: (codeMessage) => `entreprise/message/${codeMessage}`,
      providesTags: ['MessageEntreprise'],
    }),
    postMarquerMessagesLus: builder.mutation<void, MarquerMessagesLusEntrepriseRequest>({
      query: (body) => {
        return {
          url: `entreprise/message/acquittement`,
          method: 'POST',
          body,
        }
      },
    }),
  }),
})

export const { useGetListeMessagesQuery, useGetMessageByCodeQuery, usePostMarquerMessagesLusMutation } =
  messageEntrepriseApi
