import MissionMedecin from 'medecin/store/types/missionMedecin'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import MissionAdmin from 'admin/store/types/missionAdmin'

type MissionPlateforme = MissionMedecin | MissionEntreprise | MissionAdmin

export enum StatutMission {
  PROPOSEE = 'PROPOSEE',
  EN_COURS = 'EN_COURS',
  EN_DEMANDE_DE_MODIFICATION = 'EN_DEMANDE_DE_MODIFICATION',
  VALIDEE_MEDECIN = 'VALIDEE_MEDECIN',
  REFUSEE = 'REFUSEE',
  ARRETEE = 'ARRETEE',
  ANNULEE = 'ANNULEE',
  CLOTUREE = 'CLOTUREE',
  PROPOSEE_SANS_SUITE = 'PROPOSEE_SANS_SUITE',
}

export enum MotifBlocage {
  INDISPONIBILITE_PERSONNE_A_EXAMINER = '01',
}

export enum StatutSuiviMission {
  NON_DEFINI = 'NON_DEFINI',
  EN_COURS = 'EN_COURS',
  BLOQUE = 'BLOQUE',
  EXAMEN_PREVU = 'EXAMEN_PREVU',
}

export default MissionPlateforme
