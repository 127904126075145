import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'

import ProfilMedecin, { ParametrageMedecin } from '../types/profilMedecin'

export const profilMedecinApi = createApi({
  reducerPath: 'profilMedecinApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['ParametrageMedecin'],
  endpoints: (builder) => ({
    getProfilMedecin: builder.query<ProfilMedecin, void>({
      query: () => 'medecin/profil',
      providesTags: ['ParametrageMedecin'],
    }),
    putParamMedecin: builder.mutation<ParametrageMedecin, Partial<ParametrageMedecin>>({
      query: (body) => ({
        url: 'medecin/parametrage',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ParametrageMedecin'],
    }),
  }),
})

export const { useGetProfilMedecinQuery, usePutParamMedecinMutation } = profilMedecinApi
