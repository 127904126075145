import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { EditTextField, ReadOnlyEmailField, ReadOnlyTextField, SelectInputReferentiel } from 'plateforme/components'
import PhoneNumberInput from 'plateforme/components/inputs/PhoneNumberInput'
import { AFFECTATION_CENTRALE_LABEL } from 'plateforme/constantes'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import ProfilEntreprise, { CodeContact } from 'assureur/store/types/profilEntreprise'

type GestionDossierEntrepriseProps = {
  dossier: DossierEntreprise
  profilEntreprise: ProfilEntreprise
}

export default function GestionDossierEntreprise({ dossier, profilEntreprise }: GestionDossierEntrepriseProps) {
  const { control, watch } = useFormContext()
  const { gestionnaire, libelleServiceEntreprise, serviceEntreprise } = dossier

  const isCloisonnementServiceActif = profilEntreprise?.parametrage?.cloisonnementService
  const serviceEntrepriseParametrage = profilEntreprise.parametrage?.services?.find((s) => s.code === serviceEntreprise)

  const typeContactEntrepriseWatched = watch('typeContactEntreprise')

  const libelleContactWatched = () => {
    switch (typeContactEntrepriseWatched) {
      case CodeContact.CODE_BAL_GESTIONNAIRE:
        return gestionnaire?.libelle
      case CodeContact.CODE_BAL_ENTREPRISE:
        return profilEntreprise?.libelle
      case CodeContact.CODE_BAL_SERVICE:
        return serviceEntrepriseParametrage?.libelle ?? profilEntreprise?.libelle
      default:
        return null
    }
  }

  const mailContactWatched = () => {
    switch (typeContactEntrepriseWatched) {
      case CodeContact.CODE_BAL_GESTIONNAIRE:
        return gestionnaire?.mail
      case CodeContact.CODE_BAL_ENTREPRISE:
        return profilEntreprise?.mail
      case CodeContact.CODE_BAL_SERVICE:
        return serviceEntrepriseParametrage?.mail ?? profilEntreprise?.mail
      default:
        return null
    }
  }

  const telephoneContactWatched = () => {
    switch (typeContactEntrepriseWatched) {
      case CodeContact.CODE_BAL_GESTIONNAIRE:
        return gestionnaire?.telephone
      case CodeContact.CODE_BAL_ENTREPRISE:
        return profilEntreprise?.telephone
      case CodeContact.CODE_BAL_SERVICE:
        return serviceEntrepriseParametrage?.telephone ?? profilEntreprise?.telephone
      default:
        return null
    }
  }

  return (
    <Card>
      <CardHeader title="Gestion entreprise du dossier" />
      <CardContent>
        <Grid container>
          {isCloisonnementServiceActif && (
            <Grid item xs={12} sm={6} md={4}>
              <ReadOnlyTextField
                fullWidth
                id="service"
                value={libelleServiceEntreprise ?? AFFECTATION_CENTRALE_LABEL}
                label="Service"
              />
            </Grid>
          )}

          <Grid item xs={12} sm={isCloisonnementServiceActif ? 6 : 12} md={isCloisonnementServiceActif ? 4 : 8}>
            <ReadOnlyTextField fullWidth id="gestionnaire" value={gestionnaire?.libelle ?? ''} label="Gestionnaire" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="typeContactEntreprise"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <SelectInputReferentiel
                  id="type-contact-entreprise"
                  label="Contact dossier"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  referentielName="typeContact"
                  withNoSelectionItem
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          {typeContactEntrepriseWatched === CodeContact.CODE_BAL_PERSONNALISE ? (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="contactEntreprisePersonnalise.libelle"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="contact-entreprise-personnalise-libelle"
                      label="Libellé contact dossier"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="contactEntreprisePersonnalise.mail"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="contact-entreprise-personnalise-mail"
                      label="Adresse mail contact dossier"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="contactEntreprisePersonnalise.telephone"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <PhoneNumberInput
                      id="contact-entreprise-personnalise-telephone"
                      label="Téléphone contact dossier"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      fieldError={error}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <ReadOnlyTextField
                  fullWidth
                  id="contactEntreprisePersonnalise.libelle"
                  value={libelleContactWatched()}
                  label="Libellé contact dossier"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ReadOnlyEmailField
                  fullWidth
                  id="contactEntreprisePersonnalise.mail"
                  value={mailContactWatched()}
                  label="Adresse mail contact dossier"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ReadOnlyTextField
                  fullWidth
                  id="contactEntreprisePersonnalise.telephone"
                  value={telephoneContactWatched()}
                  label="Téléphone contact dossier"
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
