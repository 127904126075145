import React from 'react'
import { Grid, Slider, Typography } from '@mui/material'
import { DOMMAGES_RANGE_MARKS } from 'plateforme/constantes'

interface DommageFieldSliderProps {
  label?: string
  value?: number | number[] | undefined
}

export default function ReadOnlyDommageSlider({ label, value }: DommageFieldSliderProps) {
  return (
    <Grid item display="flex" flexDirection="row" alignItems="center">
      <Typography marginLeft={2} sx={{ width: '50%' }} color="primary" variant="subtitle2">
        {`${label} :`}
      </Typography>
      <Slider
        disabled
        value={value}
        step={0.5}
        max={7}
        min={0.5}
        marks={DOMMAGES_RANGE_MARKS}
        valueLabelDisplay="auto"
      />
    </Grid>
  )
}
