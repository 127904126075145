import React from 'react'
import { FormControlLabel, Grid, Switch } from '@mui/material'
import { AreaLoading, ReadOnlyTextField, SelectInputReferentiel } from 'plateforme/components'
import {
  isRapportCarence,
  isRapportConclusionDefinitive,
  isRapportConclusionProvisoire,
} from 'plateforme/services/rapport.services'
import DividerFormButton from 'plateforme/components/buttons/DividerFormButton'
import { formatDateFR } from 'plateforme/services/dates.services'
import ReadOnlyDatePicker from 'plateforme/components/inputs/ReadOnlyDatePicker'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import { TypeDommage } from 'assureur/store/types/dommagesEntreprise'
import { useGetRevisionDossierQuery } from 'assureur/store/apis/dossierAssureurApi'
import DommagesComponent from './DommagesFields/DommagesComponent'
import ReadOnlyLesionsEntreprise from './ReadOnlyLesionsEntreprise'
import ReadOnlyLesionSequellesEntreprise from './ReadOnlyLesionSequellesEntreprise'

interface FicheRapportPartProps {
  dossier: DossierEntreprise
  mission: MissionEntreprise
  typeConclusion: TypeRapport.CONCLUSION_PROVISOIRE | TypeRapport.CONCLUSION_DEFINITIVE | TypeRapport.CARENCE
}

export default function FicheRapportEntreprisePart({ dossier, mission, typeConclusion }: FicheRapportPartProps) {
  const { data: dossierRevision, isLoading } = useGetRevisionDossierQuery({
    codeDossier: dossier.code,
    codeMission: mission.code,
  })

  // props:
  const { code: codeDossier, refDossierEntreprise } = dossier
  const { code: codeMission, rapport } = mission

  // error:
  if (!codeDossier || !codeMission) {
    throw new Error(`Erreur de chargement des données`)
  }

  // render:
  if (isLoading) {
    return <AreaLoading height={335} />
  }
  return (
    <Grid container direction="row">
      <Grid item xs={4}>
        <ReadOnlyTextField
          fullWidth
          id="reference-dossier-entreprise"
          value={refDossierEntreprise}
          label="Référence dossier entreprise"
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyTextField
          fullWidth
          id="date-evenement"
          value={formatDateFR(dossierRevision?.evenement?.date)}
          label="Date de l'événement"
        />
      </Grid>
      <Grid item xs={4}>
        <SelectInputReferentiel
          fullWidth
          id="cadre-expertise"
          label="Cadre de l'expertise"
          referentielName="cadresExpertise"
          value={dossierRevision?.expertise?.cadreExpertise}
          readOnly
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyTextField
          fullWidth
          id="nomPersonneAExaminer"
          value={rapport?.nomPersonneAExaminer}
          label="Nom de la personne à examiner"
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyTextField
          fullWidth
          id="prenom-personne-a-examiner"
          label="Prénom de la personne à examiner"
          value={rapport?.prenomPersonneAExaminer}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyDatePicker
          InputProps={{
            id: 'date-naissance-personne-a-examiner',
            fullWidth: true,
          }}
          value={rapport?.dateNaissancePersonneAExaminer === undefined ? null : rapport?.dateNaissancePersonneAExaminer}
          label="Date de naissance de la personne à examiner"
        />
      </Grid>
      {isRapportCarence(typeConclusion) && (
        <>
          <Grid item xs={4}>
            <SelectInputReferentiel
              readOnly
              id="motif-carence"
              label="Motif de carence"
              referentielName="motifCarence"
              value={rapport?.motifCarence}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} display="flex" alignItems="center">
            <FormControlLabel
              sx={{ pointerEvents: 'none' }}
              control={<Switch disabled defaultChecked={rapport?.remissionnementPreconise} />}
              label="Préconisation médecin: remissionnement"
            />
          </Grid>
          <Grid item xs={12}>
            <ReadOnlyTextField
              fullWidth
              multiline
              id="commentaire-carence"
              label="Commentaire"
              value={rapport?.commentaire}
            />
          </Grid>
        </>
      )}
      {isRapportConclusionDefinitive(typeConclusion) && (
        <Grid item xs={4}>
          <ReadOnlyDatePicker
            InputProps={{
              id: 'date-consolidation',
              fullWidth: true,
            }}
            value={rapport?.dateConsolidationDef === undefined ? null : rapport?.dateConsolidationDef}
            label="Date de consolidation"
          />
        </Grid>
      )}
      {(isRapportConclusionProvisoire(typeConclusion) || isRapportConclusionDefinitive(typeConclusion)) && (
        <Grid item xs={4}>
          <ReadOnlyDatePicker
            InputProps={{
              id: 'date-examen',
              fullWidth: true,
            }}
            value={rapport?.dateExamen === undefined ? null : rapport?.dateExamen}
            label="Date d'examen"
          />
        </Grid>
      )}
      {isRapportConclusionProvisoire(typeConclusion) && (
        <>
          <Grid item xs={4}>
            <ReadOnlyTextField
              fullWidth
              multiline
              id="delai-nouvel-examen"
              label="Délai nouvel examen"
              value={rapport?.labelDelaiNouvelExamen}
            />
          </Grid>
          <Grid item xs={4}>
            <ReadOnlyDatePicker
              InputProps={{
                id: 'date-previsible-consolidation',
                fullWidth: true,
              }}
              value={rapport?.dateConsolidationPro === undefined ? null : rapport?.dateConsolidationPro}
              label="Date prévisionnelle de consolidation"
            />
          </Grid>
          <Grid item xs={12}>
            <ReadOnlyTextField
              fullWidth
              id="commentaire-conclusion-pro"
              label="Commentaire nouvelle examen / date de consolidation"
              value={rapport?.commentaire}
            />
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <FormControlLabel
              sx={{ pointerEvents: 'none' }}
              control={<Switch disabled defaultChecked={rapport?.remissionnementPreconise} />}
              label="Préconisation médecin: remissionnement"
            />
          </Grid>
        </>
      )}
      {rapport?.dommages && (
        <>
          <Grid item xs={12}>
            <DividerFormButton
              readOnly
              title={`Dommages temporaires ${isRapportConclusionProvisoire(typeConclusion) ? 'prévisibles' : ''}`}
            />
          </Grid>
          <Grid item xs={12}>
            <DommagesComponent
              typeDommage={TypeDommage.TEMPORAIRE}
              dommages={rapport?.dommages}
              typeRapport={typeConclusion}
            />
          </Grid>
          <Grid item xs={12}>
            <DividerFormButton
              readOnly
              title={`Dommages définitifs ${isRapportConclusionProvisoire(typeConclusion) ? 'prévisibles' : ''}`}
            />
          </Grid>
          <Grid item xs={12}>
            <DommagesComponent
              typeDommage={TypeDommage.DEFINITIF}
              dommages={rapport?.dommages}
              typeRapport={typeConclusion}
            />
          </Grid>
          {rapport.lesionSequelles && <ReadOnlyLesionSequellesEntreprise lesionSequelles={rapport.lesionSequelles} />}
          {rapport.lesions && <ReadOnlyLesionsEntreprise lesions={rapport.lesions} />}
        </>
      )}
      {(isRapportConclusionProvisoire(typeConclusion) || isRapportConclusionDefinitive(typeConclusion)) && (
        <Grid item xs={12}>
          <ReadOnlyTextField
            fullWidth
            id="commentaire-conclusion-def"
            label="Commentaire"
            value={rapport?.commentaire}
          />
        </Grid>
      )}
      <Grid item xs={4}>
        <ReadOnlyDatePicker
          label="Fait le"
          InputProps={{
            id: 'date-signature',
            fullWidth: true,
          }}
          value={rapport?.dateSignature === undefined ? null : rapport.dateSignature}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyTextField fullWidth id="lieu-signature" label="À" value={rapport?.lieuSignature} />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyTextField fullWidth id="signataire" label="Par" value={rapport?.signataire} />
      </Grid>
    </Grid>
  )
}
