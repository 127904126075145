import React from 'react'
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import { LabelIconField, ReadOnlyTextField } from 'plateforme/components'
import { formatDateFR } from 'plateforme/services/dates.services'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionEntreprise from 'medecin/store/types/missionMedecin'

interface MissionRefuseeSyntheseProps {
  dossier: DossierMedecin
  mission: MissionEntreprise
}

export default function MissionRefuseePart({ dossier, mission }: MissionRefuseeSyntheseProps) {
  const { entreprise } = dossier
  const { dateProposition, dateReponseMedecin, commentaireRefus } = mission
  return (
    <Card>
      <CardHeader title="Mission refusée" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {`La société ${entreprise?.libelle} vous a proposé une nouvelle mission le ${formatDateFR(
                dateProposition
              )}`}
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <LabelIconField
                iconType="cancel"
                iconColor="error.main"
                iconSize={34}
                label={`Vous avez refusé cette mission le ${formatDateFR(dateReponseMedecin)}`}
              />
            </Grid>
            <Grid item xs={12}>
              <ReadOnlyTextField
                fullWidth
                multiline
                minRows={3}
                id="id-commet-refus-mission"
                label="Commentaire sur le refus"
                value={commentaireRefus}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
