import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid, Stack } from '@mui/material'
import { SauvegarderButton } from 'plateforme/components'
import DelaiNonReponseComplementsPart from './DelaiNonReponseComplementsPart'
import DelaisNonFournitureRapportPart from './DelaisNonFournitureRapportPart'
import DelaisNonPrecisionDatePart from './DelaisNonPrecisionDatePart'
import DelaisNonReponsePart from './DelaisNonReponsePart'

interface TabDelaisPartProps {
  onSubmit?: VoidFunction
}

export default function TabDelaisPart({ onSubmit }: TabDelaisPartProps) {
  const { ...methods } = useFormContext()
  const readOnly = !onSubmit
  const formId = 'form-parametres-delais'
  return (
    <form onSubmit={onSubmit && methods.handleSubmit(onSubmit)} id={formId} name={formId}>
      <Grid container justifyContent="flex-start" alignItems="flex-start">
        <Grid item container md={12} lg={12}>
          <Grid item xs={12}>
            <DelaisNonReponsePart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <DelaisNonPrecisionDatePart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <DelaisNonFournitureRapportPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <DelaiNonReponseComplementsPart readOnly={readOnly} />
          </Grid>
          {!readOnly && (
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="right">
                <SauvegarderButton onClick={onSubmit}>Sauvegarder</SauvegarderButton>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  )
}
