import React from 'react'
import { Card, CardContent, Stack, useTheme } from '@mui/material'
import { LabelIconField, ReadOnlyTextField } from 'plateforme/components'
import { formatDateFR } from 'plateforme/services/dates.services'
import DossierPlateforme, { StatutDossier } from 'plateforme/store/types/dossier'

interface SuiviDossierPartProps {
  dossier: DossierPlateforme
  masqueEnCours?: boolean
}

export default function SuiviDossierPart({ dossier, masqueEnCours = false }: SuiviDossierPartProps) {
  const {
    statut: statutDossier,
    dateEnCours,
    dateCloture,
    dateMiseAJour,
    motifCloture,
    labelMotifCloture,
    commentaireCloture,
  } = dossier

  const theme = useTheme()

  const dateCreation = 'dateCreation' in dossier ? dossier.dateCreation : undefined
  const dateAnnulation = 'dateAnnulation' in dossier ? dossier.dateAnnulation : undefined
  const commentaireAnnulation = 'commentaireAnnulation' in dossier ? dossier.commentaireAnnulation : undefined

  function labelIcon() {
    switch (statutDossier) {
      case StatutDossier.EN_CREATION: {
        return (
          dateCreation && (
            <LabelIconField
              iconType="edit"
              iconSize={34}
              label={`Dossier en cours de création depuis le ${formatDateFR(dateCreation)}`}
            />
          )
        )
      }
      case StatutDossier.ANNULE: {
        return (
          (dateAnnulation || commentaireAnnulation) && (
            <Stack margin={0} padding={0}>
              {dateAnnulation && (
                <LabelIconField
                  iconType="cancel"
                  iconSize={34}
                  label={`Dossier annulé le ${formatDateFR(dateAnnulation)}`}
                />
              )}
              {commentaireAnnulation && (
                <ReadOnlyTextField
                  id="commentaire-annulation"
                  label="Commentaire"
                  value={commentaireAnnulation}
                  fullWidth
                  multiline
                  minRows={3}
                />
              )}
            </Stack>
          )
        )
      }
      case StatutDossier.EN_COURS: {
        return (
          dateEnCours &&
          !masqueEnCours && (
            <LabelIconField
              iconType="check"
              iconSize={34}
              label={`Dossier en cours depuis le ${formatDateFR(dateEnCours)}`}
            />
          )
        )
      }
      case StatutDossier.CLOTURE: {
        return (
          (dateCloture || motifCloture || commentaireCloture) && (
            <Stack margin={0} padding={0}>
              {dateCloture && (
                <LabelIconField
                  iconType="doubleCheck"
                  iconSize={34}
                  label={`Dossier clôturé le ${formatDateFR(dateCloture)}`}
                />
              )}
              {motifCloture && (
                <ReadOnlyTextField id="motif-cloture" label="Motif" value={labelMotifCloture} fullWidth />
              )}
              {commentaireCloture && (
                <ReadOnlyTextField
                  id="commentaire-cloture"
                  label="Commentaire"
                  value={commentaireCloture}
                  fullWidth
                  multiline
                  minRows={3}
                />
              )}
            </Stack>
          )
        )
      }
      case StatutDossier.PURGE: {
        return (
          dateMiseAJour && (
            <LabelIconField
              iconType="delete"
              iconSize={34}
              iconColor="error.main"
              label={`Dossier en demande de purge depuis le ${formatDateFR(dateMiseAJour)}`}
            />
          )
        )
      }
      default: {
        return null
      }
    }
  }

  const labelIconField = labelIcon()

  if (labelIconField === null || labelIconField === undefined || labelIconField === false) {
    return null
  }

  const sx =
    commentaireAnnulation || motifCloture || commentaireCloture
      ? undefined
      : { paddingBottom: `${theme.spacing(2)} !important` }
  return (
    <Card>
      <CardContent sx={sx}>{labelIconField}</CardContent>
    </Card>
  )
}
