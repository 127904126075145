import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { EditTextField, ReadOnlyTextField } from 'plateforme/components'
import { MSG_FIELD_REQUIRED } from 'plateforme/constantes'
import { StatutDossier } from 'plateforme/store/types/dossier'
import ProfilEntreprise from 'assureur/store/types/profilEntreprise'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'

type IdentificationDossierProps = {
  dossier: DossierEntreprise
  profilEntreprise: ProfilEntreprise
}

export default function IdentificationDossier({ dossier, profilEntreprise }: IdentificationDossierProps) {
  const { control } = useFormContext()
  const { code: codeDossier, statut } = dossier
  const refVictimeEntrepriseAffichable = profilEntreprise.parametrage?.refVictimeEntreprise

  return (
    <Card>
      <CardHeader title="Identification du dossier" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={refVictimeEntrepriseAffichable ? 4 : 6}>
            <Controller
              name="refDossierEntreprise"
              control={control}
              rules={{ required: MSG_FIELD_REQUIRED }}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="ref-dossier-entreprise"
                  label="Votre référence dossier"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  readOnly={statut !== StatutDossier.EN_CREATION}
                  fullWidth
                  fieldError={error}
                  required
                />
              )}
            />
          </Grid>
          {refVictimeEntrepriseAffichable && (
            <Grid item xs={12} md={4}>
              <Controller
                name="refVictimeEntreprise"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="ref-victime-entreprise"
                    label="Numéro de victime"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    readOnly={statut !== StatutDossier.EN_CREATION}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} md={refVictimeEntrepriseAffichable ? 4 : 6}>
            <ReadOnlyTextField id="code" label="Identifiant dossier Plateforme" value={codeDossier} fullWidth />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
