import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import {
  MarquerMessagesLusMedecinRequest,
  MessageMedecin,
  MessageMedecinFilter,
  MessageMedecinSortType,
} from '../types/messageMedecin'

export const messageMedecinApi = createApi({
  reducerPath: 'messageMedecinApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['MessageMedecin'],
  endpoints: (builder) => ({
    getListeMessages: builder.query<
      PageRecherche<MessageMedecin>,
      PageRechercheRequest<MessageMedecinFilter, MessageMedecinSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => {
        return {
          url: 'medecin/message',
          params: {
            ...filter,
            ...sort,
            perPage,
            page,
            onlyTotal,
          },
        }
      },
      providesTags: ['MessageMedecin'],
    }),
    postMarquerMessagesLus: builder.mutation<void, MarquerMessagesLusMedecinRequest>({
      query: (body) => {
        return {
          url: `medecin/message/acquittement`,
          method: 'POST',
          body,
        }
      },
    }),
  }),
})

export const { useGetListeMessagesQuery, usePostMarquerMessagesLusMutation } = messageMedecinApi
