import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Stack } from '@mui/material'
import EvenementPart from 'plateforme/parts/DonneesDetailleesParts/EvenementPart'
import ExpertisePart from 'plateforme/parts/DonneesDetailleesParts/ExpertisePart'
import PersonneAExaminerPart from 'plateforme/parts/DonneesDetailleesParts/PersonneAExaminerPart'
import AutreMedecinPart from 'plateforme/parts/DonneesDetailleesParts/AutreMedecinPart'
import LieuExpertisePart from 'plateforme/parts/DonneesDetailleesParts/LieuExpertisePart'
import ModifierButton from 'plateforme/components/buttons/ModifierButton'
import { LoadingProps } from 'plateforme/services/utils'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import { editionDossierHref } from 'assureur/EntrepriseApp'
import ProfilEntreprise from 'assureur/store/types/profilEntreprise'
import { getDossierRevision, getSelectedMission } from 'assureur/store/slices/selectedMission/selectedMissionSlice'
import { useDispatch, useSelector } from 'react-redux'
import { determineDerniereMission } from 'assureur/services/dossierEntreprise.services'
import { dossierEditable } from 'assureur/services/rolesEntreprise.services'
import { setErrorDossier } from 'assureur/store/slices/dossier/errorDossierSlice'
import ReferenceDossierEntreprisePart from './ReferenceDossierEntreprisePart'
import InformationsAssureEntreprisePart from './InformationsAssureEntreprisePart'
import GestionDossierEntreprisePart from './GestionDossierEntreprisePart'

type TabDonneesDetailleesEntreprisePartProps = {
  dossier?: DossierEntreprise
  derniereMission?: MissionEntreprise
  profilEntreprise?: ProfilEntreprise
}

export default function TabDonneesDetailleesEntreprisePart({
  dossier,
  derniereMission,
  profilEntreprise,
  loading,
}: TabDonneesDetailleesEntreprisePartProps & LoadingProps) {
  const { code: codeDossier } = dossier || {}
  const { data: utilisateur } = useGetUtilisateurActifQuery()
  const profilActif = utilisateur?.profilActif
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedMission = useSelector(getSelectedMission)
  const dossierMission = useSelector(getDossierRevision)
  const isMissionCurrent =
    selectedMission === undefined || selectedMission.code === determineDerniereMission(dossier)?.code

  const dossierToDisplay = dossierMission === undefined ? dossier : dossierMission

  const navigateEditionDossier = () => {
    if (!codeDossier) {
      return
    }
    dispatch(setErrorDossier({ status: 200, data: undefined }))
    navigate(editionDossierHref(codeDossier))
  }
  return (
    <Grid container>
      {dossierEditable(dossier, profilActif) && isMissionCurrent && (
        <Grid item xs={12}>
          <ModifierButton onClick={navigateEditionDossier}>Mettre à jour les informations</ModifierButton>
        </Grid>
      )}
      <Grid item md={12} lg={6}>
        <Stack>
          <ReferenceDossierEntreprisePart dossier={dossierToDisplay} loading={loading} />
          <GestionDossierEntreprisePart dossier={dossierToDisplay} loading={loading} />
          <EvenementPart dossier={dossierToDisplay} loading={loading} />
          <ExpertisePart dossier={dossierToDisplay} mission={derniereMission} loading={loading} />
          <AutreMedecinPart dossier={dossierToDisplay} loading={loading} />
          <LieuExpertisePart dossier={dossierToDisplay} loading={loading} />
        </Stack>
      </Grid>
      <Grid item md={12} lg={6}>
        <Stack>
          <PersonneAExaminerPart dossier={dossierToDisplay} loading={loading} />
          <InformationsAssureEntreprisePart
            dossier={dossierToDisplay}
            profilEntreprise={profilEntreprise}
            loading={loading}
          />
        </Stack>
      </Grid>
    </Grid>
  )
}
