import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Card, CardContent, useTheme } from '@mui/material'
import { TableauDeBordGrid, TableauDeBordGridItem, TableauDeBordTile } from 'plateforme/components'
import HistoriqueIcon from 'plateforme/components/icons/HistoriqueIcon'
import PlusIcon from 'plateforme/components/icons/PlusIcon'
import Referentiel from 'plateforme/store/types/referentiel'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import Utilisateur from 'plateforme/store/types/utilisateur'
import { LoadingProps } from 'plateforme/services/utils'
import AreaLoading from 'plateforme/components/progress/AreaLoading'
import { TABLEAU_DE_BORD_MAX_TIME_CACHE } from 'plateforme/constantes'
import CreationDossierEntrepriseForm from 'assureur/parts/editionDossier/CreationDossierEntrepriseForm'
import {
  listeHabilitationsHref,
  rechercheDossiersHref,
  rechercheFacturesHref,
  listeMedecinsHref,
  listeFluxTechniqueHref,
} from 'assureur/EntrepriseApp'
import { DossierEntrepriseFilter } from 'assureur/store/types/dossierEntreprise'
import { FactureFilter } from 'assureur/store/types/factureEntreprise'
import {
  setDossierCriteria,
  setFactureCriteria,
  setFluxTechniqueCriteria,
  setMedecinCriteria,
} from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'
import { HabilitationEntrepriseFilter } from 'assureur/store/types/habilitationEntreprise'
import { useGetListeHabilitationsActivesEntrepriseQuery } from 'assureur/store/apis/habilitationEntrepriseApi'
import {
  totalDossierEnCoursRequest,
  totalMesDossierEnCreationRequest,
  totalDossiersEnAttenteAcceptationRequest,
  totalFactureADeposeeRequest,
  totalMesDossierEnCoursRequest,
  totalMesDossiersEnAttenteAcceptationRequest,
  totalMesDossiersHorsDelaiRequest,
  totalTousLesDossiersHorsDelaisRequest,
} from 'assureur/services/dossierEntreprise.services'
import { useGetRechercheDossierQuery, useGetRechercheFactureQuery } from 'assureur/store/apis/dossierAssureurApi'
import {
  habilitationCreable,
  fluxTechniqueConsultable,
  dossierCreable,
  dossierConsultable,
  tousLesDossiersHorsDelaiConsultable,
  mesDossiersHorsDelaiConsultable,
  dossierAttenteAcceptationConsultable,
  toutesLesFacturesATraiter,
  isGestionnaire,
} from 'assureur/services/rolesEntreprise.services'
import ProfilEntreprise from 'assureur/store/types/profilEntreprise'

type TableauDeBordEntreprisePartProps = {
  utilisateur?: Utilisateur
  profilEntreprise?: ProfilEntreprise
  referentiel?: Referentiel
}

export default function TableauDeBordEntreprisePart({
  utilisateur,
  profilEntreprise,
  referentiel,
  loading,
}: TableauDeBordEntreprisePartProps & LoadingProps) {
  if (loading) {
    return <AreaLoading height={420} />
  }
  return (
    <TableauDeBordEntreprisePartLoaded
      utilisateur={utilisateur}
      profilEntreprise={profilEntreprise}
      referentiel={referentiel}
    />
  )
}

function TableauDeBordEntreprisePartLoaded({
  utilisateur,
  profilEntreprise,
  referentiel,
}: TableauDeBordEntreprisePartProps) {
  const theme = useTheme()
  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()

  const profilUtilisateur = utilisateur?.profilActif
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const affichageCreationDossier = dossierCreable(profilUtilisateur)
  const affichageCreationHabilitation = habilitationCreable(profilUtilisateur)
  const affichageConsultationFluxTechnique = fluxTechniqueConsultable(profilUtilisateur)
  const affichageConsultationDossier = dossierConsultable(profilUtilisateur)
  const affichageToutesLesFacturesATraiter = toutesLesFacturesATraiter(profilUtilisateur)
  const affichageConsultationTousLesDossiersHorsDelai = tousLesDossiersHorsDelaiConsultable(profilUtilisateur)
  const affichageConsultationMesDossiersHorsDelai = mesDossiersHorsDelaiConsultable(profilUtilisateur)
  const affichageConsultationDossierAttenteAcceptation = dossierAttenteAcceptationConsultable(profilUtilisateur)

  // Nombre de médecin dans le réseau assureur :
  const { data: listMedecinsHabilites } = useGetListeHabilitationsActivesEntrepriseQuery({
    filter: { activeFutur: 1 },
  })
  const compteurMedecinsHabilites = listMedecinsHabilites && (listMedecinsHabilites.totalItems as number)
  const filtreMedecinHabilites = { activeFutur: 1 } as HabilitationEntrepriseFilter

  // Nombre mes dossiers en cours :
  const totalMesDossiersEnCours = totalMesDossierEnCoursRequest(
    isGestionnaire(profilUtilisateur) ? utilisateur?.idUtilisateur : undefined
  )
  const { data: listMesDossiersEnCours } = useGetRechercheDossierQuery(totalMesDossiersEnCours, {
    skip: !affichageConsultationDossier,
    refetchOnMountOrArgChange: TABLEAU_DE_BORD_MAX_TIME_CACHE,
  })
  const compteurMesDossiersEnCours = listMesDossiersEnCours?.totalItems as number
  const filtreMesDossiersEnCours = totalMesDossiersEnCours.filter

  // Nombre des dossiers en cours :
  const totalDossiersEnCours = totalDossierEnCoursRequest()
  const { data: listDossiersEnCours } = useGetRechercheDossierQuery(totalDossiersEnCours, {
    skip: !affichageConsultationDossier,
    refetchOnMountOrArgChange: TABLEAU_DE_BORD_MAX_TIME_CACHE,
  })
  const compteurDossiersEnCours = listDossiersEnCours?.totalItems as number
  const filtreDossiersEnCours = totalDossiersEnCours.filter

  // Nombre de facture à traiter :
  const totalFactureADeposee = totalFactureADeposeeRequest(
    isGestionnaire(profilUtilisateur) ? utilisateur?.idUtilisateur : undefined
  )
  const { data: listFactures } = useGetRechercheFactureQuery(totalFactureADeposee, {
    skip: !affichageConsultationDossier,
    refetchOnMountOrArgChange: TABLEAU_DE_BORD_MAX_TIME_CACHE,
  })
  const compteurFacturesADeposee = listFactures?.totalItems as number
  const filtreFactureADeposee = totalFactureADeposee.filter

  // Nombre des dossiers en création
  const totalDossiersEnCreation = totalMesDossierEnCreationRequest(
    isGestionnaire(profilUtilisateur) ? utilisateur?.idUtilisateur : undefined
  )
  const { data: listDossiersEnCreation } = useGetRechercheDossierQuery(totalDossiersEnCreation, {
    skip: !affichageConsultationDossier,
    refetchOnMountOrArgChange: TABLEAU_DE_BORD_MAX_TIME_CACHE,
  })
  const compteurDossiersEnCreation = listDossiersEnCreation?.totalItems as number
  const filtreDossiersEnCreation = totalDossiersEnCreation.filter

  // Nombre mes dossiers en attente d'acceptation
  const totalMesDossiersEnAttenteAcceptation = totalMesDossiersEnAttenteAcceptationRequest(
    isGestionnaire(profilUtilisateur) ? utilisateur?.idUtilisateur : undefined
  )
  const { data: listMesDossiersEnAttenteAcceptation } = useGetRechercheDossierQuery(
    totalMesDossiersEnAttenteAcceptation,
    {
      skip: !affichageConsultationDossier,
      refetchOnMountOrArgChange: TABLEAU_DE_BORD_MAX_TIME_CACHE,
    }
  )
  const compteurMesDossiersEnAttenteAcceptation = listMesDossiersEnAttenteAcceptation?.totalItems as number
  const filtreMesDossiersEnAttenteAcceptation = totalMesDossiersEnAttenteAcceptation.filter

  // Nombre des dossiers en attente d'acceptation
  const totalDossiersEnAttenteAcceptation = totalDossiersEnAttenteAcceptationRequest()
  const { data: listDossiersEnAttenteAcceptation } = useGetRechercheDossierQuery(totalDossiersEnAttenteAcceptation, {
    skip: !affichageConsultationDossier,
    refetchOnMountOrArgChange: TABLEAU_DE_BORD_MAX_TIME_CACHE,
  })
  const compteurDossiersEnAttenteAcceptation = listDossiersEnAttenteAcceptation?.totalItems as number
  const filtreDossiersEnAttenteAcceptation = totalDossiersEnAttenteAcceptation.filter

  // Nombre mes dossiers hors delai pour un gestionnaire.
  const totalMesDossiersHorsDelai = totalMesDossiersHorsDelaiRequest(
    mesDossiersHorsDelaiConsultable(profilUtilisateur) ? utilisateur?.idUtilisateur : undefined
  )
  const { data: listMesDossiersHorsDelai } = useGetRechercheDossierQuery(totalMesDossiersHorsDelai, {
    skip: !affichageConsultationDossier,
    refetchOnMountOrArgChange: TABLEAU_DE_BORD_MAX_TIME_CACHE,
  })
  const compteurMesDossiersHorsDelai = listMesDossiersHorsDelai?.totalItems as number
  const filtreMesDossiersHorsDelai = totalMesDossiersHorsDelai.filter

  // Nombre des dossiers hors delai
  const totalTousLesDossiersHorsDelais = totalTousLesDossiersHorsDelaisRequest()
  const { data: listDossiersHorsDelais } = useGetRechercheDossierQuery(totalTousLesDossiersHorsDelais, {
    skip: !affichageConsultationDossier,
    refetchOnMountOrArgChange: TABLEAU_DE_BORD_MAX_TIME_CACHE,
  })
  const compteurDossiersHorsDelais = listDossiersHorsDelais?.totalItems as number
  const filtreDossiersHorsDelais = totalTousLesDossiersHorsDelais.filter

  const handleClickCardReseauEntreprise = (filter?: HabilitationEntrepriseFilter) => {
    if (filter) {
      dispatch(setMedecinCriteria(filter))
    }
    navigate(listeHabilitationsHref)
  }

  const handleClickCardDossier = (filter?: DossierEntrepriseFilter) => {
    if (filter) {
      dispatch(setDossierCriteria(filter))
    }
    navigate(rechercheDossiersHref)
  }

  const handleClickCardFacture = (filter?: FactureFilter) => {
    if (filter) {
      dispatch(setFactureCriteria(filter))
    }
    navigate(rechercheFacturesHref)
  }

  const handleClickCardCreationDossier = () => {
    confirm({
      title: `Création d'un nouveau dossier`,
      maxWidth: 'lg',
      fullWidth: true,
      withForm: true,
      form: (
        <CreationDossierEntrepriseForm
          utilisateur={utilisateur}
          profilEntreprise={profilEntreprise}
          referentiel={referentiel}
          onClose={closeConfirmDialog}
        />
      ),
      confirmMsg: '',
    })
  }

  const handleClickCardEchangesTechniques = () => {
    dispatch(setFluxTechniqueCriteria({}))
    navigate(listeFluxTechniqueHref)
  }

  const handleClickCardCreationHabilitation = () => {
    navigate(listeMedecinsHref)
  }

  return (
    <Card>
      <CardContent sx={{ paddingX: theme.spacing(4) }}>
        <TableauDeBordGrid container>
          {affichageCreationDossier && (
            <TableauDeBordGridItem>
              {/* Créer un nouveau dossier, fond vert */}
              <TableauDeBordTile
                backgroundColor="#37c6bf"
                isIconButton
                icon={<PlusIcon withMargin fontSize="large" />}
                description="Créer un nouveau dossier"
                onClick={handleClickCardCreationDossier}
              />
            </TableauDeBordGridItem>
          )}
          {affichageCreationHabilitation && (
            <>
              {/* Créer une nouvelle habilitation, font vert */}
              <TableauDeBordGridItem>
                <TableauDeBordTile
                  backgroundColor="#37c6bf"
                  isIconButton
                  icon={<PlusIcon withMargin fontSize="large" />}
                  description="Ajouter une habilitation"
                  onClick={handleClickCardCreationHabilitation}
                />
              </TableauDeBordGridItem>
            </>
          )}
          {affichageConsultationFluxTechnique && (
            <>
              {/* Créer un nouveau dossier, font vert */}
              <TableauDeBordGridItem>
                <TableauDeBordTile
                  backgroundColor="#37c6bf"
                  isIconButton
                  icon={<HistoriqueIcon withMargin fontSize="large" />}
                  description="Échanges techniques TransMED"
                  onClick={handleClickCardEchangesTechniques}
                />
              </TableauDeBordGridItem>
            </>
          )}
          {affichageCreationDossier && (
            <TableauDeBordGridItem>
              {/* Action dossiers en création, fond bleu */}
              <TableauDeBordTile
                backgroundColor="#507fd1"
                value={compteurDossiersEnCreation}
                description="Mes dossiers en création"
                onClick={() => handleClickCardDossier(filtreDossiersEnCreation)}
              />
            </TableauDeBordGridItem>
          )}
          {affichageCreationDossier && (
            <TableauDeBordGridItem>
              {/* Action Tous les dossiers en attente d'acceptation d'un superviseur, fond violet */}
              <TableauDeBordTile
                backgroundColor="#8b5dbc"
                value={compteurMesDossiersEnAttenteAcceptation}
                description="Mes dossiers en attente d'acceptation"
                onClick={() => handleClickCardDossier(filtreMesDossiersEnAttenteAcceptation)}
              />
            </TableauDeBordGridItem>
          )}
          {affichageConsultationDossierAttenteAcceptation && (
            <TableauDeBordGridItem>
              {/* Action tous les dossiers en attente d'acceptation d'un superviseur, fond violet */}
              <TableauDeBordTile
                backgroundColor="#8b5dbc"
                value={compteurDossiersEnAttenteAcceptation}
                description="Tous les dossiers en attente d'acceptation"
                onClick={() => handleClickCardDossier(filtreDossiersEnAttenteAcceptation)}
              />
            </TableauDeBordGridItem>
          )}
          {affichageCreationDossier && (
            <TableauDeBordGridItem>
              <TableauDeBordTile
                backgroundColor="#b1b0b0"
                value={compteurMesDossiersEnCours}
                description="Mes dossiers en cours"
                onClick={() => handleClickCardDossier(filtreMesDossiersEnCours)}
              />
            </TableauDeBordGridItem>
          )}
          {affichageConsultationDossier && (
            <TableauDeBordGridItem>
              <TableauDeBordTile
                backgroundColor="#b1b0b0"
                value={compteurDossiersEnCours}
                description="Tous les dossiers en cours"
                onClick={() => handleClickCardDossier(filtreDossiersEnCours)}
              />
            </TableauDeBordGridItem>
          )}
          <TableauDeBordGridItem>
            {/* Action mon réseau de médecins, font violet */}
            <TableauDeBordTile
              backgroundColor="#8b5dbc"
              value={compteurMedecinsHabilites}
              description="Mon réseau de médecins"
              onClick={() => handleClickCardReseauEntreprise(filtreMedecinHabilites)}
            />
          </TableauDeBordGridItem>
          {affichageCreationDossier && (
            <TableauDeBordGridItem>
              {/* Action facture en attente, fond rouge */}
              <TableauDeBordTile
                backgroundColor="#e74242"
                value={compteurFacturesADeposee}
                description="Mes factures à traiter"
                onClick={() => handleClickCardFacture(filtreFactureADeposee)}
              />
            </TableauDeBordGridItem>
          )}
          {affichageToutesLesFacturesATraiter && (
            <TableauDeBordGridItem>
              {/* Action facture en attente, fond rouge */}
              <TableauDeBordTile
                backgroundColor="#e74242"
                value={compteurFacturesADeposee}
                description="Toutes les factures à traiter"
                onClick={() => handleClickCardFacture(filtreFactureADeposee)}
              />
            </TableauDeBordGridItem>
          )}
          {affichageConsultationMesDossiersHorsDelai && (
            <TableauDeBordGridItem>
              {/* Action mes dossiers hors délais de traitement */}
              <TableauDeBordTile
                backgroundColor="#e74242"
                value={compteurMesDossiersHorsDelai}
                description="Mes dossiers hors délais de traitement"
                onClick={() => handleClickCardDossier(filtreMesDossiersHorsDelai)}
              />
            </TableauDeBordGridItem>
          )}
          {affichageConsultationTousLesDossiersHorsDelai && (
            <TableauDeBordGridItem>
              {/* Action Tous les dossiers hors délais de traitement */}
              <TableauDeBordTile
                backgroundColor="#e74242"
                value={compteurDossiersHorsDelais}
                description="Tous les dossiers hors délais de traitement"
                onClick={() => handleClickCardDossier(filtreDossiersHorsDelais)}
              />
            </TableauDeBordGridItem>
          )}
        </TableauDeBordGrid>
      </CardContent>
      {confirmDialog}
    </Card>
  )
}
