import React from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { Stack } from '@mui/material'
import ActualitesPart from 'plateforme/parts/ActualitesPart'
import MessageAccueilPart from 'plateforme/parts/MessageAccueilPart'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import { useGetReferentielQuery } from 'plateforme/store/apis/referentielApi'
import { FallbackError } from 'plateforme/components/dialog/ErrorFallback'
import TableauDeBordEntreprisePart from 'assureur/parts/home/TableauDeBordEntreprisePart'
import DerniersDossiersEntreprisePart from 'assureur/parts/home/DerniersDossiersEntreprisePart'
import DernieresNotificationsDossierEntreprisePart from 'assureur/parts/home/DernieresNotificationsDossierEntreprisePart'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'
import DernieresNotificationsMessageEntreprisePart from 'assureur/parts/home/DernieresNotificationsMessageEntreprisePart'
import {
  dossierConsultable,
  messageConsultable,
  notificationConsultable,
} from 'assureur/services/rolesEntreprise.services'

export default function HomeEntreprisePage() {
  const handleError = useErrorHandler()
  const {
    data: utilisateur,
    isError: isErrorUtilisateur,
    error: errorUtilisateur,
    isLoading: isLoadingUtilisateur,
    isFetching: isFetchingUtilisateur,
  } = useGetUtilisateurActifQuery()
  if (isErrorUtilisateur) {
    handleError({
      errorApi: errorUtilisateur,
      title: `Erreur chargement de l'utilisateur`,
      api: 'GetUtilisateurActif',
    } as FallbackError)
  }

  const {
    data: profilEntreprise,
    isError: isErrorProfilEntreprise,
    error: errorProfilEntreprise,
    isLoading: isLoadingProfilEntreprise,
    isFetching: isFetchingProfilEntreprise,
  } = useGetProfilEntrepriseQuery()
  if (isErrorProfilEntreprise) {
    handleError({
      errorApi: errorProfilEntreprise,
      title: `Erreur chargement du profil entreprise`,
      api: 'GetProfilEntreprise',
    } as FallbackError)
  }

  const {
    data: referentiel,
    isError: isErrorReferentiel,
    error: errorReferentiel,
    isLoading: isLoadingReferentiel,
    isFetching: isFetchingReferentiel,
  } = useGetReferentielQuery()
  if (isErrorReferentiel) {
    handleError({
      errorApi: errorReferentiel,
      title: 'Erreur chargement du référentiel',
      api: 'GetReferentiel',
    } as FallbackError)
  }

  const loading =
    isLoadingUtilisateur ||
    isFetchingUtilisateur ||
    isLoadingProfilEntreprise ||
    isFetchingProfilEntreprise ||
    isLoadingReferentiel ||
    isFetchingReferentiel

  const profilUtilisateur = utilisateur?.profilActif

  return (
    <Stack>
      {/* "Bonjour ..." */}
      <MessageAccueilPart utilisateur={utilisateur} loading={loading} />
      {/* actualités de la plateforme */}
      <ActualitesPart loading={loading} />
      <TableauDeBordEntreprisePart
        utilisateur={utilisateur}
        profilEntreprise={profilEntreprise}
        referentiel={referentiel}
        loading={loading}
      />
      {messageConsultable(profilUtilisateur) && <DernieresNotificationsMessageEntreprisePart loading={loading} />}
      {notificationConsultable(profilUtilisateur) && <DernieresNotificationsDossierEntreprisePart loading={loading} />}
      {dossierConsultable(profilUtilisateur) && <DerniersDossiersEntreprisePart />}
    </Stack>
  )
}
