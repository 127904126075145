import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Grid, Stack, Typography } from '@mui/material'
import { EditTextField, SelectInput } from 'plateforme/components'
import { CodeContact } from 'assureur/store/types/profilEntreprise'
import PhoneNumberInput from 'plateforme/components/inputs/PhoneNumberInput'

const EXPERTISE_TYPES = [
  { code: CodeContact.CODE_BAL_SERVICE, label: 'BAL service' }, // Uniquement si cloisonnement par service activé
  { code: CodeContact.CODE_BAL_ENTREPRISE, label: 'BAL générale' },
  { code: CodeContact.CODE_BAL_GESTIONNAIRE, label: 'BAL gestionnaire en création' },
  { code: CodeContact.CODE_BAL_PERSONNALISE, label: 'Valeur personnalisée' },
]

interface InformationsContactRowProps {
  rowTitle: string
  controllerName: string
  readOnly?: boolean
}

export default function InformationsContactRow({ rowTitle, controllerName, readOnly }: InformationsContactRowProps) {
  const { control, watch, setValue, unregister, clearErrors } = useFormContext()

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={6}>
          <Stack direction="row" alignItems="center" justifyContent="flex-start">
            <Typography width={400}>{rowTitle}</Typography>
            <Controller
              name={`${controllerName}.code`}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectInput
                  fullWidth
                  id={`${controllerName}.code`}
                  label=""
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    // Si CODE_BAL_PERSONNALISE on ajoute des champs au formulaire sinon on supprime les champs
                    if (e === CodeContact.CODE_BAL_PERSONNALISE) {
                      setValue(`${controllerName}.libelle`, '')
                      setValue(`${controllerName}.telephone`, '')
                      setValue(`${controllerName}.mail`, '')
                    } else {
                      unregister(`${controllerName}.libelle`)
                      unregister(`${controllerName}.telephone`)
                      unregister(`${controllerName}.mail`)
                    }
                    onChange(e)
                  }}
                  options={watch('parametrage.cloisonnementService') ? EXPERTISE_TYPES : EXPERTISE_TYPES.slice(1, 4)}
                  fieldError={error}
                  readOnly={readOnly}
                />
              )}
            />
          </Stack>
        </Grid>
        {watch(`${controllerName}.code`) === CodeContact.CODE_BAL_PERSONNALISE && (
          <>
            <Grid item xs={2}>
              <Controller
                name={`${controllerName}.libelle`}
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <EditTextField
                    fullWidth
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldError={error}
                    readOnly={readOnly}
                    label="Libellé contact spécifique"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name={`${controllerName}.telephone`}
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <PhoneNumberInput
                    id="telephone-contact-personnalise"
                    label="Téléphone contact personnalisé"
                    value={value}
                    onChange={(e) => {
                      clearErrors(`${controllerName}.mail`)
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    fieldError={error}
                    fullWidth
                    readOnly={readOnly}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name={`${controllerName}.mail`}
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <EditTextField
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      clearErrors(`${controllerName}.telephone`)
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    fieldError={error}
                    readOnly={readOnly}
                    label="Adresse mail contact personnalisé"
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}
