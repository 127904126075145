import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Alert, Grid } from '@mui/material'
import {
  AnnulerButton,
  ButtonsStack,
  ReadOnlyTextField,
  SelectInputParametrage,
  ValiderButton,
} from 'plateforme/components'
import { ParametrageNameEnum } from 'plateforme/components/inputs/SelectInputParametrage'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import SelectInputGestionnaire from 'plateforme/components/inputs/SelectInputGestionnaire'
import { trimToUndefined } from 'plateforme/services/utils'
import { AFFECTATION_CENTRALE_CODE, AFFECTATION_CENTRALE_LABEL } from 'plateforme/constantes'
import ProfilEntreprise from 'assureur/store/types/profilEntreprise'
import DossierEntreprise, { ChangerAffectationExEntRequest } from 'assureur/store/types/dossierEntreprise'
import { usePostChangerAffectationMutation } from 'assureur/store/apis/dossierAssureurApi'

type AffectationGestionnaireProps = {
  dossier: DossierEntreprise
  profilUtilisateur?: ProfilUtilisateurActif
  profilEntreprise?: ProfilEntreprise
  onClose?: () => void
  closeConfirmDialog: () => void
}

type AffectationGestionnaireFormValues = {
  serviceEntreprise?: string
  gestionnaire?: string
}

export default function AffectationGestionnaireForm({
  dossier,
  onClose,
  profilEntreprise,
  profilUtilisateur,
  closeConfirmDialog,
}: AffectationGestionnaireProps) {
  // props:
  const { libelleServiceEntreprise, gestionnaire } = dossier
  const { parametrage } = profilEntreprise || {}

  // default values:
  const defaultValues: AffectationGestionnaireFormValues = {
    serviceEntreprise: AFFECTATION_CENTRALE_CODE,
    gestionnaire: '',
  }

  // hooks:
  const [changerAffectation, { error: errorSauvegarder, isLoading: loadingAccepterMission }] =
    usePostChangerAffectationMutation()
  const {
    setError,
    getValues,
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  })
  const { enqueueSnackbar } = useSnackbar()
  useErrorFormMapper(errorSauvegarder as IQueryErrorResponse, setError, getValues)
  const serviceEntrepriseWatched = watch('serviceEntreprise', AFFECTATION_CENTRALE_CODE)

  // format
  const isServiceInProfilUtilisateur = profilUtilisateur?.codeService
  const isCloisonnementService = parametrage?.cloisonnementService
  const formId = 'form-affectation-gestionnaire'

  // submit form:
  const onSubmit = async () => {
    const formValues = getValues() as AffectationGestionnaireFormValues
    const body: ChangerAffectationExEntRequest = {
      serviceEntreprise: trimToUndefined(formValues.serviceEntreprise, AFFECTATION_CENTRALE_CODE),
      gestionnaire: trimToUndefined(formValues.gestionnaire),
    }
    await changerAffectation({ codeDossier: dossier.code, ...body })
      .unwrap()
      .then(() => {
        enqueueSnackbar('Le dossier a été ré-affecté avec succès', { variant: 'success' })
        closeConfirmDialog()
      })
      .catch(() => {
        enqueueSnackbar('La ré-affectation a échoué', { variant: 'error' })
      })
  }

  // render:
  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formId} name={formId}>
      <Grid container>
        <Grid item xs={12}>
          <Alert severity="info">Le dossier est actuellement affecté au gestionnaire :</Alert>
        </Grid>
        {isCloisonnementService && (
          <Grid item xs={12} md={6}>
            <ReadOnlyTextField
              id="service-entreprise-actuel"
              label="Service"
              value={libelleServiceEntreprise ?? AFFECTATION_CENTRALE_LABEL}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} md={isCloisonnementService ? 6 : 12}>
          <ReadOnlyTextField id="gestionnaire-actuel" label="Gestionnaire" value={gestionnaire?.libelle} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">Vous avez choisi d&apos;affecter à un autre service / gestionnaire :</Alert>
        </Grid>
        {isCloisonnementService && (
          <Grid item xs={12} md={6}>
            {isServiceInProfilUtilisateur ? (
              <ReadOnlyTextField
                id="service-entreprise"
                label="Service"
                value={profilUtilisateur.libelleService ?? AFFECTATION_CENTRALE_LABEL}
                fullWidth
              />
            ) : (
              <Controller
                name="serviceEntreprise"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectInputParametrage
                    id="service-entreprise"
                    parametrageName={ParametrageNameEnum.SERVICE}
                    label="Service"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    onBlur={onBlur}
                    fieldError={error}
                  />
                )}
              />
            )}
          </Grid>
        )}
        <Grid item xs={12} md={isCloisonnementService ? 6 : 12}>
          <Controller
            name="gestionnaire"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectInputGestionnaire
                id="gestionnaire"
                label="Gestionnaire"
                value={value}
                onChange={onChange}
                fullWidth
                onBlur={onBlur}
                fieldError={error}
                codeService={serviceEntrepriseWatched}
                withNoSelectionItem
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} />
        <Grid container />
        <Grid item xs={12}>
          <ButtonsStack justifyContent="end">
            <AnnulerButton color="error" onClick={onClose}>
              Annuler
            </AnnulerButton>
            <ValiderButton onClick={onSubmit} loading={loadingAccepterMission} disabled={!isValid}>
              Confirmer
            </ValiderButton>
          </ButtonsStack>
        </Grid>
      </Grid>
    </form>
  )
}
