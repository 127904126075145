import React from 'react'
import { Alert, Card, CardContent, CardHeader, Grid } from '@mui/material'
import { AreaLoading, ReadOnlyEmailField, ReadOnlyTextField } from 'plateforme/components'
import DossierPlateforme from 'plateforme/store/types/dossier'
import { LoadingProps } from 'plateforme/services/utils'

type LieuExpertisePartProps = {
  dossier?: DossierPlateforme
}

export default function LieuExpertisePart({ dossier, loading }: LieuExpertisePartProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={219.5} />
  }
  return <LieuExpertisePartLoaded dossier={dossier} />
}

function LieuExpertisePartLoaded({ dossier }: LieuExpertisePartProps) {
  // erreur:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const { expertise } = dossier

  // format:
  const isExpertiseAuCabinetMedecin = expertise?.typeLieuExpertise === 'CABINET'

  // render:
  if (!expertise) {
    return null
  }
  return (
    <Card>
      <CardHeader title="Lieu d'expertise" />
      <CardContent>
        {isExpertiseAuCabinetMedecin ? (
          <Alert severity="info">Expertise au cabinet du médecin</Alert>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Alert severity="info">
                L&apos;expertise se fera <strong>à une adresse spécifique</strong>
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <ReadOnlyTextField
                fullWidth
                minRows={3}
                multiline
                id="expertise-adresse-complete"
                value={expertise.adresseComplete}
                label="Lieu de l'expertise"
              />
            </Grid>
            <Grid item xs={12}>
              <ReadOnlyEmailField id="expertise-expertise-mail" label="Adresse mail" value={expertise.mail} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyTextField
                id="expertise-telephone-portable"
                label="Tél. portable"
                value={expertise.telephoneMobile}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyTextField
                id="expertise-telephone-fixe"
                label="Tél. fixe"
                value={expertise.telephoneFixe}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}
