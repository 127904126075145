import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, CardActionArea, CardContent, Checkbox, Grid, Stack, Typography, Box } from '@mui/material'
import { FactureIcon, RapportIcon } from 'plateforme/components'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import { derniereMissionAssociee } from 'medecin/services/dossierMedecin.services'
import { consultationDossierHref } from 'medecin/MedecinApp'
import { StatutRapport } from 'medecin/store/types/rapportConclusionMedecin'
import { StatutFacture } from 'medecin/store/types/factureMedecin'
import StatutDossierMedecinChip from './StatutMissionMedecinChip'

export interface CardDossierProp {
  dossier: DossierMedecin
}

function ValueLabled({ label, value }: { label?: string; value?: string }) {
  return (
    <Box
      sx={{
        flexDirection: 'column',
      }}
    >
      <Typography variant="subtitle2" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="subtitle2" color="text.primary" gutterBottom>
        {value}
      </Typography>
    </Box>
  )
}

export default React.memo(DossierCard)

function DossierCard({ dossier }: CardDossierProp) {
  const { code: codeDossier, entreprise, expertise, evenement, personneAExaminer } = dossier
  if (codeDossier === undefined) {
    throw new Error('Aucun code dossier défini')
  }
  const mission = derniereMissionAssociee(dossier)
  const { rapport, factures: facturesMission } = mission || {}

  let factures = [...(facturesMission ?? [])]
  factures = factures.filter((f) => f.statut !== StatutFacture.ANNULEE)

  const hasRapport = rapport?.statut === StatutRapport.VALIDE_DEFINITIVEMENT
  const hasFacture = (factures?.length ?? 0) > 0

  factures?.sort((a, b) => {
    return a.code.localeCompare(b.code)
  })

  const derniereFacture = factures?.at(0)
  const navigate = useNavigate()

  return (
    <Grid item xs={12} sm={12} md={12} key={mission?.code}>
      <Card sx={{ width: '100%' }}>
        <CardActionArea>
          <CardContent sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Box sx={{ display: 'flex', width: '80px', alignItems: 'center' }}>
              <Checkbox color="default" />
            </Box>
            <Grid
              container
              spacing={2}
              width="100%"
              onClick={() => {
                navigate(consultationDossierHref(codeDossier))
              }}
            >
              <Grid item md={8} xs={6} sx={{ paddingLeft: 0, marginLeft: 0 }}>
                <Stack direction="column" spacing={0}>
                  <Typography variant="h1" color="text.primary" gutterBottom>
                    {mission?.libelle}
                  </Typography>
                  <Typography align="left" variant="subtitle2" color="text.secondary" gutterBottom>
                    {`${personneAExaminer?.nom?.toUpperCase()}${
                      mission?.refMissionMedecin ? ` - Ma référence ${mission?.refMissionMedecin}` : ''
                    }`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item md={4} xs={6}>
                <StatutDossierMedecinChip dossier={dossier} mission={mission} />
              </Grid>
              <Grid item md={3} xs={4}>
                <ValueLabled label="Cadre de l'expertise" value={expertise?.labelCadreExpertise} />
              </Grid>
              <Grid item md={3} xs={4}>
                <ValueLabled label="Nature de l'événement" value={evenement?.labelNature} />
              </Grid>
              <Grid item md={2} xs={4}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ height: '100%' }}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {hasRapport && <RapportIcon sx={{ fontSize: 32 }} />}
                  {hasFacture && <FactureIcon sx={{ fontSize: 32 }} statutFacture={derniereFacture?.statut} />}
                </Stack>
              </Grid>
              <Grid item md={4} xs={12} textAlign="right" sx={{ alignSelf: 'flex-end' }}>
                <Typography variant="h2" color="text.primary" gutterBottom>
                  {entreprise?.libelle}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}
