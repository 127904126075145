import React from 'react'
import { useGetReferentielEntrepriseQuery } from 'medecin/store/apis/referentielEntrepriseMedecinApi'
import ReferentielEntreprise from 'medecin/store/types/referentielEntreprise'

import { useGetReferentielEntrepriseAdminQuery } from 'admin/store/apis/gestionEntrepriseApi'
import SelectInputWithQuery, { SelectInputWithQueryProps } from './SelectInputWithQuery'
import { SelectOption } from './SelectInput'

type SelectReferentielProps = Omit<SelectInputWithQueryProps<ReferentielEntreprise[]>, 'useQuery' | 'dataTransformer'>

export default function SelectInputEntreprise({ isAdmin = false, ...restProps }: SelectReferentielProps) {
  const dataTransformer = (data: ReferentielEntreprise[]) => {
    return data.map((ref) => {
      return { code: ref.code, label: ref.libelle } as SelectOption
    })
  }

  return (
    <SelectInputWithQuery
      {...restProps}
      orderBy="label"
      useQuery={isAdmin ? useGetReferentielEntrepriseAdminQuery : useGetReferentielEntrepriseQuery}
      dataTransformer={dataTransformer}
    />
  )
}
