import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Grid } from '@mui/material'
import { EditTextField, SelectInputReferentiel } from 'plateforme/components'
import PhoneNumberInput from 'plateforme/components/inputs/PhoneNumberInput'
import InfosContactRequisesAlert from '../InfosContactRequisesAlert'

export default function AutreMedecinPersonnaliseForm() {
  const { clearErrors, control, formState, setValue } = useFormContext()
  const autreMedecinError = 'expertise.autreMedecin._error'

  const severity =
    formState.errors.expertise &&
    'autreMedecin' in formState.errors.expertise &&
    formState.errors.expertise.autreMedecin &&
    '_error' in formState.errors.expertise.autreMedecin
      ? 'error'
      : 'info'

  function clearAutreMedecinError() {
    clearErrors(autreMedecinError)
    setValue(autreMedecinError, undefined, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  return (
    <>
      <Grid item xs={12}>
        <InfosContactRequisesAlert type="autreMedecin" severity={severity} />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="expertise.autreMedecin.libelle"
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <EditTextField
              id="expertise-autre-medecin-libelle"
              label="Identité autre médecin"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              fullWidth
              fieldError={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="expertise.autreMedecin.adresse1"
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <EditTextField
              id="expertise-autre-medecin-adresse1"
              label="Adresse 1"
              value={value}
              onBlur={onBlur}
              onChange={(e) => {
                clearAutreMedecinError()
                onChange(e)
              }}
              fullWidth
              fieldError={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="expertise.autreMedecin.adresse2"
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <EditTextField
              id="expertise-autre-medecin-adresse2"
              label="Adresse 2"
              value={value}
              onBlur={onBlur}
              onChange={(e) => {
                clearAutreMedecinError()
                onChange(e)
              }}
              fullWidth
              fieldError={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="expertise.autreMedecin.adresse3"
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <EditTextField
              id="expertise-autre-medecin-adresse3"
              label="Adresse 3"
              value={value}
              onBlur={onBlur}
              onChange={(e) => {
                clearAutreMedecinError()
                onChange(e)
              }}
              fullWidth
              fieldError={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Controller
          name="expertise.autreMedecin.codePostal"
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <EditTextField
              id="expertise-autre-medecin-code-postal"
              label="Code postal"
              value={value}
              onBlur={onBlur}
              onChange={(e) => {
                clearAutreMedecinError()
                onChange(e)
              }}
              fullWidth
              fieldError={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Controller
          name="expertise.autreMedecin.commune"
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <EditTextField
              id="expertise-autre-medecin-commune"
              label="Ville"
              value={value}
              onBlur={onBlur}
              onChange={(e) => {
                clearAutreMedecinError()
                onChange(e)
              }}
              fullWidth
              fieldError={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Controller
          name="expertise.autreMedecin.pays"
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <SelectInputReferentiel
              id="expertise-autre-medecin-pays"
              label="Pays"
              value={value}
              onBlur={onBlur}
              onChange={(e) => {
                clearAutreMedecinError()
                onChange(e)
              }}
              referentielName="pays"
              fullWidth
              withNoSelectionItem
              fieldError={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Controller
          name="expertise.autreMedecin.mail"
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <EditTextField
              id="expertise-autre-medecin-mail"
              label="Adresse mail"
              value={value}
              onBlur={onBlur}
              onChange={(e) => {
                clearAutreMedecinError()
                onChange(e)
              }}
              fullWidth
              fieldError={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Controller
          name="expertise.autreMedecin.telephoneMobile"
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <PhoneNumberInput
              id="autre-medecin-telephone-mobile"
              label="Tél. portable"
              value={value}
              onChange={(e) => {
                clearAutreMedecinError()
                onChange(e)
              }}
              onBlur={onBlur}
              fieldError={error}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Controller
          name="expertise.autreMedecin.telephoneFixe"
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <PhoneNumberInput
              id="autre-medecin-telephone-fixe"
              label="Tél. fixe"
              value={value}
              onChange={(e) => {
                clearAutreMedecinError()
                onChange(e)
              }}
              onBlur={onBlur}
              fieldError={error}
              fullWidth
            />
          )}
        />
      </Grid>
    </>
  )
}
