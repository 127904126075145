import DocumentTeleverse from './documentTeleverse'
import { TypeFicheConclusion } from './referentiel'

export interface Rapport {
  typeRapport?: TypeRapport.CONCLUSION_PROVISOIRE | TypeRapport.CONCLUSION_DEFINITIVE | TypeRapport.CARENCE
  nomPersonneAExaminer?: string
  prenomPersonneAExaminer?: string
  dateNaissancePersonneAExaminer?: Date
  lieuSignature?: string
  dateSignature?: Date
  signataire?: string
  statut?: StatutRapport
  typeFicheConclusion?: TypeFicheConclusion
  documents?: DocumentTeleverse[]
  dateCreation?: Date
  dateValidationMedecin?: Date
  dateMiseAJour?: Date
  consentement: boolean // En attendant d'avoir l'heritage
  refConsentement?: string // En attendant d'avoir l'heritage
  dateExamen?: Date // En attendant d'avoir l'heritage
  commentaire?: string // En attendant d'avoir l'heritage
}

export enum TypeRapport {
  CONCLUSION_PROVISOIRE = 'PRO',
  CONCLUSION_DEFINITIVE = 'DEF',
  CARENCE = 'CAR',
}

export enum LibelleRapport {
  CONCLUSION_PROVISOIRE = 'conclusions provisoires',
  CONCLUSION_DEFINITIVE = 'conclusions définitives',
  CARENCE = 'carence',
}

export enum StatutRapport {
  EN_COURS = 'EN_COURS',
  VALIDE = 'VALIDE',
  VALIDE_DEFINITIVEMENT = 'VALIDE_DEFINITIVEMENT',
  VALIDE_DEFINITIVEMENT_EN_COURS = 'VALIDE_DEFINITIVEMENT_EN_COURS',
}
