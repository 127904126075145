import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet, Route, Routes } from 'react-router-dom'
import NotFoundPage from 'plateforme/pages/NotFoundPage'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { PAGE_LARGE_PADDING_X, PAGE_PADDING_X, PAGE_PADDING_Y } from 'plateforme/constantes'
import { toAdminHref } from 'plateforme/App'
import ErrorFallback from 'plateforme/components/dialog/ErrorFallback'
import MenuAdminPart from './part/menu/MenuAdminPart'
import HomeAdminPage from './pages/HomeAdminPage'
import ParametresEntrepriseAdminPage from './pages/ParametrageEntrepriseAdminPage'
import ModifierMedecinPage from './pages/ModifierMedecinPage'
import RechercheHabilitationsMedecinAdminPage from './pages/RechercheHabilitationsMedecinAdminPage'
import GererActualitesPage from './pages/GererActualitesPage'
import RechercheNotificationsDossierAdminPage from './pages/RechercheNotificationsDossierAdminPage'
import RechercheMedecinsAdminPage from './pages/RechercheMedecinsAdminPage'
import RechercheDossierAdminPage from './pages/RechercheDossierAdminPage'
import RechercheEntrepriseReferencesPage from './pages/RechercheEntrepriseReferencesPage'
import ConsultationDossierAdminPage from './pages/ConsultationDossierAdminPage'
import ConsultationIndicateurActiviteAdminPage from './pages/ConsultationIndicateurActiviteAdminPage'
import GererNomenclaturesPage from './pages/GererNomenclaturesPage/GererNomenclaturesPage'
import RechercheNotificationsMessageAdminPage from './pages/RechercheNotificationsMessageAdminPage'

/**
 * Home Admin
 * Href = /extranet/admin/
 */
export const basePath = '/'
export const homeHref = toAdminHref(basePath)

/**
 * Recherche Entreprise référencés
 * Href = /extranet/admin/entreprises
 */
export const rechercheEntreprisesReferencesPath = 'entreprises'
export const rechercheEntreprisesReferencesHref = toAdminHref(rechercheEntreprisesReferencesPath)
/**
 * Profil entreprise
 * Href = /extranet/admin/entreprise/:codeEntreprise/profil
 */
export const parametrageEntreprisePath = '/entreprise/:codeEntreprise/profil'
export function parametrageEntrepriseHref(codeEntreprise: string) {
  return toAdminHref(parametrageEntreprisePath).replace(':codeEntreprise', codeEntreprise)
}

/**
 * Profil medecin
 * Href = /extranet/admin/medecin/:codeMedecin/modifier
 */
export const adminModifierMedecinPath = '/medecin/:codeMedecin/profil'
export function adminModifierMedecinHref(codeMedecin: string) {
  return toAdminHref(adminModifierMedecinPath).replace(':codeMedecin', codeMedecin)
}

/**
 * Admin consultation des habilitations médecin
 * Href = /extranet/admin/medecin/:codeMedecin/habilitations
 */
export const adminMedecinHabilitationsPath = '/medecin/:codeMedecin/habilitations'
export function adminMedecinHabilitationsHref(codeMedecin: string) {
  return toAdminHref(adminMedecinHabilitationsPath).replace(':codeMedecin', codeMedecin)
}

/**
 * Admin consultation de toute les actualites
 * Href = /extranet/admin/actualites
 */
export const adminActualitesPath = '/actualites'
export const adminActualitessHref = toAdminHref(adminActualitesPath)

/**
 * Admin recherche médecins
 * Href = /extranet/admin/medecins
 */
export const adminRechercheMedecinsPath = '/medecins'
export const adminRechercheMedecinsHref = toAdminHref(adminRechercheMedecinsPath)

/**
 * Admin recherche dossiers
 * Href = /extranet/admin/dossiers
 */
export const adminRechercheDossiersPath = '/dossiers'
export const adminRechercheDossiersHref = toAdminHref(adminRechercheDossiersPath)

/**
 * Admin Recherche de notifications dossiers
 * Href = /extranet/admin/notifications/dossiers
 */
export const rechercheNotificationsDossierPath = 'notifications/dossiers'
export const rechercheNotificationsDossierHref = toAdminHref(rechercheNotificationsDossierPath)

/**
 * Admin Recherche de notifications messages
 * Href = /extranet/admin/notifications/messages
 */
export const rechercheNotificationsMessagePath = 'notifications/messages'
export const rechercheNotificationsMessageHref = toAdminHref(rechercheNotificationsMessagePath)

/**
 * Consultation de dossier Entreprise
 * Href = /extranet/admin/dossier/:codeDossier
 */
export const consultationDossierPath = 'dossier/:codeDossier'
export function consultationDossierHref(codeDossier: string) {
  return toAdminHref(consultationDossierPath).replace(':codeDossier', codeDossier)
}

/**
 * Admin consultation des indicateurs d'activité
 * Href = /extranet/admin/indicateur-activite
 */
export const adminIndicateurActivitePath = '/indicateur-activite'
export function adminIndicateurActiviteHref() {
  return toAdminHref(adminIndicateurActivitePath)
}

/**
 * Gestion des nomenclatures
 * Href = /extranet/admin/nomenclatures
 */
export const gestionNomenclaturesPath = '/nomenclatures'
export function gestionNomenclaturesHref() {
  return toAdminHref(gestionNomenclaturesPath)
}

/* root {BASE_URL}/extranet/admin/ */
/* par defaut tout les roles admins sont autorisés voir /plateforme/security/roles#adminRoles */
export default function AdminApp() {
  return (
    <Routes>
      <Route path={basePath} element={<AdminLayout />}>
        <Route
          index
          element={
            <ErrorBoundary key="home-admin-error" FallbackComponent={ErrorFallback}>
              <HomeAdminPage />
            </ErrorBoundary>
          }
        />
        <Route
          index
          path={rechercheEntreprisesReferencesPath}
          element={
            <ErrorBoundary key="recherche-entreprise-references-error" FallbackComponent={ErrorFallback}>
              <RechercheEntrepriseReferencesPage />
            </ErrorBoundary>
          }
        />

        <Route
          path={parametrageEntreprisePath}
          element={
            <ErrorBoundary key="parametres-entreprise-admin-error" FallbackComponent={ErrorFallback}>
              <ParametresEntrepriseAdminPage />
            </ErrorBoundary>
          }
        />
        <Route
          path={rechercheNotificationsDossierPath}
          element={
            <ErrorBoundary key="notifications-dossier-admin-error" FallbackComponent={ErrorFallback}>
              <RechercheNotificationsDossierAdminPage />
            </ErrorBoundary>
          }
        />
        <Route
          path={rechercheNotificationsMessagePath}
          element={
            <ErrorBoundary key="notifications-message-admin-error" FallbackComponent={ErrorFallback}>
              <RechercheNotificationsMessageAdminPage />
            </ErrorBoundary>
          }
        />
        <Route
          path="*"
          element={
            <ErrorBoundary key="not-found-admin-error" FallbackComponent={ErrorFallback}>
              <NotFoundPage />
            </ErrorBoundary>
          }
        />
        <Route
          path={adminModifierMedecinPath}
          element={
            <ErrorBoundary key="modifier-medecin-error" FallbackComponent={ErrorFallback}>
              <ModifierMedecinPage />
            </ErrorBoundary>
          }
        />
        <Route
          path={adminMedecinHabilitationsPath}
          element={
            <ErrorBoundary key="recherche-habilitations-error" FallbackComponent={ErrorFallback}>
              <RechercheHabilitationsMedecinAdminPage />
            </ErrorBoundary>
          }
        />
        <Route
          path={adminActualitesPath}
          element={
            <ErrorBoundary key="liste-actualites" FallbackComponent={ErrorFallback}>
              <GererActualitesPage />
            </ErrorBoundary>
          }
        />
        <Route
          path={adminRechercheMedecinsPath}
          element={
            <ErrorBoundary key="recherche-medecins-error" FallbackComponent={ErrorFallback}>
              <RechercheMedecinsAdminPage />
            </ErrorBoundary>
          }
        />
        <Route
          path={adminRechercheDossiersPath}
          element={
            <ErrorBoundary key="recherche-dossiers-error" FallbackComponent={ErrorFallback}>
              <RechercheDossierAdminPage />
            </ErrorBoundary>
          }
        />
        <Route
          path={consultationDossierPath}
          element={
            <ErrorBoundary key="consultation-dossier-admin-error" FallbackComponent={ErrorFallback}>
              <ConsultationDossierAdminPage />
            </ErrorBoundary>
          }
        />
        <Route
          path={adminIndicateurActivitePath}
          element={
            <ErrorBoundary key="recherche-indicateur-activite-error" FallbackComponent={ErrorFallback}>
              <ConsultationIndicateurActiviteAdminPage />
            </ErrorBoundary>
          }
        />
        <Route
          path={gestionNomenclaturesPath}
          element={
            <ErrorBoundary key="nomenclatures-error" FallbackComponent={ErrorFallback}>
              <GererNomenclaturesPage />
            </ErrorBoundary>
          }
        />
      </Route>
    </Routes>
  )
}

/* Layout utilisé par toute les pages de la plateforme admin */
function AdminLayout() {
  const theme = useTheme()
  const margin = useMediaQuery(theme.breakpoints.up('xl'))
    ? theme.spacing(PAGE_PADDING_Y, PAGE_LARGE_PADDING_X)
    : theme.spacing(PAGE_PADDING_X)
  return (
    <>
      <MenuAdminPart />
      <Box margin={margin} paddingBottom={1.5}>
        <Outlet />
      </Box>
    </>
  )
}
