import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { Stack } from '@mui/system'
import TableNomenclaturesPart from 'admin/part/rechercheNomenclatures/TableNomenclaturesPart'
import TableValuesNomenclaturesPart from 'admin/part/rechercheNomenclatures/TableValuesNomenclaturesPart'
import { ButtonsStack, ChercherButton, EditTextField, RechargerButton } from 'plateforme/components'
import NavigationPart from 'plateforme/parts/NavigationPart'
import { useGetReferentielQuery } from 'plateforme/store/apis/referentielApi'
import { ReferentielDetail } from 'plateforme/store/types/referentiel'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

export interface NomenclaturesFilterData {
  motCle: string
}

export interface ReferentielTransformed extends ReferentielDetail {
  type: string
}

export default function GererNomenclaturesPage() {
  const { data } = useGetReferentielQuery()
  const [motCle, setMotCle] = useState('')
  const [nomenclature, setNomenclature] = useState('')

  const initialValues: NomenclaturesFilterData = {
    motCle: '',
  }

  const validateMotCle = (motCleValue: string) => {
    if (motCleValue.length < 3) {
      return 'Doit contenir au moins 3 caractères'
    }
    return true
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })

  let result = []

  if (data) {
    // transforme l'objet referentiel en un nouvel objet afin de pouvoir effectuer la recherche ngram
    result = Object.entries(data).flatMap(([key, value]) => {
      return value.map((v: ReferentielDetail) => ({ type: key, ...v } as ReferentielTransformed))
    })
  }

  const onSubmit = (filterData: NomenclaturesFilterData) => {
    setNomenclature('')
    setMotCle(filterData.motCle)
  }

  const handleClickInitSearch = () => {
    reset(initialValues)
    setNomenclature('')
    setMotCle('')
  }

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start" width="100%">
      <Grid item xs={12}>
        <Stack>
          <NavigationPart label="Gérer les nomenclatures" />
          <Card>
            <CardHeader title="Recherche avancée" />
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-nomenclatures">
                <Grid container>
                  <Grid item xs={12}>
                    <Controller
                      name="motCle"
                      rules={{
                        validate: validateMotCle,
                      }}
                      control={control}
                      defaultValue={initialValues.motCle}
                      render={({ field: { onBlur, onChange, value } }) => (
                        <EditTextField
                          id="keywords"
                          label="Mots clés"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonsStack>
                      <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                        Réinitialiser
                      </RechargerButton>
                      <ChercherButton
                        variant="contained"
                        type="submit"
                        form="form-recherche-nomenclatures"
                        disabled={!isValid}
                      >
                        Rechercher
                      </ChercherButton>
                    </ButtonsStack>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
      <Grid item lg={4} xs={12}>
        <TableNomenclaturesPart motCle={motCle} result={result} setNomenclature={setNomenclature} />
      </Grid>
      {(nomenclature || motCle) && (
        <Grid item lg={8} xs={12}>
          <TableValuesNomenclaturesPart motCle={motCle} nomenclature={nomenclature} result={result} />
        </Grid>
      )}
    </Grid>
  )
}
