import React, { CSSProperties, useMemo } from 'react'
import { FileRejection, ErrorCode, useDropzone } from 'react-dropzone'
import { Button } from '@mui/material'
import { useSnackbar } from 'notistack'
import { FileIcon } from '../index'

const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

function translateError(code: ErrorCode | string): string {
  switch (code) {
    case ErrorCode.FileTooLarge:
      return 'Le fichier est trop grand'
    case ErrorCode.FileInvalidType:
      // eslint-disable-next-line quotes
      return "Le format du fichier n'est pas autorisé"
    case ErrorCode.TooManyFiles:
      return 'Vous avez dépassé la limite de nombre de fichier autorisé'
    default:
      return 'Erreur inattendue'
  }
}

export type StyledDropzoneProps = {
  onDropAccepted: (acceptedFiles: File[]) => void
  disabled?: boolean
  accept: { [key: string]: string[] }
  maxSize: number
  maxFiles?: number
  buttonText?: string
  multiple?: boolean
}

export default function StyledDropzone({
  onDropAccepted,
  disabled,
  accept,
  maxSize,
  maxFiles,
  buttonText,
  multiple,
}: StyledDropzoneProps) {
  const { enqueueSnackbar } = useSnackbar()

  const handleFileRejections = (fileRejections: FileRejection[]) => {
    if (fileRejections && fileRejections.length > 0) {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach((e) =>
          enqueueSnackbar(`${translateError(e.code)}: ${file.name}`, {
            variant: 'error',
          })
        )
      })
    }
  }

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept,
    maxSize,
    onDropAccepted,
    onDropRejected: handleFileRejections,
    disabled,
    maxFiles,
    multiple,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused && focusedStyle),
      ...(isDragAccept && acceptStyle),
      ...(isDragReject && rejectStyle),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <div className="container">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div {...getRootProps({ style })}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...getInputProps()} />
        <Button
          variant="contained"
          endIcon={<FileIcon sx={{ fontSize: 32 }} />}
          size="large"
          color="secondary"
          disabled={disabled}
        >
          {buttonText ?? 'Choisir les fichiers à télécharger'}
        </Button>
        <p>Déposez vos fichiers dans cette zone ou cliquez sur le bouton ci-dessus</p>
      </div>
    </div>
  )
}
