import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card, CardContent, useTheme } from '@mui/material'
import { TableauDeBordGrid, TableauDeBordGridItem, TableauDeBordTile } from 'plateforme/components'
import PlusIcon from 'plateforme/components/icons/PlusIcon'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import Utilisateur from 'plateforme/store/types/utilisateur'
import { LoadingProps } from 'plateforme/services/utils'
import AreaLoading from 'plateforme/components/progress/AreaLoading'
import { TypeMessageNotification } from 'plateforme/store/types/referentiel'
import {
  determineAccesConsultationDossier,
  determineAccesConsultationNotification,
  determineAccesConsultationTraceFonctionnelle,
  determineAccesConsultationEntreprise,
  determineAccesGestionActualites,
  determineAccesGestionNomenclature,
  determineAccesConsultationIndicateurActivite,
  determineAccesInscriptionEntreprise,
  determineAccesInscriptionMedecin,
  determineAccesConsultationMedecin,
} from 'admin/services/rolesAdmin.service'
import {
  adminActualitessHref,
  adminIndicateurActiviteHref,
  adminRechercheDossiersHref,
  adminRechercheMedecinsHref,
  rechercheNotificationsDossierHref,
  rechercheEntreprisesReferencesHref,
  gestionNomenclaturesHref,
  rechercheNotificationsMessageHref,
} from 'admin/AdminApp'
import InscrireEntrepriseForm from 'admin/part/InscrireEntrepriseForm'
import InscrireMedecinForm from 'admin/part/InscrireMedecinForm'
import {
  setDossierCriteria,
  setEntrepriseReferenceCriteria,
  setIndicateurActiviteCriteria,
  setMedecinReferenceCriteria,
  setNotificationCriteria,
} from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'
import { ReferentielEntrepriseFilter } from 'admin/store/types/profilEntrepriseAdmin'
import { NotificationAdminFilter } from 'admin/store/types/notificationsAdmin'
import { MedecinAdminFilter } from 'admin/store/types/profilMedecinAdmin'
import { DossierAdminFilter } from 'admin/store/types/dossierAdmin'
import { IndicateurActiviteAdminFilter } from 'admin/store/types/indicateurActiviteAdmin'
import useIndicateursDeBordAdmin from './useIndicateursDeBordAdmin'

type TableauDeBordAdminPartProps = {
  utilisateur?: Utilisateur
}

export default function TableauDeBordAdminPart({ utilisateur, loading }: TableauDeBordAdminPartProps & LoadingProps) {
  if (loading) {
    return <AreaLoading height={168} />
  }
  return <TableauDeBordAdminPartLoaded utilisateur={utilisateur} />
}

function TableauDeBordAdminPartLoaded({ utilisateur }: TableauDeBordAdminPartProps) {
  const theme = useTheme()
  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()
  const profilUtilisateur = utilisateur?.profilActif
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { compteurEntreprisesReferences, filterEntreprisesReferences, compteurMedecinsReferences } =
    useIndicateursDeBordAdmin()
  const handleClickEntreprisesReferences = (filter?: ReferentielEntrepriseFilter) => {
    if (filter) {
      dispatch(setEntrepriseReferenceCriteria(filter))
    }
    navigate(rechercheEntreprisesReferencesHref)
  }
  const displayLibelleMedecinsReferences = compteurMedecinsReferences > 1 ? 'Médecins référencés' : 'Médecin référencé'
  const displayLibelleEntreprisesReferences =
    compteurEntreprisesReferences > 1 ? 'Entreprises référencées' : 'Entreprise référencée'

  const handleClickRechercheMedecins = () => {
    const initMedecinReferenceCriteria = {
      motCle: '',
      statut: undefined,
      codeEntreprise: undefined,
      dateCreationDebut: undefined,
      irca: undefined,
      expertiseSurPiece: undefined,
    } as MedecinAdminFilter
    dispatch(setMedecinReferenceCriteria(initMedecinReferenceCriteria))
    navigate(adminRechercheMedecinsHref)
  }

  const handleClickConsulterDossiers = () => {
    const initDossierCriteria = {
      motCle: undefined,
      statutsDossier: undefined,
      statutsMission: undefined,
      natureEvenement: undefined,
      codeEntreprise: undefined,
      codeMedecin: undefined,
      cadreExpertise: undefined,
      dateCreationDebut: undefined,
      dateCreationFin: undefined,
    } as DossierAdminFilter
    dispatch(setDossierCriteria(initDossierCriteria))
    navigate(adminRechercheDossiersHref)
  }

  const handleClickCardNotificationsDossierAdmin = () => {
    const initNotificationCriteria = {
      types: undefined,
      codeMedecin: undefined,
      codeEntreprise: undefined,
      dateDebut: undefined,
      dateFin: undefined,
      typesExclus: ['MEL_MED' as TypeMessageNotification],
    } as NotificationAdminFilter
    dispatch(setNotificationCriteria(initNotificationCriteria))
    navigate(rechercheNotificationsDossierHref)
  }

  const handleClickCardNotificationsMessageAdmin = () => {
    dispatch(setNotificationCriteria({ types: ['MEL_MED'] }))
    navigate(rechercheNotificationsMessageHref)
  }

  const handleClickGererNomenclatures = () => {
    navigate(gestionNomenclaturesHref())
  }

  const handleClickCardIndicateurActivite = () => {
    const initIndicateurActiviteCriteria = {
      dateCreationDebut: undefined,
      dateCreationFin: undefined,
      codeMedecin: undefined,
      codeEntreprise: undefined,
    } as IndicateurActiviteAdminFilter
    dispatch(setIndicateurActiviteCriteria(initIndicateurActiviteCriteria))
    navigate(adminIndicateurActiviteHref())
  }

  return (
    <Card>
      <CardContent sx={{ paddingX: theme.spacing(4) }}>
        <TableauDeBordGrid container>
          {determineAccesInscriptionMedecin(profilUtilisateur) && (
            <TableauDeBordGridItem>
              <TableauDeBordTile
                isIconButton
                icon={<PlusIcon withMargin fontSize="large" />}
                backgroundColor="#8cd3cf"
                description="Inscrire un médecin"
                onClick={() => {
                  confirm({
                    title: 'Inscrire un medecin',
                    confirmMsg: '',
                    maxWidth: 'md',
                    fullWidth: false,
                    withForm: true,
                    form: <InscrireMedecinForm onClose={closeConfirmDialog} />,
                  })
                }}
              />
              {confirmDialog}
            </TableauDeBordGridItem>
          )}
          {determineAccesInscriptionEntreprise(profilUtilisateur) && (
            <TableauDeBordGridItem>
              <TableauDeBordTile
                isIconButton
                icon={<PlusIcon withMargin fontSize="large" />}
                backgroundColor="#8cd3cf"
                description="Inscrire une entreprise d'assurance"
                onClick={() => {
                  confirm({
                    maxWidth: 'md',
                    fullWidth: true,
                    withForm: true,
                    form: <InscrireEntrepriseForm onClose={closeConfirmDialog} />,
                    confirmMsg: '',
                    title: `Inscrire une entreprise d'assurance`,
                  })
                }}
              />
              {confirmDialog}
            </TableauDeBordGridItem>
          )}
          {determineAccesConsultationMedecin(profilUtilisateur) && (
            <TableauDeBordGridItem>
              <TableauDeBordTile
                value={compteurMedecinsReferences}
                backgroundColor="#8b5dbc"
                description={displayLibelleMedecinsReferences}
                onClick={handleClickRechercheMedecins}
              />
            </TableauDeBordGridItem>
          )}
          {determineAccesConsultationEntreprise(profilUtilisateur) && (
            <TableauDeBordGridItem>
              <TableauDeBordTile
                value={compteurEntreprisesReferences}
                backgroundColor="#e74242"
                description={displayLibelleEntreprisesReferences}
                onClick={() => handleClickEntreprisesReferences(filterEntreprisesReferences)}
              />
            </TableauDeBordGridItem>
          )}
          {determineAccesGestionNomenclature(profilUtilisateur) && (
            <TableauDeBordGridItem>
              <TableauDeBordTile
                onClick={handleClickGererNomenclatures}
                backgroundColor="#507fd1"
                isIconButton
                description="Gérer les nomenclatures"
              />
            </TableauDeBordGridItem>
          )}
          {determineAccesConsultationDossier(profilUtilisateur) && (
            <TableauDeBordGridItem>
              <TableauDeBordTile
                onClick={handleClickConsulterDossiers}
                isIconButton
                backgroundColor="#b1b0b0"
                description="Consulter les dossiers"
              />
            </TableauDeBordGridItem>
          )}
          {determineAccesConsultationNotification(profilUtilisateur) && (
            <>
              <TableauDeBordGridItem>
                <TableauDeBordTile
                  isIconButton
                  backgroundColor="#e74242"
                  description="Consulter les notifications dossier émises par la plateforme"
                  onClick={handleClickCardNotificationsDossierAdmin}
                />
              </TableauDeBordGridItem>
              <TableauDeBordGridItem>
                <TableauDeBordTile
                  isIconButton
                  backgroundColor="#8cd3cf"
                  description="Consulter les messages médecins vers les entreprises"
                  onClick={handleClickCardNotificationsMessageAdmin}
                />
              </TableauDeBordGridItem>
            </>
          )}
          {determineAccesConsultationTraceFonctionnelle(profilUtilisateur) && (
            <TableauDeBordGridItem>
              <TableauDeBordTile
                isIconButton
                backgroundColor="#507fd1"
                description="Consulter les traces fonctionnelles de la plateforme"
              />
            </TableauDeBordGridItem>
          )}
          {determineAccesConsultationIndicateurActivite(profilUtilisateur) && (
            <TableauDeBordGridItem>
              <TableauDeBordTile
                isIconButton
                backgroundColor="#8b5dbc"
                description="Accéder aux indicateurs d'activité de la plateforme"
                onClick={handleClickCardIndicateurActivite}
              />
            </TableauDeBordGridItem>
          )}
          {determineAccesGestionActualites(profilUtilisateur) && (
            <TableauDeBordGridItem>
              <TableauDeBordTile
                isIconButton
                backgroundColor="#507fd1"
                description="Gérer les actualités"
                onClick={() => navigate(adminActualitessHref)}
              />
            </TableauDeBordGridItem>
          )}
        </TableauDeBordGrid>
      </CardContent>
    </Card>
  )
}
