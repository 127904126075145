import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useErrorHandler } from 'react-error-boundary'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Alert, AlertTitle, Box, Stack, Typography, useTheme } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'
import { AnnulerButton, AreaLoading, ButtonsStack, SauvegarderButton } from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { trimToUndefined } from 'plateforme/services/utils'
import { FallbackError } from 'plateforme/components/dialog/ErrorFallback'
import { StatutMission } from 'plateforme/store/types/mission'
import { useGetDossierByIdQuery, usePostCreerMissionMutation } from 'assureur/store/apis/dossierAssureurApi'
import ExpertisePart from 'assureur/parts/creationMission/ExpertisePart'
import IdentificationDossierPart from 'assureur/parts/creationMission/IdentificationDossierPart'
import ParametresMissionPart from 'assureur/parts/creationMission/ParametresMissionPart'
import { MissionRequest } from 'assureur/store/types/missionEntreprise'
import ChoixMedecinPart from 'assureur/parts/creationMission/ChoixMedecinPart'
import { getSelectedMedecin } from 'assureur/store/slices/selectedMedecin/selectedMedecinSlice'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'
import { consultationDossierHref } from 'assureur/EntrepriseApp'
import isMissionPrecedenteValide from 'assureur/services/missionEntreprise.service'
import { useGetMedecinsPartenaireQuery } from 'assureur/store/apis/medecinEntrepriseApi'

export default function CreationMissionEntreprisePage() {
  const { codeDossier } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [postCreerMission, { error: errorSauvegarder, isLoading: isSauvegarderLoading }] = usePostCreerMissionMutation()
  const { data: medecins } = useGetMedecinsPartenaireQuery({ actif: 1 })
  const selectedMedecin = useSelector(getSelectedMedecin)
  const navigate = useNavigate()
  const handleError = useErrorHandler()
  const theme = useTheme()
  if (codeDossier === undefined) {
    throw new Error('Aucun code dossier défini')
  }

  const {
    data: profilEntreprise,
    isError: isErrorGetProfilEntreprise,
    error: errorGetProfilEntreprise,
    isLoading: loadingProfilEntreprise,
    isFetching: fetchingProfilEntreprise,
  } = useGetProfilEntrepriseQuery()

  if (isErrorGetProfilEntreprise) {
    handleError({
      errorApi: errorGetProfilEntreprise,
      title: 'Erreur chargement des données profil entreprise',
      api: 'GetProfilEntreprise',
    } as FallbackError)
  }

  const { data: dossier, isLoading, isFetching } = useGetDossierByIdQuery({ codeDossier })
  const missionPrecedente = dossier?.missions?.find((mission) => mission.statut !== StatutMission.REFUSEE)
  // Get code medecin from : mission precedente if checked or selected medecin if not checked

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      code: dossier?.code,
      refMissionEntreprise: '',
      refMissionAnterieure: '',
      medecinPrecedent:
        (missionPrecedente?.rapport?.remissionnementPreconise &&
          isMissionPrecedenteValide(missionPrecedente, medecins)) ??
        false,
      generationOrdreMission: true,
    },
  })

  const {
    getValues,
    reset,
    setError,
    watch,
    formState: { isValid },
  } = methods

  // Mapping Errors in form:
  useErrorFormMapper(errorSauvegarder as IQueryErrorResponse, setError, getValues)

  const codeMedecinSelectionne = trimToUndefined(
    watch('medecinPrecedent') ? missionPrecedente?.medecin?.code : selectedMedecin?.code
  )

  const formId = 'form-missionner-medecin'

  const handleAnnuler = () => {
    reset()
    navigate(-1)
  }

  const onSubmit = async () => {
    const formValues = getValues()
    await postCreerMission({
      codeDossier,
      codeMedecin: codeMedecinSelectionne,
      generationOrdreMission: profilEntreprise?.parametrage?.lettreMission ? formValues.generationOrdreMission : false,
      refMissionEntreprise: trimToUndefined(formValues.refMissionEntreprise),
      refMissionAnterieure: trimToUndefined(formValues.refMissionAnterieure),
    } as MissionRequest)
      .unwrap()
      .then(() => {
        enqueueSnackbar(`La mission a été créée avec succès`, { variant: 'success' })
        navigate(consultationDossierHref(codeDossier))
      })
      .catch(() => {
        enqueueSnackbar(`La création de la mission a échoué`, { variant: 'error' })
      })
  }

  if (isLoading || isFetching || loadingProfilEntreprise || fetchingProfilEntreprise) {
    return <AreaLoading height={351} />
  }

  const title = `Missionner un médecin`

  return (
    <>
      <NavigationPart label={title} />
      <Box marginTop={2}>
        <FormProvider {...methods}>
          <form id={formId} name={formId}>
            <Stack>
              <IdentificationDossierPart dossier={dossier} profilEntreprise={profilEntreprise} />
              <ExpertisePart dossier={dossier} profilEntreprise={profilEntreprise} />
              <ChoixMedecinPart dossier={dossier} missionPrecedente={missionPrecedente} />
              <ParametresMissionPart
                dossier={dossier}
                codeMedecin={codeMedecinSelectionne}
                profilEntreprise={profilEntreprise}
              />
              <Alert
                severity="info"
                elevation={1}
                sx={{
                  '& .MuiAlert-icon': {
                    marginTop: 1,
                  },
                  '& .MuiAlert-message': {
                    width: '100%',
                  },
                }}
              >
                <AlertTitle sx={{ display: 'block', marginBottom: 0 }}>
                  <Box display="flex" flexWrap="wrap" rowGap={1} columnGap={2}>
                    <Typography display="flex" marginTop={1}>
                      Nouvelle mission n°{(dossier?.missions?.length ?? 0) + 1}
                    </Typography>
                    <ButtonsStack alignSelf="flex-end" marginLeft="auto" marginTop="2px">
                      <AnnulerButton
                        id="annuler"
                        onClick={() => handleAnnuler()}
                        color="error"
                        sx={{ bgcolor: theme.palette.tile.main }}
                      >
                        Annuler la création de mission
                      </AnnulerButton>
                      <SauvegarderButton
                        id="sauvegarder"
                        onClick={onSubmit}
                        disabled={!isValid || !codeMedecinSelectionne}
                        loading={isSauvegarderLoading}
                      >
                        Envoyer la mission au médecin
                      </SauvegarderButton>
                    </ButtonsStack>
                  </Box>
                </AlertTitle>
              </Alert>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </>
  )
}
