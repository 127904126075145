import React, { useState } from 'react'
import { EditTextField, SelectInput } from 'plateforme/components'
import { Controller, useFormContext } from 'react-hook-form'
import { Grid } from '@mui/material'
import { SelectOption } from 'plateforme/components/inputs/SelectInput'
import Decimal from 'decimal.js'

type MontantFactureProps = {
  specificLabel: string
  suffixName: string
  taux: SelectOption[]
}

export default function MontantsFactureForm({ specificLabel, suffixName, taux: optionTaux }: MontantFactureProps) {
  const { control, getValues, setValue } = useFormContext()

  const [customTaux, setCustomTaux] = useState(optionTaux)

  return (
    <Grid container>
      <Grid item lg={3} md={6} xs={6}>
        <Controller
          name={`${suffixName}.montantHT`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              label={`Montant ${specificLabel} HT`}
              type="decimal"
              inputProps={{ min: 0.0, step: '0.01' }}
              onBlur={(e) => {
                if (e.target.value !== '') {
                  const tagetValue = new Decimal(parseFloat(e.target.value))
                  setValue(`${suffixName}.montantHT`, tagetValue.toFixed(2))
                }
              }}
              onChange={(e) => {
                const targetValue = new Decimal(parseFloat(e.target.value) || 0)
                const taux = new Decimal(parseFloat(getValues(`${suffixName}.tauxTVA`) || 0))
                const newMontantTVA = targetValue.mul(taux).div(100)
                setValue(`${suffixName}.montantTVA`, newMontantTVA.toFixed(2))
                setValue(`${suffixName}.montantTTC`, targetValue.add(newMontantTVA).toFixed(2))
                onChange(e)
              }}
              fullWidth
              error={!!error}
              fieldError={error}
            />
          )}
        />
      </Grid>
      <Grid item lg={3} md={6} xs={6}>
        <Controller
          name={`${suffixName}.tauxTVA`}
          control={control}
          render={({ field: { onChange, ref: _, ...fields }, fieldState: { error } }) => (
            <SelectInput
              {...fields}
              id="mission-select"
              label="Taux de TVA"
              onChange={(e) => {
                const taux = new Decimal(parseFloat(e))
                const montantHT = new Decimal(parseFloat(getValues(`${suffixName}.montantHT`) || 0))
                const newMontantTVA = montantHT.mul(taux).div(100)
                setValue(`${suffixName}.montantTVA`, newMontantTVA.toFixed(2))
                setValue(`${suffixName}.montantTTC`, montantHT.add(newMontantTVA).toFixed(2))
                onChange(e)
              }}
              fullWidth
              error={!!error}
              fieldError={error}
              options={customTaux}
            />
          )}
        />
      </Grid>
      <Grid item lg={3} md={6} xs={6}>
        <Controller
          name={`${suffixName}.montantTVA`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              label={`Montant ${specificLabel} TVA`}
              type="decimal"
              inputProps={{ min: 0.0, step: '0.01' }}
              onBlur={(e) => {
                if (e.target.value !== '') {
                  const targetValue = new Decimal(parseFloat(e.target.value))
                  setValue(`${suffixName}.montantTVA`, targetValue.toFixed(2))
                }
              }}
              onChange={(e) => {
                const currentValue = new Decimal(parseFloat(e.target.value) || 0)
                const montantHT = new Decimal(parseFloat(getValues(`${suffixName}.montantHT`) || 0))
                const taux = currentValue.mul(100).div(montantHT)
                if (taux.isFinite()) {
                  setValue(`${suffixName}.tauxTVA`, taux.toFixed(2))
                  setCustomTaux(optionTaux.concat([{ code: `${taux.toFixed(2)}`, label: `${taux.toFixed(2)} %` }]))
                }
                setValue(`${suffixName}.montantTTC`, montantHT.add(currentValue).toFixed(2))
                onChange(e)
              }}
              fullWidth
              error={!!error}
              fieldError={error}
            />
          )}
        />
      </Grid>
      <Grid item lg={3} md={6} xs={6}>
        <Controller
          name={`${suffixName}.montantTTC`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              label={`Montant ${specificLabel} TTC`}
              type="decimal"
              readOnly
              inputProps={{ min: 0.0, step: '0.01', readOnly: true }}
              onChange={onChange}
              fullWidth
              error={!!error}
              fieldError={error}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
