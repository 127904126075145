import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import ProfilEntreprise from 'assureur/store/types/profilEntreprise'
import { EditTextField } from 'plateforme/components'
import { Controller, useFormContext } from 'react-hook-form'

type ParametresMissionPartProps = {
  dossier?: DossierEntreprise
  codeMedecin?: string
  profilEntreprise?: ProfilEntreprise
}

export default function ParametresMissionPart({ dossier, codeMedecin, profilEntreprise }: ParametresMissionPartProps) {
  const { control } = useFormContext()

  const showReferenceMissionAnterieur = !dossier?.missions?.some((m) => m.medecin?.code === codeMedecin)

  return (
    <Card>
      <CardHeader title="Paramètres de la mission" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Controller
              name="refMissionEntreprise"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="refMissionEntreprise"
                  label="Votre référence mission"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          {showReferenceMissionAnterieur && (
            <Grid item xs={12}>
              <Controller
                name="refMissionAnterieure"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="refMissionAnterieure"
                    label="Référence mission antérieure"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
          )}
          {profilEntreprise?.parametrage?.lettreMission && (
            <Grid item xs={12}>
              <Controller
                name="generationOrdreMission"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <FormControl error={!!error} variant="standard">
                    <FormControlLabel
                      id="generationOrdreMission"
                      value={value}
                      control={<Switch onBlur={onBlur} onChange={onChange} checked={value} />}
                      label="Génération de l'ordre de mission TransMED par la plateforme pour cette mission"
                    />
                    <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
