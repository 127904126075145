import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid, Stack } from '@mui/material'
import { SauvegarderButton } from 'plateforme/components'
import ActivationEvenementPart from './ActivationEvenementPart'
import DomainesContractuelsPart from './DomainesContractuelsPart'
import GestionRemissionnementPart from './GestionRemissionnementPart'
import IdentificationDossierPart from './IdentificationDossierPart'
import InformationsAssurePart from './InformationsAssurePart'
import InformationsContactPart from './InformationsContactPart'
import ParametrageCarteMedecinPart from './ParametrageCarteMedecinPart'
import ParametrageFichesConclusionsPart from './ParametrageFichesConclusionsPart'
import ParametragesAvisTechniquePart from './ParametragesAvisTechniquePart'
import ReglesMetiersPart from './ReglesMetiersPart'

interface TabReglesMetiersPartProps {
  onSubmit?: VoidFunction
}

export default function TabReglesMetiersPart({ onSubmit }: TabReglesMetiersPartProps) {
  const { ...methods } = useFormContext()
  const readOnly = !onSubmit
  const formId = 'form-parametres-regles-metier'
  return (
    <form onSubmit={onSubmit && methods.handleSubmit(onSubmit)} id={formId} name={formId}>
      <Grid container justifyContent="flex-start" alignItems="flex-start">
        <Grid item container md={12} lg={12}>
          <Grid item xs={12}>
            <ReglesMetiersPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <InformationsAssurePart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <ParametragesAvisTechniquePart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <ActivationEvenementPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <ParametrageFichesConclusionsPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <ParametrageCarteMedecinPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <DomainesContractuelsPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <InformationsContactPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <IdentificationDossierPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <GestionRemissionnementPart readOnly={readOnly} />
          </Grid>
          {!readOnly && (
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="right">
                <SauvegarderButton onClick={onSubmit}>Sauvegarder</SauvegarderButton>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  )
}
