import { useRef } from 'react'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { StyledDropzoneProps } from 'plateforme/components/inputs/StyledDropzone'
import { Upload, UploaderState, UploadFileMap } from '../slices/uploadSlice'
import { useAppDispatch, useAppSelector } from './hooks'
import { RootState } from '../store'

function mergeUploads<TUpload extends Upload>(local: UploadFileMap, remote: TUpload[]): UploadFileMap {
  return remote
    ?.map(({ key }) => [key, local[key]] as [string, File])
    .reduce((acc, [key, file]) => Object.assign(acc, { [key]: file }), {})
}

type UseUploadConfig<TUpload> = {
  uploadSliceName: string
  makeUpload: (file: File, typeDocument?: string) => TUpload
  typeDocument?: string
  addUploadAction: ActionCreatorWithPayload<TUpload[]>
  maxSize: number
  maxFiles?: number
}

export default function useUpload<TUpload extends Upload>({
  uploadSliceName,
  makeUpload,
  typeDocument,
  addUploadAction,
  maxSize,
  maxFiles,
}: UseUploadConfig<TUpload>) {
  const filesMapRef = useRef<UploadFileMap>({})
  const uploaderState = useAppSelector(
    (rootState) => rootState[uploadSliceName as keyof RootState] as UploaderState<TUpload>
  )
  const dispatch = useAppDispatch()

  const onNewUploads = (files: File[]) => {
    const [newUploads, newFilesMap] = files
      .map((file) => [file, makeUpload(file, typeDocument)] as [File, TUpload])
      .reduce(
        ([_newUploads, _newFilesMap], [file, upload]) => [
          _newUploads.concat(upload),
          Object.assign(_newFilesMap, { [upload.key]: file }),
        ],
        [[], {}] as [TUpload[], UploadFileMap]
      )
    filesMapRef.current = { ...filesMapRef.current, ...newFilesMap }
    dispatch(addUploadAction(newUploads))
  }

  filesMapRef.current = mergeUploads(filesMapRef.current, uploaderState.uploads)

  const styledDropzoneProps: Pick<StyledDropzoneProps, 'maxSize' | 'maxFiles' | 'disabled' | 'onDropAccepted'> = {
    onDropAccepted: onNewUploads,
    disabled: uploaderState.isRunning,
    maxSize,
    maxFiles,
  }
  return { filesMapRef, uploaderState, styledDropzoneProps }
}
