import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React from 'react'
import { EnumTypesFichesConclusions } from 'admin/store/types/profilEntrepriseAdmin'
import { useFormContext } from 'react-hook-form'
import ParametrageFichesConclusionsRow from './ParametrageFichesConclusionsRow'

export const FICHE_TYPES = [
  { code: EnumTypesFichesConclusions.CONV, label: 'Fiche droit commun' },
  { code: EnumTypesFichesConclusions.SIMP, label: 'Fiche simplifiée' },
]

interface ParametrageFichesConclusionsPartProps {
  readOnly?: boolean
}

export default function ParametrageFichesConclusionsPart({ readOnly }: ParametrageFichesConclusionsPartProps) {
  const { watch } = useFormContext()

  return (
    <Card>
      <CardHeader title="Paramétrage des fiches de conclusions" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Expertise unilatérale</TableCell>
                    <TableCell>Expertise conjointe</TableCell>
                    <TableCell>Ass. Exp judiciaire</TableCell>
                    <TableCell>Ass. Arbitrage</TableCell>
                    {watch('parametrage.expertiseSurPiece') && <TableCell>Avis techniques sur pièces</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {watch('parametrage.naturesEvenements.BIRC') && (
                    <ParametrageFichesConclusionsRow
                      controllerName="fichesConclusions.BIRC"
                      rowTitle="BADINTER IRCA"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.BNIR') && (
                    <ParametrageFichesConclusionsRow
                      controllerName="fichesConclusions.BNIR"
                      rowTitle="BADINTER NON IRCA"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.RCGE') && (
                    <ParametrageFichesConclusionsRow
                      controllerName="fichesConclusions.RCGE"
                      rowTitle="RC GENERALE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.RCMD') && (
                    <ParametrageFichesConclusionsRow
                      controllerName="fichesConclusions.RCMD"
                      rowTitle="RC MEDICALE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.ASSC') && (
                    <ParametrageFichesConclusionsRow
                      controllerName="fichesConclusions.ASSC"
                      rowTitle="ASS CONDUCTEUR"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.PREV') && (
                    <ParametrageFichesConclusionsRow
                      controllerName="fichesConclusions.PREV"
                      rowTitle="PREVOYANCE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.ACCV') && (
                    <ParametrageFichesConclusionsRow
                      controllerName="fichesConclusions.ACCV"
                      rowTitle="ACCIDENT DE LA VIE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.FAUT') && (
                    <ParametrageFichesConclusionsRow
                      controllerName="fichesConclusions.FAUT"
                      rowTitle="FAUTE INEXCUSABLE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.AUTR') && (
                    <ParametrageFichesConclusionsRow
                      controllerName="fichesConclusions.AUTR"
                      rowTitle="AUTRE"
                      readOnly={readOnly}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
