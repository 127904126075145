import React from 'react'
import { useParams } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import { Box } from '@mui/material'
import NavigationDossierPart from 'plateforme/parts/NavigationDossierPart'
import { FallbackError } from 'plateforme/components/dialog/ErrorFallback'
import { trimToUndefined } from 'plateforme/services/utils'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import { useGetDossierByIdQuery } from 'assureur/store/apis/dossierAssureurApi'
import TabsConsultationDossierEntreprise from 'assureur/parts/consultationDossier/TabsConsultationDossierEntreprise'
import { determineDerniereMission } from 'assureur/services/dossierEntreprise.services'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'

export default function ConsultationDossierEntreprisePage() {
  const { codeDossier } = useParams()
  const handleError = useErrorHandler()
  if (codeDossier === undefined) {
    throw new Error('Aucun code dossier défini')
  }

  const {
    data: utilisateur,
    error: utilisateurError,
    isError: isUtilisateurError,
    isLoading: isUtilisateurLoading,
    isFetching: isUtilisateurFetching,
  } = useGetUtilisateurActifQuery()

  // handle error of useGetUtilisateurActifQuery:
  if (isUtilisateurError) {
    handleError({
      errorApi: utilisateurError,
      title: 'Erreur chargement des données du profil actif',
      api: 'GetDossierByIdQuery',
    } as FallbackError)
  }

  const {
    data: dossier,
    error: dossierError,
    isError: isDossierError,
    isLoading: isDossierLoading,
    isFetching: isDossierFetching,
  } = useGetDossierByIdQuery({ codeDossier })

  // handle error of GetDossierByIdQuery:
  if (isDossierError) {
    handleError({
      errorApi: dossierError,
      title: 'Erreur chargement des données du dossier',
      api: 'GetDossierByIdQuery',
    } as FallbackError)
  }

  const {
    data: profilEntreprise,
    error: profilEntrepriseError,
    isError: isProfilEntrepriseError,
    isLoading: isProfilEntrepriseLoading,
    isFetching: isProfilEntrepriseFetching,
  } = useGetProfilEntrepriseQuery()

  if (isProfilEntrepriseError) {
    handleError({
      errorApi: profilEntrepriseError,
      title: 'Erreur chargement des données profil entreprise',
      api: 'GetProfilEntreprise',
    } as FallbackError)
  }

  const derniereMission = determineDerniereMission(dossier)
  const profilUtilisateur = utilisateur?.profilActif
  const libelleReferenceDossier =
    trimToUndefined(derniereMission?.libelle) !== undefined ? derniereMission?.libelle : dossier?.libelle
  const complementReferenceDossier = dossier?.entreprise?.libelle

  const loading =
    isDossierLoading ||
    isDossierFetching ||
    isUtilisateurLoading ||
    isUtilisateurFetching ||
    isProfilEntrepriseLoading ||
    isProfilEntrepriseFetching

  return (
    <>
      <NavigationDossierPart
        libelleReferenceDossier={libelleReferenceDossier}
        complementReferenceDossier={complementReferenceDossier}
        missions={dossier?.missions}
        codeDossier={dossier?.code}
      />
      <Box marginTop={2}>
        <TabsConsultationDossierEntreprise
          dossier={dossier}
          derniereMission={derniereMission}
          profilEntreprise={profilEntreprise}
          profilUtilisateur={profilUtilisateur}
          loading={loading}
        />
      </Box>
    </>
  )
}
