import React from 'react'
import { Alert, Grid } from '@mui/material'
import { InfiniteScrollComponent, ResultatRechercheBarCard } from 'plateforme/components'
import { SortParam, SortOption } from 'plateforme/store/types/pageRecherche'
import ProfilMedecinAdmin, { MedecinAdminSortType } from 'admin/store/types/profilMedecinAdmin'
import MedecinAdminCard from '../MedecinAdminCard'

type ListeMedecinsAdminPartProps = {
  combinedData: Array<ProfilMedecinAdmin>
  total?: number
  readMore: VoidFunction
  sortBy: (param: SortParam<MedecinAdminSortType>) => void
  initSort: VoidFunction
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
}

export default function ListeMedecinsAdminPart({
  combinedData,
  total,
  readMore,
  sortBy,
  initSort,
  isError,
  isLoading,
  isFetching,
  isSuccess,
}: ListeMedecinsAdminPartProps) {
  // les options de tri disponibles a passer au composant SortComponentMenu
  const sortOptions: SortOption<MedecinAdminSortType>[] = [
    { sortType: MedecinAdminSortType.NOM_MEDECIN_SORT, label: 'Nom du médecin' },
    { sortType: MedecinAdminSortType.CODE_MEDECIN_SORT, label: 'Identifiant médecin plateforme' },
    { sortType: MedecinAdminSortType.DATE_INSCRIPTION_SORT, label: `Date d'inscription` },
  ]

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}
    >
      <Grid item container xs={12} paddingBottom={2.5}>
        <ResultatRechercheBarCard
          title="Liste des médecins référencés"
          total={total}
          loading={isLoading || isFetching}
          sortBy={sortBy}
          sortOptions={sortOptions}
          initSort={initSort}
          withSorting
          withExportCSV
        />
      </Grid>
      {(isError && !isSuccess && !isLoading && !isFetching) || total === 0 ? (
        <Alert severity="info" sx={{ width: '100%' }}>
          Aucun médecin remonté
        </Alert>
      ) : (
        <InfiniteScrollComponent
          dataLength={combinedData?.length}
          next={readMore}
          hasMore={combinedData.length < (total ?? 0)}
          isLoading={isLoading || isFetching}
          xs={12}
          lg={6}
        >
          <Grid item container xs={12}>
            {combinedData?.map((medecin: ProfilMedecinAdmin) => (
              <Grid item xs={12} lg={6} key={medecin.code}>
                <MedecinAdminCard key={medecin.code} medecin={medecin} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScrollComponent>
      )}
    </Grid>
  )
}
