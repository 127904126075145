import React, { useEffect, useState } from 'react'
import useUpload from 'plateforme/store/hooks/useUpload'
import { ALLOWED_UPLOAD_FORMATS, MAX_UPLOAD_SIZE, MSG_FIELD_REQUIRED } from 'plateforme/constantes'
import { useAppDispatch } from 'plateforme/store/hooks/hooks'
import { Alert, Card, CardContent, CardHeader, Chip, Grid, Stack, Switch } from '@mui/material'
import StyledDropzone from 'plateforme/components/inputs/StyledDropzone'
import { Montant, FactureRequest } from 'medecin/store/types/factureMedecin'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { EditTextField, DatePickerInput, SelectInput, AnnulerButton, SauvegarderButton } from 'plateforme/components'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import useErrorFormMapper from 'plateforme/hooks/useErrorFormMapper'
import { UploadState } from 'plateforme/store/slices/uploadSlice'
import {
  addDocumentFactureUpload,
  clearDocumentFactureUpload,
  documentFactureUploadSliceName,
  startUploadDocumentFacture,
} from 'medecin/store/slices/document/documentFactureUploadSlice'
import makeUpload from 'plateforme/services/document.services'
import { SelectOption } from 'plateforme/components/inputs/SelectInput'
import { dossierMedecinApi } from 'medecin/store/apis/dossierMedecinApi'
import { toArray, trimToUndefined } from 'plateforme/services/utils'
import { useSnackbar } from 'notistack'
import UploadRowFacture from './UploadRowFacture'
import MontantsFactureForm from './MontantsFactureForm'

type FactureFormProps = {
  dossier: DossierMedecin
  cacherFormulaire: VoidFunction
}

type FactureData = {
  codeMission?: string
  file?: string
  numeroFacture?: string
  dateFacture: Date
  montantHonoraires?: MontantData
  montantFrais?: MontantData
}

type MontantData = {
  montantTTC?: string
  montantTVA?: string
  montantHT?: string
  tauxTVA?: string
}

const toMontant = (montantData?: MontantData | undefined) => {
  return {
    montantHT: montantData?.montantHT,
    tauxTVA: montantData?.tauxTVA,
    montantTVA: montantData?.montantTVA,
    montantTTC: montantData?.montantTTC,
  } as Montant
}

const FORM_ID = 'form-facture-mission'

export default function FactureForm({ dossier, cacherFormulaire }: FactureFormProps) {
  const { code: codeDossier, missions } = dossier

  const missionsFacturables = missions?.filter((m) => m.facturable === true) || []

  const initialCodeMission = missionsFacturables.length === 1 ? missionsFacturables[0].code : ''

  const initialTaux = [
    { code: '20', label: '20 %' },
    { code: '5.5', label: '5,5 %' },
    { code: '0', label: '0 %' },
  ]

  const initialValues: FactureData = {
    numeroFacture: '',
    file: undefined,
    codeMission: initialCodeMission,
    dateFacture: new Date(),
    montantHonoraires: {
      montantHT: '',
      tauxTVA: '20',
      montantTVA: '',
      montantTTC: '0',
    },
    montantFrais: {
      montantHT: '',
      tauxTVA: '20',
      montantTVA: '',
      montantTTC: '0',
    },
  }

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })
  const { enqueueSnackbar } = useSnackbar()

  const [showFrais, setShowFrais] = useState(false)

  const { filesMapRef, styledDropzoneProps, uploaderState } = useUpload({
    uploadSliceName: documentFactureUploadSliceName,
    makeUpload,
    typeDocument: 'FA',
    addUploadAction: addDocumentFactureUpload,
    maxSize: MAX_UPLOAD_SIZE,
    maxFiles: 1,
  })
  const dispatch = useAppDispatch()

  const document = uploaderState.uploads[0]

  useErrorFormMapper(
    { status: document?.error ? 400 : 200, data: document?.error },
    methods.setError,
    methods.getValues
  )

  useEffect(() => {
    dispatch(clearDocumentFactureUpload())
  }, [dispatch])

  useEffect(() => {
    methods.clearErrors('file')
  }, [uploaderState, methods])

  useEffect(() => {
    if (document?.error ?? 0 >= 400) {
      enqueueSnackbar(`${document?.error?.message}`, {
        variant: 'error',
      })
    }
    if (document?.state === UploadState.DONE && !document?.error) {
      enqueueSnackbar(`La facture a été enregistrée avec succès`, { variant: 'success' })
      methods.reset(initialValues)
      dispatch(clearDocumentFactureUpload())
      methods.clearErrors('file')
      cacherFormulaire()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document])

  const onSubmit = async (data: FactureData) => {
    const codeMission = trimToUndefined(data.codeMission)
    methods.clearErrors('file')
    if (codeDossier === undefined || codeMission === undefined) {
      throw new Error('Aucun code dossier défini')
    }
    if (toArray(filesMapRef.current).length < 1) {
      methods.setError('file', { type: 'global', message: 'Un fichier est obligatoire pour la facture' })
      return
    }

    const facture: FactureRequest = {
      codeDossier,
      codeMission,
      numeroFacture: trimToUndefined(data.numeroFacture),
      dateFacture: data.dateFacture,
      montantHonoraires: toMontant(data.montantHonoraires),
      ...(showFrais && { montantFrais: toMontant(data.montantFrais) }),
    }

    await dispatch(
      startUploadDocumentFacture({
        facture,
        uploadFileMap: filesMapRef.current,
      })
    )
    dispatch(
      dossierMedecinApi.util.invalidateTags([
        { type: 'Dossier', id: codeDossier },
        { type: 'Mission', id: codeMission },
      ])
    )
  }

  const onClear = () => {
    methods.clearErrors('file')
    dispatch(clearDocumentFactureUpload())
    methods.reset(initialValues)
  }

  return (
    <Card>
      <CardHeader title="Ajouter une facture" />
      <CardContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} id={FORM_ID}>
            <Grid container direction="row" alignItems="flex-start">
              <Grid item lg={6} md={12} xs={12}>
                <Controller
                  name="codeMission"
                  control={methods.control}
                  rules={{ required: MSG_FIELD_REQUIRED }}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <SelectInput
                      value={value}
                      id="mission-select"
                      label="Choix de mission"
                      onBlur={onBlur}
                      onChange={onChange}
                      options={missionsFacturables.map((m) => {
                        return {
                          code: m.code,
                          label: `${m.refMissionEntreprise ?? m.code} ${
                            m.refMissionMedecin ? ` / ${m.refMissionMedecin}` : ''
                          }`,
                        } as SelectOption
                      })}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={6}>
                <Controller
                  control={methods.control}
                  name="numeroFacture"
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      label="Numéro de facture"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      fieldError={error}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={6}>
                <Controller
                  name="dateFacture"
                  control={methods.control}
                  render={({ field: { ref: _, onBlur, onChange, value }, fieldState: { error } }) => (
                    <DatePickerInput
                      label="Date de la facture"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      InputProps={{
                        id: 'date-facture',
                        fullWidth: true,
                      }}
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <MontantsFactureForm specificLabel="Honoraires" suffixName="montantHonoraires" taux={initialTaux} />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Switch onChange={() => setShowFrais(!showFrais)} /> Préciser un montant de frais
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                {showFrais && (
                  <MontantsFactureForm specificLabel="Frais" suffixName="montantFrais" taux={initialTaux} />
                )}
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Stack>
                  <StyledDropzone
                    {...styledDropzoneProps}
                    accept={ALLOWED_UPLOAD_FORMATS}
                    buttonText="Ajouter le fichier de facture"
                    multiple={false}
                    onDropAccepted={(file) => {
                      dispatch(clearDocumentFactureUpload())
                      styledDropzoneProps.onDropAccepted(file)
                    }}
                  />
                  {uploaderState.uploads.map((upload) => (
                    <Stack key={upload.key} direction="row" alignItems="start">
                      <UploadRowFacture documentUpload={upload} disabled={uploaderState.isRunning} />
                      {upload.state === UploadState.DONE && !upload.error && <Chip label="succès" color="success" />}
                    </Stack>
                  ))}

                  <Controller
                    name="file"
                    control={methods.control}
                    render={({ field: { ref: _ }, fieldState: { error } }) =>
                      error ? <Alert severity="error">{error.message}</Alert> : <span />
                    }
                  />

                  {/* globalErrors.length > 0 && (
                    <Alert severity="error">
                      <AlertTitle>
                        {uploaderState.uploads[0]?.error?.code} : {document?.error?.message}
                      </AlertTitle>
                      <ul>
                        {Object.entries(globalErrors).map(([key, value]) => (
                          <li key={key}>{value?.message}</li>
                        ))}
                      </ul>
                    </Alert>
                  ) */}
                  <Stack direction="row" justifyContent="right">
                    {!uploaderState.isRunning && (
                      <>
                        <AnnulerButton
                          onClick={() => {
                            onClear()
                            cacherFormulaire()
                          }}
                        >
                          Annuler
                        </AnnulerButton>
                        <SauvegarderButton type="submit">Enregistrer la facture</SauvegarderButton>
                      </>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  )
}
