import React from 'react'
import { Stack } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'

import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'

import ListeMedecinsEntreprisePart from 'assureur/parts/rechercheMedecin/ListeMedecinsEntreprisePart'
import RechercheMedecinsEntreprisePart from 'assureur/parts/rechercheMedecin/RechercheMedecinsEntreprisePart'
import { useGetListeMedecinsEntrepriseHabilitableQuery } from 'assureur/store/apis/medecinEntrepriseApi'

export default function RechercheMedecinsEntreprisePage() {
  const {
    combinedData,
    exportParams,
    readMore,
    remoteTotal,
    search,
    initSearch,
    initSort,
    sortBy,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetListeMedecinsEntrepriseHabilitableQuery, {})

  return (
    <Stack>
      <NavigationPart label="Liste des médecins" />
      <RechercheMedecinsEntreprisePart initSearch={initSearch} search={search} searchError={error} />
      <ListeMedecinsEntreprisePart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        isSuccess={isSuccess}
        exportParams={exportParams}
      />
    </Stack>
  )
}
