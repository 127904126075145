import React from 'react'
import uuid from 'react-uuid'
import { Box, Grid, Stack, Typography } from '@mui/material'
import ReadOnlyDatePicker from 'plateforme/components/inputs/ReadOnlyDatePicker'
import { Periode, PlageDeDate } from 'assureur/store/types/dommagesEntreprise'

interface ReadOnlyDommagePeriodeProps {
  plageDeDate?: PlageDeDate
  label?: string
}

export default function ReadOnlyDommagePeriode({ plageDeDate, label }: ReadOnlyDommagePeriodeProps) {
  const periodes = plageDeDate?.periodes as Periode[]

  return (
    <Grid item display="flex" flexDirection="column">
      <Typography marginLeft={2} marginBottom={2} color="primary" variant="subtitle2">
        {`${label} :`}
      </Typography>
      <Box>
        <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
          {periodes &&
            periodes.length > 0 &&
            periodes.map((item, index) => {
              return (
                <li key={uuid()}>
                  <Stack direction="row" spacing={3.5} marginBottom={index === periodes.length - 1 ? 0 : 2.5}>
                    <ReadOnlyDatePicker
                      InputProps={{
                        id: `date-debut-${index}`,
                        fullWidth: true,
                      }}
                      value={item.du === undefined ? null : item.du}
                      label="Du"
                    />
                    <ReadOnlyDatePicker
                      InputProps={{
                        id: `date-debut-${index}`,
                        fullWidth: true,
                      }}
                      value={item.au === undefined ? null : item.au}
                      label="Au"
                    />
                  </Stack>
                </li>
              )
            })}
        </ul>
      </Box>
    </Grid>
  )
}
