import React, { useEffect } from 'react'
import { Controller, FieldError, useFieldArray, useFormContext } from 'react-hook-form'
import { addDays } from 'date-fns'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { AjouterIconButton, DatePickerInput, EditTextField, SupprimerIconButton } from 'plateforme/components'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import { isRapportConclusionDefinitive } from 'plateforme/services/rapport.services'
import { ModeDommage, UniteEstimation } from 'medecin/store/types/rapportConclusionMedecin'
import { PeriodeData } from '../FicheRapportMedecinForm'

interface DommageFieldRadioGroupProps {
  typeConclusion: TypeRapport
  name: string
  code: string
  readOnly?: boolean
  max?: number
}

function UniteRadioGroup({
  disabled,
  error,
  onBlur,
  onChange,
  value,
}: {
  disabled: boolean | undefined
  error: FieldError | undefined
  value: UniteEstimation
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  onBlur: VoidFunction
}) {
  return (
    <FormControl disabled={disabled} error={!!error} variant="standard" fullWidth>
      <RadioGroup
        row
        aria-labelledby="demo-error-radios"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        sx={{ width: '200px' }}
      >
        <FormControlLabel value={UniteEstimation.JOUR} control={<Radio />} label="Jours" sx={{ width: '80px' }} />
        <FormControlLabel
          value={UniteEstimation.SEMAINE}
          control={<Radio />}
          label="Semaines"
          sx={{ width: '110px' }}
        />
        <FormControlLabel value={UniteEstimation.MOIS} control={<Radio />} label="Mois" sx={{ width: '80px' }} />
        <FormControlLabel value={UniteEstimation.ANNEE} control={<Radio />} label="Années" sx={{ width: '110px' }} />
      </RadioGroup>
      <FormHelperText sx={{ margin: '3px 14px' }}>{error?.message}</FormHelperText>
    </FormControl>
  )
}

export default function DommageFieldRadioGroup({
  typeConclusion,
  name,
  code,
  readOnly,
  max,
}: DommageFieldRadioGroupProps) {
  const fieldName = `${name}.plageDeDates`
  const fieldArrayName = `${fieldName}.periodes`
  const { control, watch, getValues, setValue, setError, clearErrors, getFieldState, resetField } = useFormContext()
  const modeDommage = isRapportConclusionDefinitive(typeConclusion) ? ModeDommage.PERIODE : watch(`${fieldName}.mode`)
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldArrayName,
  })
  const { error: groupError } = getFieldState(fieldName)

  useEffect(() => {
    if (isRapportConclusionDefinitive(typeConclusion)) {
      setValue(`${fieldName}.mode`, ModeDommage.PERIODE)
    }
  }, [typeConclusion, setValue, fieldName])

  useEffect(() => {
    if (groupError?.message) {
      setError(`${fieldName}.mode`, groupError)
    }
  }, [groupError, fieldName, setError])

  function addTooltip(index: number) {
    if (fields.length === max) {
      return `Le nombre maximum de périodes est de ${max}`
    }
    return index === fields.length - 1 ? 'Ajouter une période' : ''
  }

  function addDisabled() {
    return fields.length === max
  }

  function addOnClick() {
    return () => {
      let du: Date | null = null
      if (fields.length > 0) {
        const lastAu = getValues(`${fieldArrayName}.${fields.length - 1}.au`)
        du = lastAu ? addDays(new Date(lastAu), 1) : null
      }

      append({ du, au: null } as PeriodeData)
    }
  }

  function removeTooltip() {
    return fields.length <= 1 ? 'Au minimum, une période est requise' : 'Retirer'
  }

  function removeDisabled() {
    return fields.length <= 1
  }

  function removeOnClick(index: number) {
    return () => remove(index)
  }

  return (
    <>
      {!isRapportConclusionDefinitive(typeConclusion) && (
        <Stack direction="row" alignItems="flex-start" spacing={2} paddingBottom={1.5}>
          <Controller
            name={`${fieldName}.mode`}
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <>
                <Typography variant="subtitle2" color={error ? 'error' : 'primary'} marginTop={2}>
                  Type :
                </Typography>
                <FormControl
                  disabled={readOnly}
                  error={!!error}
                  variant="standard"
                  sx={{ minHeight: 55, padding: 0, marginX: 1.5, display: 'flex', alignItems: 'flex-start' }}
                >
                  <RadioGroup row aria-labelledby="type-radios" value={value} onBlur={onBlur} onChange={onChange}>
                    <FormControlLabel
                      value={ModeDommage.PERIODE}
                      control={
                        <Radio
                          sx={{
                            color: `${error ? 'red' : ''}`,
                          }}
                        />
                      }
                      label="Périodes"
                      sx={{ minHeight: 55 }}
                    />
                    <FormControlLabel
                      value={ModeDommage.FOURCHETTE}
                      control={<Radio />}
                      label="Fourchette"
                      sx={{ minHeight: 55 }}
                    />
                    <FormControlLabel
                      value={ModeDommage.ESTIMATION}
                      control={<Radio />}
                      label="Estimation"
                      sx={{ minHeight: 55 }}
                    />
                  </RadioGroup>
                  <FormHelperText sx={{ margin: '3px 14px' }}>{error?.message}</FormHelperText>
                </FormControl>
              </>
            )}
          />
        </Stack>
      )}

      {modeDommage === ModeDommage.PERIODE && (
        <ul style={{ listStyle: 'none', padding: 0, margin: 0, paddingTop: '14px', minHeight: 87 }}>
          {fields.map((item, index) => {
            return (
              <li key={`${code}_${item.id}`}>
                <Stack direction="row" spacing={3.5} marginBottom={index === fields.length - 1 ? 0 : 2.5}>
                  <Controller
                    name={`${fieldArrayName}.${index}.du`}
                    control={control}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <DatePickerInput
                        InputProps={{
                          id: `${fieldArrayName}.${index}.du`,
                          fullWidth: true,
                        }}
                        label="Du"
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          const relatedFieldName = `${fieldArrayName}.${index}.au`
                          clearErrors(relatedFieldName)
                          resetField(relatedFieldName, {
                            defaultValue: watch(relatedFieldName),
                          })
                          onChange(e)
                        }}
                        readOnly={readOnly}
                        fieldError={error}
                      />
                    )}
                  />

                  <Controller
                    name={`${fieldArrayName}.${index}.au`}
                    control={control}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <DatePickerInput
                        InputProps={{
                          id: `${fieldArrayName}.${index}.au`,
                          fullWidth: true,
                        }}
                        label="Au"
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          const relatedFieldName = `${fieldArrayName}.${index}.du`
                          clearErrors(relatedFieldName)
                          resetField(relatedFieldName, {
                            defaultValue: watch(relatedFieldName),
                          })
                          onChange(e)
                        }}
                        readOnly={readOnly}
                        fieldError={error}
                      />
                    )}
                  />
                  {!readOnly && (
                    <Box height="60px" paddingTop="12px" marginLeft="8px !important">
                      <SupprimerIconButton
                        onClick={removeOnClick(index)}
                        disabled={removeDisabled()}
                        tooltip={removeTooltip()}
                      />
                    </Box>
                  )}
                  {!readOnly && (
                    <Box height="60px" minWidth="40px" paddingTop="10px" marginLeft="8px !important">
                      {index === fields.length - 1 ? (
                        <AjouterIconButton
                          title="Ajouter"
                          onClick={addOnClick()}
                          disabled={addDisabled()}
                          tooltip={addTooltip(index)}
                        />
                      ) : (
                        ' '
                      )}
                    </Box>
                  )}
                </Stack>
              </li>
            )
          })}
        </ul>
      )}

      {modeDommage === ModeDommage.FOURCHETTE && (
        <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center" justifyContent="flex-start">
              <Controller
                name={`${fieldName}.min.valeur`}
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    readOnly={readOnly}
                    fullWidth
                    type="number"
                    id={`id-gtt-estimation-min-${code}`}
                    label="Entre"
                    value={value}
                    onChange={(e) => {
                      const relatedFieldName = `${fieldName}.max.valeur`
                      clearErrors(relatedFieldName)
                      resetField(relatedFieldName, {
                        defaultValue: watch(relatedFieldName),
                      })
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    fieldError={error}
                  />
                )}
              />
              <Controller
                name={`${fieldName}.min.unite`}
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <UniteRadioGroup
                    disabled={readOnly}
                    error={error}
                    value={value}
                    onChange={(e) => {
                      const relatedFieldName = `${fieldName}.max.valeur`
                      clearErrors(relatedFieldName)
                      resetField(relatedFieldName, {
                        defaultValue: watch(relatedFieldName),
                      })
                      onChange(e)
                    }}
                    onBlur={onBlur}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center" justifyContent="flex-start">
              <Controller
                name={`${fieldName}.max.valeur`}
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    readOnly={readOnly}
                    fullWidth
                    type="number"
                    id={`id-gtt-estimation-max-${code}`}
                    label="Et"
                    value={value}
                    onChange={(e) => {
                      const relatedFieldName = `${fieldName}.min.valeur`
                      clearErrors(relatedFieldName)
                      resetField(relatedFieldName, {
                        defaultValue: watch(relatedFieldName),
                      })
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    fieldError={error}
                  />
                )}
              />
              <Controller
                name={`${fieldName}.max.unite`}
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <UniteRadioGroup
                    disabled={readOnly}
                    error={error}
                    value={value}
                    onChange={(e) => {
                      const relatedFieldName = `${fieldName}.min.valeur`
                      clearErrors(relatedFieldName)
                      resetField(relatedFieldName, {
                        defaultValue: watch(relatedFieldName),
                      })
                      onChange(e)
                    }}
                    onBlur={onBlur}
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>
      )}

      {modeDommage === ModeDommage.ESTIMATION && (
        <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center" justifyContent="flex-start">
              <Controller
                name={`${fieldName}.estimation.valeur`}
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    readOnly={readOnly}
                    fullWidth
                    type="number"
                    id={`id-gtt-estimation-${code}`}
                    label="Nombre entier"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
              <Controller
                name={`${fieldName}.estimation.unite`}
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <UniteRadioGroup
                    disabled={readOnly}
                    error={error}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                )}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
    </>
  )
}
