import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material'
import { ReadOnlyTextField, SupprimerIconButton } from 'plateforme/components'
import { formatDateFR } from 'plateforme/services/dates.services'
import { StatutMission } from 'plateforme/store/types/mission'
import RechercheMedecinsComponent from 'assureur/parts/rechercheMedecin/RechercheMedecinsComponent'
import { getSelectedMedecin, setSelectedMedecin } from 'assureur/store/slices/selectedMedecin/selectedMedecinSlice'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import { useGetMedecinsPartenaireQuery } from 'assureur/store/apis/medecinEntrepriseApi'
import HabilitationEntreprise from 'assureur/store/types/habilitationEntreprise'
import isMissionPrecedenteValide from 'assureur/services/missionEntreprise.service'

type ChoixMedecinPartProps = {
  dossier?: DossierEntreprise
  missionPrecedente?: MissionEntreprise
}

export default function ChoixMedecinPart({ dossier, missionPrecedente }: ChoixMedecinPartProps) {
  const { control, watch } = useFormContext()
  const dispatch = useDispatch()
  const selectedMedecin = useSelector(getSelectedMedecin)
  const theme = useTheme()
  const { data: medecins } = useGetMedecinsPartenaireQuery({ actif: 1 })

  const isMedecinPrecedent = watch('medecinPrecedent')
  const isMedecinSelected = !!selectedMedecin?.code
  let labelMedecinPrecedent = ''
  let isMissionPrecedenteDisable = false

  const libelleMedecinsRefuses =
    dossier?.missions
      ?.filter((m) => m.statut === StatutMission.REFUSEE)
      .map((m) => m.medecin?.libelle)
      .filter((l, i, a) => a.findIndex((t) => t === l) === i)
      .join(', ') ?? ''

  const handleRemoveMedecin = () => {
    dispatch(
      setSelectedMedecin({
        code: undefined,
        codeSIREN: undefined,
        codeRPPS: undefined,
        libelle: undefined,
        mailContact: undefined,
        adresseComplete: undefined,
        telephoneFixe: undefined,
        telephoneMobile: undefined,
      })
    )
  }

  const getDetailMissionPrecedente = () => {
    return ` (${missionPrecedente?.medecin?.libelle} - Mission ${missionPrecedente?.labelStatut} le ${formatDateFR(
      missionPrecedente?.dateMiseAJour
    )})`
  }

  if (missionPrecedente) {
    const habilitation: HabilitationEntreprise | undefined = medecins?.find(
      (m) => m.code === missionPrecedente?.medecin?.code
    )
    if (habilitation !== undefined) {
      // si médecin avec habilitation active
      if (habilitation.statut === 'ACTIVE') {
        labelMedecinPrecedent = `Remissionner le médecin précédent ${getDetailMissionPrecedente()}`
      }

      // si le médecin n'est plus habilité
      else {
        isMissionPrecedenteDisable = true
        labelMedecinPrecedent = `Pour pouvoir remissionner le médecin précédent ${getDetailMissionPrecedente()}, veuillez d'abord réactiver son habilitation`
      }
    }
  }

  // NOTE: initialisation de la state autreMedecin
  useEffect(() => {
    if (isMedecinPrecedent) {
      dispatch(
        setSelectedMedecin({
          code: missionPrecedente?.medecin?.code,
          libelle: missionPrecedente?.medecin?.libelle,
          codeRPPS: missionPrecedente?.medecin?.codeRPPS,
          codeSIREN: missionPrecedente?.medecin?.codeSIREN,
        })
      )
    } else {
      dispatch(
        setSelectedMedecin({
          code: undefined,
          libelle: undefined,
          codeRPPS: undefined,
          codeSIREN: undefined,
        })
      )
    }
  }, [
    dispatch,
    isMedecinPrecedent,
    missionPrecedente?.medecin?.code,
    missionPrecedente?.medecin?.libelle,
    missionPrecedente?.medecin?.codeRPPS,
    missionPrecedente?.medecin?.codeSIREN,
  ])

  const makeMedecinData = () => {
    if (isMedecinSelected) {
      return {
        libelle: selectedMedecin.libelle,
        codeRPPS: selectedMedecin.codeRPPS,
        codeSIREN: selectedMedecin.codeSIREN,
      }
    }
    if (isMedecinPrecedent) {
      return {
        libelle: missionPrecedente?.medecin?.libelle,
        codeRPPS: missionPrecedente?.medecin?.codeRPPS,
        codeSIREN: missionPrecedente?.medecin?.codeSIREN,
      }
    }
    return undefined
  }

  const medecinData = makeMedecinData()

  return (
    <Card>
      <CardHeader title="Choix du médecin à missionner" />
      <CardContent>
        <Grid container>
          {isMissionPrecedenteValide(missionPrecedente, medecins) && (
            <Grid item xs={12}>
              <Controller
                name="medecinPrecedent"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <FormControl error={!!error} variant="standard">
                    <FormControlLabel
                      id="medecinPrecedent"
                      value={value}
                      control={
                        <Switch
                          disabled={isMissionPrecedenteDisable}
                          onBlur={onBlur}
                          onChange={onChange}
                          checked={value}
                        />
                      }
                      label={labelMedecinPrecedent}
                    />
                    <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          )}
          {libelleMedecinsRefuses.length > 0 && (
            <Grid item xs={12}>
              <Typography
                margin={theme.spacing(0, 0, 0, 2)}
                sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
                gutterBottom
              >
                Sur ce dossier, les dernières missions précédentes ont été refusées par {libelleMedecinsRefuses}
              </Typography>
            </Grid>
          )}
          {(selectedMedecin.code !== undefined || watch('medecinPrecedent')) && (
            <>
              <Grid item xs={4}>
                <ReadOnlyTextField fullWidth id="libelle" value={medecinData?.libelle} label="Nom du médecin" />
              </Grid>
              <Grid item xs={4}>
                <ReadOnlyTextField fullWidth id="RPPS" value={medecinData?.codeRPPS} label="RPPS" />
              </Grid>
              <Grid item xs={4}>
                <Stack direction="row" spacing={3.5}>
                  <ReadOnlyTextField fullWidth id="SIREN" value={medecinData?.codeSIREN} label="SIREN" />
                  {!watch('medecinPrecedent') && (
                    <Box height="60px" paddingTop="12px" marginLeft="8px !important">
                      <SupprimerIconButton onClick={handleRemoveMedecin} tooltip="Retirer" />
                    </Box>
                  )}
                </Stack>
              </Grid>
            </>
          )}
          {!isMedecinPrecedent && !isMedecinSelected && (
            <RechercheMedecinsComponent withHabilitationActive cardsNavigable={false} />
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
