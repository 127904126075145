import React from 'react'
import { useSnackbar } from 'notistack'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import { FileInProgressIcon, TelechargerIconButton } from 'plateforme/components'
import { toHumanReadableFileSize } from 'plateforme/services/utils'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { AuthDownloadAction, AuthDownloadDocumentMessageRequest } from 'plateforme/store/types/utilisateur'
import { toTelechargementDocumentMessageHref } from 'plateforme/App'
import { usePostAuthDownloadMutation } from 'plateforme/store/apis/utilisateurApi'
import { MessageDuMedecinVersAssureur } from 'assureur/store/types/messageEntreprise'

type PiecesJointesTableProps = {
  messageMedecin: MessageDuMedecinVersAssureur
  nbLigneParPage: number
}

export default function PiecesJointesTable({
  messageMedecin,
  // le nombre de ligne sur la liste diffère en fonction de la page appelante (5 ou 10)
  nbLigneParPage,
}: PiecesJointesTableProps) {
  const codeMessage = messageMedecin.code
  const documents = messageMedecin.documents ?? []
  const [authDownload, { isLoading: isLoadingAuthDownload }] = usePostAuthDownloadMutation()
  const { enqueueSnackbar } = useSnackbar()

  const onDownload: (doc: DocumentTeleverse) => Promise<void> = async (doc: DocumentTeleverse) => {
    if (!codeMessage) {
      throw new Error('erreur inattendue')
    }

    const request: AuthDownloadDocumentMessageRequest = {
      action: AuthDownloadAction.TELECHARGER_DOCUMENT_MESSAGE,
      codeMessage,
      codeDocument: doc.code,
    }

    await authDownload(request)
      .unwrap()
      .then(() => {
        const href = toTelechargementDocumentMessageHref(codeMessage, doc.code)
        window.open(href, '_self')
      })
      .catch(() => {
        enqueueSnackbar(`Le téléchargement de la pièce jointe a échoué`, { variant: 'error' })
      })
  }

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(nbLigneParPage)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const total = documents?.length ?? 0

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom de la pièce jointe</TableCell>
              <TableCell>Format du fichier</TableCell>
              <TableCell>Taille du fichier</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {(documents ?? [])
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((document: DocumentTeleverse) => (
                <TableRow key={document.code}>
                  <TableCell component="th" scope="row">
                    {document.nomDocument}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {document.formatFichier}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {toHumanReadableFileSize(document.tailleFichier)}
                  </TableCell>
                  <TableCell>
                    {document.statut === 'ACTIF' ? (
                      <TelechargerIconButton
                        onClick={() => onDownload(document)}
                        loading={isLoadingAuthDownload}
                        tooltip
                        tooltipPlacement="bottom"
                      />
                    ) : (
                      <FileInProgressIcon tooltip tooltipPlacement="bottom" sx={{ width: 46 }} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={total}
        rowsPerPageOptions={[nbLigneParPage]}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}
