import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { Alert, Grid } from '@mui/material'
import {
  AnnulerButton,
  ButtonsStack,
  CheckboxInput,
  EditTextField,
  ReadOnlyTextField,
  SauvegarderButton,
  SelectInputParametrage,
  SelectInputNatureEvenement,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import Utilisateur from 'plateforme/store/types/utilisateur'
import { trimToUndefined, validateRequiredWithCondition } from 'plateforme/services/utils'
import { ParametrageNameEnum } from 'plateforme/components/inputs/SelectInputParametrage'
import { determineConsentementRequis } from 'plateforme/services/dossier.services'
import Referentiel from 'plateforme/store/types/referentiel'
import { AFFECTATION_CENTRALE_CODE, AFFECTATION_CENTRALE_LABEL, MSG_FIELD_REQUIRED } from 'plateforme/constantes'
import { editionDossierHref } from 'assureur/EntrepriseApp'
import { usePostCreerDossierMutation } from 'assureur/store/apis/dossierAssureurApi'
import ProfilEntreprise from 'assureur/store/types/profilEntreprise'
import { CreationDossierRequest, EtapeCreationParam } from 'assureur/store/types/dossierEntreprise'
import SelectInputCadreExpertise from 'assureur/components/SelectInputCadreExpertise'
import { setErrorDossier } from 'assureur/store/slices/dossier/errorDossierSlice'

type CreationDossierEntrepriseFormProps = {
  utilisateur?: Utilisateur
  profilEntreprise?: ProfilEntreprise
  referentiel?: Referentiel
  onClose?: VoidFunction
}

type CreationDossierEntrepriseFormValues = {
  refDossierEntreprise: string
  refVictimeEntreprise: string
  serviceEntreprise: string | null
  evenement: { nature: string | null }
  expertise: {
    cadreExpertise: string | null
  }
  consentement: boolean
  refConsentement: string
}

export default function CreationDossierEntrepriseForm({
  utilisateur,
  profilEntreprise,
  referentiel,
  onClose,
}: CreationDossierEntrepriseFormProps) {
  // error:
  if (!utilisateur || !profilEntreprise) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const profils = utilisateur.profils ?? []
  const { profilActif: profilUtilisateur } = utilisateur

  // default values:
  const defaultValues: CreationDossierEntrepriseFormValues = {
    refDossierEntreprise: '',
    refVictimeEntreprise: '',
    serviceEntreprise: profilUtilisateur?.codeService ?? AFFECTATION_CENTRALE_CODE,
    evenement: {
      nature: null,
    },
    expertise: {
      cadreExpertise: null,
    },
    consentement: false,
    refConsentement: '',
  }

  // hooks:
  const [creerDossierEntreprise, { error: errorSauvegarder, isLoading: loadingSauvegarder }] =
    usePostCreerDossierMutation()
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  })
  const { clearErrors, control, resetField, setError, getValues, watch } = methods
  useErrorFormMapper(errorSauvegarder as IQueryErrorResponse, setError, getValues)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // TODO à utiliser quand on devra afficher les champs requis
  // const consentementWatched = watch('consentement')
  const cadreExpertiseWatched = watch('expertise.cadreExpertise')

  // format:
  // TODO à utiliser quand on devra afficher les champs requis
  // noinspection JSUnusedLocalSymbols
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const consentementRequis = determineConsentementRequis(cadreExpertiseWatched, referentiel)
  const multiPartenaireActif = profils?.some((profil) => profil?.code !== profilUtilisateur?.code)
  const serviceProfilActif = profilUtilisateur?.codeService !== undefined
  const cloisonnementServiceActif = profilEntreprise?.parametrage?.cloisonnementService
  const refVictimeEntrepriseActif = profilEntreprise.parametrage?.refVictimeEntreprise
  const formId = 'form-dossier-edit'

  // submit form:
  const onSubmit = async () => {
    const formValues: CreationDossierEntrepriseFormValues = getValues()

    const body = {
      etape: EtapeCreationParam.INITIAL,
      refDossierEntreprise: trimToUndefined(formValues.refDossierEntreprise),
      refVictimeEntreprise: refVictimeEntrepriseActif ? trimToUndefined(formValues.refVictimeEntreprise) : undefined,
      evenement: {
        nature: trimToUndefined(formValues.evenement.nature),
      },
      expertise: {
        cadreExpertise: trimToUndefined(formValues.expertise.cadreExpertise),
      },
      serviceEntreprise: cloisonnementServiceActif
        ? trimToUndefined(formValues.serviceEntreprise, AFFECTATION_CENTRALE_CODE)
        : undefined,
      gestionnaire: utilisateur?.idUtilisateur,
      consentement: formValues.consentement,
      refConsentement: trimToUndefined(formValues.refConsentement),
    } as CreationDossierRequest

    await creerDossierEntreprise(body)
      .unwrap()
      .then((res) => {
        dispatch(setErrorDossier({ status: 200, data: undefined }))
        enqueueSnackbar('Le nouveau dossier est créé avec succès', { variant: 'success' })
        navigate(editionDossierHref(res.code))
      })
      .catch(() => {
        enqueueSnackbar('La création de nouveau dossier a échoué', { variant: 'error' })
      })
  }

  // render:
  return (
    <form id={formId} name={formId}>
      <Grid container paddingTop={0}>
        {/* Si l'utilisateur connecté possède au moins 2 profils avec un code différent, alors on récupère sa raisonSocial */}
        {multiPartenaireActif && (
          <Grid item xs={12}>
            <Alert severity="info" id="info">
              Création dossier pour <b>{utilisateur?.profilActif?.raisonSociale}</b>{' '}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} marginLeft={2}>
          Vous allez créer un nouveau dossier d&apos;expertise médicales avec les informations suivantes :
        </Grid>
        <Grid item xs={12} md={refVictimeEntrepriseActif ? 6 : 12}>
          <Controller
            name="refDossierEntreprise"
            control={control}
            rules={{ required: MSG_FIELD_REQUIRED }}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                id="ref-dossier-entreprise"
                label="Votre référence dossier"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fullWidth
                fieldError={error}
                required
              />
            )}
          />
        </Grid>
        {refVictimeEntrepriseActif && (
          <Grid item xs={12} md={6}>
            <Controller
              name="refVictimeEntreprise"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="ref-victime-entreprise"
                  label="Numéro de victime"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Controller
            name="evenement.nature"
            control={control}
            rules={{ required: MSG_FIELD_REQUIRED }}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <SelectInputNatureEvenement
                id="evenement-nature"
                label="Nature d'événement"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                profilEntreprise={profilEntreprise}
                referentielName="naturesEvenement"
                fullWidth
                withNoSelectionItem
                fieldError={error}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="expertise.cadreExpertise"
            control={control}
            rules={{ required: MSG_FIELD_REQUIRED }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectInputCadreExpertise
                id="expertise-cadre-expertise"
                label={`Cadre d'expertise`}
                value={value}
                onBlur={onBlur}
                onChange={(e: string) => {
                  if (!determineConsentementRequis(e)) {
                    clearErrors(['consentement', 'refConsentement'])
                    resetField('consentement', {
                      defaultValue: getValues('consentement'),
                    })
                    resetField('refConsentement', {
                      defaultValue: getValues('refConsentement'),
                    })
                  }
                  onChange(e)
                }}
                referentielName="cadresExpertise"
                fullWidth
                withNoSelectionItem
                fieldError={error}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} marginLeft={2}>
          Ce dossier sera rattaché à :
        </Grid>
        {cloisonnementServiceActif && (
          <Grid item xs={12} md={6}>
            {serviceProfilActif ? (
              <ReadOnlyTextField
                id="service-entreprise"
                label="Service"
                value={profilUtilisateur.libelleService ?? AFFECTATION_CENTRALE_LABEL}
                fullWidth
              />
            ) : (
              <Controller
                name="serviceEntreprise"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputParametrage
                    id="service-entreprise"
                    parametrageName={ParametrageNameEnum.SERVICE}
                    label="Service"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            )}
          </Grid>
        )}
        <Grid item xs={12} md={cloisonnementServiceActif ? 6 : 12}>
          <ReadOnlyTextField
            id="gestionnaire"
            label="Gestionnaire"
            value={`${utilisateur?.titre} ${utilisateur?.nom} ${utilisateur?.prenom}`}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="consentement"
            control={control}
            rules={{ required: validateRequiredWithCondition(consentementRequis) }}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <CheckboxInput
                id="consentement"
                name="consentement"
                label="RGPD : Avez-vous recueilli le consentement de la personne à examiner ?"
                labelPlacement="start"
                checked={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
                formControlSx={{ display: 'flex', flexDirection: 'row' }}
                checkboxSx={{ padding: 0, marginX: 1.5, marginTop: 1, display: 'block' }}
                errorSx={{ marginX: 2, marginTop: 0, lineHeight: '24px', display: 'flex', alignItems: 'center' }}
                required={consentementRequis}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Controller
            name="refConsentement"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                id="ref-consentement"
                label="Référence du recueil de consentement"
                fullWidth
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonsStack>
            <AnnulerButton id="annuler" onClick={onClose}>
              Annuler la création de dossier
            </AnnulerButton>
            <SauvegarderButton id="sauvegarder" onClick={onSubmit} loading={loadingSauvegarder}>
              Sauvegarder ma saisie
            </SauvegarderButton>
          </ButtonsStack>
        </Grid>
      </Grid>
    </form>
  )
}
