import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { StatutDossier } from 'plateforme/store/types/dossier'
import { StatutMission } from 'plateforme/store/types/mission'
import MissionEntreprise from './missionMedecin'
import Contact from './contactMedecin'

export default interface DossierMedecin {
  code?: string
  refDossierEntreprise?: string
  libelle?: string
  entreprise?: ReferenceEntreprise
  contactEntreprise?: ContactEntreprise
  evenement?: Evenement
  statut?: StatutDossier
  labelStatut?: string
  descriptionStatut?: string
  motifCloture?: string
  labelMotifCloture?: string
  commentaireCloture?: string
  expertise?: Expertise
  personneAExaminer?: PersonneAExaminer
  personneAContacter?: PersonneAContacter
  documents?: DocumentTeleverse[]
  missions?: [MissionEntreprise]
  editable?: boolean
  documentDeposable?: boolean
  dateCloture?: Date
  dateEnCours?: Date
  dateMiseAJour?: Date
}

export interface Evenement {
  reference?: string
  date?: Date
  nature?: string
  labelNature?: string
  commentaire?: string
}

export interface Expertise extends Contact {
  cadreExpertise?: string
  labelCadreExpertise?: string
  garantieApplicable?: string
  domaineContractuel?: DomaineContractuel
  commentaire?: string
  autreMedecin?: AutreMedecin
  prejudiceEnAggravation?: boolean
  typeLieuExpertise?: 'AUTRE' | 'CABINET'
  labelTypeLieuExpertise?: string
  libelleAdresse?: string
}

export interface PersonneAExaminer extends Contact {
  nom?: string
  prenom?: string
  sexe?: string
  labelSexe?: string
  dateNaissance?: Date
  profession?: string
  qualite?: string
  labelQualite?: string
  assure?: boolean
  contactUnique?: boolean
}

export interface PersonneAContacter extends Contact {
  nom?: string
  prenom?: string
  qualite?: string
  labelQualite?: string
  coordonneesIdentiquesPersonneAExaminer?: boolean
}

export interface AutreMedecin extends Contact {
  codeMedecin?: string
  libelle?: string
}

export interface DomaineContractuel {
  code?: string
  libelle?: string
  dommages?: string[]
}
export interface ReferenceEntreprise {
  code?: string
  codeACPR?: string
  codeSIREN?: string
  libelle?: string
}

export interface ReferenceDossier {
  code?: string
  libelle?: string
  entreprise?: ReferenceEntreprise
  refDossierEntreprise?: string
  refVictimeEntreprise?: string
  nomPersonneAExaminer?: string
  prenomPersonneAExaminer?: string
}

export interface DossierRequest {
  codeDossier: string
}

export interface DocumentRequest extends DossierRequest {
  codeDocument: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SupprimerDocumentRequest extends DocumentRequest {}

export interface HistoriqueDossierMedecin {
  dateAction?: Date
  codeMission?: string
  codeDocument?: string
  codeFacture?: string
  utilisateur?: HistoriqueUtilisateurMedecin
  partenaire?: HistoriquePartenaireMedecin
  action?: string
  actionLabel?: string
  propertiesChanged?: string[]
}

export interface HistoriqueUtilisateurMedecin {
  id?: string
  title?: string
  nom?: string
  prenom: string
  libelle?: string
}

export interface HistoriquePartenaireMedecin {
  id?: string
  title?: string
  nom?: string
  prenom: string
  libelle?: string
}

export interface DossierMedecinFilter {
  motCle?: string
  statuts?: StatutMission[]
  natureEvenement?: string
  cadreExpertise?: string
  codeEntreprise?: string
  dateDebut?: string
  dateFin?: string
}

export interface ContactEntreprise {
  libelle?: string
  mail?: string
  telephone?: string
}

export enum DossierMedecinSortType {
  ENTREPRISE_SORT = 'entreprise',
  DATE_REPONSE_MEDECIN_SORT = 'date_reponse_medecin',
  STATUT_SORT = 'statut',
  CADRE_EXPERTISE_SORT = 'cadre_expertise',
  NATURE_EVENEMENT_SORT = 'nature_evenement',
  REFERENCE_DOSSIER_SORT = 'ref_dossier',
  PERSONNE_A_EXAMINER_SORT = 'personne_a_examiner',
}
