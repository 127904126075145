import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { addDays } from 'date-fns'
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { AjouterIconButton, DatePickerInput, EditTextField, SupprimerIconButton } from 'plateforme/components'
import { UniteEstimation } from 'medecin/store/types/rapportConclusionMedecin'
import { PeriodeData, TiercePersonneData } from '../FicheRapportMedecinForm'

interface DommageFieldTiercePersonneProps {
  name: string
  code: string
  readOnly?: boolean
  max?: number
}

export default function DommageFieldTiercePersonne({ name, code, readOnly, max }: DommageFieldTiercePersonneProps) {
  const fieldName = `${name}.periodes`
  const { control, getValues, clearErrors, resetField, watch } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  })

  function addTooltip(index: number) {
    if (fields.length === max) {
      return `Le nombre maximum de personne tierce est de ${max}`
    }
    return index === fields.length - 1 ? 'Ajouter une personne' : ''
  }

  function addDisabled() {
    return fields.length === max
  }

  function addOnClick() {
    return () => {
      let du: Date | null = null
      if (fields.length > 0) {
        const lastAu = getValues(`${fieldName}.${fields.length - 1}.periode.au`)
        du = lastAu ? addDays(new Date(lastAu), 1) : null
      }

      append({
        type: null,
        nombreHeures: '',
        par: null,
        periode: { du, au: null } as PeriodeData,
      } as TiercePersonneData)
    }
  }

  function removeTooltip() {
    return fields.length <= 1 ? 'Au minimum, une personne tierce est requise' : 'Retirer'
  }

  function removeDisabled() {
    return fields.length <= 1
  }

  function removeOnClick(index: number) {
    return () => remove(index)
  }

  return (
    <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
      {fields.map((item, index) => {
        return (
          <li key={`${code}_${item.id}`}>
            <Stack
              direction="row"
              alignItems="flex-end"
              spacing={2}
              paddingBottom={index === fields.length - 1 ? 0 : 3.5}
            >
              <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={12} md={6} display="flex" alignItems="flex-start">
                  <Controller
                    name={`${fieldName}.${index}.nombreHeures`}
                    control={control}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <EditTextField
                        fullWidth
                        readOnly={readOnly}
                        type="number"
                        id={`${fieldName}.${index}.nombreHeures`}
                        label="Nombre d'heures"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        fieldError={error}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} display="flex" alignItems="flex-start">
                  <Controller
                    name={`${fieldName}.${index}.par`}
                    control={control}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <>
                        <Typography
                          variant="subtitle2"
                          color={error ? 'error' : 'primary'}
                          minWidth="48px"
                          paddingTop={2}
                        >
                          Par :
                        </Typography>
                        <FormControl
                          id={`${fieldName}.${index}.par`}
                          disabled={readOnly}
                          error={!!error}
                          variant="standard"
                        >
                          <RadioGroup
                            row
                            aria-labelledby="demo-error-radios"
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            sx={{ minHeight: 55, display: 'flex', alignItems: 'center' }}
                          >
                            <FormControlLabel value={UniteEstimation.JOUR} control={<Radio />} label="Jours" />
                            <FormControlLabel value={UniteEstimation.SEMAINE} control={<Radio />} label="Semaines" />
                            <FormControlLabel value={UniteEstimation.MOIS} control={<Radio />} label="Mois" />
                          </RadioGroup>
                          <FormHelperText>{error?.message}</FormHelperText>
                        </FormControl>
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={6} display="flex" alignItems="flex-start">
                  <Controller
                    name={`${fieldName}.${index}.periode.du`}
                    control={control}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <DatePickerInput
                        InputProps={{
                          id: `${fieldName}.${index}.periode.du`,
                          fullWidth: true,
                        }}
                        label="Du"
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          const relatedFieldName = `${fieldName}.${index}.periode.au`
                          clearErrors(relatedFieldName)
                          resetField(relatedFieldName, {
                            defaultValue: watch(relatedFieldName),
                          })
                          onChange(e)
                        }}
                        readOnly={readOnly}
                        fieldError={error}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} display="flex" alignItems="flex-start">
                  <Controller
                    name={`${fieldName}.${index}.periode.au`}
                    control={control}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <DatePickerInput
                        InputProps={{
                          id: `${fieldName}.${index}.periode.au`,
                          fullWidth: true,
                        }}
                        label="Au"
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          const relatedFieldName = `${fieldName}.${index}.periode.du`
                          clearErrors(relatedFieldName)
                          resetField(relatedFieldName, {
                            defaultValue: watch(relatedFieldName),
                          })
                          onChange(e)
                        }}
                        readOnly={readOnly}
                        fieldError={error}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {!readOnly && (
                <Box height="60px" paddingTop="12px" marginLeft="8px !important">
                  <SupprimerIconButton
                    onClick={removeOnClick(index)}
                    disabled={removeDisabled()}
                    tooltip={removeTooltip()}
                  />
                </Box>
              )}
              {!readOnly && (
                <Box height="60px" minWidth="40px" paddingTop="10px" marginLeft="8px !important">
                  {index === fields.length - 1 ? (
                    <AjouterIconButton
                      title="Ajouter"
                      onClick={addOnClick()}
                      disabled={addDisabled()}
                      tooltip={addTooltip(index)}
                    />
                  ) : (
                    ' '
                  )}
                </Box>
              )}
            </Stack>
            <Divider hidden={index === fields.length - 1} />
          </li>
        )
      })}
    </ul>
  )
}
