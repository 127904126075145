export default interface Utilisateur {
  idUtilisateur: string
  email: string
  titre: string
  prenom: string
  nom: string
  authLevel?: AuthLevel
  profilActif?: ProfilUtilisateurActif
  profils: ProfilUtilisateur[]
}

export interface ProfilUtilisateurActif {
  value: string
  typePartenaire: TypePartenaire | string
  raisonSociale: string
  code: string
  codeProfessionel: string
  codeService?: string
  libelleService?: string
  role: RoleUtilisateur
  actif: boolean
}

export interface ProfilUtilisateur {
  value: string
  typePartenaire: TypePartenaire | string
  raisonSociale: string
  code: string
  codeProfessionel: string
  codeService?: string
  libelleService?: string
  roles: RoleUtilisateur[]
  actif: boolean
  restrictionIp: boolean
}

export interface RoleUtilisateur {
  id: string
  value: string
  description: string
}

export type AuthDownloadDocumentDossierRequest = AuthDownloadRequest & {
  action: AuthDownloadAction.TELECHARGER_DOCUMENT_DOSSIER
  codeDossier: string
  codeDocument: string
}

export type AuthDownloadFicheMissionRequest = AuthDownloadRequest & {
  action: AuthDownloadAction.TELECHARGER_FICHE_MISSION
  codeDossier: string
  codeMission: string
}

export type AuthDownloadArchiveDossierRequest = AuthDownloadRequest & {
  action: AuthDownloadAction.TELECHARGER_ARCHIVE_DOSSIER
  codeDossier: string
  codeMission: string
}

export type AuthDownloadDocumentMessageRequest = AuthDownloadRequest & {
  action: AuthDownloadAction.TELECHARGER_DOCUMENT_MESSAGE
  codeMessage: string
  codeDocument: string
}

export interface AuthDownloadRequest {
  action: AuthDownloadAction
}

export enum AuthDownloadAction {
  TELECHARGER_DOCUMENT_DOSSIER = 'TELECHARGER_DOCUMENT_DOSSIER',
  TELECHARGER_FICHE_MISSION = 'TELECHARGER_FICHE_MISSION',
  TELECHARGER_CSV_DOSSIER = 'TELECHARGER_CSV_DOSSIER',
  TELECHARGER_CSV_HABILITATION = 'TELECHARGER_CSV_HABILITATION',
  TELECHARGER_CSV_FACTURE = 'TELECHARGER_CSV_FACTURE',
  TELECHARGER_ARCHIVE_DOSSIER = 'TELECHARGER_ARCHIVE_DOSSIER',
  TELECHARGER_DOCUMENT_MESSAGE = 'TELECHARGER_DOCUMENT_MESSAGE',
}

export enum TypePartenaire {
  Medecin = 'MEDECIN',
  Entreprise = 'ENTREPRISE',
  Admin = 'ADMIN',
}
export enum AuthLevel {
  Logpwd1 = 'GPSA-SIM-LOGPWD-1',
  Logpwd2 = 'GPSA-SIM-LOGPWD-2',
}
