import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Card, CardContent, CardHeader, Grid, useMediaQuery, useTheme } from '@mui/material'
import { DatePickerInput, EditTextField, SelectInputNatureEvenement } from 'plateforme/components'
import { MSG_FIELD_REQUIRED } from 'plateforme/constantes'
import { StatutDossier } from 'plateforme/store/types/dossier'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import ProfilEntreprise from 'assureur/store/types/profilEntreprise'

type InformationsEvenementProps = {
  dossier: DossierEntreprise
  profilEntreprise: ProfilEntreprise
}

export default function InformationsEvenement({ dossier, profilEntreprise }: InformationsEvenementProps) {
  const { control } = useFormContext()
  const { statut } = dossier

  const theme = useTheme()
  const showLongNumeroEvenement = !useMediaQuery(theme.breakpoints.between('md', 'xl'))

  return (
    <Card>
      <CardHeader title="Informations de l'événement" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name="evenement.reference"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="evenement-reference"
                  label={
                    showLongNumeroEvenement
                      ? 'Numéro de l’événement si différent identifiant dossier'
                      : 'Numéro de l’événement'
                  }
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="evenement.date"
              control={control}
              rules={{ required: MSG_FIELD_REQUIRED }}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <DatePickerInput
                  InputProps={{
                    id: 'evenement-date',
                    fullWidth: true,
                    required: true,
                  }}
                  label="Date de l'événement"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  readOnly={statut !== StatutDossier.EN_CREATION}
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="evenement.nature"
              rules={{ required: MSG_FIELD_REQUIRED }}
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <SelectInputNatureEvenement
                  id="evenement-nature"
                  label="Nature de l'événement"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  profilEntreprise={profilEntreprise}
                  referentielName="naturesEvenement"
                  readOnly={!dossier.editableMajeure}
                  fullWidth
                  withNoSelectionItem
                  fieldError={error}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="evenement.commentaire"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="evenement-commentaire"
                  label="Commentaire sur l'événement"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  multiline
                  minRows={3}
                  fieldError={error}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
