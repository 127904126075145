import React from 'react'
import { Alert, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { AreaLoading, InfiniteScrollComponent, NoRowsOverlay, ResultatRechercheBarCard } from 'plateforme/components'
import { SortOption, SortParam } from 'plateforme/store/types/pageRecherche'
import FluxTechniqueEntreprise, { FluxTechniqueEntrepriseSortType } from 'assureur/store/types/fluxTechniqueEntreprise'
import { parseISO } from 'date-fns'
import { formatDateTimeFR } from 'plateforme/services/dates.services'

type ListeFluxTechniquesPartProps = {
  combinedData: Array<FluxTechniqueEntreprise>
  total?: number
  readMore: VoidFunction
  sortBy: (param: SortParam<FluxTechniqueEntrepriseSortType>) => void
  initSort: VoidFunction
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
}

export default function ListeFluxTechniquesEntreprisePart({
  combinedData,
  total,
  readMore,
  sortBy,
  initSort,
  isError,
  isLoading,
  isFetching,
  isSuccess,
}: ListeFluxTechniquesPartProps) {
  // les options de tri disponibles a passer au composant SortComponentMenu
  const sortOptions: SortOption<FluxTechniqueEntrepriseSortType>[] = [
    { sortType: FluxTechniqueEntrepriseSortType.ACTION_SORT, label: 'Action' },
    { sortType: FluxTechniqueEntrepriseSortType.DETAIL_SORT, label: `Détail de l'action` },
    { sortType: FluxTechniqueEntrepriseSortType.HTTP_METHOD_SORT, label: 'Méthode HTTP' },
    { sortType: FluxTechniqueEntrepriseSortType.HTTP_PATH_SORT, label: 'Chemin HTTP' },
    { sortType: FluxTechniqueEntrepriseSortType.HTTP_STATUS_CODE_SORT, label: 'Code statut HTTP' },
    { sortType: FluxTechniqueEntrepriseSortType.ADRESSE_IP_SORT, label: 'Adresse IP' },
    { sortType: FluxTechniqueEntrepriseSortType.TIMESTAMP_SORT, label: 'Timestamp' },
  ]

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}
    >
      <Grid item container xs={12} paddingBottom={2.5}>
        <ResultatRechercheBarCard
          title="Liste des flux techniques"
          total={total}
          loading={isLoading || isFetching}
          sortBy={sortBy}
          sortOptions={sortOptions}
          initSort={initSort}
          withSorting
          withExportCSV={false}
        />
      </Grid>
      {isError && !isSuccess && !isLoading && !isFetching ? (
        <Alert severity="info" sx={{ width: '100%' }}>
          Aucune donnée
        </Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '10%' }}>Action</TableCell>
                <TableCell sx={{ width: '10%' }}>Détail de l&apos;action</TableCell>
                <TableCell sx={{ width: '10%' }}>Méthode HTTP</TableCell>
                <TableCell sx={{ width: '20%' }}>Chemin HTTP</TableCell>
                <TableCell sx={{ width: '10%' }}>Code statut HTTP</TableCell>
                <TableCell sx={{ width: '10%' }}>Adresse IP</TableCell>
                <TableCell sx={{ width: '10%' }}>Timestamp</TableCell>
              </TableRow>
            </TableHead>
          </Table>
          {total && total > 0 ? (
            <InfiniteScrollComponent
              dataLength={combinedData?.length}
              next={readMore}
              hasMore={combinedData.length < (total ?? 0)}
              isLoading={isLoading || isFetching}
              areaLoading={<AreaLoading height={90} />}
              xs={12}
            >
              {combinedData?.map((ft: FluxTechniqueEntreprise) => (
                <Table key={ft.id}>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ width: '10%' }}>{ft.action}</TableCell>
                      <TableCell sx={{ width: '10%' }}>{ft.detail}</TableCell>
                      <TableCell sx={{ width: '10%' }}>{ft.httpMethod}</TableCell>
                      <TableCell sx={{ width: '20%' }}>
                        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '15rem' }}>
                          {ft.httpPath}
                        </div>
                      </TableCell>
                      <TableCell sx={{ width: '10%' }}>{ft.httpStatusCode}</TableCell>
                      <TableCell sx={{ width: '10%' }}>{ft.ip}</TableCell>
                      <TableCell sx={{ width: '10%' }}>{formatDateTimeFR(parseISO(ft.timestamp), '', true)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ))}
            </InfiniteScrollComponent>
          ) : (
            <Table>
              <NoRowsOverlay />
            </Table>
          )}
        </TableContainer>
      )}
    </Grid>
  )
}
