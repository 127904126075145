import React, { useMemo } from 'react'

import { useErrorHandler } from 'react-error-boundary'
import { Grid } from '@mui/material'

import { Dommages, DommageField, TypeDommage } from 'assureur/store/types/dommagesEntreprise'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import { toArray } from 'plateforme/services/utils'
import { useGetReferentielQuery } from 'plateforme/store/apis/referentielApi'
import { FallbackError } from 'plateforme/components/dialog/ErrorFallback'
import { BluePaper } from 'plateforme/components'
import DommageComponent from './DommageComponent'

interface DommagesFieldsProps {
  dommages: Dommages
  typeDommage: TypeDommage
  typeRapport: TypeRapport
}

export default function DommagesComponent({ dommages, typeDommage, typeRapport }: DommagesFieldsProps) {
  const { data, error, isSuccess, isError } = useGetReferentielQuery()

  // handle error of useGetReferentielQuery:

  const handleError = useErrorHandler()
  if (isError) {
    handleError({
      errorApi: error,
      title: 'Erreur chargement des données référentiel',
      api: 'GetReferentiel',
    } as FallbackError)
  }

  const formatedDommages = useMemo(() => {
    const dommagePredefini = isSuccess && data.dommagePredefini ? data.dommagePredefini : []
    return toArray(dommages)
      .map((formatedDommage) => ({
        ...formatedDommage,
        label: dommagePredefini.find((dp) => dp.code === formatedDommage.fieldName)?.label,
        typeDommage: dommagePredefini.find((dp) => dp.code === formatedDommage.fieldName)?.typeDommage,
      }))
      .filter((d) => d.typeDommage === typeDommage)
  }, [data?.dommagePredefini, dommages, isSuccess, typeDommage])

  if (formatedDommages.length === 0) {
    return (
      <Grid container marginBottom={3}>
        <Grid item xs={12}>
          <BluePaper sx={{ fontStyle: 'italic', paddingTop: 3.5, textAlign: 'center' }}>Aucun dommage</BluePaper>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container marginBottom={3}>
      {formatedDommages &&
        formatedDommages?.map((dommage: DommageField) => (
          <Grid item xs={12}>
            <BluePaper>
              <DommageComponent dommage={dommage} key={dommage.fieldName} typeRapport={typeRapport} />
            </BluePaper>
          </Grid>
        ))}
    </Grid>
  )
}
