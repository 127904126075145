import * as React from 'react'
import { ProfilIcon } from 'plateforme/components'
import MenuBaseButton, { MenuBaseButtonProps } from 'plateforme/components/menu/MenuBaseButton'

export default function ProfilMenuButton(props: MenuBaseButtonProps) {
  const { icon, children, tooltip, ...other } = props
  return (
    <MenuBaseButton icon={icon ?? <ProfilIcon color="primary" />} tooltip={tooltip ?? 'Mon profil'} {...other}>
      {children ?? 'Mon profil'}
    </MenuBaseButton>
  )
}
