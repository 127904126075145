import React from 'react'
import { useParams } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import { Box } from '@mui/material'
import NavigationDossierPart from 'plateforme/parts/NavigationDossierPart'
import { FallbackError } from 'plateforme/components/dialog/ErrorFallback'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import { useGetDossierByIdQuery } from 'admin/store/apis/dossierAdminApi'
import TabsConsultationDossierAdmin from 'admin/part/consultationDossier/TabsConsultationDossierAdmin'
import { determineDerniereMission } from 'admin/services/dossierAdmin.services'

export default function ConsultationDossierEntreprisePage() {
  const { codeDossier } = useParams()
  const handleError = useErrorHandler()
  if (codeDossier === undefined) {
    throw new Error('Aucun code dossier défini')
  }

  const {
    data: utilisateur,
    error: utilisateurError,
    isError: isUtilisateurError,
    isLoading: isUtilisateurLoading,
    isFetching: isUtilisateurFetching,
  } = useGetUtilisateurActifQuery()

  // handle error of useGetUtilisateurActifQuery:
  if (isUtilisateurError) {
    handleError({
      errorApi: utilisateurError,
      title: 'Erreur chargement des données du profil actif',
      api: 'GetDossierByIdQuery',
    } as FallbackError)
  }

  const {
    data: dossier,
    error: dossierError,
    isError: isDossierError,
    isLoading: isDossierLoading,
    isFetching: isDossierFetching,
  } = useGetDossierByIdQuery({ codeDossier })

  // handle error of GetDossierByIdQuery:
  if (isDossierError) {
    handleError({
      errorApi: dossierError,
      title: 'Erreur chargement des données du dossier',
      api: 'GetDossierByIdQuery',
    } as FallbackError)
  }

  const derniereMission = determineDerniereMission(dossier)
  const profilUtilisateur = utilisateur?.profilActif
  const libelleReferenceDossier = derniereMission?.libelle
  const complementReferenceDossier = dossier?.entreprise?.libelle

  const loading = isDossierLoading || isDossierFetching || isUtilisateurLoading || isUtilisateurFetching

  return (
    <>
      <NavigationDossierPart
        libelleReferenceDossier={libelleReferenceDossier}
        complementReferenceDossier={complementReferenceDossier}
      />
      <Box marginTop={2}>
        <TabsConsultationDossierAdmin
          dossier={dossier}
          derniereMission={derniereMission}
          profilUtilisateur={profilUtilisateur}
          loading={loading}
        />
      </Box>
    </>
  )
}
