import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Stack } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll'
import { getFactureCriteria } from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'
import RechercheFacturesEntreprisePart from 'assureur/parts/rechercheFacture/RechercheFacturePart'
import { useGetRechercheFactureQuery } from 'assureur/store/apis/dossierAssureurApi'
import ListeFacturesEntreprisePart from 'assureur/parts/rechercheFacture/ListerFacturesEntreprisePart'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'
import { useErrorHandler } from 'react-error-boundary'
import { FallbackError } from 'plateforme/components/dialog/ErrorFallback'

export default function RechercheFacturesMedecinPage() {
  const { data: utilisateur, isError: isErrorUser, error: errorUser } = useGetUtilisateurActifQuery()
  const { data: profilEntreprise, isError: isErrorProfil, error: errorProfil } = useGetProfilEntrepriseQuery()
  const handleError = useErrorHandler()

  // handle error of useGetUtilisateurActifQuery:
  if (isErrorUser) {
    handleError({
      errorApi: errorUser,
      title: 'Erreur chargement des données utilisateur actif',
      api: 'GetUtilisateurActif',
    } as FallbackError)
  }
  // handle error of useGetProfilEntrepriseQuery:
  if (isErrorProfil) {
    handleError({
      errorApi: errorProfil,
      title: 'Erreur chargement des données profil',
      api: 'getProfilEntreprise',
    } as FallbackError)
  }
  const defaultFactureCriteria = useSelector(getFactureCriteria)
  const {
    combinedData,
    exportParams,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetRechercheFactureQuery, { defaultCriteria: defaultFactureCriteria })

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isError) {
      enqueueSnackbar('La recherche des factures a échoué', { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return (
    <Stack>
      <NavigationPart label="Liste des factures " />
      <RechercheFacturesEntreprisePart
        searchError={error}
        search={search}
        profilEntreprise={profilEntreprise}
        profilUtilisateur={utilisateur?.profilActif}
      />
      <ListeFacturesEntreprisePart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        isSuccess={isSuccess}
        exportParams={exportParams}
      />
    </Stack>
  )
}
