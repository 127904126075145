import { Paper, styled } from '@mui/material'

const BluePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(3.5),
  backgroundColor: '#f6f9ff',
  border: '0.05em solid #eae9f1',
}))

export default BluePaper
