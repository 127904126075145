import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'
import { EditTextField, SelectInputReferentiel } from 'plateforme/components'
import PhoneNumberInput from 'plateforme/components/inputs/PhoneNumberInput'
import InfosContactRequisesAlert from '../InfosContactRequisesAlert'

export default function LieuExpertiseForm() {
  const { clearErrors, control, formState, setValue, watch } = useFormContext()
  const expertiseError = 'expertise._error'
  const [isExpertiseAuCabinetDuMedecinWatched] = watch(['expertise.isExpertiseAuCabinetDuMedecin'])

  const severity = formState.errors.expertise && '_error' in formState.errors.expertise ? 'error' : 'info'

  function clearLieuExpertiseError() {
    clearErrors(expertiseError)
    setValue(expertiseError, undefined, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  return (
    <Card>
      <CardHeader title="Lieu expertise" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} display="flex" alignItems="center">
            <Controller
              name="expertise.isExpertiseAuCabinetDuMedecin"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <FormControl error={!!error} variant="standard">
                  <FormControlLabel
                    id="expertise-is-expertise-au-cabinet-du-medecin"
                    value={value}
                    control={<Switch onBlur={onBlur} onChange={onChange} checked={value} />}
                    label="Expertise au cabinet du médecin"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          {!isExpertiseAuCabinetDuMedecinWatched && (
            <>
              <Grid item xs={12}>
                <InfosContactRequisesAlert severity={severity} />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="expertise.adresse1"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="expertise-adresse1"
                      label="Adresse 1"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        clearLieuExpertiseError()
                        onChange(e)
                      }}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="expertise.adresse2"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="expertise-adresse2"
                      label="Adresse 2"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        clearLieuExpertiseError()
                        onChange(e)
                      }}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="expertise.adresse3"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="expertise-adresse3"
                      label="Adresse 3"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        clearLieuExpertiseError()
                        onChange(e)
                      }}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="expertise.codePostal"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="expertise-code-postal"
                      label="Code postal"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        clearLieuExpertiseError()
                        onChange(e)
                      }}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="expertise.commune"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="expertise-commune"
                      label="Ville"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        clearLieuExpertiseError()
                        onChange(e)
                      }}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="expertise.pays"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <SelectInputReferentiel
                      id="expertise-pays"
                      label="Pays"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        clearLieuExpertiseError()
                        onChange(e)
                      }}
                      referentielName="pays"
                      fullWidth
                      withNoSelectionItem
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="expertise.mail"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="expertise-mail"
                      label="Adresse mail"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        clearLieuExpertiseError()
                        onChange(e)
                      }}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="expertise.telephoneMobile"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <PhoneNumberInput
                      id="expertise-telephone-mobile"
                      label="Tél. portable"
                      value={value}
                      onChange={(e) => {
                        clearLieuExpertiseError()
                        onChange(e)
                      }}
                      onBlur={onBlur}
                      fieldError={error}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="expertise.telephoneFixe"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <PhoneNumberInput
                      id="expertise-telephone-fixe"
                      label="Tél. fixe"
                      value={value}
                      onChange={(e) => {
                        clearLieuExpertiseError()
                        onChange(e)
                      }}
                      onBlur={onBlur}
                      fieldError={error}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
