import { SelectOption } from 'plateforme/components/inputs/SelectInput'

export default interface EntrepriseAdmin {
  code: string
  libelle: string
  actif: boolean
  codeACPR?: string
  codeSIREN?: string
  pageIndex?: number
  total?: number
  next?: string
  motCle?: string
  statuts?: statutsEnterprise
  dateCreationDebut?: string
  adresse1: string
  adresse2: string
  adresse3: string
  codePostal: string
  commune: string
  pays: string
  labelPays: string
  mail: string
  telephone: string
  labelStatut: string
  dateCreation: Date
  dateMiseAJour: string
  dateSuppression: string
}

export interface ReferentielEntreprise extends SelectOption {
  code: string
  libelle: string
  codeACPR: string
  codeSIREN: string
  actif: boolean
}

export interface ReferentielEntrepriseFilter {
  motCle?: string
  statuts?: statutsEnterprise[]
  dateCreationDebut?: string
}

export enum ReferentielEntrepriseSortType {
  IDENTIFIANT_PLATEFORME_SORT = 'code_entreprise',
  RAISON_SOCIALE_SORT = 'libelle_entreprise',
  DATE_INSCRIPTION_SORT = 'date_creation',
}

export enum statutsEnterprise {
  INSCRIPTION = 'INSCRIPTION',
  ACTIVE = 'ACTIVE',
  INACTIF = 'INACTIVE',
}

export interface InscrireEntrepriseRequest {
  codeACPR?: string
  codeSIREN?: string
  libelle?: string
  adresse1?: string
  adresse2?: string
  adresse3?: string
  codePostal?: string
  commune?: string
  pays?: string
  mail?: string
  telephone?: string
  parametrage?: ParametrageEntrepriseRequest
}

export interface ParametrageEntrepriseRequest {
  mailReseauMedecin?: string
}

export interface ProfilEntrepriseCommon {
  code?: string
  codeACPR?: string
  codeSIREN?: string
  libelle?: string
  adresse1?: string
  adresse2?: string
  adresse3?: string
  codePostal?: string
  commune?: string
  pays?: string
  labelPays?: string
  mail?: string
  telephone?: string
  statut?: string
  labelStatut?: string
}
export interface ProfilEntreprise extends ProfilEntrepriseCommon {
  parametrage?: ParametrageEntreprise
}

export interface ParametrageEntrepriseCommon {
  canalWs?: boolean
  canalExtranet?: boolean
  notifiableParMail?: boolean
  mailFormatable?: boolean
  mailFormatObjet?: string
  lettreMission?: boolean
  cloisonnementService?: boolean
  services?: ServiceEntreprise[]
  numeroVictime?: boolean
  assure?: boolean
  expertiseSurPiece?: boolean
  relanceExpertiseSurPiece?: boolean
  intervenantOccasionnel?: boolean
  domainesContractuels?: DomaineContractuelEntreprise[]
  contacts?: Contacts
  delaisRelance?: DelaisRelance
  notifications?: Notifications
  refMissionEntrepriseObligatoire?: boolean
  remissionnementAuto?: boolean
  mailReseauMedecin?: string
}

export interface Contacts {
  BIRC?: ContactInformations
  RCGE?: ContactInformations
  BNIR?: ContactInformations
  RCMD?: ContactInformations
  ASSC?: ContactInformations
  PREV?: ContactInformations
  ACCV?: ContactInformations
  FAUT?: ContactInformations
  AUTR?: ContactInformations
}

export interface ContactInformations {
  code?: CodeContact
  libelle?: string
  mail?: string
  telephone?: string
}

export enum CodeContact {
  CODE_BAL_ENTREPRISE = 'GL',
  CODE_BAL_SERVICE = 'SV',
  CODE_BAL_PERSONNALISE = 'PE',
  CODE_BAL_GESTIONNAIRE = 'GE',
}

export interface ParametrageEntreprise extends ParametrageEntrepriseCommon {
  typesFichesConclusions?: FichesConclusions
}

export interface ParametrageType {
  code: string
  libelle: string
  actif: boolean
}

export interface ServiceEntreprise extends ParametrageType {
  mail: string
  telephone: string
  statutLabel?: string
  registered?: boolean
}

export interface FichesConclusions {
  BIRC?: TypesFichesConclusions
  RCGE?: TypesFichesConclusions
  BNIR?: TypesFichesConclusions
  RCMD?: TypesFichesConclusions
  ASSC?: TypesFichesConclusions
  PREV?: TypesFichesConclusions
  ACCV?: TypesFichesConclusions
  FAUT?: TypesFichesConclusions
  AUTR?: TypesFichesConclusions
}

export enum EnumTypesFichesConclusions {
  CONV = 'CONV',
  SIMP = 'SIMP',
}

export enum CodeFichesConclusions {
  expertiseUnilaterale = '01',
  expertiseConjointe = '02',
  expertiseJudiciaire = '03',
  arbitrage = '04',
  avisTechnique = '05',
}

export interface TypesFichesConclusions {
  [CodeFichesConclusions.expertiseUnilaterale]: EnumTypesFichesConclusions
  [CodeFichesConclusions.expertiseConjointe]: EnumTypesFichesConclusions
  [CodeFichesConclusions.expertiseJudiciaire]: EnumTypesFichesConclusions
  [CodeFichesConclusions.arbitrage]: EnumTypesFichesConclusions
  [CodeFichesConclusions.avisTechnique]?: EnumTypesFichesConclusions
}

export interface DomaineContractuelEntreprise extends ParametrageType {
  dommages?: string[]
  statutLabel?: string
  registered?: boolean
}

export interface DelaisRelance {
  reponseMission: NaturesEvenementsDelais
  reponseSuiviMission: NaturesEvenementsDelais
  validationRapport: NaturesEvenementsDelais
  validationDemandeModification: NaturesEvenementsDelais
}

export interface NaturesEvenementsDelais {
  BIRC?: Delais
  RCGE?: Delais
  BNIR?: Delais
  RCMD?: Delais
  ASSC?: Delais
  PREV?: Delais
  ACCV?: Delais
  FAUT?: Delais
  AUTR?: Delais
}

export interface Delais {
  [CodeFichesConclusions.expertiseUnilaterale]: number
  [CodeFichesConclusions.expertiseConjointe]: number
  [CodeFichesConclusions.expertiseJudiciaire]: number
  [CodeFichesConclusions.arbitrage]: number
  [CodeFichesConclusions.avisTechnique]?: number
}

export interface Notifications {
  REL_MIS_NREP: NotificationsCodes
  DEP_DOC_MED: NotificationsCodes
  DEP_DOC_ENT: NotificationsCodes
  ACC_MIS: NotificationsCodes
  REF_MIS: NotificationsCodes
  MAJ_MIS_DEXAM: NotificationsCodes
  MAJ_MIS_SBLQ: NotificationsCodes
  REL_DEXAM: NotificationsCodes
  DEP_CONC: NotificationsCodes
  REL_CONC: NotificationsCodes
  MAJ_CONC: NotificationsCodes
  DEP_FAC: NotificationsCodes
  REL_FAC: NotificationsCodes
  ANN_FAC: NotificationsCodes
  REM_AUTO: NotificationsCodes
  PUR_DOS: NotificationsCodes
  MEL_MED: NotificationsCodes
  SUP_DOC_MED: NotificationsCodes
  CLO_AUTO: NotificationsCodes
  REL_MAJ_CONC: NotificationsCodes
  PROP_MIS: NotificationsCodes
  MAJ_FAC: NotificationsCodes
  MAJ_DOS: NotificationsCodes
  CREA_DOS: NotificationsCodes
}

export interface NotificationsCodes {
  [CodeContact.CODE_BAL_ENTREPRISE]: number // GL
  [CodeContact.CODE_BAL_SERVICE]: number // SV
  [CodeContact.CODE_BAL_GESTIONNAIRE]: number // GE
}
