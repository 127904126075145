import React from 'react'
import { TabsComponent } from 'plateforme/components'
import { TabDefinition } from 'plateforme/components/tabs/TabsComponent'
import useTabNavigate from 'plateforme/hooks/useTabNavigate'
import { LoadingProps } from 'plateforme/services/utils'
import Referentiel from 'plateforme/store/types/referentiel'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import EditionDocumentsDossierEntreprisePart from 'assureur/parts/editionDocuments/EditionDocumentsDossierEntreprisePart'
import ProfilEntreprise from 'assureur/store/types/profilEntreprise'
import { useSelector } from 'react-redux'
import { getDataErrorDossier } from 'assureur/store/slices/dossier/errorDossierSlice'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import InformationsDossierForm from '../InfomationsDossierForm'
import PersonneAExaminerAContacterForm from '../PersonneAExaminerAContacterForm'
import InformationsAssureForm from '../InformationsAssureForm'
import ExpertiseEtLieuExpertiseForm from '../ExpertiseEtLieuExpertiseForm'

export type EditionDossierEntreprisePartProps = {
  dossier?: DossierEntreprise
  profilEntreprise?: ProfilEntreprise
  profilUtilisateur?: ProfilUtilisateurActif
  referentiel?: Referentiel
}

export enum TabId {
  INFORMATIONS_TAB = 'informations',
  PERSONNES_TAB = 'personnes',
  EXPERTISE_TAB = 'expertise',
  ASSURE_TAB = 'assure',
  DOCUMENTS_TAB = 'documents',
}

const tabErrorNodes = {
  tabInformations: [
    'refVictimeEntreprise',
    'refDossierEntreprise',
    'evenement',
    'gestionnaire',
    'contactEntreprise',
    'serviceEntreprise',
  ],
  tabPersonnes: ['personneAExaminer', 'personneAContacter'],
  tabExpertise: ['expertise'],
  tabAssure: ['assure'],
  tabDocuments: ['documents'],
}

export default function EditionDossierEntreprisePart({
  dossier,
  profilEntreprise,
  profilUtilisateur,
  referentiel,
  loading,
}: EditionDossierEntreprisePartProps & LoadingProps) {
  const { navigateTab } = useTabNavigate()

  const dataErrorDossier = useSelector(getDataErrorDossier)

  const getDossierErrorSet = (): Set<string | undefined> => {
    if (dataErrorDossier?.details instanceof Array) {
      return new Set(
        dataErrorDossier.details
          .flatMap((e) => e?.fields)
          .map((s?: string) => {
            const indexOfDelemeter = s?.indexOf('.')
            if (indexOfDelemeter !== -1) {
              return s?.substring(0, s?.indexOf('.'))
            }
            return s
          })
      )
    }
    if (dataErrorDossier?.details !== null) {
      return new Set(
        dataErrorDossier?.details?.fields?.map((s?: string) => {
          const indexOfDelemeter = s?.indexOf('.')
          if (indexOfDelemeter !== -1) {
            return s?.substring(0, s?.indexOf('.'))
          }
          return s
        })
      )
    }

    return new Set()
  }

  const dossierErrorSet = getDossierErrorSet()

  const tabs = ([] as TabDefinition[])
    .concat([
      {
        id: TabId.INFORMATIONS_TAB,
        title: 'Informations / références dossier',
        isError: tabErrorNodes.tabInformations?.some((errorNode) => dossierErrorSet.has(errorNode)),
        component: (
          <InformationsDossierForm
            dossier={dossier}
            profilEntreprise={profilEntreprise}
            loading={loading}
            referentiel={referentiel}
            onContinue={() => {
              navigateTab(TabId.EXPERTISE_TAB)
            }}
          />
        ),
      },
      {
        id: TabId.EXPERTISE_TAB,
        title: 'Expertise',
        isError: tabErrorNodes.tabExpertise?.some((errorNode) => dossierErrorSet.has(errorNode)),
        component: dossier && profilEntreprise && (
          <ExpertiseEtLieuExpertiseForm
            dossier={dossier}
            profilEntreprise={profilEntreprise}
            referentiel={referentiel}
            loading={loading}
            onContinue={() => {
              navigateTab(TabId.PERSONNES_TAB)
            }}
          />
        ),
      },
      {
        id: TabId.PERSONNES_TAB,
        title: 'Personne à examiner / contact',
        isError: tabErrorNodes.tabPersonnes?.some((errorNode) => dossierErrorSet.has(errorNode)),
        component: (
          <PersonneAExaminerAContacterForm
            dossier={dossier}
            profilEntreprise={profilEntreprise}
            referentiel={referentiel}
            loading={loading}
            onContinue={() => {
              navigateTab(profilEntreprise?.parametrage?.assure ? TabId.ASSURE_TAB : TabId.DOCUMENTS_TAB)
            }}
          />
        ),
      },
      profilEntreprise?.parametrage?.assure
        ? {
            id: TabId.ASSURE_TAB,
            title: 'Informations assuré',
            isError: tabErrorNodes.tabAssure?.some((errorNode) => dossierErrorSet.has(errorNode)),
            component: dossier && (
              <InformationsAssureForm
                dossier={dossier}
                loading={loading}
                onContinue={() => {
                  navigateTab(TabId.DOCUMENTS_TAB)
                }}
              />
            ),
          }
        : undefined,
      {
        id: 'documents',
        title: 'Documents du dossier',
        isError: tabErrorNodes.tabDocuments?.some((errorNode) => dossierErrorSet.has(errorNode)),
        component: (
          <EditionDocumentsDossierEntreprisePart
            dossier={dossier}
            profilActif={profilUtilisateur}
            loading={loading}
            isError={tabErrorNodes.tabDocuments?.some((errorNode) => dossierErrorSet.has(errorNode))}
            displayLeaveButton
          />
        ),
      },
    ] as TabDefinition[])
    .filter((t) => t !== undefined)

  return <TabsComponent withNavigation tabs={tabs} />
}
