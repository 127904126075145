import * as React from 'react'
import { MenuIcon } from 'plateforme/components'
import MenuBaseButton, { MenuBaseButtonProps } from 'plateforme/components/menu/MenuBaseButton'

export default function MobileMenuButton(props: MenuBaseButtonProps) {
  const { icon, tooltip, sx: otherSx, ...other } = props
  return (
    <MenuBaseButton
      icon={icon ?? <MenuIcon color="primary" fontSize="small" />}
      tooltip={tooltip ?? 'Menu'}
      sx={{ minWidth: '36px', padding: 0, ...otherSx }}
      {...other}
    />
  )
}
