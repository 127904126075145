import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Alert, Stack } from '@mui/material'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import DocumentsPart from 'plateforme/parts/DocumentsPart'
import { errorNarrowed } from 'plateforme/services/utils'
import { determineDocumentsDossier } from 'plateforme/services/dossier.services'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import { DocumentPanelType } from 'plateforme/store/types/documentUpload'
import { ReferentielTypeDocument } from 'plateforme/store/types/referentiel'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import { useDeleteDocumentDossierMutation } from 'assureur/store/apis/dossierAssureurApi'
import { consultationDossierHref } from 'assureur/EntrepriseApp'
import EditionDossierEntrepriseButtons from 'assureur/parts/editionDossier/EditionDossierEntrepriseButtons'
import { dossierDocumentDeposable } from 'assureur/services/rolesEntreprise.services'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'

type TabDocumentsPartProps = {
  dossier?: DossierEntreprise
  profilActif?: ProfilUtilisateurActif
  loading?: boolean
  isError?: boolean
  displayLeaveButton?: boolean
}

export default function EditionDocumentsDossierEntreprisePart({
  dossier,
  profilActif,
  loading,
  isError,
  displayLeaveButton = false,
}: TabDocumentsPartProps) {
  // props:
  const { code: codeDossier } = dossier || {}

  // hooks:
  const [deleteDocument, { isLoading: isLoadingDelete }] = useDeleteDocumentDossierMutation()
  const { enqueueSnackbar } = useSnackbar()
  const { confirm, confirmDialog } = useConfirmDialog()
  const navigate = useNavigate()

  // format:
  const documents = determineDocumentsDossier(dossier)
  const typeDocumentFilter = (ref: ReferentielTypeDocument) =>
    (ref.tags.includes('DOSSIER_ANNEXE') || ref.tags.includes('MISSION_ANNEXE')) &&
    ref.tags.includes('ENTREPRISE') &&
    (ref.code !== 'LM' || dossier?.lettreMissionDeposable === true)

  const readOnly = !dossierDocumentDeposable(dossier, profilActif as ProfilUtilisateurActif)

  // leave:
  const onLeave = () => {
    if (!codeDossier) {
      navigate('/')
      return
    }
    navigate(consultationDossierHref(codeDossier))
  }

  // submit dialog form:
  const onDeleteDocument = async (doc: DocumentTeleverse) => {
    return confirm({
      maxWidth: 'lg',
      confirmMsg: `Êtes-vous sûr de vouloir supprimer le document «${doc.nomDocument}» ?`,
      variant: 'delete',
      onYes: async () => {
        const codeDocument = doc.code
        if (!codeDossier || !codeDocument) {
          throw new Error('erreur inattendu')
        }
        await deleteDocument?.({
          codeDossier,
          codeDocument,
        })
          .unwrap()
          .then(() => enqueueSnackbar('Le document a été supprimé avec succès', { variant: 'success' }))
          .catch((error: FetchBaseQueryError | SerializedError | undefined) => {
            const errorValiderNarrowed = errorNarrowed(true, error)
            if (errorValiderNarrowed) {
              errorValiderNarrowed?.details?.map(({ message }) => enqueueSnackbar(message, { variant: 'error' }))
            } else {
              enqueueSnackbar('La suppression du document a échoué ', { variant: 'error' })
            }
          })
      },
    })
  }

  // rendu:
  return (
    <Stack>
      {confirmDialog}
      <DocumentsPart
        title="Nouveaux documents"
        buttonText="Ajouter des documents au dossier"
        dossier={dossier}
        documents={documents}
        loading={loading}
        typeDocumentPanel={DocumentPanelType.DOSSIER_ENTREPRISE}
        typeDocumentFilter={typeDocumentFilter}
        readOnly={readOnly}
        onDeleteDocument={onDeleteDocument}
        isLoadingDelete={isLoadingDelete}
      />
      {displayLeaveButton && dossier?.editable && (
        <>
          {isError && documents && documents.length === 0 && (
            <Alert severity="error" elevation={1} sx={{ width: '100%' }}>
              Un document est requis
            </Alert>
          )}
          <EditionDossierEntrepriseButtons dossier={dossier} onLeave={onLeave} variant="onlyLeave" />
        </>
      )}
    </Stack>
  )
}
