import React from 'react'
import { ReferentielMedecinAdmin } from 'admin/store/types/referentielEntrepriseAdmin'
import { useGetAdminReferentielMedecinQuery } from 'admin/store/apis/gestionMedecinApi'
import SelectInputWithQuery, { SelectInputWithQueryProps } from './SelectInputWithQuery'
import { SelectOption } from './SelectInput'

type SelectReferentielProps = Omit<SelectInputWithQueryProps<ReferentielMedecinAdmin[]>, 'useQuery' | 'dataTransformer'>

export default function SelectInputMedecinAdmin({ ...restProps }: SelectReferentielProps) {
  const dataTransformer = (data: ReferentielMedecinAdmin[]) => {
    return data.map((ref) => {
      return { code: ref.code, label: ref.libelle } as SelectOption
    })
  }
  return (
    <SelectInputWithQuery
      {...restProps}
      orderBy="label"
      useQuery={useGetAdminReferentielMedecinQuery}
      dataTransformer={dataTransformer}
    />
  )
}
