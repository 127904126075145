import * as React from 'react'
import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import BackdropComponent from 'plateforme/components/progress/BackdropComponent'
import { useGetUtilisateurActifQuery } from '../store/apis/utilisateurApi'
import { useAppDispatch, useAppSelector } from '../store/hooks/hooks'
import { chooseProfil, getProfilSelectionne, reset } from '../store/slices/profilSlice'
import { ProfilUtilisateurActif } from '../store/types/utilisateur'
import ForbiddenPage from '../pages/ForbiddenPage'
import ErrorPage from '../pages/ErrorPage'
import { profilHref } from '../App'
import { rootUtils } from '../store/store'

function isAuthorized(roles: string[], profilActif?: ProfilUtilisateurActif) {
  return profilActif && roles.some((role) => profilActif?.role?.value === role)
}

export default function RequireAuthInterceptor({ children, roles = [] }: { children: JSX.Element; roles?: string[] }) {
  const { data: utilisateur, error, isLoading, isFetching, isError, isSuccess, refetch } = useGetUtilisateurActifQuery()
  const profilSelectionne = useAppSelector(getProfilSelectionne)

  const dispatch = useAppDispatch()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const profilPreselectionne = params.get('profil')
  const profilActif = profilPreselectionne !== null ? undefined : utilisateur?.profilActif

  useEffect(() => {
    // Dans le cas ou il n'y a qu'un profil éligible et qu'aucun profil n'est sélectionné
    // Le choix du profil est positionné dans le store du profil
    // Cette étape n'est pas obligatoire pour le fonctionnement
    if (profilActif && !profilSelectionne) {
      dispatch(chooseProfil(profilActif?.role?.id))
      refetch()
      rootUtils.forEach((util) => dispatch(util.resetApiState()))
    } else if (profilPreselectionne) {
      dispatch(reset())
    }
  }, [profilActif, profilSelectionne, profilPreselectionne, dispatch, refetch])

  if (!isLoading && !isFetching && isSuccess) {
    // Si un utilisateur est identifié mais qu'aucun profil n'est sélectionné
    // --> alors on redirige sur la page de sélection du profil
    if (utilisateur && !profilActif) {
      // supprime le query param profil pour éviter les boucles infinies
      // if (profilPreSelectionne) {
      //   location.search = location.search.replace(`profil=${params.get('profil')}`, '')
      //   return <Navigate to={`${profilHref}?profil=${profilPreSelectionne}`} state={{ from: location }} replace />
      // }
      // location.search = location.search.replace(`profil=${params.get('profil')}`, '')
      return <Navigate to={profilHref} state={{ from: location }} replace />
    }
    if (isAuthorized(roles, profilActif)) {
      // Si un utilisateur est autorisé à accéder à cette ressource (en regardant les rôles du profil sélectionné)
      // --> alors on affiche la page demandée
      return children
    }
    // Sinon la page Forbidden sera affichée
    return <ForbiddenPage />
  }
  if (isError) {
    return <ErrorPage cause={error} />
  }

  return <BackdropComponent />
}
