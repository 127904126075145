import React, { useEffect } from 'react'
import { Chip, Grid, IconButton, Stack } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { Delete } from '@mui/icons-material'
import { useAppDispatch } from 'plateforme/store/hooks/hooks'
import { UploadState } from 'plateforme/store/slices/uploadSlice'
import { DatePickerInput, EditTextField, ReadOnlyTextField, SelectInputReferentiel } from 'plateforme/components'
import CircularProgressWithLabel from 'plateforme/components/progress/CircularProgressWithLabel'
import { toHumanReadableFileSize } from 'plateforme/services/utils'
import { formatDateISO } from 'plateforme/services/dates.services'
import { useSnackbar } from 'notistack'
import useErrorFormMapper, {
  IQueryErrorDetailsResponse,
  IQueryErrorResponse,
  IValidationErrorRepsonse,
} from 'plateforme/hooks/useErrorFormMapper'
import { ReferentielTypeDocument } from 'plateforme/store/types/referentiel'
import { DocumentUpload } from 'plateforme/store/types/documentUpload'
import { ALLOWED_UPLOAD_FORMATS } from 'plateforme/constantes'

type FormUploadRowProps = {
  changeDocumentUpload: (payload: DocumentUpload) => {
    payload: DocumentUpload
    type: string
  }

  removeDocumentUpload: (payload: string) => {
    payload: string
    type: string
  }

  setDocumentUploadValid: (payload: { key: string; isValid: boolean }) => {
    payload: {
      key: string
      isValid: boolean
    }
    type: string
  }
  documentUpload: DocumentUpload
  disabled?: boolean
  typeDocumentFilter?: (ref: ReferentielTypeDocument) => boolean
}

export default React.memo(FormUploadRow)

function FormUploadRow({
  changeDocumentUpload,
  removeDocumentUpload,
  setDocumentUploadValid,
  documentUpload,
  disabled: disabledFromParent,
  typeDocumentFilter,
}: FormUploadRowProps) {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { handleSubmit, control, setError, getValues } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      dateDocument: documentUpload.dateDocument ? new Date(documentUpload.dateDocument) : null,
      nomDocument: documentUpload.nomDocument,
      typeDocument: documentUpload.typeDocument ?? '',
    },
  })

  const onChange = (newState: DocumentUpload) => {
    handleSubmit(
      (_data) => dispatch(changeDocumentUpload({ ...newState, isValid: true })),
      (_errors) => dispatch(changeDocumentUpload({ ...newState, isValid: false }))
    )()
  }
  const onDateDocumentChange = (date: Date | null) => {
    if (date && !Number.isNaN(date.getTime())) {
      onChange({
        ...documentUpload,
        dateDocument: formatDateISO(date) ?? '',
      })
    }
  }
  const onNomDocumentChange = (value: string) =>
    onChange({
      ...documentUpload,
      nomDocument: value,
    })
  const onTypeDocumentChange = (typeDocument: string) =>
    onChange({
      ...documentUpload,
      typeDocument,
    })
  const onRemove = () => dispatch(removeDocumentUpload(documentUpload.key))

  const disabled = disabledFromParent || documentUpload.state === UploadState.DONE

  let errorDetails: IQueryErrorDetailsResponse[] = []
  if (documentUpload?.error?.details instanceof Array) {
    errorDetails = documentUpload?.error?.details as IQueryErrorDetailsResponse[]
  } else if (documentUpload?.error?.details !== null) {
    errorDetails =
      documentUpload?.error?.details === undefined ? [] : [documentUpload?.error?.details as IQueryErrorDetailsResponse]
  }

  useErrorFormMapper(
    {
      status: documentUpload?.error?.status,
      data: {
        details: errorDetails,
      } as IValidationErrorRepsonse,
    } as IQueryErrorResponse,
    setError,
    getValues
  )

  useEffect(() => {
    const status = documentUpload?.error?.status
    if (status === 500) {
      enqueueSnackbar(`${documentUpload?.error?.message}`, {
        variant: 'error',
      })
    }
  }, [documentUpload?.error, enqueueSnackbar])

  /* useEffect(() => {
   if (globalErrors.length > 0) {
     globalErrors.forEach((error) =>
       enqueueSnackbar(error.message, {
         variant: 'error',
       })
     )
    }
  }, [globalErrors, enqueueSnackbar, setError]) */

  useEffect(() => {
    handleSubmit(
      (_data) => dispatch(setDocumentUploadValid({ key: documentUpload.key, isValid: true })),
      (_errors) => dispatch(setDocumentUploadValid({ key: documentUpload.key, isValid: false }))
    )()
  }, [handleSubmit, dispatch, documentUpload.key, setDocumentUploadValid])

  const determineFormat = (format?: string) => {
    return Object.entries(ALLOWED_UPLOAD_FORMATS)
      .filter((e) => e[0] === format)
      .map((e) => e[1])
      .filter((v) => v && v.length > 0)
      .map((v) => v[0])
      .map((v) => v.replace('.', ''))
      .map((v) => v.toUpperCase())
  }

  return (
    <form>
      <Grid container>
        <Grid item xs={2}>
          <ReadOnlyTextField fullWidth label="Nom du fichier" value={documentUpload.nomFichier} disabled={disabled} />
        </Grid>

        <Grid item xs={2}>
          <ReadOnlyTextField
            fullWidth
            label="Format / Taille"
            value={`${determineFormat(documentUpload.formatFichier) ?? '--'} / ${toHumanReadableFileSize(
              documentUpload.tailleFichier
            )}`}
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={2}>
          <Controller
            name="dateDocument"
            control={control}
            render={({ field: { onBlur, onChange: onChangeRaw, value }, fieldState: { error } }) => (
              <DatePickerInput
                label="Date du document"
                InputProps={{
                  id: 'id-date-document',
                  fullWidth: true,
                }}
                value={value}
                onBlur={onBlur}
                onChange={(...args) => {
                  onChangeRaw(...args)
                  onDateDocumentChange(args[0])
                }}
                disabled={disabled}
                fieldError={error}
              />
            )}
          />
        </Grid>

        <Grid item xs={2}>
          <Controller
            name="nomDocument"
            control={control}
            render={({ field: { ref: _, ...fields }, fieldState: { error } }) => {
              return (
                <EditTextField
                  {...fields}
                  fullWidth
                  label="Nom du document"
                  onBlur={(e) => onNomDocumentChange(e.target.value)}
                  disabled={disabled}
                  fieldError={error}
                />
              )
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <Controller
            name="typeDocument"
            control={control}
            render={({ field: { ref: _, onChange: onChangeRaw, ...fields }, fieldState: { error } }) => (
              <SelectInputReferentiel
                {...fields}
                fullWidth
                id="typeDocument"
                label="Type"
                referentielName="typesDocument"
                dataFilter={typeDocumentFilter}
                onChangeRaw={onChangeRaw}
                onChange={onTypeDocumentChange}
                disabled={disabled}
                fieldError={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={2} alignItems="center">
          <Stack direction="row" alignItems="top" marginTop={1.5}>
            {!disabled && documentUpload.state === UploadState.IDLE && (
              <IconButton color="error" onClick={onRemove}>
                <Delete />
              </IconButton>
            )}
            {documentUpload.state === UploadState.IN_PROGRESS && (
              <CircularProgressWithLabel value={documentUpload.progress} />
            )}
            {documentUpload.state === UploadState.DONE && !documentUpload.error && (
              <Chip label="succès" color="success" />
            )}
            {documentUpload.error && <Chip label="erreur" color="error" />}
          </Stack>
        </Grid>
      </Grid>
    </form>
  )
}
