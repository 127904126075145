import React, { useEffect } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { Alert } from '@mui/material'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import { AreaLoading } from 'plateforme/components'
import { FallbackError } from 'plateforme/components/dialog/ErrorFallback'
import { formatDateTimeFR } from 'plateforme/services/dates.services'
import { LoadingProps } from 'plateforme/services/utils'
import PageRecherche from 'plateforme/store/types/pageRecherche'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import { useGetListeMessagesQuery, usePostMarquerMessagesLusMutation } from 'medecin/store/apis/messageMedecinApi'
import { MessageMedecin } from 'medecin/store/types/messageMedecin'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import TableauNotificationsPart from 'plateforme/parts/TableauNotificationsPart'
import { TypePartenaire } from 'plateforme/store/types/utilisateur'
import { dernieresNotificationsNonLuesParDossierFiltre } from 'medecin/services/messageMedecin.services'

interface NotificationSyntheseProps {
  dossier?: DossierMedecin
}

export default function NotificationSynthesePart({ dossier, loading }: NotificationSyntheseProps & LoadingProps) {
  const handleError = useErrorHandler()
  const filter = dernieresNotificationsNonLuesParDossierFiltre(dossier?.code ?? '')
  const {
    data: messages,
    isSuccess: isSuccessMessages,
    isLoading: isLoadingMessages,
    isError: isErrorMessages,
    error: errorMessages,
  } = useGetListeMessagesQuery({ filter, perPage: 10 }, { skip: !dossier?.code })

  if (isErrorMessages) {
    handleError({
      errorApi: errorMessages,
      title: 'Erreur chargement des notifications',
      api: 'useGetListeMessagesQuery',
    } as FallbackError)
  }
  // chargement:
  if (loading || isLoadingMessages) {
    return <AreaLoading height={148} />
  }

  if (!dossier || !messages || messages.totalItems === 0) {
    return null
  }

  return <NotificationSynthesePartLoaded dossier={dossier} messages={messages} isSuccessMessages={isSuccessMessages} />
}

function NotificationSynthesePartLoaded({
  dossier,
  messages,
  isSuccessMessages,
}: NotificationSyntheseProps & { messages: PageRecherche<MessageMedecin>; isSuccessMessages: boolean }) {
  const [marquerMessagesLus] = usePostMarquerMessagesLusMutation()
  useEffect(() => {
    if (isSuccessMessages) {
      marquerMessagesLus({ codeDossier: dossier?.code ?? '' })
    }
  }, [marquerMessagesLus, dossier, isSuccessMessages, messages])

  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()

  // rendu:
  return (
    <>
      {confirmDialog}
      <Alert
        sx={{ backgroundColor: '#dce5fc', color: '#6474B1', cursor: 'pointer' }}
        icon={<NotificationsActiveIcon sx={{ color: '#6474B1' }} />}
        onClick={() =>
          confirm({
            title: 'Nouvelle(s) Notification(s)',
            confirmMsg: '',
            maxWidth: 'lg',
            fullWidth: true,
            withForm: true,
            form: (
              <TableauNotificationsPart
                typePartenaire={TypePartenaire.Medecin}
                notifications={messages}
                onClose={closeConfirmDialog}
              />
            ),
          })
        }
      >
        <div style={{ cursor: 'pointer' }}>
          {messages.totalItems === 1
            ? `${formatDateTimeFR(messages.items[0].date)} : ${messages.items[0].labelType}`
            : `Vous avez ${messages.totalItems} nouvelle(s) notification(s) sur ce dossier depuis votre dernière connexion`}
        </div>
      </Alert>
    </>
  )
}
