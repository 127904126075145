import React from 'react'

import { useGetMedecinsPartenaireQuery } from 'assureur/store/apis/medecinEntrepriseApi'
import ReferentielMedecinAdmin from 'admin/store/types/referentielMedecinAdmin'
import { useGetReferentielAdminMedecinQuery } from 'admin/store/apis/gestionMedecinApi'
import HabilitationEntreprise from 'assureur/store/types/habilitationEntreprise'
import SelectInputWithQuery, { SelectInputWithQueryProps } from './SelectInputWithQuery'
import { SelectOption } from './SelectInput'

type SelectReferentielProps = Omit<SelectInputWithQueryProps<ReferentielMedecinAdmin[]>, 'useQuery' | 'dataTransformer'>

export default function SelectInputMedecin({ isAdmin = false, ...restProps }: SelectReferentielProps) {
  const dataTransformer = (data: ReferentielMedecinAdmin[] | HabilitationEntreprise[]) => {
    return data.map((ref) => {
      return { code: ref.code, label: ref.libelle } as SelectOption
    })
  }

  return isAdmin ? (
    <SelectInputWithQuery
      {...restProps}
      orderBy="label"
      useQuery={useGetReferentielAdminMedecinQuery}
      dataTransformer={dataTransformer}
    />
  ) : (
    <SelectInputWithQuery
      {...restProps}
      orderBy="label"
      useQuery={useGetMedecinsPartenaireQuery}
      dataTransformer={dataTransformer}
    />
  )
}
