import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material'
import { EditTextField } from 'plateforme/components'
import {
  TypeRemissionementAutoCarence,
  TypeRemissionnementAutoConclusionProvisoire,
} from 'assureur/store/types/profilEntreprise'
import { validateRequiredOptionWithCondition } from 'plateforme/services/utils'

interface GestionRemissionnementPartProps {
  readOnly?: boolean
}

export default function GestionRemissionnementPart({ readOnly }: GestionRemissionnementPartProps) {
  const { clearErrors, control, resetField, setValue, watch, trigger } = useFormContext()

  const remissionnementCarenceEnabledName = 'parametrage.remissionnementCarence.enabled'
  const remissionnementCarenceTypeAutoName = 'parametrage.remissionnementCarence.typeAuto'
  const remissionnementCarenceEnabled = watch(remissionnementCarenceEnabledName)

  const remissionnementConclusionProvisoireEnabledName = 'parametrage.remissionnementConclusionProvisoire.enabled'
  const remissionnementConclusionProvisoireTypeAutoName = 'parametrage.remissionnementConclusionProvisoire.typeAuto'
  const remissionnementConclusionProvisoireTauxAIPPName = 'parametrage.remissionnementConclusionProvisoire.tauxAIPP'
  const remissionnementConclusionProvisoireEnabled = watch(remissionnementConclusionProvisoireEnabledName)
  const remissionnementConclusionProvisoireTypeAuto = watch(remissionnementConclusionProvisoireTypeAutoName)

  function clearRemissionnementCarence() {
    resetField(remissionnementCarenceTypeAutoName)
    clearErrors(remissionnementCarenceTypeAutoName)
    setValue(remissionnementCarenceTypeAutoName, null, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  function clearRemissionnementConclusionProvisoire() {
    resetField(remissionnementConclusionProvisoireTypeAutoName)
    clearErrors(remissionnementConclusionProvisoireTypeAutoName)
    setValue(remissionnementConclusionProvisoireTypeAutoName, null, {
      shouldValidate: true,
      shouldDirty: true,
    })
    resetField(remissionnementConclusionProvisoireTauxAIPPName)
    clearErrors(remissionnementConclusionProvisoireTauxAIPPName)
    setValue(remissionnementConclusionProvisoireTauxAIPPName, '', {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  return (
    <Card>
      <CardHeader title="Gestion de remissionnement" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Controller
              name={remissionnementCarenceEnabledName}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="parametrage-remissionnement-carence-enabled"
                    value={value}
                    control={
                      <Switch
                        onChange={(e) => {
                          onChange(e)
                          clearRemissionnementCarence()
                          trigger('parametrage')
                        }}
                        disabled={readOnly}
                        onBlur={onBlur}
                        checked={value}
                      />
                    }
                    label="Remissionnement automatique suite carence"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          {remissionnementCarenceEnabled && (
            <Grid item xs={12} display="flex">
              <Controller
                name={remissionnementCarenceTypeAutoName}
                control={control}
                rules={{ required: validateRequiredOptionWithCondition(remissionnementCarenceEnabled) }}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <FormControl
                    disabled={readOnly}
                    error={!!error}
                    variant="standard"
                    sx={{ minHeight: 55, padding: 0, marginX: 1.5, display: 'flex', alignItems: 'flex-start' }}
                  >
                    <RadioGroup
                      id="parametrage-remissionnement-carence-type-auto"
                      row
                      aria-labelledby="radios-carence"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                    >
                      <FormControlLabel
                        value={TypeRemissionementAutoCarence.TOUJOURS}
                        control={<Radio />}
                        label="Toujours"
                        sx={{ minHeight: 55 }}
                      />
                      <FormControlLabel
                        value={TypeRemissionementAutoCarence.UNE_FOIS}
                        control={<Radio />}
                        label="Une seule fois"
                        sx={{ minHeight: 55 }}
                      />
                    </RadioGroup>
                    <FormHelperText sx={{ margin: '3px 14px' }}>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name={remissionnementConclusionProvisoireEnabledName}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="parametrage-remissionnement-conclusion-provisoire-enabled"
                    value={value}
                    control={
                      <Switch
                        onChange={(e) => {
                          onChange(e)
                          clearRemissionnementConclusionProvisoire()
                          trigger('parametrage')
                        }}
                        disabled={readOnly}
                        onBlur={onBlur}
                        checked={value}
                      />
                    }
                    label="Remissionnement automatique suite conclusions provisoires"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          {remissionnementConclusionProvisoireEnabled && (
            <>
              <Grid item xs={6} display="flex">
                <Controller
                  name={remissionnementConclusionProvisoireTypeAutoName}
                  control={control}
                  rules={{ required: validateRequiredOptionWithCondition(remissionnementConclusionProvisoireEnabled) }}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <FormControl
                      disabled={readOnly}
                      error={!!error}
                      variant="standard"
                      sx={{ minHeight: 55, padding: 0, marginX: 1.5, display: 'flex', alignItems: 'flex-start' }}
                    >
                      <RadioGroup
                        row
                        aria-labelledby="radios-rapport-provisoire"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                      >
                        <FormControlLabel
                          value={TypeRemissionnementAutoConclusionProvisoire.TOUJOURS}
                          control={<Radio />}
                          label="Toujours"
                          sx={{ minHeight: 55 }}
                        />
                        <FormControlLabel
                          value={TypeRemissionnementAutoConclusionProvisoire.TAUX_AIPP}
                          control={<Radio />}
                          label="En fonction du taux d'AIPP du rapport provisoire"
                          sx={{ minHeight: 55 }}
                        />
                      </RadioGroup>
                      <FormHelperText sx={{ margin: '3px 14px' }}>{error?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              {remissionnementConclusionProvisoireTypeAuto ===
                TypeRemissionnementAutoConclusionProvisoire.TAUX_AIPP && (
                <Grid item xs={6}>
                  <Controller
                    name={remissionnementConclusionProvisoireTauxAIPPName}
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <EditTextField
                        type="number"
                        fullWidth
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        fieldError={error}
                        readOnly={readOnly}
                        label="Taux strictement inférieur à (valeur numérique entière en %)"
                      />
                    )}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
