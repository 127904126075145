import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { DemandeModification } from 'plateforme/store/types/demandeModification'
import { StatutMission, StatutSuiviMission } from 'plateforme/store/types/mission'
import RapportConclusionMedecin from './rapportConclusionMedecin'
import { DossierRequest } from './dossierMedecin'
import Facture from './factureMedecin'
import { ReferenceMedecin } from './profilMedecin'

export default interface MissionMedecin {
  code?: string
  refMissionEntreprise?: string
  refMissionMedecin?: string
  refMissionAnterieure?: string
  libelle?: string
  medecin?: ReferenceMedecin
  statut?: StatutMission
  motifBlocage?: string
  labelStatut?: string
  statutSuivi?: StatutSuiviMission
  labelStatutSuivi?: string
  descriptionStatut?: string
  dateExamenPrevue?: Date
  commentaireRefus?: string
  commentaireCloture?: string
  rapport?: RapportConclusionMedecin
  factures?: [Facture]
  documents?: DocumentTeleverse[]
  associable?: boolean
  ficheGenerable?: boolean
  propositionTraitable?: boolean
  editable?: boolean
  carenceCreable?: boolean
  conclusionProvisoireCreable?: boolean
  conclusionDefinitiveCreable?: boolean
  rapportModifiable?: boolean
  facturable?: boolean
  dateProposition?: Date
  dateReponseMedecin?: Date
  dateCloture?: Date
  dateDemandeModification?: Date
  dateMiseAJour?: Date
  dateMiseAJourSuiviMission?: Date
  dateValidationMedecin?: Date
  demandesModifications: [DemandeModification]
}

export interface MissionRequest extends DossierRequest {
  codeMission: string
}

export interface SuiviMissionRequest extends MissionRequest {
  action: SuiviMissionMedecinAction
  motifBlocage?: string
  refMissionMedecin?: string
  commentaire?: string
  dateExamenPrevue?: string
}

export interface ModifierMissionMedecinRequest extends MissionRequest {
  refMissionMedecin: string
}

export interface ReferenceMission {
  code?: string
  refMissionEntreprise?: string
  refMissionMedecin?: string
  libelle?: string
}

export enum SuiviMissionMedecinAction {
  ACCEPTER = 'ACCEPTER',
  REFUSER = 'REFUSER',
  EN_COURS = 'EN_COURS',
  BLOQUER = 'BLOQUER',
  PLANIFIER_EXAMEN = 'PLANIFIER_EXAMEN',
}
