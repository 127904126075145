import * as React from 'react'
import { Grid } from '@mui/material'
import ReadOnlyTextField from 'plateforme/components/inputs/ReadOnlyTextField'
import { LieuExercice } from 'assureur/store/types/habilitationEntreprise'

type LieuExerciceMedecinTabProps = {
  index: number
  lieuExercice: LieuExercice
}

function LieuExerciceMedecinTab({ index, lieuExercice }: LieuExerciceMedecinTabProps) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <ReadOnlyTextField
          fullWidth
          rows={3}
          multiline
          id={`lieu-exercice-adresse-${index}`}
          value={lieuExercice.adresseComplete}
          label="Adresse du cabinet"
        />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyTextField
          fullWidth
          id={`lieu-exercice-telephone-${index}`}
          value={lieuExercice.telephone}
          label="Téléphone"
        />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyTextField
          fullWidth
          id={`lieu-exercice-siret-${index}`}
          value={lieuExercice.codeSIRET}
          label="Numéro de SIRET"
        />
      </Grid>
    </Grid>
  )
}

export default LieuExerciceMedecinTab
